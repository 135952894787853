// u0026 is &
// u2019 is ’
// (^|[\s\n]|<[A-Z]*\/?>) - match start of string or whitespace or newline or html tag
// ((https?|ftp):\/\/|www) - match optional http(s) or ftp or www
// [-A-Z0-9+\u0026\u2019@#/%?=()~_|!:,.;]* - This matches the main body of the URL, allowing for a variety of characters commonly found in URLs.
// [-A-Z0-9+\u0026@#/%=~()_|] - This ensures that the URL does not end with certain characters.
// ([A-Z0-9.-]+\.[A-Z]{2,}(?:\/[A-Z0-9+\u0026@#/%=~()_|.-]*)?(?:\?[A-Z0-9+\u0026@#/%=~()_|.-]*)?) - This matches the domain.
// (\b|$) - This ensures that the URL does not end with certain characters.
const URL_REGEX =
  /(^|[\s\n]|<[A-Z]*\/?>)(((https?|ftp):\/\/|www)[-A-Z0-9+\u0026\u2019@#/%?=()~_|!:,.;]*[-A-Z0-9+\u0026@#/%=~()_|]|([A-Z0-9.-]+\.[A-Z]{2,}(?:\/[A-Z0-9+\u0026@#/%=~()_|.-]*)?(?:\?[A-Z0-9+\u0026@#/%=~()_|.-]*)?))(\b|$)/gi

const EMAIL_REGEX =
  /(?<!<a[^>]+href=[^>]+>?)(?<!https?:\/\/)(?<!ftp:\/\/[A-Za-z0-9.-:]+)(?<!:\/\/)(\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,}\b)/gi

export default function hyperlink(text: string) {
  const textWithEmails = replaceEmails(text)
  return replaceUrls(textWithEmails)
}

function replaceEmails(text) {
  if (text.indexOf('@') !== -1) {
    return text.replace(EMAIL_REGEX, '<a href="mailto:$1">$1</a>')
  }
  return text
}

function urlWithProtocol(url: string) {
  if (url.startsWith('http')) return url
  if (url.startsWith('ftp')) return url

  return `http://${url}`
}

function replaceUrls(text) {
  return text.replace(URL_REGEX, (_match, space, url) => {
    const target = url.includes('mailto:') ? '' : 'target="_blank" rel="noopener noreferrer"'
    return `${space}<a href="${urlWithProtocol(url)}" ${target}>${url}</a>`
  })
}
