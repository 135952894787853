import { cn } from 'lib/util/cn'
import { SearchIcon } from 'lucide-react'
import { HTMLAttributes } from 'react'

const classNames = {
  container: `
    tw-flex tw-justify-center tw-relative
  `,
  input: `
    tw-block
    tw-w-full
    tw-ps-10
    tw-appearance-none
    tw-rounded-md
    tw-border
    tw-border-solid
    tw-border-neutral-200
    tw-p-2
    tw-shadow-none
    tw-outline-none
    tw-text-lg
    placeholder:tw-text-neutral-500
    focus:tw-ring-1
    focus:tw-ring-offset-1
    focus:tw-ring-cornflower-500
  `,
  iconContainer: `
    tw-absolute
    tw-inset-y-0
    tw-start-0
    tw-flex
    tw-items-center
    tw-ps-3
    tw-pointer-events-none
  `,
  icon: `
    tw-text-neutral-500
    tw-w-4
    tw-h-4
  `,
}

interface SearchProps {
  value: string
  onChange: (string: string) => void
  placeholder: string
  name?: string
  className?: HTMLAttributes<HTMLDivElement>['className']
}

export default function Search({ value, onChange, placeholder = 'Search', name = 'search', className }: SearchProps) {
  return (
    <div className={cn(classNames.container, className)}>
      <div className={classNames.iconContainer}>
        <SearchIcon className={classNames.icon} />
      </div>
      <input
        name={name}
        placeholder={placeholder}
        className={classNames.input}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
}
