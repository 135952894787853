import { ReactElement } from 'react'
import { useRequestContext } from '../providers/request-provider'
import HeaderActions from './header-actions'
import RequestName from './request-name'
import { TicketStatusBadge } from 'lib/components/badge/badge'
import HeaderDropdowns from './header-dropdowns'
import Breadcrumb from 'components/core/breadcrumb'
import HeaderRequestDetails from 'components/pages/request/request-header/header-request-details'

const classNames = {
  container: 'tw-flex tw-items-start tw-justify-between tw-px-3 tw-pb-3 tw-pt-4',
  rightColumn: 'tw-text-right',
}

function RequestDetails(): ReactElement {
  const { ticket, invalidFields, showValidationErrors, showRevisions } = useRequestContext()

  return (
    <div>
      <div className="tw-mb-2 tw-flex tw-items-center">
        <RequestName showValidationError={invalidFields?.includes('subject') && showValidationErrors} />
        <TicketStatusBadge status={ticket?.status} />
      </div>
      {!showRevisions && <HeaderDropdowns />}
      {showRevisions && <HeaderRequestDetails />}
    </div>
  )
}

export default function RequestHeader(): JSX.Element {
  return (
    <header>
      <Breadcrumb className="tw-ml-6 tw-mt-3" text="Back to all requests" href={`/requests`} />
      <div className={classNames.container}>
        <div>
          <RequestDetails />
        </div>
        <div className={classNames.rightColumn}>
          <HeaderActions />
        </div>
      </div>
    </header>
  )
}
