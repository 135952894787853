import Breadcrumb from 'components/core/breadcrumb'
import CreateDeliverables from 'components/pages/create/create-deliverables'
import CreateProjects from 'components/pages/create/create-projects'
import { AppSettings, CurrentUser } from 'interfaces/app'
import Badge from 'lib/components/badge/badge'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'lib/components/tabs'
import { Toaster } from 'lib/components/toast/toast'
import UserProvider from 'providers/user-provider'
import { useState } from 'react'

interface CreateProps {
  settings: AppSettings
  user: CurrentUser
}

export default function Create({ user, settings }: CreateProps) {
  const [tab, setTab] = useState<string>(new URLSearchParams(window.location.search).get('tab') || 'deliverables')

  return (
    <Tabs
      className="tw-mt-[var(--header-height,0px)] tw-h-[calc(100vh-var(--header-height,0px))] tw-w-screen tw-overflow-auto tw-p-5 tw-scrollbar-gutter-stable"
      defaultValue={tab}
    >
      <Toaster />
      <header>
        <Breadcrumb text="Back to requests / Create" href="/requests" />
        <div className="tw-flex tw-items-center tw-justify-between">
          <h2 className="tw-m-0 tw-mt-4 tw-justify-self-center">
            What are you looking to {tab === 'deliverables' ? 'create?' : 'accomplish?'}
          </h2>
          <TabsList className="tw-justify-self-end" intent="background">
            <TabsTrigger value="deliverables" intent="background">
              Deliverables
            </TabsTrigger>
            <TabsTrigger value="projects" intent="background">
              <div className="tw-mr-2">Projects</div>
              <Badge color={tab === 'projects' ? 'light' : 'primary'}>New</Badge>
            </TabsTrigger>
          </TabsList>
        </div>
      </header>
      <UserProvider settings={settings} user={user}>
        <TabsContent value="deliverables">
          <CreateDeliverables setTab={setTab} />
        </TabsContent>
        <TabsContent value="projects">
          <CreateProjects setTab={setTab} />
        </TabsContent>
      </UserProvider>
    </Tabs>
  )
}
