import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { ReactElement, useCallback, useEffect } from 'react'
import { Ticket } from 'interfaces/ticket'
import { updateCompanyDailyAvailableTimes } from 'lib/api/companies/company_daily_available_times'
import { useCompanyDailyAvailableTimesContext } from 'providers/company-daily-available-times-provider'
import { getHoursFromMinutes } from 'lib/util/date'

const classNames = {
  noTimeLeft: 'tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center',
  thinkingPickleImage: 'tw-w-full tw-h-auto',
  noTimeLeftText: 'tw-w-48 tw-text-center tw-px-2.5',
  timeToSpend: 'tw-font-bold tw-p-2  tw-pt-1 tw-h-5',
  dropdownOption: 'tw-w-full tw-p-2 tw-cursor-pointer hover:tw-bg-neutral-100',
  dropdownContent: 'tw-overflow-scroll tw-w-full',
}

export function getTextValue(value: number): string {
  if (!value) return 'Allocate Time'

  return getHoursFromMinutes(value)
}

function NoTimeLeft(): ReactElement {
  return (
    <div className={classNames.noTimeLeft}>
      <img
        src={'/images/icons/thinking_pickle.svg'}
        alt="Empty timeToSpend Image"
        className={classNames.thinkingPickleImage}
      />
      <div className={classNames.noTimeLeftText}>
        You have used all your available design time on other requests. To reallocate time, please adjust other requests
        first
      </div>
    </div>
  )
}

interface TimeToSpendDropdownProps {
  forceRenderOnTop?: boolean
  maxHeight?: string
  options?: number[]
  smallDropdown?: boolean
  ticket: Ticket
}

export default function TimeToSpendDropdown({
  forceRenderOnTop = false,
  maxHeight = '12.5rem',
  options = [],
  smallDropdown = false,
  ticket,
}: TimeToSpendDropdownProps): ReactElement {
  const { isPartialLoading, setIsPartialLoading, refreshTicketList, errors, resetErrors } =
    useCompanyDailyAvailableTimesContext()
  const { id, todaysWeightInMinutes, category } = ticket

  const oops = useCallback((err) => {
    const message = err?.message || 'Oops! Something went wrong. Please try again.'
    alert(message)
    console.error(err)
  }, [])

  useEffect(() => {
    if (errors?.length) {
      oops(errors[0])
      resetErrors()
    }
  })

  async function onOptionSelect(value: number) {
    if (isPartialLoading || value === todaysWeightInMinutes) return

    setIsPartialLoading(true)
    updateCompanyDailyAvailableTimes({ ticketId: id, value })
      .then(async () => {
        await refreshTicketList()
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message ||
          `Failed to update Company Daily Available times.\nYou might no longer have ${value} minutes left in the ${category} scope.`
        oops({ message, error })
        setIsPartialLoading(false)
      })
  }

  function DropdownContent(): ReactElement {
    if (options.length === 0) return <NoTimeLeft />

    return (
      <div className={classNames.dropdownContent} style={{ maxHeight }}>
        {!smallDropdown && <div className={classNames.timeToSpend}>Time to Spend</div>}
        <div className={classNames.dropdownOption} onClick={() => onOptionSelect(0)}>
          No Time
        </div>
        {options.map((option) => (
          <div key={option} className={classNames.dropdownOption} onClick={() => onOptionSelect(option)}>
            {getTextValue(option)}
          </div>
        ))}
      </div>
    )
  }

  return (
    <IconFlyoutMenu
      icon={['fal', 'pencil']}
      color="transparent"
      size="sm"
      forceRenderOnTop={forceRenderOnTop}
      adjustedRight={smallDropdown}
    >
      <DropdownContent />
    </IconFlyoutMenu>
  )
}
