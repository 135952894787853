import Button from 'components/core/button'
import TextInput from 'lib/components/text-input/text-input'
import { Filters } from './blocklists-page'

interface BlocklistsFiltersProps {
  filters: Filters
  handleClear: (e) => void
  onSubmit: (e) => void
  setFilters: (filters: Filters) => void
}

const classNames = {
  button: 'tw-my-2',
  filtersWrapper: 'tw-mb-4 tw-relative tw-flex tw-justify-between',
  separator: 'tw-px-2 tw-pt-2.5 tw-text-xl tw-text-peppercorn-100',
}

export default function BlocklistsFilters({ filters, handleClear, onSubmit, setFilters }: BlocklistsFiltersProps) {
  return (
    <form data-testid={'blocklists-filters-form'} onSubmit={onSubmit}>
      <div className={classNames.filtersWrapper}>
        <div className="tw-flex tw-w-80 tw-gap-4">
          <TextInput
            id="nameOrEmailEq"
            placeholder="Search by company name or email"
            onChange={(e) => setFilters({ ...filters, nameOrEmailCont: e.target.value })}
            value={filters.nameOrEmailCont}
          />
        </div>
        <div className="tw-flex tw-gap-4">
          <Button className={classNames.button} type="submit">
            Search
          </Button>
          <Button className={classNames.button} color="neutralGray" onClick={handleClear}>
            Clear
          </Button>
        </div>
      </div>
    </form>
  )
}
