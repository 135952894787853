import { ReactElement, useMemo } from 'react'
import IconButton from 'lib/components/buttons/icon-button'
import SelectBox from 'lib/components/dropdown/select-box'

interface MultipageNavigationProps {
  count: number
  index: number
  setIndex: (index: number | ((current: number) => void)) => void
}

const classNames = {
  button: 'tw-mx-1',
  container: 'tw-whitespace-nowrap tw-inline-block tw-flex tw-items-center',
  option: 'tw-mx-1 tw-flex-grow tw-text-right',
  page: 'tw-inline-block tw-px-2',
  select: 'tw-inline-block tw-mx-2 tw-w-20',
}

function createPageNumbers(count: number): number[] {
  return Array.from({ length: count }, (_, i) => i)
}

function DisplayPageNumber({ pageNumber }: { pageNumber: number }): ReactElement {
  return <div className={classNames.option}>{pageNumber + 1}</div>
}

export default function MultipageNavigation({ count, index, setIndex }: MultipageNavigationProps): ReactElement {
  const pageNumbers = useMemo(() => createPageNumbers(count), [count])

  function next() {
    if (index < count - 1) {
      setIndex((current) => current + 1)
    } else {
      setIndex(0)
    }
  }

  function prev() {
    if (index > 0) {
      setIndex((current) => current - 1)
    } else {
      setIndex(count - 1)
    }
  }

  const selectBoxOptions = pageNumbers.map((i) => ({
    displayElement: <DisplayPageNumber pageNumber={i} />,
    value: i.toString(),
  }))

  function handleSelectBoxChange(selectedValue: string) {
    setIndex(parseInt(selectedValue))
  }

  return (
    <div className={classNames.container}>
      <span className={classNames.page}>
        Page:
        <div className={classNames.select}>
          <SelectBox options={selectBoxOptions} handleChange={handleSelectBoxChange} selectedValue={index.toString()} />
        </div>
        of {count}
      </span>
      <IconButton className={classNames.button} color="secondary" icon={['fal', 'chevron-left']} onClick={prev} />
      <IconButton className={classNames.button} color="secondary" icon={['fal', 'chevron-right']} onClick={next} />
    </div>
  )
}
