import { ChangeEvent, ReactElement, ReactNode, useEffect, useRef } from 'react'

interface CheckboxProps {
  checked: boolean
  children?: ReactNode
  className?: string
  disabled?: boolean
  error?: boolean
  id?: string
  label?: string
  name?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  testId?: string
  indeterminate?: boolean
}

const classNames = {
  error: 'tw-ring-2 tw-ring-flushpink-500',
  label: 'tw-font-normal tw-m-0',
  noError: 'focus:tw-ring-2 focus:tw-ring-cornflower-500',
}

export default function Checkbox({
  checked,
  children,
  className,
  disabled,
  error = false,
  id,
  label,
  name,
  onChange,
  testId,
  indeterminate = false,
}: CheckboxProps): ReactElement {
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  return (
    <div className={`tw-flex tw-items-center ${className}`}>
      <label className={`${classNames.label} ${error ? classNames.error : classNames.noError}`}>
        <input
          ref={inputRef}
          type="checkbox"
          data-testid={testId}
          disabled={disabled}
          id={id}
          name={name}
          checked={checked}
          className="tw-m-1.5"
          onChange={onChange}
        />
        {children || label}
      </label>
    </div>
  )
}
