import { useMutation } from '@tanstack/react-query'
import { request } from 'lib/api/fetch-api'
import { queryClient } from 'providers/query-client-provider'
import { toast } from 'sonner'

export const useCreateFileExport = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void } = {}) => {
  const { mutate: createFileExport } = useMutation({
    mutationFn: ({ ticketFileId, selectedFormats }: { ticketFileId: number; selectedFormats: string[] }) =>
      Promise.all(
        selectedFormats.map((format) =>
          request({
            endpoint: 'createFileExport',
            query: { ticket_file_id: ticketFileId, output_format: format },
          }),
        ),
      ),
    onSuccess: () => {
      onSuccess?.()
      queryClient.invalidateQueries({ queryKey: ['file-exports'] })
      toast.success('File export created')
    },
    onError: () => {
      onError?.()
      toast.error('Error creating file export')
    },
  })

  return { createFileExport }
}
