import AdminFilters from 'components/elements/admin-filters'
import { Apm } from 'interfaces/apm'
import { Team } from 'interfaces/team'
import React from 'react'
import {
  ApmsFilter,
  CompanyNameFilter,
  CompanyStatusFilter,
  TeamsFilter,
  UnassignedFilter,
} from '../shared/subscriptions-filters'

interface ScaleSubscriptionsFiltersProps {
  apms: Apm[]
  companyStatusOptions: string[]
  fetchSubscriptions: () => void
  filters: ScaleFilters
  setFilters: (Filters) => void
  teams: Team[]
}

// Filter names are ransack matchers in camelCase
export interface ScaleFilters {
  companyNameCont: string
  companyStatusEq: string
  qrTeamIdEq: number
  qrTeamApmIdEq: number
  teamUnassigned: boolean
}

export const emptyScaleFilters: ScaleFilters = {
  companyNameCont: '',
  companyStatusEq: '',
  qrTeamApmIdEq: null,
  qrTeamIdEq: null,
  teamUnassigned: false,
}

export default function ScaleSubscriptionsFilters(props: ScaleSubscriptionsFiltersProps): JSX.Element {
  return (
    <AdminFilters
      emptyFilters={emptyScaleFilters}
      filters={props.filters}
      setFilters={props.setFilters}
      handleSubmit={props.fetchSubscriptions}
    >
      <CompanyNameFilter filters={props.filters} setFilters={props.setFilters} />
      <CompanyStatusFilter
        companyStatusOptions={props.companyStatusOptions}
        filters={props.filters}
        setFilters={props.setFilters}
      />
      <ApmsFilter apms={props.apms} filters={props.filters} filterKey="qrTeamApmIdEq" setFilters={props.setFilters} />
      <TeamsFilter apms={props.apms} teams={props.teams} filters={props.filters} setFilters={props.setFilters} />
      <UnassignedFilter filterKey="teamUnassigned" filters={props.filters} setFilters={props.setFilters} />
    </AdminFilters>
  )
}
