import { ReactElement } from 'react'
import TicketFileVersionsContainer from './ticket-file-versions-container'
import { useTicketFileVersionsContext } from './ticket-file-versions-provider'
import { hasOnlyEmptyValues } from 'lib/object/utils'
import AssetsEmptyState from './assets-empty-state'

const classNames = {
  h4: 'tw-ml-3.5',
  title: 'tw-py-2 tw-flex tw-w-full tw-bg-peppercorn-50',
}

function Title(): ReactElement {
  return (
    <div className={classNames.title}>
      <img src="/images/icons/file-magnifying-glass.svg" alt="magnifying glass" />
      <h4 className={classNames.h4}>Assets</h4>
    </div>
  )
}

export default function AssetsPageContainer(): ReactElement {
  const { isLoading, metadata, filters } = useTicketFileVersionsContext()

  const renderEmptyPage = !isLoading && metadata.total === 0 && hasOnlyEmptyValues(filters)

  return (
    <>
      <div className="tw-fixed">
        <Title />
      </div>
      {renderEmptyPage ? <AssetsEmptyState /> : <TicketFileVersionsContainer isGridView={true} />}
    </>
  )
}
