import { ReactElement, useMemo } from 'react'

import { CustomSize, SkillSize } from 'lib/api/skills/skills'

import { convertStringToCustomSize, getSizesTitle } from 'lib/util/skill-sizes/skill-sizes'
import SkillSizes from 'components/pages/request/skills/skill-sizes'
import { Popover } from '@headlessui/react'
import { ChevronDown, ChevronUp } from 'lucide-react'

interface AdminTicketSizeDropdownProps {
  skillSizes: SkillSize[]
  selectedSkillSizes: SkillSize[]
  selectedCustomSizes: string
  onChange: (sizes: { skillSize?: SkillSize[]; customSizes?: CustomSize[] }) => void
  label?: string
}

export default function AdminTicketSizeDropdown({
  skillSizes,
  selectedSkillSizes,
  selectedCustomSizes,
  onChange,
  label,
}: AdminTicketSizeDropdownProps): ReactElement {
  const sizeTitle = useMemo(() => {
    return getSizesTitle(selectedSkillSizes, selectedCustomSizes, 'Select Sizes')
  }, [selectedSkillSizes, selectedCustomSizes])

  return (
    <Popover as="div" className="tw-relative">
      {({ open }) => (
        <>
          {label && <div className="tw-mb-4">{label}</div>}
          <Popover.Button className="tw-flex tw-h-10 tw-w-full tw-items-center tw-justify-between tw-rounded-lg tw-border tw-border-solid tw-border-neutral-200 tw-bg-white tw-py-2 tw-pl-3 tw-text-sm tw-text-black focus:tw-ring focus:tw-ring-cornflower-500">
            <>
              {sizeTitle} {open ? <ChevronDown /> : <ChevronUp />}
            </>
          </Popover.Button>
          <Popover.Panel
            className="tw-absolute tw-left-0 tw-top-24 tw-z-30 tw-rounded-md tw-bg-white tw-p-8"
            style={{ boxShadow: '0 10px 50px -12px' }}
          >
            <SkillSizes
              skillSizes={skillSizes}
              onSelect={onChange}
              ticketSkillSizes={selectedSkillSizes}
              ticketCustomSizes={convertStringToCustomSize(selectedCustomSizes)}
            />
          </Popover.Panel>
          <Popover.Overlay />
        </>
      )}
    </Popover>
  )
}
