import { ReactElement } from 'react'
import GenAiBanner from './banners-gen-ai'
import { useGenAiContext } from '../providers/gen-ai-provider'

const className = {
  container: 'tw-px-5 tw-my-5',
}

export default function BannersList(): ReactElement {
  const { genAiRequests } = useGenAiContext()

  if (!genAiRequests.length) {
    return null
  }

  return (
    <div className={className.container}>
      {genAiRequests.map((item) => (
        <GenAiBanner key={item.id} request={item} />
      ))}
    </div>
  )
}
