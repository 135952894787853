import { createContext, ReactElement, ReactNode, useContext, useMemo, useRef, useState } from 'react'
import { AppSettings, CurrentUser } from 'interfaces/app'
import Login from 'components/elements/login/login'
import SignUp from 'components/elements/login/sign-up'
import ForgotPassword from 'components/elements/login/forgot-password'
import ResetPassword from 'components/elements/login/reset-password'
import CollaboratorAuthenticator, { NewUser } from 'lib/auth/collaborator-authenticator'
import { toast } from 'lib/components/toast/toast'

interface UserContextProps {
  allowCollaborators?: boolean
  children: ReactNode
  settings: AppSettings
  user: CurrentUser
}

type UserContextValue = {
  auth: {
    authenticate: (email: string, password: string) => Promise<boolean>
    authRoute: AuthRoute
    setAuthRoute: (route: AuthRoute) => void
  }
  create: (user: NewUser) => Promise<void>
  logout: () => void
  settings: AppSettings
  token: string
  user: CurrentUser
}

enum Routes {
  LOGIN,
  SIGNUP,
  FORGOTPASSWORD,
  RESETPASSWORD,
  APP,
  ERROR,
}

export enum AuthRoute {
  LOGIN = Routes.LOGIN,
  SIGNUP = Routes.SIGNUP,
  FORGOTPASSWORD = Routes.FORGOTPASSWORD,
  RESETPASSWORD = Routes.RESETPASSWORD,
}

const UserContext = createContext({})

export function useUserContext(): UserContextValue {
  return useContext(UserContext) as UserContextValue
}

function AuthRoutes({ authRoute }: { authRoute: AuthRoute }) {
  switch (authRoute) {
    case AuthRoute.SIGNUP:
      return <SignUp />
    case AuthRoute.FORGOTPASSWORD:
      return <ForgotPassword />
    case AuthRoute.RESETPASSWORD:
      return <ResetPassword />
    case AuthRoute.LOGIN:
    default:
      return <Login />
  }
}

export default function UserProvider({
  allowCollaborators = false,
  children,
  settings,
  user,
}: UserContextProps): ReactElement {
  const authenticator = useRef<CollaboratorAuthenticator>(new CollaboratorAuthenticator())
  const [authenticatedUser, setAuthenticatedUser] = useState<CurrentUser>(
    user || (allowCollaborators ? authenticator.current.user : null),
  )
  const queryParameters = new URLSearchParams(window.location.search)
  const [authRoute, setAuthRoute] = useState<AuthRoute>(() => {
    if (queryParameters.has('invitee')) {
      return AuthRoute.SIGNUP
    }
    if (queryParameters.has('change_password_token')) {
      return AuthRoute.RESETPASSWORD
    }
    return AuthRoute.LOGIN
  })
  const [token, setToken] = useState<string>(allowCollaborators ? authenticator.current.token : null)

  const route: Routes | AuthRoute = useMemo(() => {
    if (!authenticatedUser && !allowCollaborators) {
      return Routes.ERROR
    }

    if (!authenticatedUser && allowCollaborators) {
      return authRoute
    }

    return Routes.APP
  }, [authenticatedUser, allowCollaborators, authRoute])

  async function authenticate(email: string, password: string) {
    if (!allowCollaborators) {
      throw new Error('Collaborator authentication is not enabled.')
    }

    try {
      await authenticator.current.authenticate(email.toLowerCase(), password)
      setToken(authenticator.current.token)
      setAuthenticatedUser(authenticator.current.user)
      return true
    } catch (error) {
      toast.error('Invalid username or password')
      console.error('Collaborator could not be logged in.', error)
      return false
    }
  }

  async function create(user: NewUser) {
    try {
      await authenticator.current.create(user)
      setToken(authenticator.current.token)
      setAuthenticatedUser(authenticator.current.user)
    } catch (error) {
      if (error.status === 422 && error.response?.data?.email) {
        toast.error('We could not create an account with this email address.')
      } else {
        toast.error('We could not create your account. Please try again.')
      }
      console.error('Collaborator could not be created.', error)
    }
  }

  function logout() {
    setAuthenticatedUser(null)
  }

  const context: UserContextValue = {
    auth: {
      authenticate,
      authRoute,
      setAuthRoute,
    },
    create,
    logout,
    settings,
    token,
    user: authenticatedUser,
  }

  return (
    <UserContext.Provider value={context}>
      {route === Routes.ERROR && <div>Authentication Required</div>}
      {route !== Routes.APP && <AuthRoutes authRoute={authRoute} />}
      {route === Routes.APP && children}
    </UserContext.Provider>
  )
}
