import { baseRequest, BaseResponse, getApi } from '../api'
export interface Brand {
  id: number
  name: string
  colors: BrandColor[]
}
export interface BrandColor {
  id: number
  type: string
  value: string
}
export interface BrandsResponse extends BaseResponse {
  brands: Brand[]
}

const companiesBaseUrl = '/api/internal/companies'
export async function getCompanyBrands(companyId: number): Promise<BrandsResponse> {
  const url = `${companiesBaseUrl}/${companyId}/brands`
  const response = await baseRequest(url, getApi)

  return {
    error: response.error,
    status: response.status,
    brands: response.data as unknown as Brand[],
  }
}
