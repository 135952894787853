import Button from 'components/core/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SupportingDocumentation } from 'interfaces/supporting-documentation'
import SupportingDocumentationText from './supporting-documentation-text'
import { ChangeEvent } from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

interface EditableSupportingDocumentationProps {
  supportingDocumentation?: SupportingDocumentation
  editMode: boolean
  setEditMode: (editMode: boolean) => void
  newSupportingDocumentationBody: string
  setNewSupportingDocumentationBody: (body: string) => void
}

const classNames = {
  td: 'tw-py-2 tw-mx-3',
  textarea:
    'mr-3 px-2 py-1 tw-resize shadow-sm focus:ring-gherkin focus-visible:ring-gherkin block w-full sm:text-sm border-gray-300 rounded-md',
}

export default function EditableSupportingDocumentation({
  supportingDocumentation,
  editMode,
  newSupportingDocumentationBody,
  setNewSupportingDocumentationBody,
  setEditMode,
}: EditableSupportingDocumentationProps): JSX.Element {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNewSupportingDocumentationBody(e.target.value)
  }

  if (editMode) {
    return (
      <td className={classNames.td}>
        <textarea
          className={classNames.textarea}
          onChange={handleChange}
          value={newSupportingDocumentationBody}
          placeholder="Add supporting text and/or links"
        />
      </td>
    )
  }

  if (supportingDocumentation) {
    return (
      <td className={classNames.td}>
        <SupportingDocumentationText supportingDocumentation={supportingDocumentation} />
      </td>
    )
  }

  return (
    <td className={classNames.td}>
      <Button type="button" color="lightGray" size="cut" onClick={() => setEditMode(true)}>
        <FontAwesomeIcon icon={faPlus} className="tw-mr-1" /> Add Documentation
      </Button>
    </td>
  )
}
