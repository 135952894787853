import { AnimatedLoadingScreen, LoadingScreen } from 'components/pages/requests/empty-screens'
import { useEffect, useState } from 'react'

const PendoIframe = ({ src }: { src: string }) => {
  const [isPendoReady, setIsPendoReady] = useState(false)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    if (window?.pendo?.isReady()) {
      setIsPendoReady(true)
      return
    }

    const interval = setInterval(() => {
      if (window?.pendo?.isReady()) {
        setIsPendoReady(true)
        clearInterval(interval)
      }
    }, 100)

    const timeout = setTimeout(() => {
      if (!window?.pendo?.isReady()) {
        clearInterval(interval)
        setError(true)
      }
    }, 5000)

    return () => {
      clearInterval(interval)
      clearTimeout(timeout)
    }
  }, [])

  if (error) {
    return (
      <div className="tw-flex tw-h-[80vh] tw-items-center tw-justify-center">
        <AnimatedLoadingScreen
          variant="errorHole"
          text="Uh oh! Looks like we couldn't load the feedback form."
          subText="You can try refreshing the page."
        />
      </div>
    )
  }

  if (!isPendoReady) {
    return (
      <div className="tw-flex tw-h-[80vh] tw-items-center tw-justify-center">
        <LoadingScreen />
      </div>
    )
  }

  return (
    <iframe
      frameBorder="0"
      height="100%"
      id="productboard-iframe"
      src={src}
      style={{ width: '100%', height: '100%', overflow: 'auto', minHeight: '80vh' }}
    />
  )
}

export default PendoIframe
