export const colorStatus = (ticket) => {
  const states = {
    'open': 'status-color1',
    'assigned': 'status-color2',
    'incomplete': 'status-color3',
    'customer_clarification': 'status-color3',
    'in_progress': 'status-color4',
    'quality_review': 'status-color4',
    'customer_review': 'status-color1',
    'closed': 'status-color5',
  }

  ticket.statusColor = `atm-item-status ${states[ticket.state]}`
  return ticket;
}

export async function request(url) {
  const requestHeader = new Headers();
  requestHeader.append('Content-Type', 'application/json;charset=utf-8')
  requestHeader.append('Accept', 'application/json')
  requestHeader.append('X-CSRF-Token', document.querySelector('[name=csrf-token]').content);

  const response = await fetch(
    url,
    {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: requestHeader
      }
  )

  return response.json()
}

export async function ticketsVisibility(context, tickets, todaysWeight) {
  if(tickets.length > 0) {
    document.getElementById('todays-weight').textContent = `(${todaysWeight})`
    tickets = tickets.sort((currentTicket, nextTicket) => currentTicket['queue_position']['position'] - nextTicket['queue_position']['position']);
    context.renderTickets(tickets.map(colorStatus))
  } else {
    context.emptyResponseTarget.hidden = false;
  }
  context.conversationLoadingTarget.hidden = true;
  context.detailsLoadingTarget.hidden = true;
  context.loadingTarget.hidden = true;
}

export function initScripts(block, html) {
  const re = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
  let match;
  while (match = re.exec(html)) {
      // ignore non-javascript scripts
      if (!match[0].includes('text/x-tmpl')) {
          let newScript = document.createElement("script");
          newScript.appendChild(document.createTextNode(match[1]));
          block.appendChild(newScript);
      }
  }
}

export function highlightSelected(target) {
  $('.atm-item-name').css('font-weight', '100')
  $(target).css('font-weight','bold');
}

export function commitStateIntoStore(url) {
  let positionArr = [...document.querySelectorAll('.atm-list > li')].map(({dataset}) => dataset.id)

  $.ajax({
    url: url,
    method: 'PATCH',
    headers: {
      'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content,
    },
    data: { ids: positionArr }
  });
}
