import { AppSettings } from 'interfaces/app'
import { bindJWT, getApi } from 'lib/api/api'
import AllCompanyTagsProvider from 'providers/all-company-tags-provider'
import ConfirmationModalProvider from 'providers/confirmation-modal-provider'
import QueryClientProvider from 'providers/query-client-provider'
import { Search } from './search'
import FeatureFlagsProvider from 'lib/components/feature-flags/feature-flags-provider'
import { Toaster } from 'lib/components/toast/toast'

export interface SearchPageProps {
  settings: AppSettings
}

export default function SearchPage() {
  return (
    <QueryClientProvider>
      <Toaster />
      <FeatureFlagsProvider>
        <ConfirmationModalProvider>
          <AllCompanyTagsProvider>
            <Search />
          </AllCompanyTagsProvider>
        </ConfirmationModalProvider>
      </FeatureFlagsProvider>
    </QueryClientProvider>
  )
}

// Testing
export async function getSearch() {
  const getWithJWT = bindJWT(getApi, null)
  const response = await getWithJWT(`/api/internal/search?query=test`)
  return response.data
}
