import { useQuery } from '@tanstack/react-query'
import GrowingTextInput from 'components/core/text-input/growing-text-input'
import { requestQuery } from 'lib/api/fetch-api'
import CopyTextButton from 'lib/components/buttons/copy-text-button'
import IconButton from 'lib/components/buttons/icon-button'
import { Drawer } from 'lib/components/drawer/drawer'
import { displayDate } from 'lib/util/date'
import { ArrowRightToLine, FoldersIcon, LinkIcon } from 'lucide-react'
import { useState } from 'react'

export interface ProjectDrawerProps {
  isOpen: boolean
  setOpen: (open: boolean) => void
  projectId: number
}

export default function ProjectDrawer({ isOpen, setOpen, projectId }: ProjectDrawerProps) {
  const [projectName, setProjectName] = useState<string>()

  const { data } = useQuery({
    queryKey: ['project', projectId],
    queryFn: requestQuery({ endpoint: 'getProject', query: { id: projectId } }),
  })

  const project = data?.data

  if (project && projectName === undefined) {
    setProjectName(project?.name)
  }

  return (
    <Drawer isOpen={isOpen} setOpen={setOpen} size="md">
      <Drawer.Header setOpen={setOpen} showCloseButton={false}>
        <div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-pb-2 tw-pt-4">
          <div className="tw-flex tw-items-center tw-gap-4">
            <IconButton size="xs" color="transparent" onClick={() => setOpen(false)} className="tw-mr-2">
              <ArrowRightToLine className="tw-text-neutral-700" />
            </IconButton>
            <FoldersIcon className="lu-xl tw-text-neutral-700" />
            <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
              <GrowingTextInput value={projectName} onChange={(e) => setProjectName(e.target.value)} name="name" />
              <div>Last updated on {displayDate(project?.updatedAt)}</div>
            </div>
          </div>
          <div>
            <CopyTextButton textToCopy={window.location.href} defaultIcon={<LinkIcon />} />
          </div>
        </div>
      </Drawer.Header>
      <Drawer.Body>
        <div>WIP</div>
      </Drawer.Body>
    </Drawer>
  )
}
