import { useAllCompanyTagsContext } from 'providers/all-company-tags-provider'
import { useSingleQueueContext } from 'providers/single-queue-provider'
import { CompanyTag } from 'lib/api/company-tags/company-tags'
import CompanyTaggingProvider, { ChangeTagAction } from 'providers/company-tagging-provider'
import { SelectBoxCompanyTaggingPopover } from 'components/elements/company-tagging/company-tagging-popover'

export default function CompanyTaggingFilter() {
  const { companyTags } = useAllCompanyTagsContext()
  const { filters, handleFilterChange, activeTab, fetchTickets } = useSingleQueueContext()
  const { companyTagsIdInAny } = filters

  const selectedCompanyTags =
    (companyTagsIdInAny && companyTags.filter((tag) => companyTagsIdInAny.includes(tag.id))) || []

  function handleSelectTag(tag: CompanyTag) {
    const newTags = [...selectedCompanyTags, tag]
    handleFilterChange({
      ...filters,
      companyTagsIdInAny: newTags.map((tag) => tag.id),
    })
  }

  function handleUnselectTag(tag: CompanyTag) {
    const newTags = selectedCompanyTags.filter((selectedTag) => selectedTag.id !== tag.id)
    const newTagsIds = newTags.map((tag) => tag.id)

    handleFilterChange({
      ...filters,
      companyTagsIdInAny: newTagsIds?.length ? newTagsIds : null,
    })
  }

  function handleDeleteTag(tag: CompanyTag) {
    handleFilterChange({
      ...filters,
      companyTagsIdInAny: selectedCompanyTags.filter((selectedTag) => selectedTag.id !== tag.id).map((tag) => tag.id),
    })
  }

  function handleUpdate(tag: CompanyTag, action: ChangeTagAction) {
    switch (action) {
      case ChangeTagAction.Select:
        handleSelectTag(tag)
        break
      case ChangeTagAction.Unselect:
        handleUnselectTag(tag)
        break
      case ChangeTagAction.Update:
        fetchTickets()
        break
      case ChangeTagAction.Delete:
        handleDeleteTag(tag)
        fetchTickets()
        break
      case ChangeTagAction.ClearAll:
        handleFilterChange({
          ...filters,
          companyTagsIdInAny: null,
        })
        break
    }
  }

  return (
    <div className="tw-mr-2 tw-mt-2">
      <CompanyTaggingProvider
        selectedTags={activeTab === 'draft' ? [] : selectedCompanyTags}
        updateTaggableState={handleUpdate}
      >
        <SelectBoxCompanyTaggingPopover disabled={activeTab === 'draft'} />
      </CompanyTaggingProvider>
    </div>
  )
}
