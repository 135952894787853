import axios from 'axios'
import { getInitialFilters } from 'components/elements/admin-filters'
import PageHeader from 'components/elements/page-header'
import Pagination from 'components/elements/pagination'
import CancellationsItem from 'components/pages/admin/reports/cancellations/cancellations-item'
import CancellationsList from 'components/pages/admin/reports/cancellations/cancellations-list'
import { EmptyTableScreen, LoadingScreen } from 'components/pages/requests/empty-screens'
import { Cancellation } from 'interfaces/cancellation'
import { Toaster, toast } from 'lib/components/toast/toast'
import { hasOnlyFalsyValues } from 'lib/object/utils'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import CancellationsFilters, {
  CancellationFilters,
  emptyCancellationFilters,
  parsedFilterValues,
} from './cancellations-filters'

const columnNames = {
  Client: 'company_name',
  Status: 'predicted_label',
  'Confidence Level': 'label_probability',
  'Supporting Documentation': 'cancellation_notes',
  'Status Log': 'status_log',
  'Age of Client': 'company_created_at',
  'Cancellation Requested': 'created_at',
  'Days Until Cancellation': 'active_until',
  Designers: null,
  '# of Requests': 'number_of_requests',
  'Date of Last Request': 'date_of_last_request',
  'Reason for Cancellation': 'reason',
  'Refund Status': 'refund_status',
  'Selected Reason': 'message',
  'Brightback Reason': 'brightback_reason',
  Feedback: 'feedback',
  Competition: 'competition',
  Sentiment: 'sentiment',
  '': 'overflow_menu',
}

export interface fetchCancellationsProps {
  pageParams?: { page: number }
  sortParams?: { sort_column: string; sort_direction: string }
  formatParams?: { format: string }
  filterParams?: CancellationFilters | Record<string, never>
}

const defaultSort = {
  sort_column: 'created_at',
  sort_direction: 'ASC',
}

const downloadCSV = (data: string) => {
  const blob = new Blob([data], { type: 'csv' })

  const a = document.createElement('a')
  a.download = 'cancellations.csv'
  a.href = URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

function AdminCancellationsReportPage() {
  const initialFilters = getInitialFilters(emptyCancellationFilters)

  const [cancellations, setCancellations] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [cancellationCount, setCancellationCount] = useState(0)
  const [cancellationReasonsOptions, setCancellationReasonsOptions] = useState<string[]>(null)
  const [refundStatusOptions, setRefundStatusOptions] = useState<string[]>(null)
  const [predictedLabelsOptions, setPredictedLabelsOptions] = useState<Record<string, number>>(null)
  const [filters, setFilters] = useState(parsedFilterValues(initialFilters as CancellationFilters))
  const [sortColumn, setSortColumn] = useState<string>(null)
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC')

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const oops = useCallback(() => toast.error('Oops, something went wrong.'), [])

  const setLabel = 'Preventable Cancellation'
  const textFilterPlaceholder = 'Search by name or email'

  useEffect(() => {
    if (sortColumn) {
      fetchCancellations().catch(oops)
    }
    // TODO: add memoization to have more reliable dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortColumn, sortDirection])

  useEffect(() => {
    fetchCancellations().catch(oops)

    fetchCancellationFields().catch(oops)
    // TODO: add memoization to have more reliable dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchCancellationFields = async () => {
    const { data } = await axios.get('/api/internal/report/cancellations/fields')

    const { reasons, refund_statuses, predicted_labels } = data

    setCancellationReasonsOptions(reasons)
    setRefundStatusOptions(refund_statuses)
    setPredictedLabelsOptions(predicted_labels)
  }

  const getSortParams = () => {
    const mappedSortColumn = columnNames[sortColumn]
    if (!mappedSortColumn) return defaultSort

    return {
      sort_column: mappedSortColumn,
      sort_direction: sortDirection,
    }
  }

  const fetchCancellations = async ({
    pageParams,
    sortParams = getSortParams(),
    formatParams = { format: 'json' },
    filterParams = hasOnlyFalsyValues(filters) ? {} : filters,
  }: fetchCancellationsProps = {}) => {
    if (formatParams.format === 'json') setIsLoading(true)
    else setIsDownloading(true)

    const { data } = await axios.get(window.Routes.apiInternalReportCancellationsUrl(), {
      params: {
        ...pageParams,
        ...sortParams,
        ...formatParams,
        ...filterParams,
      },
    })

    if (formatParams.format === 'csv') {
      downloadCSV(data)
      setIsDownloading(false)
      return
    }

    const { cancellations: cancellation_list, per_page, count } = data

    setIsLoading(false)
    setCancellations(cancellation_list)
    setPerPage(per_page)
    setCancellationCount(count)
  }

  const onPageClick = (data) => {
    setCurrentPage(data.selected)
    fetchCancellations({ pageParams: { page: data.selected + 1 } }).catch(oops)
    window.scrollTo(0, 0)
  }

  const Header = () => (
    <PageHeader title=" Admin Cancellations Report " hideDropdown={true}>
      {`Showing ${Math.min(perPage, cancellations.length)} out of ${cancellationCount}`}
    </PageHeader>
  )

  const sortBy = (column = 'created_at', direction: 'ASC' | 'DESC' = 'ASC') => {
    if (sortColumn !== column) setSortColumn(column)
    if (sortDirection !== direction) setSortDirection(direction)
  }

  const CancellationsTable = () => {
    if (isLoading) {
      return <LoadingScreen />
    }

    if (cancellations.length === 0) return <EmptyTableScreen className="tw-w-10/12" />

    return (
      <div className="tw-w-10/12">
        <CancellationsList
          headers={Object.keys(columnNames)}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          sortBy={sortBy}
        >
          {cancellations.map((cancellation: Cancellation) => (
            <CancellationsItem
              cancellation={cancellation}
              key={cancellation.id}
              onCancellationChange={fetchCancellations}
            />
          ))}
        </CancellationsList>
        <Pagination
          pageCount={Math.ceil(cancellationCount / perPage)}
          onPageChange={onPageClick}
          forcePage={currentPage}
        />
      </div>
    )
  }

  return (
    <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row">
      <Header />
      <CancellationsFilters
        setFilters={setFilters}
        filters={filters}
        setLabel={setLabel}
        textFilterPlaceholder={textFilterPlaceholder}
        cancellationReasonsOptions={cancellationReasonsOptions}
        refundStatusOptions={refundStatusOptions}
        predictedLabelsOptions={predictedLabelsOptions}
        fetchCancellations={fetchCancellations}
        isDownloading={isDownloading}
      />
      <CancellationsTable />
    </div>
  )
}

// eslint-disable-next-line react/display-name
export default function (): ReactElement {
  return (
    <>
      <Toaster />
      <AdminCancellationsReportPage />
    </>
  )
}
