import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu } from '@headlessui/react'
import DropdownMenu from 'components/elements/dropdown-menu'
import { TicketFileVersion } from 'lib/api/ticket-files/ticket-file-versions'
import { useState } from 'react'
import AddToTicketModal from './add-to-ticket-modal'

const classNames = {
  addToTicket:
    'tw-text-neutral-800 tw-bg-peppercorn-50 tw-rounded-full tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-items-center hover:tw-text-neutral-800',
  buttons: 'tw-w-32 tw-h-full tw-flex tw-flex-wrap tw-justify-end tw-items-center tw-pr-2',
  cellFooter: 'tw-w-full tw-h-14 tw-flex tw-items-center tw-bg-white tw-pl-3 tw-rounded-b-lg tw-text-neutral-800',
  footerText: 'tw-w-full tw-block tw-truncate',
  icon: 'tw-w-6 tw-h-6',
  kebab: 'tw-bg-neutral-50 tw-rounded-full tw-w-10 tw-m-2 tw-flex tw-items-center tw-justify-center',
  kebabChildrenWrapper: 'tw-py-3 tw-px-4',
  kebabItem: 'tw-flex tw-items-center tw-pl-3 tw-h-10 hover:tw-bg-peppercorn-100',
}

function Buttons(): JSX.Element {
  return (
    <div className={classNames.buttons}>
      <AddToTicket />
      <Kebab />
    </div>
  )
}

function AddToTicket(): JSX.Element {
  const [open, setOpen] = useState(false)

  return (
    <a href="#" onClick={() => setOpen(true)} className={classNames.addToTicket}>
      <FontAwesomeIcon icon={['fal', 'plus-circle']} className="tw-ml-3 tw-text-lg" />
      <AddToTicketModal open={open} setOpen={setOpen} />
    </a>
  )
}

function Kebab(): JSX.Element {
  const KebabItems = [<>Copy Link to Asset</>]

  return (
    <DropdownMenu size="lg" renderOnTop={true}>
      <div className={classNames.kebabChildrenWrapper}>
        {KebabItems.map((item, index) => (
          <Menu.Item key={index}>
            <div className={classNames.kebabItem}>{item}</div>
          </Menu.Item>
        ))}
      </div>
    </DropdownMenu>
  )
}

export default function Footer({ name }: TicketFileVersion): JSX.Element {
  return (
    <div className={classNames.cellFooter}>
      <div className={classNames.footerText}>{name}</div>
      <Buttons />
    </div>
  )
}
