import { Annotation, AnnotationRecord, deleteAnnotation, VideoAnnotationData } from 'lib/api/annotations/annotations'
import {
  AnnotationTimelineItem,
  ConversationTimelineItem,
  getTimelineItems,
  TimelineItem,
  TimelineItemTypes,
} from 'lib/api/timeline/timeline'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useMediaContext } from '../media/media-provider'
import TimelineCache from '../timeline/timeline-cache'
import DetailTask from '../types/detail-task'
import { useAnnotationsContext } from './annotations-provider'
import { useRequestContext } from './request-provider'
import { useDirectionsContext } from './timeline-directions-provider'
import { useTimelineContext } from './timeline-provider'

type CreatableTimelineItemTypes =
  | TimelineItemTypes.ANNOTATION
  | TimelineItemTypes.CONVERSATION
  | TimelineItemTypes.DIRECTION

interface TimelineListContextValue {
  addComment: (body: string) => Promise<void>
  canCreateDirections: boolean
  getNextPage: () => Promise<void>
  hasNextPage: boolean
  isCurrentVersion: boolean
  currentVersion: number
  newItemType: CreatableTimelineItemTypes
  orderedItems: TimelineItem[]
  removeItem: (item: TimelineItem) => Promise<void>
  selectedVersion: number
  selectLatestVersion: () => void
  setSelectedVersion: (version: number) => void
}

interface TimelineListProviderProps {
  children: ReactNode
  onLoad: (behaviorIsSmooth?: boolean) => void
}

type ItemsResponseTuple = [TimelineItem[], string]

export const ALL_VERSIONS = 0

const TimelineListContext = createContext<TimelineListContextValue>({} as TimelineListContextValue)

async function getItems(
  ticketId: number,
  version: number,
  nextPageUrl: string,
  cache: TimelineCache,
): Promise<ItemsResponseTuple> {
  if (!nextPageUrl) {
    const cachedItems = cache.getItems(version)
    if (cachedItems) {
      const newNextPageUrl = cache.getNextPageUrl(version)
      return [cachedItems, newNextPageUrl]
    }
  }
  const { data, meta } = await getTimelineItems(ticketId, version, nextPageUrl)
  cache.addItems(version, data, meta.nextPage)
  const items = cache.getItems(version)
  return [items, meta.nextPage]
}

function isVideoAnnotation(videoAnnotation: AnnotationTimelineItem) {
  return Object.hasOwn(videoAnnotation.annotation.data, 'range')
}

export default function TimelineListProvider({ children, onLoad }: TimelineListProviderProps): ReactElement {
  const [items, setItems] = useState<TimelineItem[]>([])
  const [hasNextPage, setHasNextPage] = useState<boolean>(false)
  const [scrollToBottom, setScrollToBottom] = useState<'smooth' | boolean>()

  const { ticket } = useRequestContext()
  const { removeComment, subscribeToTimeline, unsubscribeToTimeline } = useTimelineContext()
  const { canCreateDirections, deleteDirection, subscribeToDirections, unsubscribeToDirections } =
    useDirectionsContext()
  const { removeVideoAnnotation, showAnnotationCreator, subscribeToAnnotations, unsubscribeToAnnotations } =
    useAnnotationsContext()
  const { getFileById, updateFileAnnotations, files } = useMediaContext()

  const [newItemType, setNewItemType] = useState<CreatableTimelineItemTypes>(
    canCreateDirections ? TimelineItemTypes.DIRECTION : TimelineItemTypes.CONVERSATION,
  )
  const [selectedVersion, setSelectedVersion] = useState<number>(ticket.currentVersion)

  const isCurrentVersion = useMemo(
    () => selectedVersion === ALL_VERSIONS || selectedVersion === ticket.currentVersion || !ticket.currentVersion,
    [selectedVersion, ticket.currentVersion],
  )

  const orderedItems = useMemo(() => {
    return items.slice().reverse()
  }, [items])

  const cache = useRef(new TimelineCache())

  useEffect(() => {
    // Clean up orphaned annotations when files change
    if (orderedItems && files) {
      const orphanedItems = orderedItems.filter((item) => {
        if (item.taskType === TimelineItemTypes.ANNOTATION) {
          const annotationItem = item as AnnotationTimelineItem
          const fileExists = files.some(
            (file) =>
              file.id === annotationItem.annotation.assetId || file.id === annotationItem.annotation.fileParentId,
          )
          return !fileExists
        }
        return false
      })

      if (orphanedItems.length > 0) {
        setItems((current) =>
          current.filter(
            (item) => !orphanedItems.some((orphan) => orphan.id === item.id && orphan.taskType === item.taskType),
          ),
        )
        orphanedItems.forEach((item) => {
          cache.current.removeItem(item)
        })
      }
    }
  }, [files, orderedItems])

  function convertAnnotationToTimelineItem(annotation: Annotation): AnnotationTimelineItem {
    return {
      id: annotation.detailTaskId,
      annotation: {
        id: annotation.id,
        assetId: annotation.assetId,
        body: annotation.body,
        data: annotation.data,
        fileName: annotation.fileName,
        fileParentId: annotation.fileParentId,
        fileVersion: annotation.fileVersion,
        previewUrl: annotation.previewUrl,
        userId: annotation.userId,
        user: annotation.user,
        uuid: annotation.uuid,
      },
      createdAt: annotation.createdAt,
      description: annotation.body,
      private: false,
      status: annotation.status,
      taskId: annotation.id,
      taskType: TimelineItemTypes.ANNOTATION,
      ticketVersion: annotation.fileVersion,
    } as AnnotationTimelineItem
  }

  async function getNextPage() {
    const nextPageUrl = cache.current.getNextPageUrl(selectedVersion)
    if (nextPageUrl) {
      const [totalItemsForVersion, newNextPageUrl] = await getItems(
        ticket.id,
        selectedVersion,
        nextPageUrl,
        cache.current,
      )
      setItems(totalItemsForVersion)
      setHasNextPage(!!newNextPageUrl)
    }
  }

  async function removeAnnotation(item: AnnotationTimelineItem) {
    await deleteAnnotation(item.annotation.id)
    const annotatedFile = getFileById(item.annotation.assetId, item.annotation.fileParentId)

    if (isVideoAnnotation(item)) {
      await removeVideoAnnotation(item.annotation.uuid)
    }

    const newAnnotations = annotatedFile.annotations.filter(
      (ticketFileAnnotation) => ticketFileAnnotation.id !== item.annotation.id,
    )
    updateFileAnnotations(annotatedFile, newAnnotations)
  }

  async function removeItem(item: TimelineItem) {
    if (item.taskType === TimelineItemTypes.CONVERSATION) {
      return removeComment(item as ConversationTimelineItem)
    }

    setItems((previous) => {
      return previous.filter((previousItem) => previousItem.id !== item.id)
    })

    if (item.taskType === TimelineItemTypes.DIRECTION) {
      await deleteDirection(item as unknown as DetailTask)
    } else if (item.taskType === TimelineItemTypes.ANNOTATION) {
      await removeAnnotation(item as AnnotationTimelineItem)
    }
  }

  function selectLatestVersion() {
    setSelectedVersion(ticket.currentVersion)
  }

  function showNewItemForm(itemType: CreatableTimelineItemTypes): void {
    if (canCreateDirections) {
      const type = itemType ?? TimelineItemTypes.DIRECTION
      setNewItemType(type)
    } else if (itemType === TimelineItemTypes.DIRECTION) {
      setNewItemType(null)
    } else if (itemType === null) {
      setNewItemType(TimelineItemTypes.CONVERSATION)
    } else {
      setNewItemType(itemType)
    }
  }

  useEffect(() => {
    let isAbandoned = false

    if (ticket.id) {
      getItems(ticket.id, selectedVersion, null, cache.current).then((itemsResponseTuple) => {
        if (!isAbandoned) {
          const [items, nextPageKey] = itemsResponseTuple
          setItems(items)
          setHasNextPage(!!nextPageKey)
          setScrollToBottom(true)
        }
      })
    }
    return () => {
      isAbandoned = true
    }
    // having onLoad as a dependency would causes scrolling issues
  }, [selectedVersion, ticket.id])

  useLayoutEffect(() => {
    if (items.length > 0 && scrollToBottom) {
      onLoad(scrollToBottom === 'smooth')
      setScrollToBottom(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length, scrollToBottom])

  useEffect(() => {
    showNewItemForm(showAnnotationCreator ? TimelineItemTypes.ANNOTATION : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAnnotationCreator])

  useEffect(() => {
    function addImageAnnotation(annotation: AnnotationRecord<AnnotoriousAnnotation>) {
      const annotationAsItem = convertAnnotationToTimelineItem(annotation)

      addItem(annotationAsItem)
    }

    function addItem(item: unknown) {
      const timelineItem = cache.current.addItem(item as TimelineItem)
      setItems((previous) => {
        return [timelineItem, ...previous]
      })
      setScrollToBottom('smooth')
    }

    function addVideoAnnotation(annotation: AnnotationRecord<AnnotoriousAnnotation> | VideoAnnotationData) {
      const annotationAsItem: AnnotationTimelineItem = convertAnnotationToTimelineItem(annotation as Annotation)

      addItem(annotationAsItem)
    }

    function highlightAnnotation(item: unknown) {
      if (item) {
        setSelectedVersion((previous) => {
          if (previous !== ALL_VERSIONS) {
            return (item as AnnotationRecord<AnnotoriousAnnotation>).fileVersion ?? previous
          }
          return ALL_VERSIONS
        })
      }
    }

    function removeItem(item: unknown) {
      cache.current.removeItem(item as TimelineItem)
      setItems((previous) => {
        return previous.filter((previousItem) => previousItem.id !== (item as TimelineItem).id)
      })
    }

    function updateAnnotation(annotation: AnnotationRecord<AnnotoriousAnnotation> | VideoAnnotationData) {
      const annotationAsItem = convertAnnotationToTimelineItem(annotation as Annotation)
      updateItem(annotationAsItem)
    }

    function updateItem(item: unknown) {
      const timelineItem = cache.current.updateItem(item as TimelineItem)
      setItems((previous) => {
        return previous.map((previousItem) => {
          if (previousItem.id === timelineItem.id) {
            return timelineItem
          }
          return previousItem
        })
      })
    }

    subscribeToTimeline('addComment', addItem)
    subscribeToTimeline('removeComment', removeItem)
    subscribeToTimeline('saveComment', updateItem)
    subscribeToDirections('add', addItem)
    subscribeToDirections('remove', removeItem)
    subscribeToDirections('update', updateItem)
    subscribeToAnnotations('addImage', addImageAnnotation)
    subscribeToAnnotations('addVideo', addVideoAnnotation)
    subscribeToAnnotations('highlight', highlightAnnotation)
    subscribeToAnnotations('update', updateAnnotation)
    return () => {
      unsubscribeToTimeline('addComment', addItem)
      unsubscribeToTimeline('removeComment', removeItem)
      unsubscribeToTimeline('saveComment', updateItem)
      unsubscribeToDirections('add', addItem)
      unsubscribeToDirections('remove', removeItem)
      unsubscribeToDirections('update', updateItem)
      unsubscribeToAnnotations('addImage', addImageAnnotation)
      unsubscribeToAnnotations('addVideo', addVideoAnnotation)
      unsubscribeToAnnotations('highlight', highlightAnnotation)
      unsubscribeToAnnotations('update', updateAnnotation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const context: TimelineListContextValue = {
    canCreateDirections,
    getNextPage,
    hasNextPage,
    isCurrentVersion: isCurrentVersion,
    currentVersion: ticket.currentVersion,
    newItemType,
    orderedItems,
    removeItem,
    selectedVersion,
    selectLatestVersion,
    setSelectedVersion,
  } as TimelineListContextValue

  return <TimelineListContext.Provider value={context}>{children}</TimelineListContext.Provider>
}

export function useTimelineListContext(): TimelineListContextValue {
  return useContext(TimelineListContext) as TimelineListContextValue
}
