import { Drawer } from 'lib/components/drawer/drawer'
import RolesTable from './roles-table'
import { useState } from 'react'

export default function RolesLink({
  isAdmin,
  text,
  textSize,
}: {
  isAdmin: boolean
  text: string
  textSize: 'xs' | 'sm'
}): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <a
        href="#"
        className={`tw-text-${textSize} tw-text-cornflower-500 hover:tw-cursor-pointer`}
        onClick={() => setIsOpen(true)}
      >
        {text}
      </a>
      <Drawer isOpen={isOpen} setOpen={setIsOpen} size="lg">
        <Drawer.Header title="Roles and Permissions" setOpen={setIsOpen} />
        <Drawer.Body className="tw-w-full">{isOpen && <RolesTable isAdmin={isAdmin} />}</Drawer.Body>
      </Drawer>
    </>
  )
}
