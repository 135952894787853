export function humanize(str) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase()
    })
}

export function capitalize(str): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function getInitials(str): string {
  const names = str.split(' ')
  let initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}

export function abbreviatedUnits(units: string) {
  switch (units) {
    case 'pixels':
      return 'px'
    case 'inches':
      return 'in'
    case 'feet':
      return 'ft'
    case 'centimeters':
      return 'cm'
    default:
      return units || ''
  }
}

export function truncateText(text: string, maxLength = 40) {
  return text.length >= maxLength ? `${text.substring(0, maxLength)}...` : text
}
