import { ReactElement, ReactNode, createContext, useContext, useRef } from 'react'
import {
  ConversationComment,
  createConversationComment,
  deleteConversationComment,
  updateConversationComment,
} from 'lib/api/conversations/conversations'
import { ConversationTimelineItem } from 'lib/api/timeline/timeline'

import DirectionsProvider from './timeline-directions-provider'
import { useRequestContext } from './request-provider'
import { toast } from 'lib/components/toast/toast'

interface TimelineContextValue {
  addComment: (body: string) => Promise<void>
  removeComment: (commentItem: ConversationTimelineItem) => Promise<void>
  saveComment: (commentId: number, body: string) => Promise<void>
  subscribeToTimeline: (event: SubscriptionEvent, callback: SubscriptionCallback) => void
  unsubscribeToTimeline: (event: SubscriptionEvent, callback: SubscriptionCallback) => void
}

type SubscriptionCallback = (comment: ConversationComment | ConversationTimelineItem) => void

type SubscriptionEvent = 'addComment' | 'removeComment' | 'saveComment'

interface TimelineProviderProps {
  children: ReactNode
}

const TimelineContext = createContext({})

export default function TimelineProvider({ children }: TimelineProviderProps): ReactElement {
  const { ticketId } = useRequestContext()

  const subscriptions = useRef({
    addComment: [],
    removeComment: [],
    saveComment: [],
  })

  async function addComment(body: string) {
    try {
      const response = await createConversationComment(ticketId, body)
      const conversationItem = response as ConversationTimelineItem
      subscriptions.current.addComment.forEach((callback) => callback(conversationItem))
    } catch (error) {
      toast.error('There was a problem adding the comment. Please try again.')
      throw error
    }
  }

  async function removeComment(conversationItem: ConversationTimelineItem) {
    try {
      await deleteConversationComment(ticketId, conversationItem.id)
      subscriptions.current.removeComment.forEach((callback) => callback(conversationItem))
    } catch (error) {
      toast.error('There was a problem updating the comment. Please try again.')
      throw error
    }
  }

  async function saveComment(commentId: number, body: string) {
    try {
      const response = await updateConversationComment(ticketId, commentId, body)
      const conversationItem = response as ConversationTimelineItem
      subscriptions.current.saveComment.forEach((callback) => callback(conversationItem))
    } catch (error) {
      toast.error('There was a problem updating the comment. Please try again.')
      throw error
    }
  }

  function subscribeToTimeline(event: string, callback: SubscriptionCallback) {
    subscriptions.current[event].push(callback)
  }

  function unsubscribeToTimeline(event: string, callback: SubscriptionCallback) {
    function removeCallbackIterator(cb: SubscriptionCallback) {
      return cb !== callback
    }

    subscriptions.current[event] = subscriptions.current[event].filter(removeCallbackIterator)
  }

  const context: TimelineContextValue = {
    addComment,
    removeComment,
    saveComment,
    subscribeToTimeline,
    unsubscribeToTimeline,
  }

  return (
    <TimelineContext.Provider value={context}>
      <DirectionsProvider>{children}</DirectionsProvider>
    </TimelineContext.Provider>
  )
}

export function useTimelineContext(): TimelineContextValue {
  return useContext(TimelineContext) as TimelineContextValue
}
