import { Copy, Pen, Trash } from 'lucide-react'
import { MouseEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react'

import Avatar from 'components/core/avatar'
import { AnnotationRecord, AnnotationStatus } from 'lib/api/annotations/annotations'
import Badge from 'lib/components/badge/badge'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { displayDate } from 'lib/util/date'

import { useRequestContext } from 'components/pages/request/providers/request-provider'
import { VideoJSAnnotation } from 'lib/api/ticket-files/ticket-files'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'
import AnnotationStatusBadge from '../annotations/annotation-status-badge'
import ApproveRejectButtons from '../annotations/approve-reject-buttons'
import { useMediaContext } from '../media/media-provider'
import { useAnnotationsContext } from '../providers/annotations-provider'
import { DetailTaskType } from '../types/detail-task'

interface AnnotationSourceProps {
  annotation: AnnotationRecord<AnnotoriousAnnotation> | AnnotationRecord<VideoJSAnnotation>
}

interface TimelineAnnotationDetailsProps {
  annotation: AnnotationRecord<AnnotoriousAnnotation> | AnnotationRecord<VideoJSAnnotation>
  enableEditMode: () => void
  onDeleteClick: (annotation: AnnotationRecord<AnnotoriousAnnotation> | AnnotationRecord<VideoJSAnnotation>) => void
}

const classNames = {
  article: 'tw-mb-4 tw-bg-neutral-50 tw-text-neutral-800 tw-rounded-lg tw-p-4 hover:tw-cursor-pointer',
  body: {
    editable: 'tw-cursor-text tw-text-neutral-900 tw-py-2',
    nonEditable: 'tw-text-neutral-800 tw-py-2',
  },
  header: {
    avatar: 'tw-shrink',
    byline: 'tw-flex-grow tw-text-left tw-flex',
    container: 'tw-flex tw-direction-row tw-justify-between tw-items-center tw-p-1 tw-gap-2',
    controls: '',
  },
  itemContainer: 'tw-flex tw-divide-solid tw-divide-y-0 tw-divide-x tw-divide-peppercorn-100 tw-min-w-0',
  itemDescriptionContainer: 'tw-pt-2 tw-px-2 tw-text-neutral-800 tw-flex-grow tw-truncate',
  thumbnail: 'tw-mr-2 tw-h-6',
  tooltip:
    'tw--top-16 tw-absolute tw-bg-neutral-500 tw-px-3 tw-py-1.5 tw-right-2 tw-rounded tw-text-left tw-text-sm tw-text-white tw-whitespace-nowrap tw-z-50',
}

const PLACEHOLDERS_FOLDER = 'file_placeholders'

function AnnotationSource({ annotation }: AnnotationSourceProps): ReactElement {
  const { getFilePageNumber, getParentFileName } = useMediaContext()

  const thumbnail = useMemo(() => {
    if (annotation.previewUrl && annotation.previewUrl.startsWith(PLACEHOLDERS_FOLDER)) {
      return `/images/${annotation.previewUrl}`
    }
    return annotation.previewUrl
  }, [annotation.previewUrl])

  const annotationTime = useMemo(() => {
    if (Object.hasOwn(annotation, 'time')) {
      return formatAnnotationTime(annotation.time)
    }
    return null
  }, [annotation])

  const pageNumber = useMemo(() => {
    if (annotation.fileParentId) {
      return getFilePageNumber(annotation.fileParentId, annotation.assetId)
    }
    return null
  }, [annotation?.assetId, annotation?.fileParentId, getFilePageNumber])

  const fileName = useMemo(() => {
    if (annotation?.fileParentId) {
      return getParentFileName(annotation.fileParentId) || annotation?.fileName
    }

    return annotation?.fileName
  }, [getParentFileName, annotation?.fileName, annotation?.fileParentId])

  return (
    <div className="tw-flex tw-items-center tw-gap-2">
      {!!thumbnail && <img alt="thumbnail" className={classNames.thumbnail} src={thumbnail} />}
      <div className="tw-break-all tw-font-bold tw-text-neutral-800">{fileName}</div>
      {pageNumber !== null && `pg.${pageNumber}`}
      {annotationTime !== null && <span className="tw-text-neutral-600">{` ${annotationTime}`}</span>}
    </div>
  )
}

export default function TimelineAnnotationDetails({
  annotation,
  enableEditMode,
  onDeleteClick,
}: TimelineAnnotationDetailsProps): ReactElement {
  const [showCopyTooltip, setShowCopyTooltip] = useState(false)
  const [showAnnotationBackground, setShowAnnotationBackground] = useState(false)
  const [selectAnnotationAfterChangingPage, setSelectAnnotationAfterChangingPage] = useState(false)

  const timeout = useRef(null)
  const selectAnnotationTimeout = useRef(null)
  const scrollToDiv = useRef<HTMLDivElement>()

  const {
    canEditAnnotation,
    isCollaboratorView,
    highlightedAnnotation,
    selectAnnotation,
    setHighlightedAnnotation,
    showAnnotationCreator,
  } = useAnnotationsContext()

  const { selectFileById } = useMediaContext()
  const { ticket } = useRequestContext()

  const isSubmittedByCustomer = ticket?.submittedBy?.id === annotation?.user?.id

  const allowEditAndHighlight =
    showAnnotationCreator !== DetailTaskType.IMAGE_ANNOTATION &&
    showAnnotationCreator !== DetailTaskType.VIDEO_ANNOTATION

  const annotationMeta = useMemo(() => {
    try {
      return {
        createdAtDate: displayDate(annotation.createdAt),
        creatorName: annotation.createdBy,
      }
    } catch {
      return {
        createdAtDate: 'n/a',
        creatorName: 'n/a',
      }
    }
  }, [annotation.createdAt, annotation.createdBy])

  const isAnnotationHighlighted = useMemo(() => {
    if (highlightedAnnotation?.id) {
      return highlightedAnnotation?.id === annotation.id
    }

    if (highlightedAnnotation?.uuid) {
      return highlightedAnnotation?.uuid === annotation.uuid
    }
    return false
  }, [annotation.id, annotation.uuid, highlightedAnnotation?.id, highlightedAnnotation?.uuid])

  async function handleCopyClick() {
    await navigator.clipboard.writeText(annotation.body)
    showTooltip()
  }

  function handleDeleteClick() {
    onDeleteClick(annotation)
  }

  function handleOnClick(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation()

    selectFileById(annotation.assetId, annotation.fileParentId)

    if (allowEditAndHighlight) {
      setHighlightedAnnotation(annotation)
      selectAnnotation(annotation)
    }
  }

  function onMouseEnterHandler(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation()
    setShowAnnotationBackground(true)
    if (allowEditAndHighlight) {
      selectAnnotation(annotation)
    }
  }

  function onMouseLeaveHandler(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation()

    setShowAnnotationBackground(false)

    if (allowEditAndHighlight) {
      selectAnnotation(highlightedAnnotation || null)
    }
  }

  function showTooltip() {
    setShowCopyTooltip(true)

    timeout.current = setTimeout(() => {
      setShowCopyTooltip(false)
    }, 3000)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
      clearTimeout(selectAnnotationTimeout.current)
    }
  }, [])

  useEffect(() => {
    if (isAnnotationHighlighted && scrollToDiv.current) {
      scrollToDiv.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [isAnnotationHighlighted])

  useEffect(() => {
    if (selectAnnotationAfterChangingPage) {
      selectAnnotationTimeout.current = setTimeout(() => {
        selectAnnotation(annotation)
        setSelectAnnotationAfterChangingPage(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAnnotationAfterChangingPage])

  const highlightedAnnotationBorder =
    highlightedAnnotation && isAnnotationHighlighted ? 'tw-border-cornflower-500' : 'tw-border-transparent'

  const highlightedAnnotationBackground =
    (allowEditAndHighlight && showAnnotationBackground) || isAnnotationHighlighted || !isSubmittedByCustomer
      ? 'tw-bg-cornflower-50 hover:tw-shadow-md tw-cursor-pointer'
      : 'tw-bg-neutral-50'

  return (
    <article
      className={`${classNames.article} tw-border-2 tw-border-solid ${highlightedAnnotationBorder} ${highlightedAnnotationBackground}`}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      data-testid="timeline-annotation-details"
      onClick={handleOnClick}
      ref={scrollToDiv}
    >
      <header className={classNames.header.container}>
        <div className={classNames.header.avatar}>
          <Avatar src={annotation.user?.avatar} name={annotation.user?.fullName} />
        </div>
        <div className={classNames.header.byline}>
          <div>{annotation.user.fullName}</div>
          <Badge style="pill" color="light">
            {displayDate(annotationMeta.createdAtDate)}
          </Badge>
        </div>
        <div className={classNames.header.controls} onClick={(e) => e.stopPropagation()}>
          <IconFlyoutMenu
            color={showCopyTooltip ? 'success' : 'transparent'}
            icon={showCopyTooltip ? ['far', 'check'] : ['far', 'ellipsis-v']}
            adjustedLeft
            viewportBottomThreshold={230}
          >
            {canEditAnnotation(annotation) && (
              <>
                <IconFlyoutMenu.Button onClick={enableEditMode}>
                  <Pen className="lu-light lu-sm tw-mr-1" /> Edit
                </IconFlyoutMenu.Button>
                <IconFlyoutMenu.Button onClick={handleDeleteClick}>
                  <Trash className="lu-light lu-sm tw-mr-1" /> Delete
                </IconFlyoutMenu.Button>
              </>
            )}
            <IconFlyoutMenu.Button onClick={handleCopyClick}>
              <Copy className="lu-light lu-sm tw-mr-1" /> Copy
            </IconFlyoutMenu.Button>
          </IconFlyoutMenu>
          <div className="tw-relative">
            {showCopyTooltip && <div className={classNames.tooltip}>Annotation Copied!</div>}
          </div>
        </div>
      </header>

      <div
        className={`${classNames.itemContainer} ${
          showAnnotationCreator === DetailTaskType.VIDEO_ANNOTATION ? '' : 'tw-cursor-pointer'
        }`}
      >
        <div className={classNames.itemDescriptionContainer}>
          <AnnotationStatusBadge status={annotation.status} />
          <AnnotationSource annotation={annotation} />
          <div
            className={canEditAnnotation(annotation) ? classNames.body.editable : classNames.body.nonEditable}
            onClick={enableEditMode}
            style={{ textWrap: 'wrap' }}
          >
            {annotation.body}
          </div>
          {!isCollaboratorView && annotation.status === AnnotationStatus.Review && (
            <ApproveRejectButtons annotation={annotation} />
          )}
        </div>
      </div>
    </article>
  )
}

function formatAnnotationTime(totalSeconds: number) {
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = Math.floor(totalSeconds % 60)
  const paddedSeconds = seconds.toString().padStart(2, '0')
  return `${minutes}:${paddedSeconds}`
}
