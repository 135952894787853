import { useEffect } from 'react'
import SingleSelect, { OptionType } from 'components/core/single-select'
import { PredictedLabel } from './table/column-elements'

interface EditablePredictedLabelProps {
  predictedLabel: string
  editMode: boolean
  newPredictedLabel: OptionType
  setNewPredictedLabel: (value: OptionType) => void
  humanAnnotated: boolean
}

export const statusSelectOptions = [
  { value: 'preventable', label: 'Preventable' },
  { value: 'non_preventable', label: 'Non-Preventable' },
  { value: 'not_applicable', label: 'Not Applicable' },
]

export const getPredictedLabelFromValue = (value: string): string =>
  statusSelectOptions.find((option) => option.value === value)?.label

const EditablePredictedLabel = ({
  predictedLabel,
  editMode,
  newPredictedLabel,
  setNewPredictedLabel,
  humanAnnotated,
}: EditablePredictedLabelProps): JSX.Element => {
  useEffect(() => {
    if (!newPredictedLabel) {
      setNewPredictedLabel({
        label: getPredictedLabelFromValue(predictedLabel) || 'Not Applicable',
        value: predictedLabel || 'not_applicable',
      })
    }
  }, [newPredictedLabel, predictedLabel, setNewPredictedLabel])

  if (editMode) {
    return (
      <td>
        <SingleSelect
          name="predicted-label"
          inputId="predicted-label"
          placeholder="Select Status"
          className="tw-mr-2 tw-w-52"
          options={statusSelectOptions}
          value={newPredictedLabel}
          onChange={(value: OptionType) => setNewPredictedLabel(value)}
        />
      </td>
    )
  }

  return <PredictedLabel humanAnnotated={humanAnnotated} predictedLabel={newPredictedLabel?.value?.toString()} />
}

export default EditablePredictedLabel
