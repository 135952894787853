import { useEffect, useRef, useState } from 'react'
import TextInput, { TextInputProps } from './text-input'

import styles from './text-input.module.scss'

interface GrowingTextInputProps extends TextInputProps {
  value: string
  name: string
  label?: string
  hideLabel?: boolean
  focusInput?: boolean
  [otherProps: string]: unknown
}

export default function GrowingTextInput({
  value,
  name,
  label,
  hideLabel,
  focusInput,
  ...otherProps
}: GrowingTextInputProps): JSX.Element {
  const spanRef = useRef<HTMLSpanElement>()
  const [inputWidth, setInputWidth] = useState(spanRef.current?.offsetWidth || 250)

  useEffect(() => {
    const extraWidth = 100
    const spanWidth = spanRef.current?.offsetWidth

    if (spanWidth !== inputWidth) {
      setInputWidth(spanWidth + extraWidth)
    }
  }, [inputWidth, value])

  return (
    <div>
      <span
        className={`${
          styles.input || ''
        } tw-top--999999 tw-left--999999 tw-invisible tw-absolute tw-block tw-w-auto tw-appearance-none tw-rounded tw-border tw-border-solid tw-bg-white tw-px-4 tw-text-lg tw-shadow-none tw-outline-none`}
        style={{ maxWidth: '68%', minWidth: '150px' }}
        ref={spanRef}
      >
        {value}
      </span>
      <TextInput
        {...otherProps}
        focusInput={focusInput}
        value={value}
        name={name}
        style={{ width: `${inputWidth}px` }}
        label={label}
        hideLabel={hideLabel}
      />
    </div>
  )
}
