import { useMemo } from 'react'
import { LinkButton } from 'components/core/button'
import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import AnnotatedMediaViewer from './annotated-media-viewer'
import VideoJsPlayer from './video-js-player'

interface MediaViewerProps {
  currentTicketFile: TicketFile | null
  selectedFile: TicketFile
}

export default function MediaViewer({ selectedFile, currentTicketFile }: MediaViewerProps) {
  const showImageAnnotationViewer = useMemo(() => {
    if (selectedFile.isExtractable) {
      return selectedFile.extractedPages?.length > 0
    }
    return selectedFile.hasImagePreview
  }, [selectedFile.isExtractable, selectedFile.extractedPages, selectedFile.hasImagePreview])

  const parentTicketFileId = useMemo(() => {
    return currentTicketFile?.id === selectedFile.id ? undefined : selectedFile.id
  }, [currentTicketFile?.id, selectedFile.id])

  if (selectedFile.isVideo) {
    return <VideoJsPlayer ticketFile={selectedFile} />
  }

  if (showImageAnnotationViewer) {
    return (
      <AnnotatedMediaViewer
        isExtractable={selectedFile.isExtractable}
        parentTicketFileId={parentTicketFileId}
        ticketFile={currentTicketFile}
      />
    )
  }

  function externalLinkFormatType() {
    if (selectedFile.externalLink.includes('canva.com')) return 'Canva'
    if (selectedFile.externalLink.includes('docs.google.com/presentation')) return 'Google Slides'
    return 'External'
  }

  return (
    <div>
      <div className="tw-py-6">
        <h4>Preview and annotations are unavailable for this type of file.</h4>
        To view the file, click the download button or external link button.
      </div>
      <img src={`/images/${selectedFile.previewUrl}`} alt="" data-testid="placeholder-image" />
      {selectedFile.externalLink && (
        <div className="tw-mt-4 tw-flex tw-justify-center">
          <LinkButton url={selectedFile.externalLink} color="lightGray" target="_blank">
            Open {externalLinkFormatType()} Link
          </LinkButton>
        </div>
      )}
    </div>
  )
}
