import { Dispatch, SetStateAction } from 'react'

export enum TicketFeedbackValues {
  Unrated = 'unrated',
  ThumbsUp = 'thumbs_up',
  ThumbsDown = 'thumbs_down',
}

export interface TicketFeedback {
  createdAt: string
  reasons: string
  thumbRating: TicketFeedbackValues
  privateFeedback: string
}

export enum RatingsSteps {
  Rate,
  Feedback,
  ThankYou,
}

export type SetStepType = Dispatch<SetStateAction<RatingsSteps>>
