import Dropdown from 'lib/components/dropdown/dropdown'
import TicketFormats from '../ticket-formats/ticket-formats'
import { useMemo } from 'react'
import { ErrorLabel, TitleWithErrorIcon } from './header-errors'
import { getFormatsTitle } from 'lib/util/ticket-formats'

interface RequestFileTypePopoverProps {
  availableFormats: string[]
  onChange: (unknown) => void
  ticketSelectedFormats: string[]
  showValidationError: boolean
}

const label = 'Format(s)*'

export default function RequestFormatsPopover({
  availableFormats,
  onChange,
  ticketSelectedFormats,
  showValidationError,
}: RequestFileTypePopoverProps): JSX.Element {
  const title = useMemo(() => {
    return getFormatsTitle(ticketSelectedFormats, 'Select Formats')
  }, [ticketSelectedFormats])

  return (
    <div className="tw-px-4">
      <Dropdown
        icon="chevron"
        label={showValidationError ? <ErrorLabel label={label} /> : label}
        title={showValidationError ? <TitleWithErrorIcon title={title} /> : title}
        size="sm"
      >
        <TicketFormats
          availableFormats={availableFormats}
          onSelect={onChange}
          selectedTicketFormats={ticketSelectedFormats}
        />
      </Dropdown>
    </div>
  )
}
