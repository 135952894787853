import { ReactElement, useState } from 'react'

import { AnnotationRecord } from 'lib/api/annotations/annotations'

import { VideoJSAnnotation } from 'lib/api/ticket-files/ticket-files'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'
import { useAnnotationsContext } from '../providers/annotations-provider'
import TimelineAnnotationDetails from './timeline-annotation-details'
import TimelineAnnotationEdit from './timeline-annotation-edit'

interface TimelineAnnotationProps {
  annotation: AnnotationRecord<AnnotoriousAnnotation | VideoJSAnnotation>
  onDeleteClick: (annotation: AnnotationRecord<AnnotoriousAnnotation | VideoJSAnnotation>) => void
}

export default function TimelineAnnotation({ annotation, onDeleteClick }: TimelineAnnotationProps): ReactElement {
  const [editMode, setEditMode] = useState(false)
  const { setAnnotationsInEditMode, cancelAnnotation, canEditAnnotation, setShowImageAnnotationCreator } =
    useAnnotationsContext()

  function disableEditMode() {
    setEditMode(false)
    setAnnotationsInEditMode((previous) => (previous -= 1))
  }

  function enableEditMode() {
    if (canEditAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>)) {
      setEditMode(true)
      setShowImageAnnotationCreator(false)
      setAnnotationsInEditMode((previous) => (previous += 1))
      cancelAnnotation()
    }
  }

  return (
    <>
      {editMode ? (
        <TimelineAnnotationEdit annotation={annotation} disableEditMode={disableEditMode} />
      ) : (
        <TimelineAnnotationDetails
          annotation={annotation as AnnotationRecord<AnnotoriousAnnotation>}
          enableEditMode={enableEditMode}
          onDeleteClick={onDeleteClick}
        />
      )}
    </>
  )
}
