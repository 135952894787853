import React, { ReactNode } from 'react'
import Checkbox from '../core/checkbox'

interface BulkActionsBarProps {
  totalSelected: number
  children: ReactNode
  bulkAllToggle: (e?: unknown) => void
}

export default function BulkActionsBar({ totalSelected, children, bulkAllToggle }: BulkActionsBarProps): JSX.Element {
  return (
    <div className="tw-flow-root tw-justify-items-center tw-align-middle">
      <div className="tw-float-left tw-flex tw-items-center">
        <Checkbox checked={totalSelected > 1} label={''} onChange={bulkAllToggle} />
        <div className="tw-flex tw-items-center tw-px-6">{children}</div>
      </div>

      <div className="tw-float-right tw-mr-4 tw-flex tw-items-center tw-py-2 tw-align-middle tw-text-xs tw-font-normal tw-text-peppercorn-700">
        {totalSelected} Tickets Selected
      </div>
    </div>
  )
}
