import { ReactNode } from 'react'

export interface SwitchProps {
  children?: ReactNode
  value?: boolean
  onClick?: (toggle: boolean) => void
}

const classNames = {
  container: {
    base: 'tw-bg-neutral-200 tw-w-10 tw-h-5 tw-p-1 tw-relative tw-inline-flex tw-cursor-pointer tw-rounded-full tw-border-none tw-flex-shrink-0 tw-transition-colors tw-duration-200 tw-ease-in-out',
    selected: 'tw-bg-gherkin-500',
  },
  slider: {
    base: 'tw-w-3 tw-h-3 tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out',
    selected: 'tw-translate-x-5',
  },
}

const Switch = ({ children, value, onClick, ...otherProps }: SwitchProps) => (
  <div className="tw-flex tw-items-center tw-gap-2" {...otherProps}>
    <div>{children}</div>
    <button
      onClick={() => onClick(!value)}
      className={`${classNames.container.base} ${value && classNames.container.selected}`}
    >
      <span aria-hidden="true" className={`${classNames.slider.base} ${value && classNames.slider.selected}`} />
    </button>
  </div>
)

export default Switch
