import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { requestQuery } from 'lib/api/fetch-api'
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  ColumnDef,
  SortingState,
  RowSelectionState,
  ColumnFiltersState,
} from '@tanstack/react-table'
import { SearchTicket } from 'interfaces/ticket'
import { useEffect, useState } from 'react'
import { displayDate } from 'lib/util/date'
import UserAvatar from 'components/elements/user-avatar'
import SearchOverflowMenu from './overflow-menu'
import ActionsHeader from './actions-header'
import Checkbox from 'components/core/checkbox'
import TicketLinkElement from '../requests/table-elements/ticket-link-element'
import { queryClient } from 'providers/query-client-provider'
import Tooltip from 'lib/components/tooltip/tooltip'
import { DesignRequestStatus } from 'lib/api/tickets/tickets'
import { TicketStatusBadge } from 'lib/components/badge/badge'
import { BrandColor } from '../request/brands/brands'

export const columnIds = {
  query: 'query',
  ticket_state: 'ticket_statuses[]',
  skill_name: 'skill_categories[]',
  brand_ids: 'brand_ids[]',
  user_ids: 'user_ids[]',
  designer_ids: 'designer_ids[]',
  ticket_updated_date: 'ticket_updated_date',
  format: 'ticket_deliverable_formats[]',
  actions: 'actions',
  tag: 'company_tag_ids[]',
}

export const defaultColumnVisibility = {
  [columnIds.query]: true,
  [columnIds.ticket_state]: true,
  [columnIds.skill_name]: true,
  [columnIds.brand_ids]: true,
  [columnIds.user_ids]: false,
  [columnIds.designer_ids]: true,
  [columnIds.ticket_updated_date]: true,
  [columnIds.format]: false,
  [columnIds.actions]: true,
  [columnIds.tag]: false,
}

const columnToSortKey = {
  [columnIds.query]: 'title',
  [columnIds.ticket_state]: 'ticket_status',
  [columnIds.skill_name]: 'skill_category',
  [columnIds.brand_ids]: 'brand_name',
  [columnIds.user_ids]: 'user_name',
  [columnIds.designer_ids]: 'designer_name',
  [columnIds.ticket_updated_date]: 'ticket_updated_at',
}

export const LOCAL_STORAGE_SEARCH_VISIBILITY_KEY = 'search-table-column-visibility'

const columns: ColumnDef<SearchTicket>[] = [
  {
    id: 'select-col',
    cell: ({ row, table }) => {
      const CheckboxCmp = (
        <Checkbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={(e) => {
            row.getToggleSelectedHandler()(e)
            table.setGlobalFilter(row.original.ticket_status)
          }}
        />
      )

      if (row.getCanSelect()) {
        return CheckboxCmp
      }

      return (
        <Tooltip
          content={
            "You can't perform bulk actions on requests that are archived, canceled, draft, or different statuses from each other"
          }
        >
          {CheckboxCmp}
        </Tooltip>
      )
    },
  },
  {
    accessorKey: 'title',
    header: 'Title',
    id: columnIds.query,
    cell: ({ row }) => {
      return (
        <TicketLinkElement
          ticket={{
            id: BigInt(row.original.ticket_id),
            ticketId: BigInt(row.original.ticket_id),
            subject: row.original.title,
            skillName: row.original.skill.name,
            thumbnail: row.original.thumbnail,
            status: row.original.ticket_status,
            url: `/tickets/${row.original.ticket_id}`,
          }}
          showTags={true}
          editTags={false}
          selectedTags={row.original.ticket_company_tags}
          updateSelectedTags={() => {
            queryClient.invalidateQueries({ queryKey: ['search'] })
          }}
        />
      )
    },
  },
  {
    accessorKey: 'ticket_status',
    cell: ({ row }) => {
      return <TicketStatusBadge className="tw-ml-0" status={row.original.ticket_status} />
    },
    header: 'Status',
    id: columnIds.ticket_state,
  },
  {
    accessorKey: 'skill.category',
    header: 'Request Type',
    id: columnIds.skill_name,
  },
  {
    accessorKey: 'brand.name',
    cell: ({ row }) => {
      return (
        row.original.brand && (
          <a href={`/brands/${row.original.brand.id}`} className="tw-flex tw-flex-col tw-gap-2">
            <div className="tw-text-sm tw-font-normal tw-text-cornflower-500">{row.original.brand.name}</div>
            <div className="tw-flex tw-gap-2">
              {row.original.brand?.colors?.map((color) => (
                <BrandColor variant={{ size: 'sm' }} key={color} color={color} />
              ))}
            </div>
          </a>
        )
      )
    },
    header: 'Brand',
    id: columnIds.brand_ids,
    sortDescFirst: false,
  },
  {
    id: columnIds.tag,
  },
  {
    accessorKey: 'ticket_created_by.name',
    header: 'Created By',
    id: columnIds.user_ids,
    cell: ({ row }) => {
      return <UserAvatar name={row.original.ticket_created_by.name} avatar={row.original.ticket_created_by.avatar} />
    },
  },
  {
    id: columnIds.designer_ids,
    accessorKey: 'designer.name',
    header: 'Designer',
    cell: ({ row }) => {
      return <UserAvatar isDesigner={true} name={row.original.designer?.name} avatar={row.original.designer?.avatar} />
    },
  },
  {
    id: columnIds.ticket_updated_date,
    accessorKey: 'ticket_updated_at',
    header: 'Last Updated',
    cell: ({ row }) => {
      return displayDate(row.original.ticket_updated_at * 1000, false).toLowerCase()
    },
    meta: {
      filterTitle: 'updated',
      filterVariant: 'date',
      filterStartKey: 'ticket_updated_at_gte',
      filterEndKey: 'ticket_updated_at_lte',
    },
  },
  {
    id: columnIds.format,
    header: 'Format',
    cell: ({ row }) => {
      return row.original?.ticket_deliverable_formats?.map((f) => `.${f}`)?.join(', ')
    },
    meta: {
      filterVariant: 'format',
      filterTitle: 'Format',
    },
    enableSorting: false,
  },
  {
    id: 'actions',
    enableSorting: false,
    cell: ({ cell }) => {
      return <SearchOverflowMenu ticket={cell.row.original} />
    },
    header: ({ table }) => <ActionsHeader table={table} />,
  },
]

const DEFAULT_PAGE_SIZE = 20

export const getFilters = (inputUrl?: URL) => {
  const url = inputUrl || new URL(window.location.href)
  const newFilters = []
  url.searchParams.forEach((value, key) => {
    if (key === 'page' || key === 'sort') {
      return
    }
    if (!value) {
      return
    }
    newFilters.push({
      id: key,
      value: value.split(','),
    })
  })

  return newFilters
}

const getSort = () => {
  const url = new URL(window.location.href)
  const sort = url.searchParams.get('sort')
  if (!sort) {
    return []
  }
  const [id, desc] = sort.split(':')
  return [{ id, desc: desc === 'desc' }]
}

export const useTable = () => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const [columnVisibility, setColumnVisibility] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_SEARCH_VISIBILITY_KEY)) || defaultColumnVisibility,
  )

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(new URL(window.location.href).searchParams.get('page')) || 1,
    pageSize: DEFAULT_PAGE_SIZE,
  })

  const [sorting, setSorting] = useState<SortingState>(getSort())

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(getFilters())

  // Used for holding the status of bulk select
  const [globalFilter, setGlobalFilter] = useState('')

  const query = useQuery({
    queryKey: ['search', pagination, columnFilters, sorting],
    queryFn: requestQuery({
      endpoint: 'getSearch',
      query: {
        ...columnFilters.reduce((acc, filter) => {
          // Special handling for date filter
          if (filter.id === columnIds.ticket_updated_date && Array.isArray(filter.value)) {
            const [start, end] = filter.value
            if (start) acc['ticket_updated_at_gte'] = start
            if (end) acc['ticket_updated_at_lte'] = end
            return acc
          }

          if (Array.isArray(filter.value)) {
            if (filter.value.length === 0) {
              return acc
            }
            acc[filter.id] = filter.value
            return acc
          }
          acc[filter.id] = filter.value
          return acc
        }, {}),
        page: String(pagination.pageIndex),
        sort_order: columnToSortKey[sorting[0]?.id] && (sorting[0]?.desc ? 'desc' : 'asc'),
        sort_key: columnToSortKey[sorting[0]?.id],
      },
    }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    // If all rows are unchecked
    if (Object.values(rowSelection).every((value) => value === false)) {
      setGlobalFilter('')
    }
  }, [rowSelection])

  useEffect(() => {
    const url = new URL(window.location.href)
    url.search = ''
    columnFilters.forEach((filter) => {
      if (Array.isArray(filter.value) && filter.value.length === 0) {
        return
      }
      url.searchParams.set(filter.id, String(filter.value))
    })
    url.searchParams.set('page', String(pagination.pageIndex))
    if (sorting.length > 0) {
      url.searchParams.set('sort', sorting.map((sort) => `${sort.id}:${sort.desc ? 'desc' : 'asc'}`)[0])
    }
    url.search = decodeURIComponent(url.search)
    window.history.pushState({}, '', url.toString())
  }, [pagination.pageIndex, columnFilters, sorting])

  const table = useReactTable({
    columns,
    data: query?.data?.data || [],
    state: {
      pagination,
      columnFilters,
      columnVisibility,
      sorting,
      rowSelection,
      globalFilter,
    },
    maxMultiSortColCount: 1,
    onColumnFiltersChange: (updaterOrValue) => {
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE })
      setColumnFilters(updaterOrValue)
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    enableRowSelection: (row) =>
      (!globalFilter || row.original.ticket_status === globalFilter) &&
      ![DesignRequestStatus.archived, DesignRequestStatus.canceled, DesignRequestStatus.draft].includes(
        row.original.ticket_status as DesignRequestStatus,
      ),
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    getRowId: (row) => String(row.ticket_id),
  })

  return {
    pagination,
    query,
    count: query?.data?.meta?.total,
    loading: query.isLoading,
    table,
    error: query.error,
  }
}
