import { ReactNode, useEffect, useState } from 'react'
import {
  getIsWatchingTicket,
  stopWatchingTicket as stopWatchingTicketApiCall,
  watchTicket as watchTicketApiCall,
} from 'lib/api/tickets/tickets'
import { BellOff, BellRing } from 'lucide-react'
import { cn } from 'lib/util/cn'
import { toast } from 'lib/components/toast/toast'

export default function WatchTicketItem({
  ticketId,
  className,
  children,
}: {
  ticketId: string
  className?: HTMLDivElement['className']
  children?: (props: { isWatching: boolean | null }) => ReactNode
}): JSX.Element {
  const [isWatching, setIsWatching] = useState<boolean | null>(null)

  useEffect(() => {
    const fetchIsWatching = async () => {
      try {
        const result = await getIsWatchingTicket(ticketId)
        setIsWatching(result.data)
      } catch (error) {
        console.error('Error fetching watch status:', error)
      }
    }
    fetchIsWatching()
  }, [ticketId])

  const watchTicket = async () => {
    try {
      setIsWatching(true)
      toast.success('You are now subscribed to email updates for this request')
      const result = await watchTicketApiCall(ticketId)
      if (result.status !== 'success') {
        setIsWatching(false)
      }
    } catch (error) {
      console.error('Error watching ticket:', error)
    }
  }

  const stopWatchingTicket = async () => {
    try {
      setIsWatching(false)
      toast.success('You have un-subscribed to email updates for this request')
      const result = await stopWatchingTicketApiCall(ticketId)
      if (result.status !== 'success') {
        setIsWatching(true)
      }
    } catch (error) {
      console.error('Error unwatching ticket:', error)
    }
  }

  const handleClick = () => {
    if (isWatching) {
      stopWatchingTicket()
    } else {
      watchTicket()
    }
  }

  const defaultContent = isWatching ? (
    <>
      <BellOff className="lu-sm" />
      Stop notifications
    </>
  ) : (
    <>
      <BellRing className="lu-sm" />
      Get notifications
    </>
  )

  return (
    <div
      onClick={handleClick}
      className={cn(
        { 'tw-cursor-pointer': isWatching != null },
        { 'tw-opacity-75': isWatching == null },
        'tw-flex tw-items-center tw-gap-1',
        className,
      )}
      data-testid="watch-ticket-item"
    >
      {children ? children({ isWatching }) : defaultContent}
    </div>
  )
}
