import SelectBox, { SelectBoxOption } from 'lib/components/dropdown/select-box'
import { HTMLAttributes } from 'react'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'

interface AdminTicketStateDropdownProps {
  state?: string
  onChange: (state: string) => void
  className?: HTMLAttributes<HTMLDivElement>['className']
}

const AdminTicketStateDropdown = ({ state, onChange, className }: AdminTicketStateDropdownProps) => {
  const { ticket } = useAdminTicketContext()

  const options: SelectBoxOption[] = [
    {
      value: 'draft',
      displayElement: <>Draft</>,
      disabled: ticket.disableManualChanges.includes('draft'),
    },
    {
      value: 'open',
      displayElement: <>Open</>,
      disabled: ticket.disableManualChanges.includes('open'),
    },
    {
      value: 'assigned',
      displayElement: <>Assigned</>,
      disabled: ticket.disableManualChanges.includes('assigned'),
    },
    {
      value: 'in_progress',
      displayElement: <>In Progress</>,
      disabled: ticket.disableManualChanges.includes('in_progress'),
    },
    {
      value: 'incomplete',
      displayElement: <>Incomplete</>,
      disabled: ticket.disableManualChanges.includes('incomplete'),
    },
    {
      value: 'quality_review',
      displayElement: <>Quality Review</>,
      disabled: ticket.disableManualChanges.includes('quality_review'),
    },
    {
      value: 'customer_review',
      displayElement: <>Customer Review</>,
      disabled: ticket.disableManualChanges.includes('customer_review'),
    },
    {
      value: 'customer_clarification',
      displayElement: <>Customer Clarification</>,
      disabled: ticket.disableManualChanges.includes('customer_clarification'),
    },
    {
      value: 'closed',
      displayElement: <>Closed</>,
      disabled: ticket.disableManualChanges.includes('closed'),
    },
    {
      value: 'archived',
      displayElement: <>Archived</>,
      disabled: ticket.disableManualChanges.includes('archived'),
    },
    {
      value: 'canceled',
      displayElement: <>Canceled</>,
      disabled: ticket.disableManualChanges.includes('canceled'),
    },
    {
      value: 'inactive',
      displayElement: <>Inactive</>,
      disabled: ticket.disableManualChanges.includes('inactive'),
    },
  ]

  return <SelectBox options={options} handleChange={onChange} selectedValue={state} className={className} />
}

export default AdminTicketStateDropdown
