import { Switch } from '@headlessui/react'
import { cn } from 'lib/util/cn'

const classNames = {
  switchGroup: 'tw-flex tw-items-center',
  switchLabel: 'tw-mr-1 tw-mb-0 tw-text-black tw-uppercase',
  switchToggle: {
    disabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw--translate-x-1',
    enabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw-translate-x-4',
  },
  switchWrapper: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md tw-grid tw-grid-cols-3 tw-gap-4',
}

interface SourceSwitchProps {
  source: string
  setSource: (value: string) => void
}

export default function SourceSwitch({ source, setSource }: SourceSwitchProps) {
  return (
    <div className={classNames.switchWrapper}>
      <Switch.Group>
        <div className={classNames.switchGroup}>
          <Switch.Label className={classNames.switchLabel}>Source</Switch.Label>
          <Switch
            checked={source === 'true'}
            onChange={(value) => setSource(value.toString())}
            className={cn(
              source === 'true' ? 'tw-bg-gherkin-500' : 'tw-bg-neutral-200',
              'tw-relative tw-inline-flex tw-h-[18px] tw-w-[38px] tw-flex-shrink-0 tw-cursor-pointer tw-rounded-full tw-border-none tw-p-0.5 tw-transition-colors tw-duration-200 tw-ease-in-out',
            )}
          >
            <span className="sr-only">{source}</span>
            <span
              aria-hidden="true"
              className={cn(
                'tw-pointer-events-none tw-inline-block tw-h-[14px] tw-w-[14px] tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out',
                source === 'true' ? 'tw-translate-x-[20px]' : 'tw--translate-x-0',
              )}
            />
          </Switch>
        </div>
      </Switch.Group>
    </div>
  )
}
