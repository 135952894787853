// remove once using tag-filter

import { Table } from '@tanstack/react-table'
import SearchFilter from './filters/search-filter'
import BrandFilter from './filters/brand-filter'
import StatusFilter from './filters/status-filter'
import SkillFilter from './filters/skill-filter'
import CreatedByFilter from './filters/created-by-filter'
import FormatFilter from './filters/format-filter'
import UpdatedAtFilter from './filters/updated-at-filter'
import DesignersFilter from './filters/designers-filter'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import { cn } from 'lib/util/cn'
import { LinkLikeButton } from 'components/core/button'
import SaveSearch from './save-search'
import TagsFilter from './filters/tag-filter-no-edit'
import { Info } from 'lucide-react'
import { Popover, PopoverTrigger, PopoverContent } from 'lib/components/popover/popover'

export interface FiltersProps<TData> {
  table: Table<TData>
}

export default function Filters<TData>({ table }: FiltersProps<TData>) {
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <div className="tw-flex tw-w-full tw-items-center tw-gap-2">
        <SearchFilter table={table} className="tw-w-3/4" />
        <Popover>
          <PopoverTrigger asChild>
            <Info className="tw-h-5 tw-w-5 tw-text-black" />
          </PopoverTrigger>
          <PopoverContent intent="tooltip">
            <div className="tw-text-white">
              Search lets you find requests by titles, IDs, and request details like directions and annotations. Want
              tips on locating design requests? Check out our{' '}
              <a
                className="tw-text-white tw-underline"
                href="http://help.designpickle.com/en/articles/3809713"
                target="_blank"
                rel="noreferrer"
              >
                Knowledge Base article
              </a>{' '}
              for best practices.
            </div>
          </PopoverContent>
        </Popover>
        {table.getState().columnFilters.length > 0 && (
          <>
            <div className="tw-mx-2 tw-h-6 tw-w-px tw-bg-peppercorn-100"></div>
            <SaveSearch />
            <LinkLikeButton color="secondary" onClick={() => table.resetColumnFilters(true)}>
              Clear filters
            </LinkLikeButton>
          </>
        )}
      </div>
      <div className={cn('tw-flex tw-flex-wrap tw-items-center tw-gap-2')}>
        {isFeatureFlagEnabled('company_tags') && <TagsFilter table={table} />}
        <StatusFilter table={table} />
        <SkillFilter table={table} />
        <BrandFilter table={table} />
        <UpdatedAtFilter table={table} />
        <DesignersFilter table={table} />
        <CreatedByFilter table={table} />
        <FormatFilter table={table} />
      </div>
    </div>
  )
}
