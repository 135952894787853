import { CheckIcon, CopyIcon } from 'lucide-react'
import { cloneElement, ReactElement, useState } from 'react'
import Tooltip, { Direction } from '../tooltip/tooltip'
import IconButton, { Colors } from './icon-button'

interface CopyTextButtonProps {
  textToCopy: string
  tooltipDirection?: Direction
  defaultButtonColor?: Colors
  tooltipCopyText?: string
  tooltipCopiedText?: string
  defaultIcon?: ReactElement
}
export default function CopyTextButton({
  textToCopy,
  tooltipDirection = 'left',
  defaultButtonColor = 'secondary',
  tooltipCopyText = 'Copy to clipboard',
  tooltipCopiedText = 'Copied!',
  defaultIcon = <CopyIcon />,
}: CopyTextButtonProps) {
  const [copyPressed, setCopyPressed] = useState(false)

  async function handleCopyText() {
    await navigator.clipboard.writeText(textToCopy)

    setCopyPressed(true)
  }

  function resetCopyPressed() {
    setCopyPressed(false)
  }

  return (
    <Tooltip
      content={copyPressed ? tooltipCopiedText : tooltipCopyText}
      width={400}
      direction={tooltipDirection}
      leftOffset={-10}
    >
      <IconButton
        color={copyPressed ? 'success' : defaultButtonColor}
        onClick={handleCopyText}
        onMouseLeave={resetCopyPressed}
        data-testid={copyPressed ? 'check-button' : 'clone-button'}
      >
        {copyPressed ? (
          <CheckIcon className="tw-h-4 tw-w-4" />
        ) : (
          cloneElement(defaultIcon, {
            className: 'tw-h-4 tw-w-4',
          })
        )}
      </IconButton>
    </Tooltip>
  )
}
