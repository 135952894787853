import { DesignerAllocation } from 'lib/api/company-designer-allocations/company-designer-allocations'
import DesignerRow from './designer-row'
import { useDesignerAllocationsContext } from './designer-allocations-provider'
import { LoadingScreenTableRow } from 'components/pages/requests/empty-screens'

const classNames = {
  header: 'tw-p-3 tw-text-left tw-text-xs tw-font-medium tw-uppercase tw-tracking-wide tw-text-gray-500',
  row: 'tw-border-0 tw-border-solid tw-border-b tw-border-peppercorn-100',
  table: 'tw-min-w-full tw-mb-4',
  wrapper: 'tw-mt-4',
}

export default function DesignerTable() {
  const { companyAllocation, isLoading } = useDesignerAllocationsContext()
  const { designerAllocations } = companyAllocation

  return (
    <div className={classNames.wrapper}>
      <table className={classNames.table}>
        <thead>
          <tr className={classNames.row}>
            <th scope="col" className={classNames.header}>
              Position
            </th>
            <th scope="col" className={classNames.header}>
              Creative
            </th>
            <th scope="col" className={classNames.header}>
              Timezone
            </th>
            <th scope="col" className={classNames.header}>
              Scopes
            </th>
            <th scope="col" className={classNames.header}>
              Total Allocated Companies
            </th>
            <th scope="col" className={classNames.header}>
              Daily Capacity
            </th>
            <th scope="col" className={classNames.header}></th>
          </tr>
        </thead>
        {isLoading ? (
          <tbody>
            <LoadingScreenTableRow />
          </tbody>
        ) : (
          <tbody>
            {designerAllocations.map((allocation: DesignerAllocation, index: number) => (
              <DesignerRow key={allocation.id} designerAllocation={allocation} index={index} />
            ))}
            <DesignerRow
              key={0}
              position={designerAllocations.length > 0 ? designerAllocations.at(-1).position + 1 : 1}
              index={designerAllocations.length}
            />
          </tbody>
        )}
      </table>
    </div>
  )
}
