import { RefObject } from 'react'

/**
 * @class MaskCanvas
 * @description
 * This class is used to create a canvas element with a mask applied to it.
 * The mask is applied to the canvas by overlaying a mask image on top of the
 * original image where the white pixels are made semi-transparent
 * blue. Pass in an HTMLElement and the class will append the canvas to it.
 * Be sure to call the destroy method when unmounting your component.
 * @param {Object} RefObject - A React ref for an HTMLElement.
 * @param {String} imageUrl - The URL of the original image.
 * @param {String} maskUrl - The URL of the mask image.
 * @param {Number} [canvasHeight] - The canvas be displayed with this height but keep the aspect ratio of the image.
 * @example
 *   const ref = useRef()
 *   const imageUrl = 'https://example.com/image.jpg'
 *   const maskUrl = 'https://example.com/mask.png'
 *   const maskCanvas = new MaskCanvas(ref, imageUrl, maskUrl, 100)
 *
 *   maskCanvas.destroy()
 */
export default class MaskCanvas {
  readonly #canvas: HTMLCanvasElement
  readonly #canvasHeight: number
  #context = null
  readonly #imageUrl: string
  readonly #maskUrl: string
  #parentNode: HTMLElement = null
  readonly #ref: RefObject<HTMLElement>

  constructor(ref: RefObject<HTMLElement>, imageUrl: string, maskUrl: string, canvasHeight = 96) {
    this.#imageUrl = imageUrl
    this.#maskUrl = maskUrl
    this.#canvasHeight = canvasHeight
    this.#ref = ref
    this.#canvas = document.createElement('canvas', {})
    this.#context = this.#canvas.getContext('2d')

    const image = new Image()
    image.crossOrigin = 'anonymous'
    image.onload = this.#loadImage.bind(this, image)
    image.src = this.#imageUrl
  }

  destroy() {
    while (this.#parentNode?.firstChild) {
      this.#parentNode.removeChild(this.#parentNode.firstChild)
    }
    this.#parentNode = null
  }

  #loadImage(image: HTMLImageElement) {
    const scale = this.#canvasHeight / image.height
    const width = image.width * scale

    this.#canvas.width = width
    this.#canvas.height = this.#canvasHeight

    this.#context.drawImage(image, 0, 0, width, this.#canvasHeight)

    const mask = new Image()
    mask.crossOrigin = 'anonymous'
    mask.onload = this.#loadMask.bind(this, mask, width)
    mask.src = this.#maskUrl
  }

  #loadMask(mask: HTMLImageElement, width: number) {
    const tempCanvas = document.createElement('canvas')
    const tempContext = tempCanvas.getContext('2d')
    tempCanvas.width = width
    tempCanvas.height = this.#canvasHeight

    tempContext.drawImage(mask, 0, 0, width, this.#canvasHeight)

    this.#makeStyledMask(tempContext, width, this.#canvasHeight)

    this.#context.drawImage(tempCanvas, 0, 0)
    this.#ref.current.appendChild(this.#canvas)
    this.#parentNode = this.#ref.current
  }

  #makeStyledMask(context: CanvasRenderingContext2D, width: number, height: number) {
    const imageData = context.getImageData(0, 0, width, height)
    const data = imageData.data
    for (let i = 0; i < data.length; i += 4) {
      if (data[i] === 0 && data[i + 1] === 0 && data[i + 2] === 0) {
        // black pixels, make transparent
        data[i + 3] = 0
      } else {
        // white pixels, make them semi-transparent blue
        data[i] = 120
        data[i + 1] = 120
        data[i + 2] = 250
        data[i + 3] = 204
      }
    }
    context.putImageData(imageData, 0, 0)
  }
}
