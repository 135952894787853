import ExternalActionLinkItem from 'components/elements/dropdown/items/external-action-link-item'
import { haveFiles } from 'lib/ticket/utils'
import ActionLinkItem from '../items/action-link-item'

interface ArchivedTabMenuProps {
  id: bigint | number
  subject: string
  filesDownloadUrl: string
  status: string
  ticketFilesCount: bigint | number
  userId: number
  menuActionBtn: (route: string, data: Record<string, unknown>, method: string) => void
  showDelete?: boolean
}

export default function ArchivedTabMenu({
  id,
  filesDownloadUrl,
  menuActionBtn,
  subject,
  ticketFilesCount,
  userId,
  showDelete = true,
}: ArchivedTabMenuProps): JSX.Element {
  const downloadAssetsButtonRoute = filesDownloadUrl ? filesDownloadUrl.toString() : ''
  const deleteTicketUrl = window.Routes.apiInternalTicketUrl(id)

  const deleteButtonData = { id: id, user: userId }

  const deleteModalData = {
    title: 'Delete Request?',
    message: `Are you sure you want to delete ${subject}?`,
    confirmBtnText: 'Yes, Delete',
    confirmAction: () => {
      menuActionBtn(deleteTicketUrl, deleteButtonData, 'DELETE')
    },
  }

  return (
    <>
      {haveFiles(ticketFilesCount) && (
        <ExternalActionLinkItem
          label="Download Assets"
          iconClass="far fa-folder-download"
          route={downloadAssetsButtonRoute}
        />
      )}
      <ExternalActionLinkItem
        label="Duplicate"
        iconClass="far fa-copy"
        route={`/tickets/${id}/duplications`}
        method="POST"
      />
      {showDelete && (
        <ActionLinkItem
          label="Delete"
          iconClass="fas fa-trash"
          route={deleteTicketUrl}
          method="DELETE"
          data={deleteButtonData}
          menuActionBtn={menuActionBtn}
          modalData={deleteModalData}
        />
      )}
    </>
  )
}
