import { ChevronDown, ChevronUp } from 'lucide-react'
import { QaReview } from 'lib/api/qa_reviews/qa_reviews'
import { displayDate } from 'lib/util/date'
import { QaReviewFiltersParams } from './qa-reviews-page'

interface QaReviewsTableProps {
  filters: QaReviewFiltersParams
  qaReviews: QaReview[]
  setFilters: (filters: QaReviewFiltersParams) => void
}

const classNames = {
  table: 'tw-w-full',
  tableCell: 'tw-p-4 tw-align-bottom tw-border-0 tw-border-t-neutral-200 tw-border-t tw-border-solid',
  tableHead: 'tw-text-peppercorn-900',
  tableHeadCell:
    'tw-p-4 tw-align-bottom tw-border-0 tw-border-b-neutral-200 tw-border-b tw-border-solid tw-cursor-pointer',
  tableRow: 'even:tw-bg-neutral-50',
}

export default function QaReviewsTable({ filters, qaReviews, setFilters }: QaReviewsTableProps) {
  const [sortValue, sortDirection] = filters.s.split(' ')
  const headers: { label: string; value: string }[] = [
    { label: 'Created at', value: 'created_at' },
    { label: 'Company', value: 'ticket_company_name' },
    { label: 'Ticket', value: 'ticket_id' },
    { label: 'Designer', value: 'ticket_designer_name' },
    { label: 'Status', value: 'state' },
    { label: 'Quality Specialist', value: 'qa_specialist_name' },
    { label: 'Ticket Status Changed At', value: 'ticket_state_changed_at' },
    { label: 'Started At', value: 'started_at' },
    { label: 'Finished At', value: 'finished_at' },
  ]

  const onSortClick = (value) => {
    if (sortValue === value) {
      const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
      setFilters({ ...filters, s: `${value} ${newSortDirection}` })
    } else {
      setFilters({ ...filters, s: `${value} desc` })
    }
  }

  function getSortDirection(value) {
    if (sortValue === value) return sortDirection

    return 'asc'
  }

  return (
    <table className={classNames.table}>
      <thead className={classNames.tableHead}>
        <tr>
          {headers.map(({ label, value }) => (
            <th className={classNames.tableHeadCell} key={label} onClick={() => onSortClick(value)}>
              {label}
              {getSortDirection(value) === 'asc' && <ChevronDown className="lu-light lu-sm" />}
              {getSortDirection(value) === 'desc' && <ChevronUp className="lu-light lu-sm" />}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {qaReviews.map((review) => (
          <tr key={review.id} data-testid="qa-review" className={classNames.tableRow}>
            <td className={classNames.tableCell}>{displayDate(review.createdAt)}</td>
            <td className={classNames.tableCell}>
              <a href={`/admin/companies?company_id=${review.companyId}`}>{review.companyName}</a>
            </td>
            <td className={classNames.tableCell}>
              {review.ticketId && (
                <a href={`/admin/tickets/pte/${review.ticketId}`}>{`#${review.ticketId} - ${review.ticketSubject}`}</a>
              )}
            </td>
            <td className={classNames.tableCell}>
              <a href={`/admin/designers?designer_id=${review.designerId}`}>{review.designerName}</a>
            </td>
            <td className={classNames.tableCell}>{review.state}</td>
            <td className={classNames.tableCell}>
              <a href={`/admin/qa_specialists/${review.qaSpecialist?.id}/edit`}>{review.qaSpecialist?.name}</a>
            </td>
            <td className={classNames.tableCell}>{displayDate(review.ticketStateChangedAt)}</td>
            <td className={classNames.tableCell}>{displayDate(review.startedAt)}</td>
            <td className={classNames.tableCell}>{displayDate(review.finishedAt)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
