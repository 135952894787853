import { ReactElement, useState } from 'react'

import { AnnotationRecord } from 'lib/api/annotations/annotations'

import { useAnnotationsContext } from '../providers/annotations-provider'
import EditAnnotation from './edit-annotation'
import AnnotationDetails from './annotation-details'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'

interface AnnotationListItemProps {
  annotation: AnnotationRecord<AnnotoriousAnnotation>
  onDeleteClick: (annotation: AnnotationRecord<AnnotoriousAnnotation>) => void
}

export default function AnnotationListItem({ annotation, onDeleteClick }: AnnotationListItemProps): ReactElement {
  const [editMode, setEditMode] = useState(false)
  const { cancelAnnotation, canEditAnnotation, setShowImageAnnotationCreator } = useAnnotationsContext()

  function disableEditMode() {
    setEditMode(false)
  }

  function enableEditMode() {
    if (canEditAnnotation(annotation)) {
      setEditMode(true)
      setShowImageAnnotationCreator(false)
      cancelAnnotation()
    }
  }

  return (
    <>
      {editMode ? (
        <EditAnnotation annotation={annotation} disableEditMode={disableEditMode} />
      ) : (
        <AnnotationDetails annotation={annotation} enableEditMode={enableEditMode} onDeleteClick={onDeleteClick} />
      )}
    </>
  )
}
