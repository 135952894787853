import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { Transfer } from 'interfaces/transfer'
import { ReactElement } from 'react'

import { ConvertDateToAmerican, DateDifferenceInDays } from '../shared/table/column-elements'

interface TransferProps {
  transfer: Transfer
  onTransferChange?: () => void
}

export const DaysUntilTransfer = ({ transferDate }: { transferDate: string }): ReactElement => {
  const calculateRemainingDays = (date) => {
    const daysUntilTransfer = DateDifferenceInDays(Date.parse(date), Date.now())
    if (daysUntilTransfer > 0) return `${daysUntilTransfer} days`
    return null
  }

  const remainingDays = calculateRemainingDays(transferDate)

  return (
    <td className="" data-testid="company-days-until-transfer-container">
      {remainingDays}
      <br />
      {`Will be Transferred ${ConvertDateToAmerican(transferDate)}`}
    </td>
  )
}

export const CompanyOptions = ({ transfer, onTransferChange }: TransferProps): ReactElement => {
  const { preventable } = transfer

  const HandleDeleteTransfer = async () => {
    const url = window.Routes.apiInternalReportTransferUrl({
      id: transfer.id,
    })

    if (!window.confirm(`Are you sure you want to delete this transfer?`)) return

    await axios.delete(url)
    onTransferChange()
  }

  const HandleMarkPreventable = async () => {
    const url = window.Routes.apiInternalReportTransferUrl({
      id: transfer.id,
    })

    if (!window.confirm(`Are you sure you want to mark this transfer as${preventable ? ' not' : ''} preventable?`))
      return

    await axios.put(url, {
      preventable: !preventable,
    })
    onTransferChange()
  }

  return (
    <td className="tw-pr-3" data-testid="company-options-container">
      <span className="tw-cursor-pointer tw-text-red-500" onClick={HandleDeleteTransfer}>
        <FontAwesomeIcon icon={['fal', 'trash-alt']} />{' '}
      </span>
      <span className="tw-cursor-pointer tw-text-blue-300" onClick={HandleMarkPreventable}>
        <FontAwesomeIcon icon={[preventable ? 'fas' : 'fal', 'shield']} />
      </span>
    </td>
  )
}

export const NumberOfRequests = ({ transfer }: TransferProps): ReactElement => (
  <td>
    <a
      href={window.Routes.adminTicketsUrl({
        company_id: transfer.company.id,
      })}
      target="_blank"
      rel="noreferrer"
    >
      {transfer.company.numberOfRequests}
    </a>
  </td>
)
