import { useState, useMemo, useEffect, HTMLAttributes } from 'react'
import { Table } from '@tanstack/react-table'
import { debounce } from 'lodash'
import { columnIds } from '../use-table'
import SearchInput from 'lib/components/search-input/search-input'

interface SearchFilterProps<TData> {
  table: Table<TData>
  className?: HTMLAttributes<HTMLDivElement>['className']
}

export default function SearchFilter<TData>({ table, className }: SearchFilterProps<TData>) {
  const column = table.getColumn(columnIds.query)

  const filterValue = column.getFilterValue()

  const [search, setSearch] = useState((filterValue as string) || '')

  const debouncedOnChange = useMemo(() => debounce((value: string) => column.setFilterValue(value), 500), [column])

  const handleChange = (value: string) => {
    setSearch(value)
    debouncedOnChange(value)
  }

  useEffect(() => {
    if (!filterValue) {
      setSearch('')
    } else {
      setSearch(String(filterValue))
    }
  }, [filterValue])

  if (!column) return null

  return <SearchInput value={search} onChange={handleChange} className={className} />
}
