import { useEffect, useRef, useState } from 'react'
import { useBrandsContext } from './brands-provider'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { Brand } from 'lib/api/companies/companies'
import noBrandsImage from 'images/requests/request-dropdown-empty-brands.jpg'
import { cva, VariantProps } from 'class-variance-authority'
interface BrandsProps {
  onSelect: (brand: Brand) => void
  companyId: number
  ticketBrand: Brand
}

interface MultiBrandsProps {
  onSelect: (brands: Brand[]) => void
  companyId: number
  ticketBrands: Brand[]
}

interface BrandDetailsProps {
  brand: Brand
  onSelect: (brand: Brand) => void
  isSelected: boolean
}

const sizes = cva('tw-rounded-full tw-border tw-border-solid tw-border-neutral-300', {
  variants: {
    size: { sm: 'tw-h-4 tw-w-4', md: 'tw-h-6 tw-w-6' },
  },
  defaultVariants: {
    size: 'md',
  },
})

export type BrandColorVariant = VariantProps<typeof sizes>

export function BrandColor({ color, variant }: { color: string; variant?: BrandColorVariant }): JSX.Element {
  return <div className={sizes(variant)} style={{ backgroundColor: color }} />
}

function BrandDetails({ brand, onSelect, isSelected }: BrandDetailsProps): JSX.Element {
  const classNames = {
    container: `tw-flex
      tw-p-4
      tw-rounded
      tw-cursor-pointer
      tw-justify-between
      tw-items-center
      tw-transition-all
      tw-border-solid
      tw-border-2
      tw-border-neutral-200
      hover:tw-bg-cornflower-50
      hover:tw-border-cornflower-300`,
    selectedContainer: 'tw-bg-cornflower-100 tw-border-cornflower-300',
    brandName: `tw-text-sm tw-text-neutral-800 tw-font-normal`,
    colorsContainer: `tw-flex tw-gap-2`,
  }
  const colors = brand.colors.slice(0, 4)

  return (
    <div
      className={`${classNames.container} ${isSelected ? classNames.selectedContainer : ''}`}
      onClick={() => onSelect(brand)}
    >
      <div className={classNames.brandName}>{brand.name}</div>
      <div className={classNames.colorsContainer}>
        {colors.map((brandColor) => (
          <BrandColor key={brandColor.id} color={brandColor.value} />
        ))}
      </div>
    </div>
  )
}

export default function Brands({ onSelect, companyId, ticketBrand }: BrandsProps): JSX.Element {
  // The ref is used to call onSelect on dismissal of the dropdown
  const selectedBrandRef = useRef(ticketBrand)
  const { getBrandsByCompany, isLoading } = useBrandsContext()
  const [brandsByCompany, setBrandsByCompany] = useState([])
  // State is still needed for the rerender cycle to be called
  const [selectedBrand, setSelectedBrand] = useState(ticketBrand)
  const classNames = {
    noBrandsContainer: `tw-flex tw-flex-col tw-items-center`,
    noBrandsText: `tw-text-sm tw-text-neutral-800`,
  }

  useEffect(() => {
    let isAbandoned
    getBrandsByCompany(companyId).then((response) => {
      if (!isAbandoned) {
        setBrandsByCompany(response)
      }
    })
    return () => {
      isAbandoned = true
    }
  }, [companyId, getBrandsByCompany])

  function handleSelectBrand(brand: Brand) {
    if (selectedBrand?.id === brand?.id) {
      selectedBrandRef.current = null
      setSelectedBrand(null)
    } else {
      selectedBrandRef.current = brand
      setSelectedBrand(brand)
    }
  }

  useEffect(() => {
    // Gets called when dropdown is dismissed
    return function () {
      if (selectedBrandRef.current !== ticketBrand) {
        onSelect(selectedBrandRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (ticketBrand) {
      selectedBrandRef.current = ticketBrand
      setSelectedBrand(ticketBrand)
    }
  }, [ticketBrand])

  if (isLoading) {
    return <LoadingScreen size="2xl" />
  }

  if (!isLoading && brandsByCompany.length === 0) {
    return (
      <div className={classNames.noBrandsContainer}>
        <img src={noBrandsImage} alt="Empty Brands Image" className="tw-h-auto tw-w-full" />
        <div className={`${classNames.noBrandsText} tw-font-semibold`}>You don&apos;t currently have any brands.</div>
        <div className={`${classNames.noBrandsText} tw-font-normal`}>Get started by creating a brand profile.</div>
      </div>
    )
  }

  return (
    <>
      <h3>Brand Profile</h3>
      <div className="tw-flex tw-flex-col tw-gap-4">
        {brandsByCompany?.map((b) => (
          <BrandDetails key={b.id} brand={b} onSelect={handleSelectBrand} isSelected={b.id === selectedBrand?.id} />
        ))}
      </div>
    </>
  )
}

export function MultiBrands({ onSelect, companyId, ticketBrands }: MultiBrandsProps): JSX.Element {
  // The ref is used to call onSelect on dismissal of the dropdown
  const selectedBrandsRef = useRef([])
  const { getBrandsByCompany, isLoading } = useBrandsContext()
  const [brandsByCompany, setBrandsByCompany] = useState([])
  // State is still needed for the rerender cycle to be called
  const [selectedBrands, setSelectedBrands] = useState(ticketBrands)
  const classNames = {
    noBrandsContainer: `tw-flex tw-flex-col tw-items-center`,
    noBrandsText: `tw-text-sm tw-text-neutral-800`,
  }

  useEffect(() => {
    let isAbandoned
    getBrandsByCompany(companyId).then((response) => {
      if (!isAbandoned) {
        setBrandsByCompany(response)
      }
    })
    return () => {
      isAbandoned = true
    }
  }, [companyId, getBrandsByCompany])

  function handleSelectBrand(brand: Brand) {
    if (selectedBrands.find((b) => b.id === brand.id)) {
      const updatedValues = selectedBrands.filter((b) => b.id !== brand.id)

      selectedBrandsRef.current = updatedValues
      setSelectedBrands(updatedValues)
    } else {
      const updatedValues = [...selectedBrands, brand]

      selectedBrandsRef.current = updatedValues
      setSelectedBrands(updatedValues)
    }
  }

  useEffect(() => {
    // Gets called when dropdown is dismissed
    return function () {
      if (selectedBrandsRef.current !== selectedBrands) {
        onSelect(selectedBrandsRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (ticketBrands) {
      selectedBrandsRef.current = ticketBrands
      setSelectedBrands(ticketBrands)
    }
  }, [ticketBrands])

  if (isLoading) {
    return <LoadingScreen size="2xl" />
  }

  if (!isLoading && brandsByCompany.length === 0) {
    return (
      <div className={classNames.noBrandsContainer}>
        <img src={noBrandsImage} alt="Empty Brands Image" className="tw-h-auto tw-w-full" />
        <div className={`${classNames.noBrandsText} tw-font-semibold`}>You don&apos;t currently have any brands.</div>
        <div className={`${classNames.noBrandsText} tw-font-normal`}>Get started by creating a brand profile.</div>
      </div>
    )
  }

  return (
    <>
      <h3>Brand Profiles</h3>
      <div className="tw-flex tw-flex-col tw-gap-4">
        {brandsByCompany?.map((b) => (
          <BrandDetails key={b.id} brand={b} onSelect={handleSelectBrand} isSelected={selectedBrands.includes(b)} />
        ))}
      </div>
    </>
  )
}
