import React from 'react'
import { Menu } from '@headlessui/react'

interface ModalData {
  title: string
  message: string
}

// Either overwrite the onClick using clickAction or fill out the remaining properties.
interface ActionLinkItemProps {
  label: string
  iconClass: string
  route?: string
  method?: 'GET' | 'POST' | 'PATCH' | 'DELETE'
  data?: Record<string, unknown>
  menuActionBtn?: (route: string, data: Record<string, unknown>, method: string, modalData: ModalData) => void
  modalData?: ModalData
  onClick?: () => void
}

export default function ActionLinkItem({
  label,
  iconClass,
  route,
  method,
  data,
  menuActionBtn,
  modalData,
  onClick,
}: ActionLinkItemProps): JSX.Element {
  function onClickAction(event) {
    event.preventDefault()
    event.stopPropagation()
    if (onClick instanceof Function) {
      onClick()
    } else {
      menuActionBtn(route, data, method, modalData)
    }
  }

  return (
    <Menu.Item>
      {() => (
        <a
          href="#"
          onClick={onClickAction}
          className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-peppercorn-700 hover:tw-bg-peppercorn-100 hover:tw-text-peppercorn-900"
        >
          <i className={iconClass}></i>
          &nbsp;
          {label}
        </a>
      )}
    </Menu.Item>
  )
}
