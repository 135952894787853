import { useEffect, useMemo } from 'react'
import { uniqBy } from 'lodash'

import { CompanyTag } from 'lib/api/company-tags/company-tags'
import TagInput, { Tag } from 'lib/components/tag-input/tag-input'

import { useCompanyTaggingContext } from 'providers/company-tagging-provider'
import CompanyTaggingSearchResults from './company-tagging-search-results'
import { errorMessage } from './input-validation'

export default function CompanyTaggingTypeahead({ showTitle }: { showTitle?: boolean }) {
  const {
    createAndSelectCompanyTag,
    searchResults,
    searchTerm,
    selectedTags,
    partiallySelectedTags,
    setSearchTerm,
    unselectCompanyTag,
    warningMessage,
  } = useCompanyTaggingContext()

  const errorMessageText = errorMessage(searchTerm)

  const selectedTagsAsTags: Tag[] = useMemo(() => {
    if (partiallySelectedTags?.length > 0) {
      return uniqBy(
        selectedTags.filter((tag) => !partiallySelectedTags.some((partialTag) => partialTag.id === tag.id)),
        'id',
      )
    }
    return uniqBy(selectedTags, 'id')
  }, [selectedTags, partiallySelectedTags])

  async function handleSubmit() {
    if (searchResults.length > 0 || !searchTerm || !!errorMessageText) return
    await createAndSelectCompanyTag(searchTerm)
  }

  async function handleRemoveTag(tag: Tag) {
    await unselectCompanyTag(tag as CompanyTag)
  }

  useEffect(() => {
    return () => {
      setSearchTerm('')
    }
  }, [setSearchTerm])

  return (
    <div>
      {showTitle && <h4>Tags</h4>}
      {warningMessage && <p className="tw-mb-2 tw-text-sm tw-text-neutral-600">{warningMessage}</p>}
      <div className="tw-max-h-40 tw-overflow-y-auto">
        <TagInput
          focusOnMount
          selectedTags={selectedTagsAsTags}
          removeTag={handleRemoveTag}
          handleSubmit={handleSubmit}
          inputValue={searchTerm}
          onInputChange={setSearchTerm}
          placeholder="Search for a tag..."
          tagColor="primary"
          error={!!errorMessageText}
        />
      </div>
      <CompanyTaggingSearchResults />
      {errorMessageText && <p className="tw-mb-2 tw-px-2 tw-text-sm tw-text-red-500">{errorMessageText}</p>}
    </div>
  )
}
