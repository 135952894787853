import { DetailTaskType } from 'components/pages/request/types/detail-task'
import RequestAssistGenerateModal from '../../ai/modals/request-assist-generate-modal'
import { CreateAssistGenerateModal } from '../../ai/modals/create-assist-generate-modal'
import DirectionManualCopyModal from './direction-manual-copy-modal'

interface DirectionModalProps {
  onClose: () => void
  type: DetailTaskType
}

export default function DirectionModal({ onClose, type }: DirectionModalProps): JSX.Element {
  return (
    <>
      <RequestAssistGenerateModal open={type === DetailTaskType.GEN_AI_REQUEST} setOpen={() => onClose()} />
      <DirectionManualCopyModal open={type === DetailTaskType.COPY} setOpen={() => onClose()} />
      <CreateAssistGenerateModal open={type === DetailTaskType.TXT2IMG} setOpen={() => onClose()} />
    </>
  )
}
