import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import { CompanyTag, getCompanyTags } from 'lib/api/company-tags/company-tags'
import { toast } from 'lib/components/toast/toast'

export interface AllCompanyTagsProviderProps {
  children: ReactNode
}

export interface AllCompanyTagContextValue {
  companyTags: CompanyTag[]
  setCompanyTags: Dispatch<SetStateAction<CompanyTag[]>>
}

const AllCompanyTagsContext = createContext<AllCompanyTagContextValue | null>(null)

export function useAllCompanyTagsContext(): AllCompanyTagContextValue {
  const context = useContext<AllCompanyTagContextValue>(AllCompanyTagsContext)
  if (!context) {
    throw new Error('useAllCompanyTagsContext must be used within a AllCompanyTagsProvider')
  }
  return context
}

export default function AllCompanyTagsProvider({ children }: AllCompanyTagsProviderProps) {
  const [companyTags, setCompanyTags] = useState<CompanyTag[]>([])

  useEffect(() => {
    async function fetchCompanyTags() {
      try {
        const tags = await getCompanyTags()
        setCompanyTags(tags)
      } catch (error) {
        console.error('Error fetching company tags', error)
        toast.error('Error fetching company tags')
      }
    }
    fetchCompanyTags()
  }, [])

  const contextValue: AllCompanyTagContextValue = {
    companyTags,
    setCompanyTags,
  }

  return <AllCompanyTagsContext.Provider value={contextValue}>{children}</AllCompanyTagsContext.Provider>
}
