import { AdminUser } from 'providers/admin-user-provider'
import { bindJWT, postApi } from 'lib/api/api'
import { snakeCaseKeys } from 'lib/object/utils'

export interface AdminConversation {
  body: string
  createdAt: string
  incoming: boolean
  private: boolean
  ticketId: number
  ticketVersion: number
  user: AdminUser
  id: number
  updatedAt: string
}

export interface AdminConversationCreateOptionalParams {
  draft?: boolean
  private?: boolean
  userId?: number
}

const baseUrl = '/api/internal/admin/tickets/'

export async function createConversation(
  ticketId: number,
  body: string,
  optionalParams?: AdminConversationCreateOptionalParams,
  jwt: string = null,
): Promise<AdminConversation> {
  const postWithJWT = bindJWT(postApi, jwt)
  const snakeCaseParams = optionalParams ? snakeCaseKeys(optionalParams) : {}
  const response = await postWithJWT(`${baseUrl}${ticketId}/conversations`, { body, ...snakeCaseParams })
  return response.data.data
}

export function createDraftMessage(ticketId: number, body: string, jwt: string = null): Promise<AdminConversation> {
  return createConversation(ticketId, body, { draft: true }, jwt)
}
