import { BaseResponse, baseRequest, patchApi, postApi } from '../api'

const baseUrl = '/api/internal/ticket_feedbacks'

export interface TicketFeedbacks {
  rating: number
  privateFeedback: string
  ticketId: bigint
  reasons: {
    understandingDirections: boolean
    creativeQuality: boolean
    turnaroundTime: boolean
    communications: boolean
    other: boolean
  }
}

export interface saveTicketFeedbackResponse extends BaseResponse {
  id: number
}

export function save(params: TicketFeedbacks) {
  return postApi(baseUrl, params as unknown as Record<string, unknown>)
}

export async function saveTicketFeedback(params): Promise<saveTicketFeedbackResponse> {
  const response = await baseRequest(baseUrl, postApi, params as unknown as Record<string, unknown>)
  return {
    error: response.error,
    status: response.status,
    id: response.data.id as unknown as number,
  }
}

export function updateTicketFeedback(params): Promise<BaseResponse> {
  return baseRequest(`${baseUrl}/${params.id}`, patchApi, params as unknown as Record<string, unknown>)
}
