import Button from 'components/core/button'
import TextInput from 'components/core/text-input/text-input'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { Skill } from 'interfaces/skill'
import { createSkillSize, skillSizeRoutes } from 'lib/api/admin/skill-sizes/skill-sizes'
import { Toaster, toast } from 'lib/components/toast/toast'
import { useState } from 'react'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-14',
  option: 'tw-mx-1 tw-flex-grow tw-text-right',
  select: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row',
}

interface SkillSizeCreatePageProps {
  skill: Skill
}

function AdminSkillSizeCreate({ skill }: SkillSizeCreatePageProps) {
  const [name, setName] = useState<string>('')
  const [permalink, setPermalink] = useState<string>('')
  const [details, setDetails] = useState<string>('')
  const [image, setImage] = useState<File | null>(null)
  const [width, setWidth] = useState<number | null>(null)
  const [height, setHeight] = useState<number | null>(null)
  const [units, setUnits] = useState<string>('')
  const [updating, setUpdating] = useState<boolean>(false)

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    setUpdating(true)

    try {
      const { status } = await createSkillSize(skill.id, {
        name,
        permalink,
        details,
        width,
        height,
        units,
        image,
      })

      if (status === 200) {
        toast.success('Skill Size Updated')
        window.open(skillSizeRoutes.index(skill.id), '_self')
      }
    } catch (error) {
      console.error(error)
      toast.error('Failed to update skill size')
    } finally {
      setUpdating(false)
    }
  }

  if (updating) {
    return <LoadingScreen />
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.container}>
        <a href={skillSizeRoutes.index(skill.id)} className="tw-mb-4 tw-block tw-text-sm tw-text-gherkin-500">
          Back to Skill Sizes
        </a>
        <h1 className="tw-mb-4 tw-text-2xl tw-font-semibold">Create Skill Size for {skill.name}</h1>
        <form data-testid="edit-form" onSubmit={handleSubmitForm}>
          <div className="tw-flex tw-flex-col tw-pb-2">
            <label htmlFor="image" className="tw-mb-2 tw-block tw-text-sm tw-font-semibold">
              Image
            </label>
            <input data-testid="image" type="file" name="image" onChange={(e) => setImage(e.target.files[0])} />
          </div>
          <TextInput
            data-testid="name"
            label="Name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            name="name"
          />
          <TextInput
            data-testid="permalink"
            label="Permalink"
            onChange={(e) => setPermalink(e.target.value)}
            value={permalink}
            name="permalink"
          />
          <TextInput
            data-testid="details"
            label="Details"
            onChange={(e) => setDetails(e.target.value)}
            value={details}
            name="details"
          />
          <TextInput
            data-testid="width"
            label="Width"
            onChange={(e) => setWidth(+e.target.value)}
            value={width || ''}
            type="number"
            name="width"
          />
          <TextInput
            data-testid="height"
            label="Height"
            onChange={(e) => setHeight(+e.target.value)}
            value={height || ''}
            type="number"
            name="height"
          />
          <TextInput
            data-testid="units"
            label="Units"
            onChange={(e) => setUnits(e.target.value)}
            value={units}
            name="units"
          />
          <div className="tw-mt-8"></div>
          <Button data-testid="submit-button" color="green" type="submit">
            Save
          </Button>
        </form>
      </div>
    </div>
  )
}

export default function AdminSkillSizeCreatePage({ skill }: SkillSizeCreatePageProps) {
  return (
    <>
      <Toaster />
      <AdminSkillSizeCreate skill={skill} />
    </>
  )
}
