import { SubmittedByUser } from 'lib/api/ticket-files/ticket-file-versions'
import { findById } from 'lib/array/utils'
import Select, { StylesConfig } from 'react-select'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../../../../tailwind.config'

const { colors } = resolveConfig(tailwindConfig).theme

interface SubmittedByFilterProps {
  users: SubmittedByUser[]
  onSelectUsers: (value) => void
  selectedUsers: SubmittedByUser[]
}

interface SubmittedByOption {
  value: SubmittedByUser
  label: string
}

const MultiSelectStyles: StylesConfig<SubmittedByOption, true> = {
  menuList: (provided) => ({
    ...provided,
    background: colors.white,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 100,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    background: state.isSelected ? colors.gherkin : colors.white,
    opacity: state.isDisabled ? 0.5 : 1,
    color: state.isSelected ? colors.white : colors.black,
    cursor: state.isDisabled ? 'default' : 'pointer',
    '&:hover': {
      background: state.isDisabled ? colors.white : colors.cornflower[50],
      color: colors.black,
    },
  }),
  control: (styles) => ({
    ...styles,
    height: '48px',
    background: colors.white,
    cursor: 'pointer',
    borderColor: colors.peppercorn[100],
    boxShadow: colors.peppercorn[100],
    whiteSpace: 'nowrap',
    '&:hover': {
      borderColor: colors.peppercorn[100],
    },
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '16px', color: colors.peppercorn[800] }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: colors.peppercorn[100],
    }
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: colors.peppercorn[800],
    fontSize: '12px',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: colors.peppercorn[800],
    ':hover': {
      backgroundColor: colors.peppercorn[800],
      color: colors.white,
    },
  }),
} as StylesConfig<SubmittedByOption, true>

export default function SubmittedByFilter({ users, onSelectUsers, selectedUsers }: SubmittedByFilterProps) {
  const classNames = {
    select: 'tw-text-xs tw-my-2 tw-min-w-48',
    image: 'tw-w-6 tw-mr-2 tw-rounded-full',
  }

  function getAvatar(user: SubmittedByUser) {
    return user.avatarUrl || '/images/avatars/designer_photo_placeholder_125px.jpg'
  }

  function fullName(user: SubmittedByUser) {
    return `${user.firstName} ${user.lastName}`
  }

  return (
    <Select
      components={{ IndicatorSeparator: null }}
      className={classNames.select}
      defaultValue={selectedUsers.length > 0 && selectedUsers.map((user) => ({ value: user, label: fullName(user) }))}
      placeholder={'Submitted By'}
      options={
        (users &&
          users
            .filter((user) => !findById(user.id, selectedUsers))
            .map((user) => ({ value: user, label: fullName(user) }))) ||
        []
      }
      onChange={(e) => onSelectUsers(e.map((user) => user.value))}
      styles={MultiSelectStyles}
      isClearable
      isMulti
      formatOptionLabel={({ value }) => (
        <div className="user-option">
          <img src={getAvatar(value)} alt="user-image" className={classNames.image} />
          <span>{fullName(value)}</span>
        </div>
      )}
    />
  )
}
