import { useUserContext, AuthRoute } from 'providers/user-provider'
import { ReactNode } from 'react'

export default function AuthBranding({ children }: { children?: ReactNode }) {
  const { settings, auth } = useUserContext()
  const customBranding = settings?.customBranding

  const showLogo = (customBranding && customBranding?.logo) || !customBranding

  const defaultLogoSrc = '/images/states/not-authenticated.svg'
  const routeLogoMap = {
    [AuthRoute.FORGOTPASSWORD]: '/images/states/reset-password.svg',
    [AuthRoute.RESETPASSWORD]: '/images/states/reset-password.svg',
  }

  const logoSrc = customBranding?.logo || routeLogoMap[auth.authRoute] || defaultLogoSrc

  return (
    <div className="tw-flex tw-h-screen tw-items-center tw-justify-center">
      <div className="tw-mx-auto">
        {!customBranding && (
          <div className="tw-absolute tw-right-4 tw-top-4 tw-inline-block">
            Existing Design Pickle customer? <a href="/users/sign_in">Log in here</a>
          </div>
        )}
        <div className={'tw-mx-auto tw-mb-6 tw-block tw-h-auto tw-w-48'}>
          {showLogo && <img src={logoSrc} alt="Authentication Logo" className="tw-block tw-h-auto tw-w-full" />}
        </div>
        <div>{children}</div>
      </div>
    </div>
  )
}
