import { Search, X } from 'lucide-react'
import { cn } from 'lib/util/cn'
import { HTMLAttributes, InputHTMLAttributes } from 'react'

interface SearchInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  className?: HTMLAttributes<HTMLDivElement>['className']
  value: string
  onChange: (value: string) => void
  placeholder?: string
}

const SearchInput = ({
  className,
  value,
  onChange,
  placeholder = 'Search by title, directions, annotations, tags, and more',
  ...otherProps
}: SearchInputProps) => {
  return (
    <div className={cn('tw-relative tw-flex tw-justify-center', className)}>
      <Search className="tw-pointer-events-none tw-absolute tw-left-3 tw-top-1/2 tw-h-4 tw-w-4 tw--translate-y-1/2 tw-transform tw-text-neutral-500" />
      <input
        placeholder={placeholder}
        className={cn(
          'tw-block tw-h-10 tw-w-full tw-appearance-none tw-text-ellipsis tw-rounded-md tw-border tw-border-solid tw-p-2 tw-pl-10 tw-shadow-none tw-outline-none placeholder:tw-text-neutral-500 focus:tw-ring-2 focus:tw-ring-cornflower-500',
          value && 'tw-pr-8',
        )}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...otherProps}
      />
      {!!value && (
        <div
          onClick={(e) => {
            e.stopPropagation()
            onChange('')
            return
          }}
          className="tw-absolute tw-right-0 tw-flex tw-h-10 tw-w-8 tw-flex-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-r-md tw-border tw-border-solid hover:tw-bg-peppercorn-50"
        >
          <X className="lu-sm tw-inline-block" data-testid="multiselect-clear" />
        </div>
      )}
    </div>
  )
}

export default SearchInput
