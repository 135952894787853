import { useState } from 'react'
import { useSkillsContext } from './skills-provider'
import Search from 'components/core/search'

export default function SearchSkillsByName() {
  const [searchValue, setSearchValue] = useState('')
  const { filterCategoriesBySkillName } = useSkillsContext()

  function handleSearchChange(value: string) {
    setSearchValue(value)
    filterCategoriesBySkillName(value)
  }

  return (
    <Search
      name="search-request-name"
      value={searchValue}
      onChange={handleSearchChange}
      placeholder="Search"
      className="tw-mt-4 tw-w-80"
    />
  )
}
