import React, { useState } from 'react'
import { Transfer } from 'interfaces/transfer'
import { DaysUntilTransfer, NumberOfRequests } from 'components/pages/admin/reports/transfers/transfers-item-elements'

import {
  CompanyName,
  ConfidenceLevel,
  ConvertDateToAmerican,
  DefaultRow,
  CompanyAge,
} from 'components/pages/admin/reports/shared/table/column-elements'
import TableRowActions from 'components/pages/admin/reports/shared/TableRowActions'
import EditablePredictedLabel, {
  getPredictedLabelFromValue,
} from 'components/pages/admin/reports/shared/editable-predicted-label'
import { OptionType } from 'components/core/single-select'
import EditableSupportingDocumentation from 'components/pages/admin/reports/shared/editable-supporting-documentation'
import EditableTransferReason, {
  getReasonFromValue,
} from 'components/pages/admin/reports/transfers/editable-transfer-reason'
import LatestStatusLog from 'components/pages/admin/reports/shared/latest-status-log'
import { update } from 'lib/api/reporting/designer_transfers'

interface TransferItemsProps {
  transfer: Transfer
  onTransferChange: () => void
}

export default function TransferItem({ transfer }: TransferItemsProps): JSX.Element {
  const {
    company,
    createdAt,
    humanAnnotated,
    transferReason,
    predictedLabel,
    labelProbability,
    transferNotes,
    transferDate,
    transferType,
    latestStatusLog,
  } = transfer

  const [editMode, setEditMode] = useState(false)
  const [lastSavedPredictedLabelValue, setLastSavedPredictedLabelValue] = useState<OptionType>({
    value: predictedLabel,
    label: getPredictedLabelFromValue(predictedLabel),
  })
  const [predictedLabelSelectValue, setPredictedLabelSelectValue] = useState<OptionType>(lastSavedPredictedLabelValue)
  const [lastSavedStatusLog, setLastSavedStatusLog] = useState(latestStatusLog)
  const [newSupportingDocumentationBody, setNewSupportingDocumentationBody] = useState(transferNotes || '')
  const [lastSavedSupportingDocumentation, setLastSavedSupportingDocumentation] = useState(transferNotes)
  const supportingDocumentation = lastSavedSupportingDocumentation ? { body: lastSavedSupportingDocumentation } : null
  const [lastSavedReasonValue, setLastSavedReasonValue] = useState<OptionType>({
    value: transferReason,
    label: getReasonFromValue(transferReason),
  })
  const [reasonSelectValue, setReasonSelectValue] = useState<OptionType>(lastSavedReasonValue)
  const [lastHumanAnnotated, setLastHumanAnnotated] = useState(humanAnnotated || false)

  const { designers, dateOfLastRequest } = company

  const handleCancelUpdate = () => {
    setEditMode(false)
    setPredictedLabelSelectValue(lastSavedPredictedLabelValue || null)
    setNewSupportingDocumentationBody(lastSavedSupportingDocumentation || '')
    setReasonSelectValue(lastSavedReasonValue || null)
  }

  const generateParams = () => ({
    predicted_label:
      predictedLabelSelectValue?.value !== lastSavedPredictedLabelValue?.value
        ? predictedLabelSelectValue?.value
        : null,
    transfer_notes:
      newSupportingDocumentationBody && newSupportingDocumentationBody !== lastSavedSupportingDocumentation
        ? newSupportingDocumentationBody
        : null,
    transfer_reason: reasonSelectValue?.value !== lastSavedReasonValue?.value ? reasonSelectValue?.value : null,
  })

  const updateTransfer = async () => {
    const params = generateParams()

    if (!params.predicted_label && !params.transfer_notes && !params.transfer_reason) {
      setEditMode(false)
      return
    }

    const response = await update(transfer.id, params)
    if (response.status === 200) {
      setEditMode(false)
      if (params.predicted_label) {
        setLastHumanAnnotated(true)
        setLastSavedPredictedLabelValue(predictedLabelSelectValue)
        setLastSavedStatusLog(response?.data?.designer_transfer?.latestStatusLog)
      }

      if (params.transfer_notes) {
        setLastSavedSupportingDocumentation(newSupportingDocumentationBody)
      } else {
        setNewSupportingDocumentationBody(lastSavedSupportingDocumentation || '')
      }

      if (params.transfer_reason) {
        setLastSavedReasonValue(reasonSelectValue)
      }
    }
  }

  const handleUpdateTransfer = async () => {
    await updateTransfer()
  }

  return (
    <tr>
      <CompanyName {...transfer} />
      <EditablePredictedLabel
        predictedLabel={predictedLabel}
        editMode={editMode}
        newPredictedLabel={predictedLabelSelectValue}
        setNewPredictedLabel={setPredictedLabelSelectValue}
        humanAnnotated={lastHumanAnnotated}
      />
      <ConfidenceLevel labelProbability={labelProbability} />
      <EditableSupportingDocumentation
        supportingDocumentation={supportingDocumentation}
        editMode={editMode}
        setEditMode={setEditMode}
        newSupportingDocumentationBody={newSupportingDocumentationBody}
        setNewSupportingDocumentationBody={setNewSupportingDocumentationBody}
      />
      <LatestStatusLog latestStatusLog={lastSavedStatusLog} />
      <CompanyAge {...transfer} />
      <td data-testid="company-transfer-requested-at-container">{ConvertDateToAmerican(createdAt)}</td>
      {[transferType].map(DefaultRow)}
      <EditableTransferReason
        reason={transferReason}
        editMode={editMode}
        newReason={reasonSelectValue}
        setNewReason={setReasonSelectValue}
      />
      <td data-testid="company-designers-container" className="tw-pr-2">
        {designers?.map((designer) => <span key={designer.id}>{designer.name}</span>)}
      </td>
      {DefaultRow(dateOfLastRequest || 'N/A', 3)}
      <DaysUntilTransfer transferDate={transferDate} />
      <NumberOfRequests transfer={transfer} />
      <TableRowActions
        editMode={editMode}
        setEditMode={setEditMode}
        onCancel={handleCancelUpdate}
        onSave={handleUpdateTransfer}
      />
    </tr>
  )
}
