import React from 'react'
import { StatusLog } from 'interfaces/status-log'
import { ConvertDateToAmerican, statusBadge } from './table/column-elements'

interface StatusLogProps {
  latestStatusLog: StatusLog
}

const LatestStatusLog = ({ latestStatusLog }: StatusLogProps): JSX.Element => {
  const { predictedLabelFrom, predictedLabelTo, userEmail, updatedAt } = latestStatusLog

  return (
    <td>
      {latestStatusLog && (
        <div className="tw-my-1 tw-text-sm">
          {predictedLabelFrom || predictedLabelTo ? (
            <div className="tw-my-1 tw-flex tw-content-center">
              <h6 className="tw-bold tw-m-1">Status:</h6>
              <p className="tw-m-0">
                <span className="tw-pr-1">from {statusBadge[predictedLabelFrom]?.label || 'Not Applicable'}</span>
                <span>to {statusBadge[predictedLabelTo]?.label}</span>
              </p>
            </div>
          ) : null}
          <div className="tw-my-1 tw-flex tw-content-center">
            <h6 className="tw-bold tw-m-1">User:</h6>
            <p className="tw-m-0">{userEmail}</p>
          </div>
          <div className="tw-my-1 tw-flex tw-content-center">
            <h6 className="tw-bold tw-m-1">Last Update Date:</h6>
            <p className="tw-m-0">{ConvertDateToAmerican(updatedAt)}</p>
          </div>
        </div>
      )}
    </td>
  )
}

export default LatestStatusLog
