import { Switch } from '@headlessui/react'
import Tooltip from 'lib/components/tooltip/tooltip'
import { cn } from 'lib/util/cn'
import { Info } from 'lucide-react'

export default function ShareToggle({ isShared, onToggle }: { isShared: boolean; onToggle: () => void }) {
  return (
    <Switch.Group>
      <div className="tw-flex tw-items-center tw-gap-2">
        <Switch
          checked={isShared}
          onChange={onToggle}
          className={cn(
            isShared ? 'tw-bg-gherkin-500' : 'tw-bg-neutral-200',
            'tw-relative tw-inline-flex tw-h-[18px] tw-w-[38px] tw-flex-shrink-0 tw-cursor-pointer tw-rounded-full tw-border-none tw-p-0.5 tw-transition-colors tw-duration-200 tw-ease-in-out',
          )}
        >
          <span className="sr-only">Enable link</span>
          <span
            aria-hidden="true"
            className={cn(
              'tw-pointer-events-none tw-inline-block tw-h-[14px] tw-w-[14px] tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out',
              isShared ? 'tw-translate-x-[20px]' : 'tw--translate-x-0',
            )}
          />
        </Switch>
        <Switch.Label className="tw-mb-0 tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-black">
          Enable link{' '}
          <Tooltip content={'For more information on what collaborators can see, refer to the knowledge base'}>
            <Info className="tw--mt-1 tw-w-4 tw-text-peppercorn-400" />
          </Tooltip>
        </Switch.Label>
      </div>
    </Switch.Group>
  )
}
