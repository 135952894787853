import { useSyncExternalStore } from 'react'

type SidebarState = 'collapsed' | 'expanded'

function useSidebarState() {
  const setSidebarState = (open: boolean) => {
    const newValue = open ? 'expanded' : 'collapsed'
    window.localStorage.setItem('sidebar', newValue)
    window.dispatchEvent(new StorageEvent('storage', { key: 'sidebar', newValue }))
  }

  const getSnapshot = () => localStorage.getItem('sidebar') ?? ('expanded' as SidebarState)

  const subscribe = (listener: () => void) => {
    window.addEventListener('storage', listener)
    return () => void window.removeEventListener('storage', listener)
  }

  const store = useSyncExternalStore(subscribe, getSnapshot)

  return [store === 'expanded', setSidebarState] as const
}

export default useSidebarState
