import axios, { AxiosError, AxiosResponse } from 'axios'

export interface BaseResponse {
  status: number
  error: unknown
  data?: Record<string, unknown>
}

export interface ResponseMeta {
  nextPage: string
  previousPage: string
  total: number
  pageSize: number
}

export type ApiError = AxiosError<{ message: string }>

interface Header extends Record<string, unknown> {
  Authorization?: string
}

export interface Metadata {
  nextPage: string
  previousPage: string
  total: number
  pageSize: number
}

type ApiMethod = typeof getApi | typeof postApi | typeof putApi | typeof patchApi | typeof deleteApi

type Params = Record<string, unknown>

export async function baseRequest(url: string, method: ApiMethod, params?: Params): Promise<BaseResponse> {
  try {
    const { data, status } = await method(url, params)

    return {
      data,
      error: null,
      status,
    }
  } catch (error) {
    throw { ...error, status: error.response.status }
  }
}

function headersIfPresent(headers?: Params) {
  return headers && { headers }
}

export function bindJWT(method: typeof getApi, jwt: string) {
  let headers: Header
  if (jwt) {
    headers = { Authorization: `Bearer ${jwt}` }
  }
  return (url: string, paramsOrData?: Params) => method(url, paramsOrData, headers)
}

export function deleteApi(url: string, _?: Params, headers?: Params): Promise<AxiosResponse> {
  return axios.delete(url, { ...headersIfPresent(headers) })
}

export function getApi(url: string, params?: Params, headers?: Params): Promise<AxiosResponse> {
  return axios.get(url, { ...headersIfPresent(headers), params })
}

export function getBlob(url: string, onDownloadProgress?: (event: ProgressEvent) => void): Promise<AxiosResponse> {
  return axios.get(url, {
    responseType: 'blob',
    onDownloadProgress,
  })
}

export function patchApi(url: string, data: Params, headers?: Params): Promise<AxiosResponse> {
  return axios.patch(url, data, { ...headersIfPresent(headers) })
}

export function postApi(url: string, data?: Params, headers?: Params): Promise<AxiosResponse> {
  return axios.post(url, data, { ...headersIfPresent(headers) })
}

export function putApi(url: string, data: Params, headers?: Params): Promise<AxiosResponse> {
  return axios.put(url, data, { ...headersIfPresent(headers) })
}
