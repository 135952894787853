import Tooltip from 'lib/components/tooltip/tooltip'
import { useDesignerAllocationsContext } from './designer-allocations-provider'

const classNames = {
  wrapper: 'tw-overflow-x-auto',
  table: 'tw-w-full tw-table-auto',
  row: '',
  header: 'tw-border tw-border-gherkin-300 tw-border-solid tw-px-4 tw-py-2 tw-text-gherkin-500',
  data: 'tw-border tw-border-gherkin-300 tw-border-solid tw-px-4 tw-py-2',
  unassigned: 'tw-text-red-500 tw-border tw-border-gherkin-300 tw-border-solid tw-px-4 tw-py-2',
}

export default function ScopeTable() {
  const { companyAllocation } = useDesignerAllocationsContext()
  const scopes = companyAllocation.totalAllocatedTimePerScopeOfService

  return (
    <div className={classNames.wrapper}>
      <table className={classNames.table}>
        <thead>
          <tr className={classNames.row}>
            <th scope="col" className={classNames.header}>
              Scope
            </th>
            <th scope="col" className={classNames.header}>
              Number of Creatives
            </th>
            <th scope="col" className={classNames.header}>
              Reserved Time
            </th>
            <th scope="col" className={classNames.header}>
              Company Time
            </th>
          </tr>
        </thead>
        <tbody>
          {scopes.map(({ name, numberOfDesigners, reservedTime, companyTotalDailyTime }) => (
            <tr key={name} className={classNames.row}>
              {numberOfDesigners > 0 ? (
                <td className={classNames.data}>{name}</td>
              ) : (
                <td className={classNames.unassigned}>
                  <Tooltip content="This scope has no creatives assigned">{name}</Tooltip>
                </td>
              )}
              <td className={classNames.data}>{numberOfDesigners}</td>
              <td className={classNames.data}>{reservedTime}</td>
              <td className={classNames.data}>{companyTotalDailyTime}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
