const classNames = {
  boldText: 'tw-font-bold',
  container:
    'tw-flex tw-flex-col tw-flex-wrap tw-items-center tw-justify-center tw-mb-2 tw-pt-12 tw-overflow-auto tw-text-peppercorn-900',
  image: 'tw-mb-4',
}

export default function FiltersEmptyState(): JSX.Element {
  const customStyle = {
    gridView: { height: 'calc(100vh - 22rem)' },
  }

  return (
    <div className={classNames.container} style={customStyle.gridView}>
      <img src="/images/states/not-found.svg" alt="No Assets Found" className={classNames.image} />
      <div className={classNames.boldText}>{"Uh oh! We weren't able to find any assets."}</div>
      <div>Try adjusting your search or filters to find what you’re looking for.</div>
    </div>
  )
}
