import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/core/button'
import { CancellationFilters } from 'components/pages/admin/reports/cancellations/cancellations-filters'
import { TransferFilters } from 'components/pages/admin/reports/transfers/transfers-filters'
import { ProFilters } from 'components/pages/admin/subscriptions/pro/pro-subscriptions-filters'
import { ScaleFilters } from 'components/pages/admin/subscriptions/scale/scale-subscriptions-filters'
import { hasOnlyFalsyValues } from 'lib/object/utils'
import { ReactElement, ReactNode } from 'react'
import styles from './admin-filters.module.scss'

type AdminFilters = CancellationFilters | ProFilters | ScaleFilters | TransferFilters

interface AdminFiltersProps {
  children: ReactNode
  emptyFilters: AdminFilters
  filters: AdminFilters
  setFilters: (object) => void
  handleSubmit: (event) => void
  downloadCSV?: () => ReactNode
  onClear?: (params?: { filterParams?: CancellationFilters | Record<string, never> | TransferFilters }) => void
}

export default function AdminFilters(props: AdminFiltersProps): ReactElement {
  const onSubmit = (e) => {
    e.preventDefault()
    props.handleSubmit(e)
  }

  const onClear = (e) => {
    const { onClear, emptyFilters, setFilters } = props
    e.preventDefault()
    setFilters(emptyFilters)
    onClear && onClear({ filterParams: {} })
  }

  return (
    <div className="tw-w-2/12">
      <div className="tw-flex tw-flex-auto tw-justify-between tw-p-2 tw-font-bold tw-text-peppercorn-800">
        <div>
          <span className={styles.filtersIcon}>
            <FontAwesomeIcon icon="filter" />
          </span>
          Filters
        </div>
        {!hasOnlyFalsyValues(props.filters) && (
          <div className={styles.clearIcon} onClick={onClear}>
            <span>Clear</span>
            <FontAwesomeIcon icon="times-circle" />
          </div>
        )}
      </div>
      <div className={styles.filters}>
        <form onSubmit={onSubmit}>
          {props.children}
          <Button color="outlineDark" type="submit">
            Search
          </Button>
        </form>
      </div>
      {props.downloadCSV && props.downloadCSV()}
    </div>
  )
}

export function getInitialFilters(emptyFilters: AdminFilters): AdminFilters {
  const windowUrl = window.location.search
  const params = new URLSearchParams(windowUrl)
  const filters = { ...emptyFilters }

  Object.keys(emptyFilters).forEach((filterKey) => {
    if (params.has(filterKey)) {
      const value = params.get(filterKey)
      if (value === 'true' || value === 'false') {
        filters[filterKey] = value === 'true'
      } else {
        filters[filterKey] = value
      }
    }
  })

  return filters as AdminFilters
}

interface SearchTitleFilterProps {
  value: string
  updateValue: (string) => void
  onClick?: () => void
  placeholder: string
}

export const SearchTitleFilter = ({
  value,
  updateValue,
  placeholder,
  onClick,
}: SearchTitleFilterProps): JSX.Element => {
  return (
    <div className="tw-relative tw-w-full tw-min-w-fit">
      <input
        placeholder={placeholder}
        className="tw-my-2 tw-w-full tw-appearance-none tw-rounded tw-border tw-border-solid tw-border-peppercorn-100 tw-px-8 tw-py-2.5 tw-text-xs tw-shadow-none tw-outline-none hover:tw-border-gherkin focus:tw-border-gherkin"
        type="text"
        value={value}
        onClick={onClick}
        onChange={(e) => updateValue(e.target.value)}
      />
      <span className="tw-absolute tw-left-3 tw-top-4">
        <FontAwesomeIcon icon="search" />
      </span>
      {value && (
        <span className="tw-absolute tw-right-3 tw-top-4 tw-cursor-pointer" onClick={() => updateValue('')}>
          <FontAwesomeIcon icon="times" />
        </span>
      )}
    </div>
  )
}
