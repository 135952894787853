import { TimelineItem } from 'lib/api/timeline/timeline'

const ALL_VERSIONS = 0

export default class TimelineCache {
  #cache: Map<string, TimelineItem>
  #nextPageUrls: Map<number, string>
  readonly #registry: Record<string, Set<string>>

  constructor() {
    this.#cache = new Map()
    this.#nextPageUrls = new Map()
    this.#registry = {}
  }

  addItem(item: TimelineItem): TimelineItem {
    this.#invalidateRegistryEntryForAllVersions()
    this.#registry[item.ticketVersion] ??= new Set()
    return this.#insert(item.ticketVersion, item)
  }

  addItems(version: number, items: TimelineItem[], nextPageUrl): void {
    this.#registry[version] ??= new Set()
    items.forEach((item) => {
      this.#insert(version, item)
    })
    this.#nextPageUrls.set(version, nextPageUrl)
  }

  getItems(version: number) {
    if (!this.#registry[version]) {
      return null
    }
    const ids = Array.from(this.#registry[version])
    return ids.map((id) => this.#cache.get(id))
  }

  getNextPageUrl(version: number) {
    return this.#nextPageUrls.get(version)
  }

  removeItem(item: TimelineItem) {
    const { __cacheKey } = this.#identify(item)

    this.#cache.delete(__cacheKey)

    if (this.#registry[item.ticketVersion]) {
      this.#registry[item.ticketVersion].delete(__cacheKey)
    }

    if (this.#registry[ALL_VERSIONS]) {
      this.#registry[ALL_VERSIONS].delete(__cacheKey)
    }
  }

  updateItem(item: TimelineItem): TimelineItem {
    const keyedItem = this.#identify(item)
    this.#cache.set(keyedItem.__cacheKey, keyedItem)
    return keyedItem
  }

  #identify(item: TimelineItem) {
    const __cacheKey = `${item.taskType}-${item.id}`
    return {
      __cacheKey,
      ...item,
    }
  }

  #insert(version: number, item: TimelineItem): TimelineItem {
    const keyedItem = this.#identify(item)
    this.#registry[version].add(keyedItem.__cacheKey)
    this.#cache.set(keyedItem.__cacheKey, keyedItem)
    return keyedItem
  }

  #invalidateRegistryEntryForAllVersions() {
    delete this.#registry[ALL_VERSIONS]
  }
}
