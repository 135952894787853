import TableHead from 'components/core/table-head'
import { DroppableTableRow } from 'components/core/table-row'
import Button from 'components/core/button'
import { useSingleQueueContext } from 'providers/single-queue-provider'
import { DroppableProvided } from 'react-beautiful-dnd'

interface TableHeaderProps {
  droppableProvided: DroppableProvided
}

export default function PriorityTableHeader({ droppableProvided }: TableHeaderProps) {
  const { setPriorityCancelModal, setPriorityConfirmModal } = useSingleQueueContext()

  return (
    <TableHead>
      <DroppableTableRow innerRef={droppableProvided.innerRef} droppableProvided={droppableProvided}>
        <th scope="col" className="tw-px-6 tw-pr-0.5 tw-text-left tw-text-xs"></th>
        <th scope="col" colSpan={3} className="tw-pr-0.5 tw-text-left tw-text-xs tw-tracking-wider">
          <div className="tw-flex tw-justify-items-center tw-align-middle">
            <div className="tw-float-left tw-flex tw-items-center">
              <div className="tw-flex tw-items-center tw-gap-4 tw-px-6">
                <Button type={'button'} color={'lightGray'} size={'cut'} onClick={() => setPriorityCancelModal(true)}>
                  Cancel
                </Button>
                <Button type={'button'} color={'purple'} size={'cut'} onClick={() => setPriorityConfirmModal(true)}>
                  Save Priority
                </Button>
              </div>
            </div>
          </div>
        </th>
        <th
          scope="col"
          className="tw-px-6 tw-py-3 tw-pl-4 tw-pr-0.5 tw-text-left tw-text-xs tw-font-semibold tw-uppercase tw-tracking-wider tw-text-gray-500"
        >
          Status
        </th>
        <th
          scope="col"
          className="tw-px-6 tw-py-3 tw-pl-4 tw-pr-0.5 tw-text-left tw-text-xs tw-font-semibold tw-uppercase tw-tracking-wider tw-text-gray-500"
        >
          Request Type
        </th>
        <th
          scope="col"
          className="tw-px-6 tw-py-3 tw-pl-4 tw-pr-0.5 tw-text-left tw-text-xs tw-font-semibold tw-uppercase tw-tracking-wider tw-text-gray-500"
        >
          Designer
        </th>
        <th
          scope="col"
          className="tw-px-6 tw-py-3 tw-pl-4 tw-pr-0.5 tw-text-left tw-text-xs tw-font-semibold tw-uppercase tw-tracking-wider tw-text-gray-500"
        >
          Submitted By
        </th>
        <th
          scope="col"
          className="tw-px-6 tw-py-3 tw-pl-4 tw-pr-0.5 tw-text-left tw-text-xs tw-font-semibold tw-uppercase tw-tracking-wider tw-text-gray-500"
        >
          Last Updated
        </th>
      </DroppableTableRow>
    </TableHead>
  )
}
