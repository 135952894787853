import { Company } from 'interfaces/company'
import { Designer } from 'interfaces/designer'
import { BaseResponse, Metadata, baseRequest, deleteApi, getApi, postApi } from 'lib/api/api'

export interface DesignersResponse extends BaseResponse {
  designerList: Designer[]
}

export interface BlocklistResponse extends BaseResponse {
  blocklistedCompanies: Company[]
  meta: Metadata
}

export interface BlocklistDetailResponse extends BaseResponse {
  blocklistsData: BlocklistsResponse[]
}

export interface BlocklistCreateResponse extends BaseResponse {
  blocklistData: BlocklistsResponse
}

export interface BlocklistDestroyResponse extends BaseResponse {
  message: string
}

export interface BlocklistsResponse {
  id: number
  designer: Designer
}

export const routes = {
  index: '/admin/blocklists',
  show: (id: number) => `/admin/blocklists/${id}`,
}

const apiRoutes = {
  create: '/api/internal/blocklists',
  get: '/api/internal/blocklists',
  show: (id: number) => `/api/internal/blocklists/${id}`,
  destroy: (id: number) => `/api/internal/blocklists/${id}`,
}

export async function getDesigners(): Promise<DesignersResponse> {
  const response = await baseRequest('/api/internal/designers', getApi, { scope: 'active.with_designer_role.sorted' })

  return {
    error: response.error,
    status: response.status,
    designerList: response.data as unknown as Designer[],
  }
}

export async function getBlocklists(params): Promise<BlocklistResponse> {
  const response = await baseRequest(apiRoutes.get, getApi, params)
  const { data, meta } = response.data

  return {
    error: response.error,
    status: response.status,
    blocklistedCompanies: data as unknown as Company[],
    meta: meta as Metadata,
  }
}

export async function getBlocklist(id: number): Promise<BlocklistDetailResponse> {
  const response = await baseRequest(apiRoutes.show(id), getApi)
  const { data } = response.data

  return {
    error: response.error,
    status: response.status,
    blocklistsData: data as unknown as BlocklistsResponse[],
  }
}

export async function createBlocklist(params): Promise<BlocklistCreateResponse> {
  const response = await baseRequest(apiRoutes.create, postApi, params)

  return {
    error: response.error,
    status: response.status,
    blocklistData: response.data as unknown as BlocklistsResponse,
  }
}

export async function deleteBlocklist(id: number): Promise<BlocklistDestroyResponse> {
  const response = await baseRequest(apiRoutes.destroy(id), deleteApi)
  const { message } = response.data

  return {
    error: response.error,
    status: response.status,
    message: message as unknown as string,
  }
}
