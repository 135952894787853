import { createContext, useContext, useState } from 'react'
import { getFilterOptions, FilterOptions } from 'lib/api/ticket-files/ticket-file-versions'

interface ContextProps {
  children: JSX.Element
}

interface FilterOptionsContextValue {
  getFilterOptionsValues: () => Promise<FilterOptions>
  isLoading: boolean
}

const FilterOptionsContext = createContext({})

export function useFilterOptionsContext(): FilterOptionsContextValue {
  return useContext(FilterOptionsContext) as FilterOptionsContextValue
}

export default function FilterOptionsProvider({ children }: ContextProps): JSX.Element {
  const [options, setOptions] = useState<FilterOptions>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  async function getFilterOptionsValues() {
    if (options) return options

    try {
      const { options } = await getFilterOptions()
      setOptions(options)
      return options
    } catch (e) {
      console.error('Filter Options could not be fetched.', e)
      return null
    } finally {
      setIsLoading(false)
    }
  }

  const context: FilterOptionsContextValue = {
    getFilterOptionsValues,
    isLoading,
  }

  return <FilterOptionsContext.Provider value={context}>{children}</FilterOptionsContext.Provider>
}
