import { ReactElement } from 'react'
import { AppSettings, CurrentUser } from 'interfaces/app'
import CollaboratorTicket from './collaborator-ticket'
import App from 'components/pages/app/app'

interface CollaboratorTicketProps {
  settings: AppSettings
  user: CurrentUser
}

export default function CollaboratorTicketPage({ settings, user }: CollaboratorTicketProps): ReactElement {
  return (
    <App settings={settings} user={user} allowCollaborators>
      <CollaboratorTicket />
    </App>
  )
}
