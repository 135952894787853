import { Card } from 'lib/components/card/card'
import { FilterDropdown } from './filter-dropdown'
import { useMemo } from 'react'

interface SourcesFilterProps {
  sources: string[]
  onSelectSources: (sources: string[]) => void
  selectedSources: string[]
}

function getLabel(source) {
  switch (source) {
    case 'uploaded_by_customer':
      return 'User Uploaded'
    case 'uploaded_by_creative':
      return 'Delivered Files'
    case 'stock_asset':
      return 'Stock Library'
    default:
      return null
  }
}

export default function SourcesFilter({ sources, onSelectSources, selectedSources }: SourcesFilterProps) {
  const selectedSourcesLabels = selectedSources.map((source) => getLabel(source))
  const title = useMemo(() => {
    if (!selectedSourcesLabels || selectedSourcesLabels?.length === 0) return 'Source(s)'
    if (selectedSourcesLabels.length <= 1) return selectedSourcesLabels.join(', ')
    return `${selectedSourcesLabels.slice(0, 1).join(', ')} + ${selectedSourcesLabels.length - 1} more`
  }, [selectedSourcesLabels])

  return (
    <FilterDropdown title={title}>
      <FilterDropdown.Content
        onSelectFilter={onSelectSources}
        filterOptions={sources}
        selectedFilterOptions={selectedSources}
        title="Source(s)"
      />
    </FilterDropdown>
  )
}

export function SelectableSource({
  source,
  onClick,
  isSelected,
}: {
  source: string
  onClick: (source: string) => void
  isSelected: boolean
}): JSX.Element {
  const classNames = {
    cardBody: `tw-pt-4`,
    image: `tw-w-20 tw-h-20 tw-text-peppercorn-900`,
    cardFooter: `tw-flex tw-flex-col tw-text-center tw-text-sm`,
  }

  return (
    <Card size="md" onClick={() => onClick(source)} key={source} isSelected={isSelected} isSquare>
      <Card.Body className={classNames.cardBody} centered>
        <img src={`/images/sources/${source}.svg`} alt={`${source}-image`} />
      </Card.Body>
      <Card.Footer className={classNames.cardFooter}>{getLabel(source)}</Card.Footer>
    </Card>
  )
}
