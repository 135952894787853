import OpenSeadragon from 'openseadragon'
import { getBlob } from 'lib/api/api'

interface Coordinates {
  x: number
  y: number
}

interface DownloadSourceImageResponse {
  blob: Blob
  height: number
  width: number
}

interface OpenSeadragonImageTileSource {
  height: number
  width: number
}

const cursorUrl =
  'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" style="fill:white; stroke:black; stroke-width:2;"><circle cx="20" cy="20" r="19"/></svg>'

const viewerWidth = 800

const strokeStyle = 'rgba(120, 120, 250, 1)'

function canvasToBlobPromise(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob)
      } else {
        reject(new Error('Canvas to Blob Conversion Failed'))
      }
    }, 'image/png')
  })
}

function convertToBlackAndWhite(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D): Promise<Blob> {
  const image = context.getImageData(0, 0, canvas.width, canvas.height)
  let color: number

  for (let i = 0; i < image.data.length; i += 4) {
    color = image.data[i + 3] === 0 ? 0 : 255
    image.data[i] = color
    image.data[i + 1] = color
    image.data[i + 2] = color
    image.data[i + 3] = 255
  }

  const temporaryCanvas = document.createElement('canvas')
  temporaryCanvas.width = canvas.width
  temporaryCanvas.height = canvas.height
  const temporaryContext = temporaryCanvas.getContext('2d')
  temporaryContext.putImageData(image, 0, 0)

  return canvasToBlobPromise(temporaryCanvas)
}

async function convertToPNG(blob: Blob): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.crossOrigin = 'anonymous'

    image.onerror = reject

    image.onload = () => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      canvas.height = image.height
      canvas.width = image.width

      context.drawImage(image, 0, 0)
      canvas.toBlob(resolve, 'image/png')
    }

    image.src = URL.createObjectURL(blob)
  })
}

function detectImageFileType(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      const bytes = new Uint8Array(fileReader.result as ArrayBuffer)
      const arr = bytes.subarray(0, 4)
      let header = ''
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16)
      }

      switch (header) {
        case '89504e47':
          resolve('image/png')
          break
        case '47494638':
          resolve('image/gif')
          break
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
          resolve('image/jpeg')
          break
        default:
          resolve('unknown')
          break
      }
    }

    fileReader.onerror = () => {
      reject(new Error('FileReader Error'))
    }

    fileReader.readAsArrayBuffer(blob)
  })
}

async function downloadSourceImage(sourceImageUrl: string): Promise<DownloadSourceImageResponse> {
  const { data: blob } = await getBlob(sourceImageUrl)

  return new Promise((resolve, reject) => {
    const temporaryImage = new Image()
    temporaryImage.crossOrigin = 'anonymous'
    temporaryImage.onload = () => {
      const { height, width } = temporaryImage
      resolve({ blob, height, width })
    }
    temporaryImage.onerror = reject
    temporaryImage.src = URL.createObjectURL(blob)
  })
}

export default class InpaintingCanvas {
  readonly #canvas: HTMLCanvasElement
  #container: HTMLElement
  readonly #context: CanvasRenderingContext2D
  readonly #onDrawComplete: () => void
  #openSeadragonInstance: OpenSeadragon.Viewer
  readonly #sourceImageUrl: string

  #isDrawing = false
  #canvasOffset: Coordinates = { x: 0, y: 0 }

  constructor(openSeadragonInstance: OpenSeadragon.Viewer, sourceImageUrl: string, onDraw: () => void) {
    this.#container = openSeadragonInstance.container
    this.#canvas = document.createElement('canvas')
    this.#context = this.#canvas.getContext('2d')
    this.#onDrawComplete = onDraw
    this.#openSeadragonInstance = openSeadragonInstance
    this.#sourceImageUrl = sourceImageUrl

    this.disable()

    this.#canvas.style.cursor = `url('${cursorUrl}') 20 20, auto`
    this.#canvas.style.opacity = '0.8'
    this.#canvas.style.position = 'absolute'
    this.#canvas.style.zIndex = '1'

    this.#container.prepend(this.#canvas)
    this.#canvas.addEventListener('mousedown', this.#start.bind(this))
    this.#canvas.addEventListener('mousemove', this.#draw.bind(this))
    this.#canvas.addEventListener('mouseup', this.#stop.bind(this))
  }

  clear() {
    this.#context.clearRect(0, 0, this.#canvas.width, this.#canvas.height)
  }

  destroy() {
    this.#container.removeChild(this.#canvas)
  }

  disable() {
    this.#canvas.style.display = 'none'
  }

  enable() {
    this.#canvas.style.display = 'block'
  }

  async getDrawing() {
    const download = await downloadSourceImage(this.#sourceImageUrl)
    const sourceImageFileType = await detectImageFileType(download.blob)
    if (sourceImageFileType !== 'image/png') {
      download.blob = await convertToPNG(download.blob)
    }

    const blobs = { mask: null, original: null }

    blobs.mask = await convertToBlackAndWhite(this.#canvas, this.#context)

    if (download.width !== this.#canvas.width || download.height !== this.#canvas.height) {
      blobs.mask = await resizeImage(blobs.mask, download.width, download.height)
    }
    blobs.original = download.blob
    return { mask: blobs.mask, original: blobs.original }
  }

  update(currentZoom = 100) {
    const source = this.#openSeadragonInstance.world.getItemAt(0).source as unknown as OpenSeadragonImageTileSource
    const scaleFactor = source.width / viewerWidth
    const height = source.height / scaleFactor
    const osdCanvas = this.#openSeadragonInstance.drawer.canvas as HTMLCanvasElement

    if (currentZoom && currentZoom < 100) {
      const zoomedWidth = (viewerWidth * currentZoom) / 100
      const zoomedLeft = (viewerWidth - zoomedWidth) / 2
      this.#canvas.width = zoomedWidth
      this.#canvas.height = (height * currentZoom) / 100
      this.#canvas.style.left = `${zoomedLeft}px`
      this.#canvas.style.top = '0'
    } else {
      this.#canvas.width = viewerWidth
      this.#canvas.height = height
      this.#canvas.style.left = '0'
      const top = (osdCanvas.clientHeight - height) / 2
      this.#canvas.style.top = `${top}px`
    }
  }

  #draw(mouseEvent: MouseEvent) {
    if (this.#isDrawing) {
      const x = mouseEvent.clientX - this.#canvasOffset.x
      const y = mouseEvent.clientY - this.#canvasOffset.y
      this.#context.lineWidth = 40
      this.#context.lineCap = 'round'
      this.#context.strokeStyle = strokeStyle

      this.#context.lineTo(x, y)
      this.#context.stroke()
      this.#context.beginPath()
      this.#context.moveTo(x, y)
    }
  }

  #start(mouseEvent: MouseEvent) {
    const { x, y } = this.#canvas.getBoundingClientRect()
    this.#canvasOffset = { x, y }
    this.#isDrawing = true
    this.clear()
    this.#draw(mouseEvent)
  }

  #stop() {
    this.#isDrawing = false
    this.#context.beginPath()
    this.#onDrawComplete()
  }
}

function resizeImage(blob: Blob, newWidth: number, newHeight: number): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = () => {
      const temporaryCanvas = document.createElement('canvas')
      temporaryCanvas.width = newWidth
      temporaryCanvas.height = newHeight

      const temporaryContext = temporaryCanvas.getContext('2d')
      temporaryContext.drawImage(img, 0, 0, newWidth, newHeight)
      temporaryCanvas.toBlob((blob) => {
        if (blob) {
          resolve(blob)
        } else {
          reject(new Error('Canvas to Blob Conversion Failed'))
        }
      }, 'image/png')
    }
    img.src = URL.createObjectURL(blob)
  })
}
