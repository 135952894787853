import { cn } from 'lib/util/cn'
import { MouseEvent, ReactNode, useEffect, useState, JSX, HtmlHTMLAttributes, forwardRef } from 'react'

export type ButtonColor = keyof typeof classNames.colors
type ButtonRole = 'primary' | 'secondary' | 'destructive'

export type ButtonSize = 'normal' | 'cut'

export interface ButtonProps extends HtmlHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode
  className?: string
  color?: ButtonColor
  disabled?: boolean
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>
  rounded?: boolean
  size?: 'normal' | 'cut'
  type?: 'button' | 'submit' | 'reset'
}

interface LinkLikeButtonProps {
  children?: ReactNode
  color?: ButtonRole
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
  className?: HtmlHTMLAttributes<HTMLButtonElement>['className']
}

const classNames = {
  colors: {
    green: `
      tw-bg-gherkin
      tw-text-peppercorn-50
      hover:tw-bg-gherkin-500
      hover:tw-text-white
      disabled:tw-bg-gherkin
      disabled:tw-text-peppercorn-50
      `,
    outlineDark: `
      tw-bg-transparent
      tw-text-peppercorn-800
      tw-border-peppercorn-800
      hover:tw-text-white
      hover:tw-bg-peppercorn-800
      disabled:tw-bg-transparent
      disabled:tw-text-peppercorn-800
      `,
    lightGray: `
      tw-bg-white
      tw-text-peppercorn-700
      tw-border-peppercorn-200
      hover:tw-bg-peppercorn-50
      disabled:tw-bg-white
      `,
    neutralGray: `
      tw-bg-white
      tw-text-neutral-900
      tw-border-neutral-300
      hover:tw-bg-neutral-100
      disabled:tw-bg-white
      `,
    neutral: `
      tw-bg-neutral-100
      tw-text-neutral-900
      hover:tw-bg-neutral-200
      hover:tw-text-neutral-900
      disabled:tw-bg-neutral-100
      `,
    cherry: `
      tw-bg-cherry-100
      tw-text-cherry-500
      tw-border-cherry-100
      hover:tw-bg-cherry-50
      disabled:tw-bg-cherry-100
      `,
    outlineCherry: `
      tw-bg-transparent
      tw-text-cherry-500
      tw-border-cherry-500
      hover:tw-text-white
      hover:tw-bg-cherry-500
      disabled:tw-bg-transparent
      disabled:tw-text-cherry-500
      `,
    purple: `
      tw-bg-cornflower-500
      tw-text-white
      hover:tw-bg-cornflower-600
      hover:tw-text-white
      disabled:tw-bg-cornflower-500
      `,
    lightPurple: `
      tw-bg-cornflower-100
      tw-text-neutral-800
      hover:tw-bg-cornflower-200
      disabled:tw-bg-cornflower-50
      `,
    transparent: `
      tw-bg-transparent
      tw-text-neutral-500
      `,
    red: `
      tw-bg-red-500
      tw-text-white
      hover:tw-bg-red-400
    `,
    yellow: `
      tw-bg-sunnyyellow-600
      tw-text-white
      hover:tw-bg-yellow-400
    `,
  },
  common:
    'tw-text-center tw-font-normal tw-border tw-border-transparent tw-border-solid disabled:tw-opacity-60 hover:tw-no-underline',
  corners: {
    normal: 'tw-rounded-md',
    rounded: 'tw-rounded-3xl',
  },
  roles: {
    primary: 'tw-text-cornflower-500 hover:tw-text-cornflower-800',
    secondary: 'tw-text-neutral-800 hover:tw-text-neutral-600',
    destructive: 'tw-text-flushpink-500 hover:tw-text-flushpink-700',
  },
  sizes: {
    normal: 'tw-py-1.5 tw-px-3.5 tw-text-base',
    cut: 'tw-py-2 tw-px-3.5 tw-text-xs',
  },
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      color = 'green',
      disabled,
      onClick,
      rounded = false,
      size = 'normal',
      type = 'button',
      ...otherProps
    },
    ref,
  ) => {
    const [isMounted, setIsMounted] = useState<boolean>(true)
    const [isWaitingForClickResponse, setIsWaitingForClickResponse] = useState<boolean>(false)

    const classes = getButtonClassNames(size, rounded, color, className, disabled)

    function handleClick(event: MouseEvent<HTMLButtonElement>) {
      if (!isWaitingForClickResponse) {
        const response = onClick?.(event)
        if (response instanceof Promise) {
          setIsWaitingForClickResponse(true)
          response.then(
            () => isMounted && setIsWaitingForClickResponse(false),
            () => isMounted && setIsWaitingForClickResponse(false),
          )
        }
      }
    }

    useEffect(
      () => () => {
        setIsMounted(false)
      },
      [],
    )

    return (
      <button
        ref={ref}
        className={classes}
        onClick={handleClick}
        disabled={disabled || isWaitingForClickResponse}
        type={type}
        {...otherProps}
      >
        {children}
      </button>
    )
  },
)

Button.displayName = 'Button'

export default Button

export function getButtonClassNames(
  size: ButtonSize,
  rounded: boolean,
  color: ButtonColor,
  additionalClassNames: string = null,
  disabled = false,
): string {
  const classes = [
    classNames.common,
    classNames.sizes[size],
    rounded ? classNames.corners.rounded : classNames.corners.normal,
    classNames.colors[color],
  ]
  if (disabled) {
    classes.push('tw-cursor-not-allowed')
  } else {
    classes.push('tw-cursor-pointer')
  }
  if (additionalClassNames) {
    classes.push(additionalClassNames)
  }
  return cn(classes)
}

interface LinkButtonProps extends HtmlHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode
  className?: string
  color?: ButtonColor
  rounded?: boolean
  size?: ButtonSize
  target?: '_self' | '_blank'
  url: string
  download?: string
}
export function LinkButton({
  children,
  className,
  color = 'green',
  rounded = false,
  size = 'normal',
  target = '_self',
  url,
  ...props
}: LinkButtonProps): JSX.Element {
  const classes = getButtonClassNames(size, rounded, color, className)

  return (
    <a className={classes} href={url} target={target} {...props}>
      {children}
    </a>
  )
}

export function LinkLikeButton({ children, color = 'primary', onClick, className }: LinkLikeButtonProps): JSX.Element {
  return (
    <button
      className={cn(
        'tw-cursor-pointer tw-border-none tw-bg-transparent tw-p-0 tw-font-semibold tw-leading-tight',
        classNames.roles[color],
        className,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
