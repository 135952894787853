import { ReactElement, useState } from 'react'
import { ConversationTimelineItem } from 'lib/api/timeline/timeline'
import TimelineConversationDetails from './timeline-conversation-details'
import TimelineConversationEdit from './timeline-conversation-edit'

interface TimelineConversationProps {
  conversation: ConversationTimelineItem
  editable?: boolean
  onDelete?: () => void
  onSubmit: (body: string) => Promise<void>
}

export default function TimelineConversation({
  conversation,
  editable = false,
  onDelete,
  onSubmit,
}: TimelineConversationProps): ReactElement {
  const [editMode, setEditMode] = useState(false)

  function disableEditMode() {
    setEditMode(false)
  }

  function enableEditMode() {
    setEditMode(true)
  }

  function handleOnSubmit(body: string) {
    onSubmit(body)
    disableEditMode()
  }

  if (editable && editMode) {
    return <TimelineConversationEdit conversation={conversation} onCancel={disableEditMode} onSubmit={handleOnSubmit} />
  }
  return (
    <TimelineConversationDetails
      conversation={conversation}
      editable={editable}
      enableEditMode={enableEditMode}
      onDelete={onDelete}
    />
  )
}
