import MediaUploadButton from './media-upload-button'
import StockMediaModalButton from './stock-media-modal-button'

export default function MediaUploaders(): JSX.Element {
  return (
    <div className="tw-flex tw-flex-col tw-gap-3">
      <StockMediaModalButton />
      <MediaUploadButton />
    </div>
  )
}
