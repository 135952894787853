import { useState } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { Drawer } from 'lib/components/drawer/drawer'
import Button, { LinkLikeButton } from 'components/core/button'
import { request, requestQuery } from 'lib/api/fetch-api'
import { ColumnFiltersState } from '@tanstack/react-table'
import { getFilters } from './use-table'
import { SavedSearch } from 'interfaces/saved-search'
import { ArrowRightToLine, Trash, ChevronLeft } from 'lucide-react'
import UserAvatar from 'components/elements/user-avatar'
import { displayDate } from 'lib/util/date'
import { AnimatedLoadingScreen } from '../requests/empty-screens'
import { toast } from 'lib/components/toast/toast'
interface SavedSearchesProps {
  setFilters: (updater: ColumnFiltersState) => void
}

const SavedSearchesDrawer = ({ setFilters }: SavedSearchesProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [deleteSearch, setDeleteSearch] = useState<SavedSearch | null>(null)
  const queryClient = useQueryClient()

  const { data: savedSearches } = useQuery({
    queryKey: ['savedSearches'],
    queryFn: requestQuery({ endpoint: 'getSavedSearches' }),
  })

  const onSavedSearchClick = (search: SavedSearch) => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(search.parameters)
    url.search = searchParams.toString()
    setFilters(getFilters(url))
    setIsOpen(false)
  }

  const deleteMutation = useMutation({
    mutationFn: (id: string) => request({ endpoint: 'deleteSavedSearch', query: { id } }),
    onSuccess: () => {
      toast.success('Saved search deleted')
      setDeleteSearch(null)
      queryClient.invalidateQueries({ queryKey: ['savedSearches'] })
    },
    onError: () => toast.error('Error deleting saved search'),
  })

  return (
    <>
      <Button color="neutralGray" onClick={() => setIsOpen(true)}>
        View Saved Searches
      </Button>
      <Drawer isOpen={isOpen} setOpen={setIsOpen} size="md">
        <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-8 tw-p-8">
          <h3 className="tw-flex tw-items-center tw-gap-2">
            <div
              className="tw-flex tw-h-10 tw-w-10 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-bg-peppercorn-50 hover:tw-bg-peppercorn-100"
              onClick={() => {
                deleteSearch ? setDeleteSearch(null) : setIsOpen(false)
              }}
            >
              {deleteSearch ? <ChevronLeft /> : <ArrowRightToLine />}
            </div>
            {deleteSearch ? 'Delete Saved Search' : 'Saved Searches'}
          </h3>
          {deleteSearch ? (
            <div className="tw-flex tw-h-full tw-flex-col">
              <div className="tw-font-semibold">Are you sure you want to delete &quot;{deleteSearch.name}&quot;?</div>
              <div className="tw-text-sm">This action cannot be undone.</div>
              <div className="tw-mt-auto tw-flex tw-flex-row tw-justify-end tw-gap-2">
                <Button disabled={deleteMutation.isPending} color="lightGray" onClick={() => setDeleteSearch(null)}>
                  Go back
                </Button>
                <Button
                  disabled={deleteMutation.isPending}
                  color="red"
                  onClick={() => deleteMutation.mutate(deleteSearch.id)}
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : savedSearches?.data?.length ? (
            <table className="tw-w-full tw-border-collapse tw-border-solid tw-border-neutral-200 tw-bg-white">
              <thead className="">
                <tr className="tw-border-solid tw-border-neutral-200 tw-bg-neutral-50">
                  <th className="tw-p-4 tw-text-xs tw-font-medium tw-uppercase tw-text-black">Name</th>
                  <th className="tw-p-4 tw-text-xs tw-font-medium tw-uppercase tw-text-black">Created by</th>
                  <th className="tw-p-4 tw-text-xs tw-font-medium tw-uppercase tw-text-black">Last Updated</th>
                  <th className="tw-p-4 tw-text-xs tw-font-medium tw-uppercase tw-text-black">{/* Actions */}</th>
                </tr>
              </thead>
              <tbody>
                {savedSearches?.data?.map((search) => (
                  <tr key={search.id} className="tw-border-solid tw-border-neutral-200 tw-text-left">
                    <td className="tw-p-4 tw-text-left">
                      <LinkLikeButton
                        className="tw-max-w-xs tw-overflow-hidden tw-truncate tw-overflow-ellipsis tw-whitespace-nowrap"
                        onClick={() => onSavedSearchClick(search)}
                      >
                        {search.name}
                      </LinkLikeButton>
                    </td>
                    <td className="tw-p-4 tw-text-left">
                      <UserAvatar avatar={search?.user?.avatar} name={search?.user?.fullName} />
                    </td>
                    <td className="tw-p-4 tw-text-left">{displayDate(search.updatedAt, false).toLocaleLowerCase()}</td>
                    <td className="tw-px-4 tw-py-2">
                      <button
                        className="tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-border-none tw-bg-peppercorn-50 tw-p-0 hover:tw-bg-peppercorn-100"
                        onClick={() => {
                          setDeleteSearch(search)
                        }}
                      >
                        <Trash className="tw-h-5 tw-w-5 tw-text-red-600" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
              <AnimatedLoadingScreen
                variant="binocularsSad"
                text="No saved searches yet..."
                subText={`When you've set up a search you want to come back to, simply click the "Save Search" button on the search page to keep it handy for future use.`}
              />
            </div>
          )}
        </div>
      </Drawer>
    </>
  )
}

export default SavedSearchesDrawer
