import RoundedButtonWithIcon from 'components/core/rounded-button-with-icon'
import { useMediaContext } from './media-provider'

export default function MediaUploadButton(): JSX.Element {
  const { open } = useMediaContext()

  return (
    <div className="tw-relative">
      <RoundedButtonWithIcon icon={['fal', 'upload']} color="neutralGray" onClick={open}>
        Upload Files
      </RoundedButtonWithIcon>
    </div>
  )
}
