import DropdownMenu from 'components/elements/dropdown-menu'
import ActiveTabMenu from 'components/elements/dropdown/menus/active-tab-menu'
import ArchivedTabMenu from 'components/elements/dropdown/menus/archived-tab-menu'
import DraftTabMenu from 'components/elements/dropdown/menus/draft-tab-menu'
import { SearchTicket } from 'interfaces/ticket'
import { useConfirmationModalContext } from 'providers/confirmation-modal-provider'
import { DesignRequestStatus } from 'lib/api/tickets/tickets'
import { queryClient } from 'providers/query-client-provider'
interface SearchOverflowMenuProps {
  ticket: SearchTicket
}

const SearchOverflowMenu = ({ ticket }: SearchOverflowMenuProps) => {
  const {
    ticket_id,
    title,
    ticket_status: friendlyStatusName,
    files_download_url: filesDownloadUrl,
    ticket_created_by: user,
    assets,
  } = ticket

  const id = ticket_id
  const ticketFilesCount = assets?.length || 0

  const { menuButtonActionWithCallbacks } = useConfirmationModalContext()

  const menuButtonAction = menuButtonActionWithCallbacks(() => queryClient.invalidateQueries({ queryKey: ['search'] }))

  return (
    <DropdownMenu>
      {friendlyStatusName === DesignRequestStatus.draft && (
        <DraftTabMenu
          requestId={id}
          url={`tickets/${id}`}
          subject={title}
          userId={user.id}
          menuActionBtn={menuButtonAction}
          showDelete={false}
        />
      )}
      {(friendlyStatusName === DesignRequestStatus.archived || friendlyStatusName === DesignRequestStatus.canceled) && (
        <ArchivedTabMenu
          id={id}
          filesDownloadUrl={filesDownloadUrl}
          menuActionBtn={menuButtonAction}
          status={friendlyStatusName}
          subject={title}
          ticketFilesCount={ticketFilesCount}
          userId={user?.id}
          showDelete={false}
        />
      )}
      {friendlyStatusName !== DesignRequestStatus.draft &&
        friendlyStatusName !== DesignRequestStatus.archived &&
        friendlyStatusName !== DesignRequestStatus.canceled && (
          <ActiveTabMenu
            id={id}
            subject={title}
            filesDownloadUrl={filesDownloadUrl}
            status={friendlyStatusName}
            userId={user?.id}
            menuActionBtn={menuButtonAction}
            ticketFilesCount={ticketFilesCount}
            hideMoveToTop
          />
        )}
    </DropdownMenu>
  )
}

export default SearchOverflowMenu
