import useSidebarState from 'lib/hooks/useSidebarState'
import { cn } from 'lib/util/cn'
import { ArrowLeftToLine, ArrowRightToLine, ExternalLink } from 'lucide-react'
import IconButton from '../buttons/icon-button'
import { getAdminItems, getMainItems } from './sidebar-menus'
import Button from 'components/core/button'
import { PATHS } from 'lib/constants/paths'
import { menuItemVariants, MenuItem, PopoverMenuItem } from '../menu-item/menu-item'
import { UserWithFeatureAndAbilities } from 'interfaces/user'
import { CurrentUser } from 'interfaces/app'
import { TrialCta } from '../trial/trial'

interface SidebarProps {
  version: string
  userAbilities: UserWithFeatureAndAbilities
  user: CurrentUser
}

const Sidebar = ({ version, userAbilities, user }: SidebarProps) => {
  const [isOpen, setIsOpen] = useSidebarState()

  const intent = isOpen ? 'rowMobileCol' : 'col'

  const filteredAdminItems = getAdminItems(userAbilities)
  const filteredMainItems = getMainItems(userAbilities)

  return (
    <div className={cn('tw-transition-all tw-duration-300', isOpen ? 'md:tw-w-52' : 'tw-hidden tw-w-24 md:tw-block')}>
      <div
        className={cn(
          'tw-h-full tw-bg-white tw-px-2 tw-py-4 tw-shadow-lg tw-transition-all tw-duration-300',
          'tw-fixed tw-z-20 tw-flex tw-h-full tw-flex-col tw-overflow-hidden tw-bg-white',
          isOpen ? 'tw-w-28 md:tw-w-52' : 'tw-w-24',
        )}
      >
        <div className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-52 tw-flex-col tw-gap-1 tw-px-2 tw-pt-16">
          {userAbilities.isInternalRole && (
            <>
              <div className="tw-py-2">Admin</div>
              {filteredAdminItems.map((item) => (
                <PopoverMenuItem key={item.label} intent={intent} item={item} />
              ))}
              <hr className="tw-my-2 tw-w-full tw-border-neutral-200" />
              <div className="tw-py-2">Main</div>
            </>
          )}
          {filteredMainItems.map((item) => (
            <PopoverMenuItem key={item.label} intent={intent} item={item} />
          ))}
          <hr className="tw-my-2 tw-w-full tw-border-neutral-200" />

          <MenuItem
            intent={intent}
            item={{
              label: 'FreshStock',
              Icon: () => (
                <img width={24} height={24} src="/images/icons/fresh-stock-small-logo.svg" alt="FreshStock Logo" />
              ),
              RightIcon: ExternalLink,
              path: PATHS.FRESH_STOCK,
              newTab: true,
            }}
          />
          <hr className="tw-my-2 tw-w-full tw-border-neutral-200" />

          <TrialCta user={user} className="tw-my-auto tw-w-24 md:tw-hidden" />

          <div className={menuItemVariants({ intent, hover: false, className: 'tw-mt-auto tw-hidden md:tw-flex' })}>
            {isOpen ? (
              <>
                <Button className="tw-w-full" color="neutral" onClick={() => setIsOpen(!isOpen)}>
                  <ArrowLeftToLine className="tw-mr-2" />
                  Collapse
                </Button>
              </>
            ) : (
              <>
                <IconButton
                  color="secondary"
                  onClick={() => {
                    setIsOpen(!isOpen)
                  }}
                >
                  <ArrowRightToLine />
                </IconButton>
                Expand
              </>
            )}
          </div>
          <hr className="tw-my-0 tw-mt-auto tw-w-full tw-border-neutral-200 md:tw-mt-0" />
          <div className={menuItemVariants({ intent, hover: false })}>
            <div className="tw-w-full tw-text-center tw-text-xs tw-text-neutral-500">
              {isOpen ? 'Version' : 'V.'} {version}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
