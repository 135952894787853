import { Table } from '@tanstack/react-table'
import { columnIds, defaultColumnVisibility, LOCAL_STORAGE_SEARCH_VISIBILITY_KEY } from './use-table'
import { LinkLikeButton } from 'components/core/button'

import { SlidersHorizontal } from 'lucide-react'
import SelectBox from 'lib/components/dropdown/select-box'

export default function ActionsHeader<TData>({ table }: { table: Table<TData> }) {
  return (
    <SelectBox
      searchable={false}
      multiple={true}
      button={
        <div className="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-peppercorn-100">
          <SlidersHorizontal className="lu-sm" />
        </div>
      }
      label="Columns"
      showLabel={false}
      options={[
        { label: 'Request Type', value: columnIds.skill_name, displayElement: <>Request Type</> },
        { label: 'Brand', value: columnIds.brand_ids, displayElement: <>Brand</> },
        { label: 'Created By', value: columnIds.user_ids, displayElement: <>Created By</> },
        { label: 'Designer', value: columnIds.designer_ids, displayElement: <>Designer</> },
        { label: 'Last Updated', value: columnIds.ticket_updated_date, displayElement: <>Last Updated</> },
        { label: 'Format', value: columnIds.format, displayElement: <>Format</> },
      ]}
      dropdownClassName="tw-right-0"
      selectedValue={table.getVisibleFlatColumns().map((column) => column.id)}
      handleChange={(selected) => {
        const visibility = table.getAllColumns().reduce((acc, column) => {
          acc[column.id] = selected.includes(column.id)
          return acc
        }, {})

        localStorage.setItem(LOCAL_STORAGE_SEARCH_VISIBILITY_KEY, JSON.stringify(visibility))

        table.setColumnVisibility(visibility)
      }}
      footer={
        <div className="tw-mb-2 tw-w-full tw-border-0 tw-border-t tw-border-solid tw-border-peppercorn-100 tw-pt-2 tw-text-center">
          <LinkLikeButton
            onClick={() => {
              localStorage.removeItem(LOCAL_STORAGE_SEARCH_VISIBILITY_KEY)
              table.setColumnVisibility(defaultColumnVisibility)
            }}
          >
            Restore defaults
          </LinkLikeButton>
        </div>
      }
    />
  )
}
