import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react'
import Modal from 'lib/components/modals/modal'
import CompanyTaggingProvider, { ChangeTagAction, View, ViewStates } from 'providers/company-tagging-provider'
import Button from 'components/core/button'
import { CompanyTagging, TaggableType, updateCompanyTagging } from 'lib/api/company-tagging/company-tagging'
import { groupBy } from 'lodash'
import CompanyTags from 'components/elements/company-tagging/company-tags'
import { TaggableTag } from 'lib/api/company-tags/company-tags'
import IconButton from 'lib/components/buttons/icon-button'
import { X } from 'lucide-react'
import { toast } from 'lib/components/toast/toast'

interface BulkManageTagsModalProps {
  isOpen: boolean
  onSubmit: () => void
  selectedTags: TaggableTag[]
  setIsOpen: Dispatch<SetStateAction<boolean>>
  taggableIds: Array<number>
  taggableType: TaggableType
}

export default function BulkManageTagsModal({
  isOpen,
  onSubmit,
  selectedTags,
  setIsOpen,
  taggableIds,
  taggableType,
}: BulkManageTagsModalProps): ReactElement {
  const [currentView, setCurrentView] = useState<View>({ viewState: ViewStates.Search })
  const [bulkSelectedTags, setBulkSelectedTags] = useState(
    selectedTags?.filter((tag) => taggableIds.includes(tag.taggableId)) || [],
  )

  const partiallySelectedTags = bulkSelectedTags.filter(
    (tag) => bulkSelectedTags.filter((t) => t.id === tag.id).length < taggableIds.length,
  )

  async function handleSubmit() {
    try {
      const groupedSelectedTags = groupBy(bulkSelectedTags, 'taggableId')
      const groupedSelectedTagsKeys = Object.keys(groupedSelectedTags)

      const diff = taggableIds.filter((taggableId) => !groupedSelectedTagsKeys.includes(taggableId.toString()))
      if (diff?.length > 0) {
        diff.forEach((taggableId) => {
          groupedSelectedTags[taggableId.toString()] = [{ taggableId: taggableId, taggableType } as TaggableTag]
        })
      }

      for (const key of Object.keys(groupedSelectedTags)) {
        const selectedCompanyTaggings: CompanyTagging[] = groupedSelectedTags[key].map(
          (tag) =>
            ({
              taggableType: taggableType,
              taggableId: tag.taggableId,
              companyTagId: tag.id,
            }) as CompanyTagging,
        )

        await updateCompanyTagging(selectedCompanyTaggings)
      }

      onSubmit?.()
    } catch (e) {
      console.error('Error bulk updating tags', e)
      toast.error('Error bulk updating tags')
    }
  }

  function handleUpdateTaggableState(updatedTag: TaggableTag, action: ChangeTagAction) {
    switch (action) {
      case ChangeTagAction.Select: {
        const newlySelectedTags = taggableIds.map((taggableId) => ({
          taggableId,
          taggableType,
          ...updatedTag,
        }))

        // Only update local tags since we do not save until the user clicks submit
        setBulkSelectedTags((previousSelectedTags) => [...previousSelectedTags, ...newlySelectedTags])
        break
      }
      case ChangeTagAction.Unselect:
        // Only update local tags since we do not save until the user clicks submit
        setBulkSelectedTags(
          (previousSelectedTags) => previousSelectedTags.filter((t) => t.id !== updatedTag.id) as TaggableTag[],
        )
        break
      case ChangeTagAction.Update:
        setBulkSelectedTags((previousSelectedTags) =>
          previousSelectedTags.map((tag) => (tag.id === updatedTag.id ? { ...tag, ...updatedTag } : tag)),
        )
        break
      case ChangeTagAction.Delete:
        setBulkSelectedTags((previousSelectedTags) => previousSelectedTags.filter((t) => t.id !== updatedTag.id))
        break
    }
  }

  useEffect(() => {
    setBulkSelectedTags(selectedTags?.filter((tag) => taggableIds.includes(tag.taggableId)) || [])
  }, [selectedTags, taggableIds])

  return (
    <Modal open={isOpen} setOpen={setIsOpen} size="lg">
      {currentView?.viewState === ViewStates.Search && (
        <Modal.Header as="div">
          <div className="tw-flex tw-items-center tw-justify-between">
            <h4>Add or remove tag(s) to requests</h4>
            <IconButton color="secondary" onClick={() => setIsOpen(false)}>
              <X />
            </IconButton>
          </div>
        </Modal.Header>
      )}
      <Modal.Body setOpen={setIsOpen}>
        <CompanyTaggingProvider
          taggableType={taggableType}
          selectedTags={bulkSelectedTags}
          partiallySelectedTags={partiallySelectedTags}
          onViewChange={(view: View) => setCurrentView(view)}
          updateTaggableState={handleUpdateTaggableState}
        >
          <CompanyTags handleXClick={() => setIsOpen(false)} showTitle={false} />
        </CompanyTaggingProvider>
      </Modal.Body>
      {currentView?.viewState === ViewStates.Search && (
        <Modal.Footer>
          <div className="tw-flex tw-justify-end tw-gap-2 tw-pt-4">
            <Button color="lightGray" type="button" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button color="purple" type="button" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  )
}
