import Button from 'components/core/button'
import TextInput from 'lib/components/text-input/text-input'
import { Filters } from './company-designer-allocations-page'
import Checkbox from 'components/core/checkbox'

interface CompanyDesignerAllocationsFiltersProps {
  filters: Filters
  handleClear: (e) => void
  onSubmit: (e) => void
  setFilters: (filters: Filters) => void
}

const classNames = {
  button: 'tw-my-2',
  filtersWrapper: 'tw-mb-4 tw-relative tw-flex tw-justify-between',
  separator: 'tw-px-2 tw-pt-2.5 tw-text-xl tw-text-peppercorn-100',
}

export default function CompanyDesignerAllocationsFilters({
  filters,
  handleClear,
  onSubmit,
  setFilters,
}: CompanyDesignerAllocationsFiltersProps) {
  return (
    <form data-testid={'company-designer-allocations-filters-form'} onSubmit={onSubmit}>
      <div className={classNames.filtersWrapper}>
        <div className="tw-flex">
          <div className="tw-flex tw-w-80 tw-gap-4">
            <TextInput
              id="nameOrEmailEq"
              placeholder="Search by company name or email"
              onChange={(e) => setFilters({ ...filters, companyNameOrCompanyEmailCont: e.target.value })}
              value={filters.companyNameOrCompanyEmailCont}
            />
          </div>
          <div className="tw-m-5">
            <Checkbox
              checked={filters.powerPlan === '1'}
              label="Power"
              onChange={() => {
                if (filters.powerPlan === '1') {
                  setFilters({ ...filters, powerPlan: '0' })
                } else {
                  setFilters({ ...filters, powerPlan: '1' })
                }
              }}
            />
          </div>
          <div className="tw-m-5">
            <Checkbox
              checked={filters.proPremiumPlan === '1'}
              label="Pro / Premium"
              onChange={() => {
                if (filters.proPremiumPlan === '1') {
                  setFilters({ ...filters, proPremiumPlan: '0' })
                } else {
                  setFilters({ ...filters, proPremiumPlan: '1' })
                }
              }}
            />
          </div>
          <div className="tw-m-5">
            <Checkbox
              checked={filters.unassigned === '1'}
              label="Unassigned"
              onChange={() => {
                if (filters.unassigned === '1') {
                  setFilters({ ...filters, unassigned: '0' })
                } else {
                  setFilters({ ...filters, unassigned: '1' })
                }
              }}
            />
          </div>
        </div>
        <div className="tw-flex tw-gap-4">
          <Button className={classNames.button} type="submit">
            Search
          </Button>
          <Button className={classNames.button} color="neutralGray" onClick={handleClear}>
            Clear
          </Button>
        </div>
      </div>
    </form>
  )
}
