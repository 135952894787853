import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SkillSize } from 'lib/api/skills/skills'
import { Card } from 'lib/components/card/card'

interface SkillSizeProps {
  skillSize: SkillSize
  onClick: (size: SkillSize) => void
  isSelected: boolean
  isCardsSquare: boolean
}

export default function SelectableSkillSize({
  skillSize,
  onClick,
  isSelected,
  isCardsSquare,
}: SkillSizeProps): JSX.Element {
  const classNames = {
    cardBody: `tw-pt-4`,
    iconContainer: `tw-w-14 tw-h-14 tw-flex tw-justify-center tw-items-center`,
    cardFooter: `tw-flex tw-flex-col tw-text-center`,
  }

  function isSquare(skillSize: SkillSize) {
    const isEqual = !!skillSize?.width && skillSize.width === skillSize.height

    return isEqual || skillSize.details.includes('1:1')
  }

  function isPortrait(skillSize: SkillSize) {
    return skillSize.height > skillSize.width || skillSize.details.includes('9:16')
  }

  function Icon({ skillSize }: { skillSize: SkillSize }): JSX.Element {
    if (isSquare(skillSize)) {
      return <FontAwesomeIcon icon={['fal', 'square']} size="2x" />
    }

    if (isPortrait(skillSize)) {
      return <FontAwesomeIcon icon={['fal', 'rectangle-portrait']} size="2x" />
    }

    return <FontAwesomeIcon icon={['fal', 'rectangle-landscape']} size="2x" />
  }

  return (
    <Card
      size="md"
      onClick={() => onClick(skillSize)}
      key={skillSize.id}
      isSelected={isSelected}
      isSquare={isCardsSquare}
    >
      <Card.Body className={classNames.cardBody} centered>
        <div className={classNames.iconContainer}>
          <Icon skillSize={skillSize} />
        </div>
      </Card.Body>
      <Card.Footer className={classNames.cardFooter}>
        <p className="tw-m-0">{skillSize.name}</p>
        <p className="tw-m-0 tw-text-xs tw-text-neutral-500">{skillSize.details}</p>
      </Card.Footer>
    </Card>
  )
}
