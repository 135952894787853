import { useEffect, useMemo } from 'react'
import RoundedButtonWithIcon from 'components/core/rounded-button-with-icon'
import { useMediaContext } from './media-provider'

declare global {
  interface Window {
    DesignPickle: {
      StockAssetsPicker: {
        subscribe: (name: string, callback: (photosCollection) => void) => void
        unsubscribe: (name: string) => void
      }
    }
  }
}

export default function StockMediaModalButton(): JSX.Element {
  const { addStockAssets, ticket } = useMediaContext()

  const ticketId = useMemo(() => ticket?.id, [ticket?.id])

  useEffect(() => {
    if (Object.hasOwn(window, 'DesignPickle') && Object.hasOwn(window.DesignPickle, 'StockAssetsPicker')) {
      window.DesignPickle.StockAssetsPicker.subscribe('StockMediaModalButton', addStockAssets)
      return () => {
        window.DesignPickle.StockAssetsPicker.unsubscribe('StockMediaModalButton')
      }
    }
  }, [addStockAssets])

  return (
    <RoundedButtonWithIcon
      icon={['fal', 'images']}
      color="neutralGray"
      data-class="photos-modal"
      data-max-photos-allowed="30"
      data-stock-asset-template="request-form"
      data-ticket-id={ticketId}
      data-title=""
      data-toggle="ajax-modal"
      data-url="/photos"
    >
      Stock Libraries
    </RoundedButtonWithIcon>
  )
}
