import { ReactElement } from 'react'
import { ChevronLeft } from 'lucide-react'

import { routes } from 'lib/api/admin/tickets/admin-tickets'

import AdminHeaderDetails from './admin-header-details'

export default function AdminHeader(): ReactElement {
  return (
    <header className="tw-px-2 tw-py-4">
      <a
        href={routes.assignedToMe}
        className="tw-mb-2 tw-flex tw-w-max tw-items-center tw-text-neutral-400 hover:tw-text-cornflower-500"
      >
        <ChevronLeft className="lu-light lu-md" />
        Back to all tickets
      </a>
      <AdminHeaderDetails />
    </header>
  )
}
