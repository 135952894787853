import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function TitleWithErrorIcon({ title }: { title: string }) {
  return (
    <>
      {title} <FontAwesomeIcon icon={faCircleExclamation} className="tw-text-flushpink-500" />
    </>
  )
}

export function ErrorLabel({ label }: { label: string }) {
  return <div className="tw-text-flushpink-500">{label}</div>
}
