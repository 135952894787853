import { ReactElement, useMemo } from 'react'
import TicketVersionSelectBox from 'lib/components/ticket/ticket-versions-select-box'
import { generateRangeTo } from 'lib/util/range'
import { useRequestContext } from '../providers/request-provider'
import { useTimelineListContext } from '../providers/timeline-list-provider'
import { DesignRequestStatus } from 'lib/api/tickets/tickets'

export default function TimelineFilters(): ReactElement {
  const { ticket } = useRequestContext()
  const { selectedVersion, setSelectedVersion } = useTimelineListContext()

  const versions = useMemo(() => generateRangeTo(ticket.currentVersion), [ticket.currentVersion])

  function handleSelectedVersionChange(version: number) {
    setSelectedVersion(version)
  }

  if (ticket.status === DesignRequestStatus.draft || ticket.currentVersion === 1) {
    return null
  }

  return (
    <div className="tw-sticky tw-top-0 tw-z-10 tw-bg-white tw-py-5">
      <TicketVersionSelectBox
        selectedVersion={selectedVersion}
        setSelectedVersion={handleSelectedVersionChange}
        versions={versions}
      />
    </div>
  )
}
