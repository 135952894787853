import { Company } from 'interfaces/company'
import { User } from 'interfaces/user'
import { bindJWT, getApi } from 'lib/api/api'

// @deprecated
export interface AdminCompany {
  id: number
  active_campaign_id: string | null
  address_1: string
  address_2: string | null
  can_share_work: boolean
  city: string
  contact_method: string | null
  contact_number: string | null
  country: string
  coupons_used_list: string[]
  created_at: string
  currency: string
  current_month_fresh_stock_downloads: number
  customer_type: string
  dpus_count: number
  email: string
  extra_users: boolean
  final_designers: string | null
  fresh_stock_monthly_limit: number
  hubspot_id: string | null
  last_ticket_created_at: string
  latest_cancellation_id: string | null
  name: string
  notes: string | null
  number_of_employees: string
  one_off_credits: number
  overflow_protection: boolean
  phone: string | null
  pickle_coupon_id: string | null
  pro_ci_backup: boolean
  pro_mg_backup: boolean
  pro_pd_backup: boolean
  production_coordinator_id: string | null
  put_on_hold_at: string | null
  requests_count: number
  sensitive: boolean
  settings: { chatgpt_credits: number }
  skype: string | null
  state: string
  status: string
  stripe_customer_id: string
  stripe_on_hold_schedule_id: string | null
  subscription_quantity: number | null
  tag_list: string[]
  tax_exempt_status: string
  ticket_rate: string
  tickets_count: number
  total_fresh_stock_downloads: number
  updated_at: string
  used_api: boolean
  website_url: string
  zip: string
  zone_preference: string
}

const companiesBaseUrl = '/api/internal/admin/companies'

export async function searchCompanies(name: string, jwt: string = null): Promise<Company[]> {
  const getWithJWT = bindJWT(getApi, jwt)
  const response = await getWithJWT(`${companiesBaseUrl}/search`, { name })
  return response.data.data as Company[]
}

export async function getUsersForCompany(companyId: number, jwt: string = null): Promise<User[]> {
  const getWithJWT = bindJWT(getApi, jwt)
  const response = await getWithJWT(`${companiesBaseUrl}/${companyId}/users`)
  return response.data.data as User[]
}
