import ActionLinkItem from 'components/elements/dropdown/items/action-link-item'
import ExternalActionLinkItem from 'components/elements/dropdown/items/external-action-link-item'
import { AbilityResponse, fetchAbility } from 'lib/api/ability/ability'
import { isStatusCompleted, isStatusOnDeck, showDownloadAction } from 'lib/ticket/utils'
import { Loader2 } from 'lucide-react'
import { useUserContext } from 'providers/user-provider'
import { useEffect, useState } from 'react'
import WatchTicketItem from '../items/watch-ticket-item'

interface ActiveTabMenuProps {
  id: bigint | number
  subject: string
  filesDownloadUrl: string
  status: string
  userId: number
  menuActionBtn: (route: string, data: Record<string, unknown>, method: string) => void
  ticketFilesCount: bigint | number
  hideMoveToTop?: boolean
}

export default function ActiveTabMenu({
  id,
  subject,
  filesDownloadUrl,
  status,
  userId,
  menuActionBtn,
  ticketFilesCount,
  hideMoveToTop = false,
}: ActiveTabMenuProps): JSX.Element {
  const { user: loggedInUser } = useUserContext()
  const [ticketAbility, setTicketAbility] = useState<AbilityResponse>(null)
  const downloadAssetsButtonUrl = filesDownloadUrl ? filesDownloadUrl.toString() : ''
  const markArchivedTicketUrl = window.Routes.apiInternalMarkArchivedUrl()
  const updateTicketUrl = window.Routes.apiInternalTicketUrl(id)
  const editTicketUrl = window.Routes.ticket(id, { edit_request: true })
  const cancelTicketUrl = window.Routes.cancelApiInternalTicket(id)
  const cancelButtonData = { id: id, user: userId }
  const markArchivedButtonData = {
    ticket_ids: [id],
    user_id: userId,
    update_action: 'mark_archived',
  }
  const moveToTopButtonData = {
    ticket_id: id,
    user_id: userId,
    update_action: 'move_to_top',
  }

  const cancelModalData = {
    title: 'Cancel Request?',
    message: `Are you sure you want to cancel ${subject}?`,
    confirmBtnText: 'Yes, Cancel',
    confirmAction: () => {
      menuActionBtn(cancelTicketUrl, cancelButtonData, 'PATCH')
    },
  }

  useEffect(() => {
    const fetchTicketAbility = async () => {
      const ability = await fetchAbility('Ticket', Number(id))
      setTicketAbility(ability)
    }
    fetchTicketAbility()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (ticketAbility == null) {
    return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-py-4 tw-opacity-50">
        <Loader2 className="tw-animate-spin" />
      </div>
    )
  }

  return (
    <>
      {!hideMoveToTop && isStatusOnDeck(status) && ticketAbility?.can('moveToTop') && (
        <ActionLinkItem
          label="Move to Top"
          iconClass="far fa-arrow-to-top"
          route={updateTicketUrl}
          method="PATCH"
          data={moveToTopButtonData}
          menuActionBtn={menuActionBtn}
        />
      )}
      {isStatusOnDeck(status) && ticketAbility?.can('edit') && (
        <ExternalActionLinkItem label="Edit" iconClass="far fa-pen" route={editTicketUrl} />
      )}
      {showDownloadAction(status, ticketFilesCount) && (
        <ExternalActionLinkItem
          label="Download Assets"
          iconClass="far fa-folder-download"
          route={downloadAssetsButtonUrl}
        />
      )}
      {isStatusCompleted(status) && ticketAbility?.can('markArchived') && (
        <ActionLinkItem
          label="Archive"
          iconClass="far fa-check"
          route={markArchivedTicketUrl}
          method="PATCH"
          data={markArchivedButtonData}
          menuActionBtn={menuActionBtn}
        />
      )}
      {ticketAbility?.can('duplicate') && (
        <ExternalActionLinkItem
          label="Duplicate"
          iconClass="far fa-copy"
          route={`/tickets/${id}/duplications`}
          method="POST"
        />
      )}
      {!isStatusCompleted(status) && ticketAbility?.can('cancel') && (
        <ActionLinkItem
          label="Cancel"
          iconClass="fas fa-ban"
          data={cancelButtonData}
          menuActionBtn={menuActionBtn}
          modalData={cancelModalData}
        />
      )}
      {loggedInUser.id !== userId && (
        <WatchTicketItem
          ticketId={String(id)}
          className="tw-px-4 tw-py-2 tw-text-sm tw-text-peppercorn-700 hover:tw-bg-peppercorn-100 hover:tw-text-peppercorn-900"
        />
      )}
    </>
  )
}
