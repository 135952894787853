import { TicketFileVersion } from 'lib/api/ticket-files/ticket-file-versions'
import TicketFileVersionsCell from './ticket-file-versions-cell'
import { useTicketFileVersionsContext } from './ticket-file-versions-provider'

const classNames = {
  gridView: 'tw-flex tw-flex-wrap tw-justify-left tw-mb-2 tw-overflow-auto',
}

export default function TicketFileVersionsGrid({ ticketFileVersions }: { ticketFileVersions: TicketFileVersion[] }) {
  const { selectedList } = useTicketFileVersionsContext()
  const hasRailsAlerts = document.querySelector('div.alert.alert-warning.mt-2') !== null

  function containerHeight() {
    const initialHeight = hasRailsAlerts ? 22 : 17
    if (selectedList.length) return initialHeight + 4

    return initialHeight
  }

  const customStyle = {
    gridView: { height: `calc(100vh - ${containerHeight()}rem)` },
  }

  return (
    <div className={classNames.gridView} style={customStyle.gridView}>
      {ticketFileVersions.map((ticketFileVersion) => (
        <TicketFileVersionsCell ticketFileVersion={ticketFileVersion} key={ticketFileVersion.id} />
      ))}
    </div>
  )
}
