import React, { useState } from 'react'

import { Cancellation } from 'interfaces/cancellation'
import {
  ConfidenceLevel,
  ConvertDateToAmerican,
  CompanyName,
  DefaultRow,
  CompanyAge,
} from 'components/pages/admin/reports/shared/table/column-elements'
import EditableSupportingDocumentation from 'components/pages/admin/reports/shared/editable-supporting-documentation'
import TableRowActions from 'components/pages/admin/reports/shared/TableRowActions'
import { OptionType } from 'components/core/single-select'
import EditablePredictedLabel, {
  getPredictedLabelFromValue,
} from 'components/pages/admin/reports/shared/editable-predicted-label'

import { DaysUntilCancellation, NumberOfRequests, RefundStatus } from './cancellations-item-elements'
import LatestStatusLog from 'components/pages/admin/reports/shared/latest-status-log'
import { saveNotes, update } from 'lib/api/reporting/cancellations'

interface CancellationItemsProps {
  cancellation: Cancellation
  onCancellationChange: () => void
}

export default function CancellationItem(props: CancellationItemsProps): JSX.Element {
  const { cancellation, onCancellationChange } = props
  const [editMode, setEditMode] = useState(false)

  const {
    brightbackReason,
    cancellationNotes,
    company,
    competition,
    createdAt,
    feedback,
    labelProbability,
    latestStatusLog,
    message,
    predictedLabel,
    reason,
    sentiment,
  } = cancellation

  const [latestCancellationNote, setLatestCancellationNote] = useState(
    cancellationNotes?.length > 0 ? cancellationNotes[0] : null,
  )
  const [newCancellationNoteBody, setNewCancellationNoteBody] = useState(latestCancellationNote?.body || '')
  const [lastSavedPredictedLabelValue, setLastSavedPredictedLabelValue] = useState<OptionType>({
    value: predictedLabel || 'not_applicable',
    label: getPredictedLabelFromValue(predictedLabel) || 'Not Applicable',
  })
  const [lastSavedStatusLog, setLastSavedStatusLog] = useState(latestStatusLog)
  const [predictedLabelSelectValue, setPredictedLabelSelectValue] = useState<OptionType>()
  const [humanAnnotated, setHumanAnnotated] = useState(cancellation?.humanAnnotated || false)

  const { designers, dateOfLastRequest } = company

  const saveCancellationNote = async () => {
    const params = { cancellation_id: cancellation.id, body: newCancellationNoteBody }
    const response = await saveNotes(params)
    if (response.status === 200) {
      setLatestCancellationNote(response?.data?.data)
    }
  }

  const updateCancellationPredictedLabel = async () => {
    const params = { predicted_label: predictedLabelSelectValue.value }
    const response = await update(cancellation.id, params)
    if (response.status === 200) {
      setHumanAnnotated(true)
      setLastSavedPredictedLabelValue(predictedLabelSelectValue)
      setLastSavedStatusLog(response?.data?.cancellation?.latestStatusLog)
    }
  }

  const handleUpdateCancellation = async () => {
    if (newCancellationNoteBody && newCancellationNoteBody !== latestCancellationNote?.body) {
      try {
        await saveCancellationNote()
      } catch {
        alert('Oops, something went wrong.')
      }
    }

    if (predictedLabelSelectValue?.value !== lastSavedPredictedLabelValue?.value) {
      await updateCancellationPredictedLabel()
    }

    setEditMode(false)
  }

  const handleCancelUpdate = () => {
    setNewCancellationNoteBody(latestCancellationNote?.body || '')
    setPredictedLabelSelectValue(lastSavedPredictedLabelValue || { value: 'not_applicable', label: 'Not Applicable' })
    setEditMode(false)
  }

  return (
    <tr>
      <CompanyName {...cancellation} />
      <EditablePredictedLabel
        predictedLabel={predictedLabel}
        editMode={editMode}
        newPredictedLabel={predictedLabelSelectValue}
        setNewPredictedLabel={setPredictedLabelSelectValue}
        humanAnnotated={humanAnnotated}
      />
      <ConfidenceLevel labelProbability={labelProbability} />
      <EditableSupportingDocumentation
        supportingDocumentation={latestCancellationNote}
        editMode={editMode}
        setEditMode={setEditMode}
        newSupportingDocumentationBody={newCancellationNoteBody}
        setNewSupportingDocumentationBody={setNewCancellationNoteBody}
      />
      <LatestStatusLog latestStatusLog={lastSavedStatusLog} />
      <CompanyAge {...cancellation} />
      <td className="tw-py-2" data-testid="company-cancellation-requested-at-container">
        {ConvertDateToAmerican(createdAt)}
      </td>
      <DaysUntilCancellation cancellation={cancellation} />
      <td className="tw-py-2" data-testid="company-designers-container">
        {designers?.map((designer) => <span key={designer.id}>{designer.name}</span>)}
      </td>
      <NumberOfRequests cancellation={cancellation} />
      {[dateOfLastRequest, reason].map(DefaultRow)}
      <RefundStatus cancellation={cancellation} onCancellationChange={onCancellationChange} />
      {[message, brightbackReason, feedback, competition, sentiment].map(DefaultRow)}
      <TableRowActions
        editMode={editMode}
        setEditMode={setEditMode}
        onCancel={handleCancelUpdate}
        onSave={handleUpdateCancellation}
      />
    </tr>
  )
}
