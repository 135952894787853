import { SubscriptionType, TicketTemplate } from 'interfaces/project-templates'
import Checkbox from 'lib/components/checkbox/checkbox'
import { cn } from 'lib/util/cn'
import { BrushIcon, ClapperboardIcon, PenToolIcon, PresentationIcon, VideoIcon } from 'lucide-react'
import { Dispatch, SetStateAction } from 'react'

const classNames = {
  img: 'tw-h-20 tw-w-20 tw-rounded',
  tr: 'tw-border-solid tw-border-t-2 tw-border-b-2 tw-border-neutral-200',
  td: 'tw-px-4 tw-py-2',
  th: 'tw-sticky tw-top-[110px] tw-p-4 tw-bg-neutral-100 tw-text-xs tw-font-medium tw-text-black tw-items-center tw-gap-2',
  icon: 'tw-neutral-800 tw-h-4 tw-w-4',
}

const SUBSCRIPTION_TYPE_ICONS = {
  'Graphic Design': PenToolIcon,
  'Custom Illustration': BrushIcon,
  'Presentation Design': PresentationIcon,
  'Motion Graphics': VideoIcon,
  'Video Editing': ClapperboardIcon,
}

function subscriptionTypeIcon(subscriptionType: SubscriptionType) {
  const Icon = SUBSCRIPTION_TYPE_ICONS[subscriptionType]
  return <Icon className={classNames.icon} />
}

interface TicketTemplatesTableProps {
  ticketTemplates: TicketTemplate[]
  isBlankProjectTemplate: boolean
  selectedTicketTemplates: TicketTemplate[]
  setSelectedTicketTemplates: Dispatch<SetStateAction<TicketTemplate[]>>
}

export default function TicketTemplatesTable({
  ticketTemplates,
  isBlankProjectTemplate,
  selectedTicketTemplates,
  setSelectedTicketTemplates,
}: TicketTemplatesTableProps) {
  function isTicketTemplateSelected(ticketTemplate: TicketTemplate) {
    return selectedTicketTemplates.some((t) => t.id === ticketTemplate.id)
  }

  function toggleTicketTemplateSelection(ticketTemplate: TicketTemplate) {
    setSelectedTicketTemplates((prev) =>
      prev.some((t) => t.id === ticketTemplate.id)
        ? prev.filter((t) => t.id !== ticketTemplate.id)
        : [...prev, ticketTemplate],
    )
  }

  return (
    <table className="tw-my-2 tw-w-full tw-border tw-border-neutral-200 tw-bg-white">
      <>
        <thead className="tw-border-b-2 tw-border-t tw-border-solid tw-border-neutral-200 tw-bg-neutral-50">
          <tr className={classNames.tr}>
            <th className={classNames.th}>
              <div className="tw-flex tw-items-center tw-gap-2">TITLE</div>
            </th>
            <th className={classNames.th}>
              <div className="tw-flex tw-items-center tw-gap-2">DELIVERABLE TYPE</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {ticketTemplates?.map((ticketTemplate) => (
            <tr
              key={ticketTemplate.id}
              className={cn(classNames.tr, {
                'tw-bg-cornflower-100': isBlankProjectTemplate && isTicketTemplateSelected(ticketTemplate),
              })}
            >
              <td className={cn(classNames.td, 'tw-flex tw-items-center tw-gap-4')}>
                {isBlankProjectTemplate && (
                  <Checkbox
                    isChecked={isTicketTemplateSelected(ticketTemplate)}
                    onClick={() => toggleTicketTemplateSelection(ticketTemplate)}
                  />
                )}
                <img src={ticketTemplate.skill.imageUrl} alt={ticketTemplate.skill.name} className={classNames.img} />
                <div className="tw-flex tw-flex-col">
                  <h5 className="tw-text-medium tw-m-0 tw-font-semibold tw-text-neutral-800">
                    {ticketTemplate.skill.name}
                  </h5>
                  <div className="tw-text-xs tw-text-neutral-500">{ticketTemplate.skill.skillCategoryName}</div>
                </div>
              </td>
              <td className={classNames.td}>
                <div className="tw-flex tw-items-center tw-gap-2">
                  {subscriptionTypeIcon(ticketTemplate.skill.subscriptionType)}
                  {ticketTemplate.skill.subscriptionType}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </>
    </table>
  )
}
