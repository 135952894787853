import * as TabsPrimitive from '@radix-ui/react-tabs'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

import { cva, VariantProps } from 'class-variance-authority'
import { cn } from 'lib/util/cn'

const Tabs = TabsPrimitive.Root

const tabsListVariants = cva(
  'tw-bg-muted tw-text-muted-foreground tw-inline-flex tw-h-10 tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-md tw-p-1',
  {
    variants: {
      intent: {
        background:
          'tw-items-stretch tw-divide-x tw-border tw-border-solid tw-border-cornflower-100 tw-p-0 tw-text-cornflower-600 tw-font-semibold',
        underline: 'tw-font-bold',
      },
    },
    defaultVariants: {
      intent: 'underline',
    },
  },
)

type TabsListProps = VariantProps<typeof tabsListVariants> & ComponentPropsWithoutRef<typeof TabsPrimitive.List>

const TabsList = forwardRef<ElementRef<typeof TabsPrimitive.List>, TabsListProps>(
  ({ className, intent, ...props }, ref) => (
    <TabsPrimitive.List ref={ref} className={cn(tabsListVariants({ intent }), className)} {...props} />
  ),
)
TabsList.displayName = TabsPrimitive.List.displayName

const tabsTriggerVariants = cva(
  'tw-ring-offset-background focus-visible:tw-ring-ring tw-inline-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-border-transparent tw-bg-transparent tw-px-3 tw-text-sm tw-outline-none tw-transition-all focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[state=active]:tw-text-cornflower-600',
  {
    variants: {
      intent: {
        underline: 'tw-border-b-4 tw-py-1.5 data-[state=active]:tw-border-b-cornflower-500',
        background: 'tw-px-4 data-[state=active]:tw-bg-cornflower-100 tw-font-semibold',
      },
    },
    defaultVariants: {
      intent: 'underline',
    },
  },
)

type TabsTriggerProps = VariantProps<typeof tabsTriggerVariants> &
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>

const TabsTrigger = forwardRef<ElementRef<typeof TabsPrimitive.Trigger>, TabsTriggerProps>(
  ({ className, intent, ...otherProps }, ref) => (
    <TabsPrimitive.Trigger ref={ref} className={cn(tabsTriggerVariants({ intent }), className)} {...otherProps} />
  ),
)
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'tw-ring-offset-background focus-visible:tw-ring-ring tw-mt-2 focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2',
      className,
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsContent, TabsList, TabsTrigger }
