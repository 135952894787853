/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'controllers'
import './application.scss'
import * as Routes from 'routes.js.erb'
import AnnotationComments from '@contently/videojs-annotation-comments'
import InitializeLucide from './lucide-initializer'

// allows global access to AnnotationComments as they are used in Rails views
window.AnnotationComments = AnnotationComments

// Defines all Rails named routes as javascript helpers
window.Routes = Routes

// Support component names relative to this directory:
var componentRequireContext = require.context('components/react_rails_components', true)
var ReactRailsUJS = require('react_ujs')
// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext)
InitializeLucide()
