import {Controller} from 'stimulus'

export default class extends Controller {
    static values = {
        url: String
    }

    connect() {
        this.removeManageOptionToolbar();
    }

    removeManageOptionToolbar() {
        if (window.location.pathname !== `/admin/${this.urlValue}`) {
            const table = document.getElementById('manage-table');
            table.classList.add('hide')
        }
    }

    getDataTableElement(url) {
        try {
            return $(`#admin-${url}-table`).DataTable();
        } catch (e) {}
    }

    hideColumn(e) {
        e.stopPropagation();
        const table = this.getDataTableElement(this.urlValue);
        if(e.target.nodeName === 'svg') {
            if(e.target.parentNode.dataset.id) {
                table.column(e.target.parentNode.dataset.id).visible(false);
            }
        } else if(e.target.nodeName === 'path') {
            if(e.target.parentNode.parentNode.dataset.id) {
                table.column(e.target.parentNode.parentNode.dataset.id).visible(false);
            }
        }
    }
}
