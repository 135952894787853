import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { MessageSquareQuote } from 'lucide-react'
import Button from 'components/core/button'
import Modal from 'lib/components/modals/modal'
import Textarea from 'lib/components/textarea/textarea'
import { useDirectionsContext } from '../../providers/timeline-directions-provider'

interface DirectionManualCopyModalProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const classNames = {
  buttonWrapper: 'tw-flex tw-justify-end tw-gap-2 tw-mt-4',
  cancelButton:
    'tw-bg-peppercorn-50 hover:tw-bg-peppercorn-100 tw-mt-3 tw-inline-flex tw-w-full tw-justify-center tw-px-4 tw-py-2 tw-border-0 sm:tw-mt-0 sm:tw-w-auto sm:tw-text-sm',
  modalTitle: 'tw-text-lg tw-font-bold tw-flex tw-items-center tw-gap-1',
}

export default function DirectionManualCopyModal({ open, setOpen }: DirectionManualCopyModalProps): JSX.Element {
  const [inputValue, setInputValue] = useState('')
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const { addCopy } = useDirectionsContext()

  function handleManualCopySubmit() {
    addCopy(inputValue)
    setOpen(false)
    setInputValue('')
  }

  function handleManualCopyCancel() {
    setOpen(false)
    setInputValue('')
  }

  function handleAfterEnter() {
    textareaRef.current.focus()
  }

  return (
    <Modal afterEnter={handleAfterEnter} open={open} setOpen={setOpen} size="lg">
      <Modal.Header>
        <span className={classNames.modalTitle}>
          <MessageSquareQuote />
          <span>Add My Own Copy</span>
        </span>
      </Modal.Header>

      <Modal.Body setOpen={setOpen} closeButton>
        <p>
          Type or paste copy here that you want your designer to use on your design! You can edit this copy in your
          request later.
        </p>
        <p>Pro Tip: While there is no character limit here, most design copy is short and to the point!</p>
        <Textarea
          ref={textareaRef}
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          name="Manual Copy"
          id="manual-copy"
          maxLength={-1}
          placeholder="Buy one get one FREE! Enjoy this offer now before Sept. 30, 2023, or while supplies last!"
        />
      </Modal.Body>

      <Modal.Footer>
        <div className={classNames.buttonWrapper}>
          <Button
            type="button"
            color="neutralGray"
            className={classNames.cancelButton}
            onClick={handleManualCopyCancel}
          >
            Cancel
          </Button>
          <Button color="purple" type="button" onClick={handleManualCopySubmit} disabled={inputValue.length < 3}>
            Add to request
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
