import { Table } from '@tanstack/react-table'
import SelectBox, { SelectBoxOption, SelectBoxValue } from 'lib/components/dropdown/select-box'
import { useFirstRender } from '../use-first-render'
import { columnIds } from '../use-table'

export default function SkillFilter<TData>({ table }: { table: Table<TData> }) {
  const { skills } = useFirstRender()
  const column = table.getColumn(columnIds.skill_name)

  const handleChange = (value: SelectBoxValue[]) => {
    column.setFilterValue(value.length > 0 ? value : undefined)
  }

  const options: SelectBoxOption[] =
    skills?.map((skill) => ({
      value: skill,
      displayElement: <span>{skill}</span>,
      label: skill,
    })) || []

  const selectedValue = () => {
    const filterValue = column.getFilterValue()
    if (Array.isArray(filterValue)) {
      return filterValue.map(String)
    }
    return []
  }

  if (!column) return null
  return (
    <SelectBox
      label="Request type"
      buttonClassName="tw-min-w-52 tw-max-w-xs"
      showLabel={false}
      placeholder="Request type"
      options={options}
      selectedValue={selectedValue()}
      handleChange={handleChange}
      multiple={true}
      searchable={false}
    />
  )
}
