import React from 'react'
import Button from 'components/core/button'
import DropdownMenu from 'components/elements/dropdown-menu'
import CancellationsOverflowMenu from 'components/elements/dropdown/menus/cancellations-overflow-menu'

interface TableRowActionsProps {
  editMode: boolean
  setEditMode(editMode: boolean): void
  onCancel(): void
  onSave(): void
}
const TableRowActions = ({ editMode, setEditMode, onCancel, onSave }: TableRowActionsProps): JSX.Element => {
  const TdContainer = ({ children }: { children: JSX.Element }) => (
    <td className="tw-sticky tw--right-4 tw-whitespace-nowrap tw-bg-white">{children}</td>
  )

  if (editMode) {
    return (
      <TdContainer>
        <div className="tw-flex tw-items-center tw-justify-items-center">
          <Button className="mr-2" type="button" color="lightGray" size="cut" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="mr-2" type="button" color="green" size="cut" onClick={onSave}>
            Save
          </Button>
        </div>
      </TdContainer>
    )
  }

  return (
    <TdContainer>
      <div className="tw-float-right">
        <DropdownMenu sticky={true}>
          <CancellationsOverflowMenu setShowEdit={setEditMode} />
        </DropdownMenu>
      </div>
    </TdContainer>
  )
}

export default TableRowActions
