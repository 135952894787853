import React, { useState } from 'react'
import { Table } from '@tanstack/react-table'
import { ChevronDown, ChevronUp, X } from 'lucide-react'
import {
  format,
  startOfDay,
  endOfDay,
  subDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
} from 'date-fns'
import { Popover, PopoverContent, PopoverTrigger } from 'lib/components/popover/popover'
import { cn } from 'lib/util/cn'
import { Calendar } from 'lib/components/calendar/calendar'

type Preset = 'today' | 'yesterday' | 'thisWeek' | 'lastWeek' | 'thisMonth' | 'lastMonth'

const presets: { label: string; value: Preset }[] = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'This Week', value: 'thisWeek' },
  { label: 'Last Week', value: 'lastWeek' },
  { label: 'This Month', value: 'thisMonth' },
  { label: 'Last Month', value: 'lastMonth' },
]

const toUnixTimestamp = (date: Date): string => {
  return Math.floor(date.getTime() / 1000).toString()
}

export default function UpdatedAtFilter<TData>({ table }: { table: Table<TData> }) {
  const column = table.getColumn('ticket_updated_date')
  const [open, setOpen] = useState(false)

  const filterValue = column?.getFilterValue() as [string?, string?] | undefined

  const getPresetDates = (preset: Preset): [string, string] => {
    const now = new Date()
    switch (preset) {
      case 'today':
        return [toUnixTimestamp(startOfDay(now)), toUnixTimestamp(endOfDay(now))]
      case 'yesterday': {
        const yesterday = subDays(now, 1)
        return [toUnixTimestamp(startOfDay(yesterday)), toUnixTimestamp(endOfDay(yesterday))]
      }
      case 'thisWeek':
        return [toUnixTimestamp(startOfWeek(now)), toUnixTimestamp(endOfWeek(now))]
      case 'lastWeek': {
        const lastWeek = subDays(now, 7)
        return [toUnixTimestamp(startOfWeek(lastWeek)), toUnixTimestamp(endOfWeek(lastWeek))]
      }
      case 'thisMonth':
        return [toUnixTimestamp(startOfMonth(now)), toUnixTimestamp(endOfMonth(now))]
      case 'lastMonth': {
        const lastMonth = subMonths(now, 1)
        return [toUnixTimestamp(startOfMonth(lastMonth)), toUnixTimestamp(endOfMonth(lastMonth))]
      }
    }
  }

  const handlePresetClick = (preset: Preset) => {
    const [from, to] = getPresetDates(preset)
    column?.setFilterValue([from, to])
    setOpen(false)
  }

  const formatDateRange = (filterValue?: [string?, string?]): string => {
    if (!filterValue?.[0] && !filterValue?.[1]) return 'Last updated'

    const from = filterValue?.[0] ? new Date(parseInt(filterValue[0]) * 1000) : undefined
    const to = filterValue?.[1] ? new Date(parseInt(filterValue[1]) * 1000) : undefined

    if (from && to) {
      return `${format(from, 'MMM d')} to ${format(to, 'MMM d')}`
    }
    if (from) return `From ${format(from, 'MMM d')}`
    if (to) return `Until ${format(to, 'MMM d')}`
    return 'Last updated'
  }

  const clearDates = (e: React.MouseEvent) => {
    e.preventDefault()
    column?.setFilterValue(undefined)
  }

  const hasDateSelected = !!(filterValue?.[0] || filterValue?.[1])

  const startDate = filterValue?.[0] ? new Date(parseInt(filterValue[0]) * 1000) : undefined
  const endDate = filterValue?.[1] ? new Date(parseInt(filterValue[1]) * 1000) : undefined

  return (
    <div className="tw-">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <button
            className={cn(
              'tw-flex tw-h-10 tw-w-full tw-min-w-52 tw-items-center tw-justify-between tw-gap-2 tw-overflow-hidden tw-rounded-md tw-border tw-border-solid tw-border-neutral-200 tw-bg-white tw-py-2 tw-pl-3 tw-pr-2 tw-text-sm tw-text-black focus:tw-ring focus:tw-ring-cornflower-500',
              {
                'tw-ring tw-ring-cornflower-500': open,
                'tw-border-cornflower-400 tw-bg-cornflower-100': hasDateSelected,
              },
            )}
          >
            {formatDateRange(filterValue)}
            {open ? (
              <ChevronUp className="lu-sm tw-flex-shrink-0" />
            ) : (
              <ChevronDown className="lu-sm tw-flex-shrink-0" />
            )}
            {hasDateSelected && (
              <div
                onClick={clearDates}
                className="tw--mr-2 tw-flex tw-h-10 tw-w-8 tw-flex-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-border-0 tw-border-l tw-border-solid tw-border-cornflower-400 hover:tw-bg-cornflower-200"
              >
                <X className="lu-sm tw-inline-block" data-testid="multiselect-clear" />
              </div>
            )}
          </button>
        </PopoverTrigger>
        <PopoverContent className="tw-flex tw-flex-col tw-gap-4 tw-bg-white" align="start">
          <span className="tw-text-sm tw-font-bold tw-text-peppercorn-900">Last updated</span>
          <div className="tw-flex tw-items-stretch tw-gap-8">
            <div>
              <ul className="tw-flex tw-list-none tw-flex-col tw-gap-4 tw-pl-2 tw-pr-4 tw-text-sm tw-text-peppercorn-900">
                {presets.map((preset) => (
                  <li
                    key={preset.value}
                    onClick={() => handlePresetClick(preset.value)}
                    className="tw-cursor-pointer hover:tw-text-cornflower-600"
                  >
                    {preset.label}
                  </li>
                ))}
              </ul>
            </div>
            <Calendar
              mode="range"
              selected={{
                from: startDate,
                to: endDate,
              }}
              defaultMonth={startDate ?? subMonths(new Date(), 1)}
              onSelect={(date) => {
                column?.setFilterValue([toUnixTimestamp(startOfDay(date.from)), toUnixTimestamp(endOfDay(date.to))])
              }}
              numberOfMonths={2}
              className="tw-border-l-1 tw-border-0 tw-border-solid tw-border-peppercorn-100 tw-pl-6"
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
