import { ReactElement } from 'react'
import Button from 'components/core/button'

interface CommentsNotLoadedProps {
  retry: () => void
}

export default function CommentsNotLoaded({ retry }: CommentsNotLoadedProps): ReactElement {
  return (
    <div className="tw-mb-4 tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-p-4 tw-text-center">
      <div>
        <strong>This conversation could not be loaded.</strong>
      </div>
      <Button onClick={retry}>Retry</Button>
    </div>
  )
}
