import Button from 'components/core/button'
import { AnnotationRecord, AnnotationStatus } from 'lib/api/annotations/annotations'
import { VideoJSAnnotation } from 'lib/api/ticket-files/ticket-files'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'
import { Check, X } from 'lucide-react'
import { MouseEvent } from 'react'
import { ApproveRejectVideoAnnotation, useAnnotationsContext } from '../providers/annotations-provider'
import { DetailTaskType } from '../types/detail-task'

interface ApproveRejectButtonsProps {
  annotation: AnnotationRecord<AnnotoriousAnnotation> | AnnotationRecord<VideoJSAnnotation>
}

export default function ApproveRejectButtons({ annotation }: ApproveRejectButtonsProps) {
  const { saveAnnotation, updateVideoAnnotationStatus } = useAnnotationsContext()

  async function saveStatus(e: MouseEvent<HTMLButtonElement>, status: AnnotationStatus) {
    e.stopPropagation()

    try {
      if (annotation.type === DetailTaskType.VIDEO_ANNOTATION) {
        await updateVideoAnnotationStatus(annotation as ApproveRejectVideoAnnotation, status)
      } else {
        await saveAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>, annotation.body, status)
      }
    } catch (error) {
      console.error('ApproveRejectButtons Error:', error)
      alert('There was an error saving your annotation')
    }
  }

  return (
    <div className="tw-mt-4 tw-flex tw-items-center tw-gap-2">
      <Button color="green" onClick={(e) => saveStatus(e, AnnotationStatus.Approved)}>
        <Check className="lu-md" /> Approve
      </Button>
      <Button color="red" onClick={(e) => saveStatus(e, AnnotationStatus.Rejected)}>
        <X className="lu-md" /> Reject
      </Button>
    </div>
  )
}
