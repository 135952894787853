import React, { ReactNode } from 'react'
import Button, { ButtonProps } from 'components/core/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface RoundedButtonWithIconProps extends Pick<ButtonProps, 'color' | 'onClick'> {
  icon: IconProp
  children: ReactNode
}

export default function RoundedButtonWithIcon({
  children,
  color,
  icon,
  onClick,
  ...otherProps
}: RoundedButtonWithIconProps): JSX.Element {
  return (
    <Button
      type="button"
      className="tw-w-full tw-font-bold"
      rounded
      size="cut"
      color={color}
      onClick={onClick}
      {...otherProps}
    >
      <FontAwesomeIcon icon={icon} color="regular" />
      <span className="tw-ml-2">{children}</span>
    </Button>
  )
}
