import Avatar from 'components/core/avatar'
import { Collaborator } from 'lib/api/tickets/tickets'

export default function CollaboratorsList({
  collaborators,
  invitedCollaborators,
  className,
}: {
  collaborators: Collaborator[]
  invitedCollaborators: string[]
  className: React.HTMLAttributes<HTMLDivElement>['className']
}) {
  return (
    <div className={`tw-mb-6 ${className}`}>
      {collaborators.length > 0 || invitedCollaborators.length > 0 ? (
        <>
          {collaborators.map((collaborator) => (
            <div className="tw-mt-4 tw-flex tw-items-center" key={collaborator.id}>
              <Avatar name={collaborator.name} />
              <div className="tw-ml-2">
                <h5 className="tw-m-0 tw-text-sm">{collaborator.name}</h5>
                <p className="tw-m-0 tw-text-xs">{collaborator.email}</p>
              </div>
            </div>
          ))}
          {invitedCollaborators.map((invitedCollaborator) => (
            <div className="tw-mt-4 tw-flex tw-items-center" key={invitedCollaborator}>
              <Avatar name={invitedCollaborator} />
              <div className="tw-ml-2">
                <h5 className="tw-m-0 tw-text-sm tw-font-normal">Invitation sent to {invitedCollaborator}</h5>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div>
          <div className="tw-flex tw-items-center tw-justify-center">
            <img src="/images/states/empty-list.svg" alt="Empty list guy" height={170} width={170} />
          </div>
          <p className="tw-m-0 tw-text-sm">
            Looks like no collaborators have joined. Share link above to your collaborators for them to add annotations.
          </p>
        </div>
      )}
    </div>
  )
}
