/** @type {import('tailwindcss').Config} */
module.exports = {
  prefix: 'tw-',
  content: ['./app/**/*.haml', './app/**/*.tsx'],
  corePlugins: {
    preflight: false,
  },
  theme: {
    extend: {
      colors: {
        cherry: {
          50: '#FFEAF5',
          100: '#FEDAEC',
          200: '#FD91C7',
          300: '#FB419F',
          400: '#FA198A',
          500: '#E50576',
          600: '#AE045A',
          700: '#77033D',
          800: '#400121',
          900: '#090005',
        },
        peppercorn: {
          50: '#F5F4F6',
          100: '#DEE2E6',
          200: '#CBC6CA',
          300: '#ADA6AD',
          400: '#90868F',
          DEFAULT: '#716870',
          600: '#555055',
          700: '#413C40',
          800: '#292628',
          900: '#0C0A0B',
        },
        berry: {
          50: '#EFF6FF',
          100: '#DBEAFE',
          DEFAULT: '#3B82F6',
          800: '#1E40AF',
        },
        chili: {
          200: '#FFC9BD',
          300: '#FFA799',
          DEFAULT: '#FF6047',
          800: '#AD1800',
        },
        squash: {
          100: '#FEF3C7',
          200: '#FDE68A',
          300: '#FCD34D',
          400: '#FBBF24',
          DEFAULT: '#F59E0B',
          800: '#92400E',
        },
        gherkin: {
          100: '#C0F9DE',
          200: '#7DEEC1',
          300: '#2DDC9C',
          DEFAULT: '#00BC8F',
          500: '#009672',
          800: '#0D5431',
        },
        eggplant: {
          DEFAULT: '#495ce8',
          500: '#556FF2',
          600: '#3D59E5',
        },
        skyblue: {
          50: '#EAFAFE',
          100: '#D5F5FD',
          800: '#135362',
        },
        flushpink: {
          50: '#FCE6F1',
          100: '#FACDE4',
          200: '#F59BC8',
          300: '#EF69AD',
          400: '#EA3791',
          500: '#E50576',
          600: '#B7045E',
          700: '#890347',
          800: '#5C022F',
          900: '#2E0118',
        },
        picklegreen: {
          50: '#E6F8F4',
          100: '#CCF2E9',
          200: '#99E4D2',
          500: '#00BC8F',
          600: '#009672',
          800: '#004B39',
        },
        sunnyyellow: {
          50: '#FFF9EC',
          100: '#FFF2D8',
          400: '#FECB63',
          600: '#CB9830',
          800: '#664C18',
        },
        cornflower: {
          50: 'var(--color-cornflower-50)',
          100: 'var(--color-cornflower-100)',
          200: 'var(--color-cornflower-200)',
          300: 'var(--color-cornflower-300)',
          400: 'var(--color-cornflower-400)',
          500: 'var(--color-cornflower-500)',
          600: 'var(--color-cornflower-600)',
          700: 'var(--color-cornflower-700)',
          800: 'var(--color-cornflower-800)',
          900: 'var(--color-cornflower-900)',
        },
      },
      outline: {
        none: ['2px solid transparent', '2px'],
        white: ['2px dotted white', '2px'],
        black: ['2px dotted black', '2px'],
      },
    },
  },
  plugins: [
    function ({ addUtilities }) {
      addUtilities({
        '.scrollbar-gutter-stable': {
          'scrollbar-gutter': 'stable',
        },
      })
    },
  ],
}
