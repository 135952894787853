import { ReactElement, useRef } from 'react'
import Textarea from 'lib/components/textarea/textarea'
import IconButton from 'lib/components/buttons/icon-button'

interface CommentFormProps {
  onSend: (body: string) => void | Promise<void>
}

const classNames = {
  container: 'tw-flex tw-items-center tw-gap-2 tw-pb-4',
  textarea: 'tw-w-full tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-p-2',
}

export default function CommentForm({ onSend }: CommentFormProps): ReactElement {
  const bodyRef = useRef<HTMLTextAreaElement>()

  async function handleAddClick() {
    if (bodyRef.current.disabled) {
      return
    }
    const response = onSend(bodyRef.current.value)
    if (response instanceof Promise) {
      try {
        bodyRef.current.disabled = true
        await response
        bodyRef.current.value = ''
        bodyRef.current.disabled = false
      } catch (error) {
        console.error('There was a problem adding the comment.', error)
        bodyRef.current.disabled = false
        bodyRef.current.focus()
      }
    } else {
      bodyRef.current.value = ''
      bodyRef.current.focus()
    }
  }

  return (
    <div className={classNames.container}>
      <Textarea
        ref={bodyRef}
        className={classNames.textarea}
        maxLength={-1}
        placeholder="Start typing your reply."
        rows="2"
        resize={{ vertical: true }}
      />
      <IconButton onClick={handleAddClick} icon={['far', 'paper-plane']} color="primary" />
    </div>
  )
}
