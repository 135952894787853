import { ChangeEvent, KeyboardEvent, ReactElement, useMemo, useRef, useState } from 'react'
import { DetailTaskType } from '../types/detail-task'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { BetaBadge } from 'lib/components/badge/badge'
import DirectionModal from '../directions/modals/direction-modal'
import { useDirectionsContext } from '../providers/timeline-directions-provider'
import TextInput from 'lib/components/text-input/text-input'
import { TextCounter } from 'lib/components/textarea/textarea'
import IconButton from 'lib/components/buttons/icon-button'
import { Check } from 'lucide-react'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import { useAnnotationsContext } from 'components/pages/request/providers/annotations-provider'

const MINIMUM_LENGTH = 3

const PLACEHOLDER = 'Start typing general direction'

function DirectionsFlyoutMenu(): ReactElement {
  const [taskType, setTaskType] = useState<DetailTaskType>(null)

  function clearTaskType() {
    setTaskType(null)
  }

  function menuItemClicked(type: DetailTaskType) {
    setTaskType(type)
  }

  return (
    <>
      <IconFlyoutMenu icon={['far', 'plus']} color="primary" viewportBottomThreshold={150}>
        <IconFlyoutMenu.Button onClick={() => menuItemClicked(DetailTaskType.GEN_AI_REQUEST)}>
          Help me write my design request <BetaBadge />
        </IconFlyoutMenu.Button>
        <IconFlyoutMenu.Button onClick={() => menuItemClicked(DetailTaskType.TXT2IMG)}>
          Help me create imagery <BetaBadge />
        </IconFlyoutMenu.Button>
        <IconFlyoutMenu.Button onClick={() => menuItemClicked(DetailTaskType.COPY)}>
          Add my own copy
        </IconFlyoutMenu.Button>
      </IconFlyoutMenu>
      <DirectionModal onClose={clearTaskType} type={taskType} />
    </>
  )
}

function hasMinimumLength(s: string) {
  return s.trim().length >= MINIMUM_LENGTH
}

export default function TimelineDirectionForm(): ReactElement {
  const [showAttemptedSubmitError, setShowAttemptedSubmitError] = useState<boolean>(false)
  const [value, setValue] = useState<string>('')
  const { addTask, directions, setNewDirectionHasValue, showDirectionErrors } = useDirectionsContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const { annotationsInEditMode, areAnnotationsValid } = useAnnotationsContext()

  const inputRef = useRef(null)

  const showError = useMemo(() => {
    if (isFeatureFlagEnabled('merging')) {
      return showDirectionErrors && !areAnnotationsValid() && annotationsInEditMode === 0
    }
    if (directions?.length === 0) {
      return showDirectionErrors || showAttemptedSubmitError
    }

    return (showDirectionErrors && value.trim().length !== 0) || (showAttemptedSubmitError && !hasMinimumLength(value))
  }, [
    annotationsInEditMode,
    areAnnotationsValid,
    directions?.length,
    isFeatureFlagEnabled,
    showAttemptedSubmitError,
    showDirectionErrors,
    value,
  ])

  function clear() {
    inputRef.current.value = ''
    setValue('')
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value

    setNewDirectionHasValue(!!newValue)
    setValue(newValue)

    if (newValue.trim().length === 0 || hasMinimumLength(newValue)) {
      setShowAttemptedSubmitError(false)
    }
  }

  async function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      save()
    }
  }

  function save() {
    if (!hasMinimumLength(value)) {
      setShowAttemptedSubmitError(true)
      return
    }

    setShowAttemptedSubmitError(false)
    addTask(value.trim())
    clear()
  }

  return (
    <>
      <div className="tw-flex tw-w-full tw-items-start tw-gap-2">
        <div className="tw-mt-3">
          <DirectionsFlyoutMenu />
        </div>
        <div className="tw-w-full">
          <div className="tw-flex tw-w-full tw-items-center tw-gap-2">
            <TextInput
              error={showError}
              maxLength={500}
              onChange={onChange}
              onKeyDown={onKeyDown}
              placeholder={PLACEHOLDER}
              ref={inputRef}
              value={value}
            />
            <div style={{ minWidth: '40px' }}>
              <IconButton color="secondary" onClick={save}>
                <Check className="lu-sm" />
              </IconButton>
            </div>
          </div>
          <TextCounter value={value} className="tw-mr-12 tw-text-right" />
        </div>
      </div>
      <p className="tw--mt-8 tw-pt-2.5">Press enter for another line</p>
    </>
  )
}
