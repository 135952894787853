import { client } from 'filestack-react'

export const filestackCdnUrl = 'https://cdn.filestackcontent.com'

export interface FilestackFile extends client.PickerFileMetadata {
  filename: string
  handle: string
  mimetype: string
  originalFile: File
  originalPath: string
  size: number
  source: 'local_file_system'
  status: 'InTransit'
  uploadId: string
  url: string
}

export interface FilestackUploadDoneParams extends client.PickerFileMetadata {
  filesFailed: FilestackFile[]
  filesUploaded: FilestackFile[]
}
