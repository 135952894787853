import Banner from 'lib/components/banner/banner'
import { GenAiRequest, GenAiRequestStatus, GenAiRequestTypes } from 'lib/api/gen-ai/gen-ai-requests'
import { LinkLikeButton } from 'components/core/button'
import { useGenAiContext } from '../providers/gen-ai-provider'
import CreateAssistResultsModal from '../ai/modals/create-assist-results-modal'
import EditAssistResultsModal from '../ai/modals/edit-assist-results-modal'
import RequestAssistResultsModal from '../ai/modals/request-assist-results-modal'
import { IconName } from '@fortawesome/free-regular-svg-icons'
import { useUserContext } from 'providers/user-provider'

interface GenAiBannerProps {
  request: GenAiRequest
}

interface GenAiSuccessBannerProps {
  request: GenAiRequest
  onDismissClick: () => void
  onViewClick: () => void
}

interface GenAiFailBannerProps {
  request: GenAiRequest
  onRetryClick: () => void
  onDismissClick: () => void
}

interface GenAiInProgressBannerProps {
  request: GenAiRequest
}

interface RequestTypeConfig {
  icon: IconName
  text: string
}

const requestTypeConfigs: Record<string, RequestTypeConfig> = Object.freeze({
  [GenAiRequestTypes.Elaborate]: { icon: 'sparkles', text: 'Request Assist' },
  [GenAiRequestTypes.IMG2IMG]: { icon: 'magic', text: 'Create Assist' },
  [GenAiRequestTypes.Inpainting]: { icon: 'paint-brush', text: 'Edit Assist' },
  [GenAiRequestTypes.TXT2IMG]: { icon: 'magic', text: 'Create Assist' },
})

function isCreateAssistType(requestType: GenAiRequestTypes): boolean {
  return requestType === GenAiRequestTypes.TXT2IMG || requestType === GenAiRequestTypes.IMG2IMG
}

export default function GenAiBanner({ request }: GenAiBannerProps) {
  const { removeAndDismiss, removeAndRetry, selectedRequestId, setSelectedRequestId } = useGenAiContext()
  const { user } = useUserContext()

  function close() {
    setSelectedRequestId(null)
  }

  function dismiss() {
    removeAndDismiss(request.id)
  }

  function view() {
    setSelectedRequestId(request.id)
  }

  function retry() {
    removeAndRetry(request)
  }

  if (!requestTypeConfigs[request.requestType]) {
    return null
  }

  return (
    <>
      {request.status === GenAiRequestStatus.Completed && (
        <GenAiSuccessBanner request={request} onDismissClick={dismiss} onViewClick={view} />
      )}
      {request.status === GenAiRequestStatus.Error && (
        <GenAiFailBanner request={request} onDismissClick={dismiss} onRetryClick={retry} />
      )}
      {request.status === GenAiRequestStatus.New && <GenAiInProgressBanner request={request} />}
      {request.requestType === GenAiRequestTypes.Elaborate && (
        <RequestAssistResultsModal open={selectedRequestId === request.id} request={request} setOpen={close} />
      )}
      {isCreateAssistType(request.requestType) && (
        <CreateAssistResultsModal open={selectedRequestId === request.id} request={request} setOpen={close} />
      )}
      {user?.genAiInpainting && request.requestType === GenAiRequestTypes.Inpainting && (
        <EditAssistResultsModal open={selectedRequestId === request.id} request={request} setOpen={close} />
      )}
    </>
  )
}

export function GenAiSuccessBanner({ request, onDismissClick, onViewClick }: GenAiSuccessBannerProps) {
  const settings = requestTypeConfigs[request.requestType]

  return (
    <Banner
      type="success"
      heading={settings.text}
      headingIcon={settings.icon}
      subheading={request.message}
      border="dashed"
    >
      <LinkLikeButton color="primary" onClick={onViewClick}>
        View
      </LinkLikeButton>
      <LinkLikeButton color="secondary" onClick={onDismissClick}>
        Dismiss
      </LinkLikeButton>
    </Banner>
  )
}

export function GenAiFailBanner({ request, onRetryClick, onDismissClick }: GenAiFailBannerProps) {
  const settings = requestTypeConfigs[request.requestType]

  return (
    <Banner
      type="warning"
      heading={settings.text}
      headingIcon={settings.icon}
      subheading={request.message}
      border="dashed"
    >
      <LinkLikeButton color="primary" onClick={onRetryClick}>
        Retry
      </LinkLikeButton>
      <LinkLikeButton color="secondary" onClick={onDismissClick}>
        Dismiss
      </LinkLikeButton>
    </Banner>
  )
}

export function GenAiInProgressBanner({ request }: GenAiInProgressBannerProps) {
  const { removeAndCancel } = useGenAiContext()
  const settings = requestTypeConfigs[request.requestType]

  function onCancelClick() {
    removeAndCancel(request.id)
  }

  return (
    <Banner
      type="inProgress"
      heading={settings.text}
      headingIcon={settings.icon}
      subheading={request.message}
      border="dashed"
    >
      <LinkLikeButton color="primary" onClick={onCancelClick}>
        Cancel
      </LinkLikeButton>
    </Banner>
  )
}
