import Search from 'components/core/search'

interface CreateSearchProps {
  searchValue: string
  handleSearchChange: (value: string) => void
}

export default function CreateSearch({ searchValue, handleSearchChange }: CreateSearchProps) {
  return (
    <div className="tw-mt-6 tw-flex tw-w-full tw-justify-center">
      <Search
        className="tw-w-full"
        name="create-search"
        value={searchValue}
        onChange={handleSearchChange}
        placeholder="Search"
      />
    </div>
  )
}
