import { bindJWT, deleteApi, getApi, patchApi, postApi } from '../api'
import { TaggableType } from 'lib/api/company-tagging/company-tagging'
import { snakeCaseKeys } from 'lib/object/utils'

const baseUrl = '/api/internal/company_tags'

export interface CompanyTag {
  id: number
  name: string
  meta: {
    permissions: {
      update: boolean
      destroy: boolean
    }
  }
  createdAt: string
  updatedAt: string
}

export interface TaggableTag extends CompanyTag {
  taggableId: number
  taggableType: string
}

export async function createCompanyTag(name: string, jwt: string = null): Promise<CompanyTag> {
  const postWithJWT = bindJWT(postApi, jwt)
  const { data } = await postWithJWT(`${baseUrl}`, { name })
  return data.data
}

export async function updateCompanyTag(id: number, name: string, jwt: string = null): Promise<CompanyTag> {
  const patchWithJWT = bindJWT(patchApi, jwt)
  const { data } = await patchWithJWT(`${baseUrl}/${id}`, { name })
  return data.data
}

export async function deleteCompanyTag(id: number, jwt: string = null): Promise<void> {
  const deleteWithJWT = bindJWT(deleteApi, jwt)
  await deleteWithJWT(`${baseUrl}/${id}`)
}

export async function getCompanyTags(jwt: string = null): Promise<CompanyTag[]> {
  const getWithJWT = bindJWT(getApi, jwt)
  const { data } = await getWithJWT(`${baseUrl}`)
  return data.data
}

export async function getCompanyTagCount(id: number, taggableType: TaggableType, jwt: string = null): Promise<number> {
  const getWithJWT = bindJWT(getApi, jwt)
  const { data } = await getWithJWT(`${baseUrl}/${id}/count`, snakeCaseKeys({ taggableType }))
  return data.data.count
}
