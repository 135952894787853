import { useCompanyDailyAvailableTimesContext } from 'providers/company-daily-available-times-provider'
import { ReactElement } from 'react'
import TicketRow from './ticket-row'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import TimeToSpendHeader from 'components/pages/admin/time-allocations/time-to-spend-header'
import Pagination from 'components/elements/pagination'

const classNames = {
  titleColumn: 'tw-w-48',
  loading: 'tw-h-24 tw-flex tw-items-center tw-justify-center tw-w-full',
}

export default function TicketsTable(): ReactElement {
  const { tickets, isLoading, companyDailyAvailableTimes, pagination, company, getTicketsByCompany } =
    useCompanyDailyAvailableTimesContext()

  if (isLoading) {
    return (
      <div className={classNames.loading}>
        <LoadingScreen />
      </div>
    )
  }

  function Header() {
    return (
      <thead>
        <tr>
          <th className={classNames.titleColumn}>Title</th>
          <th className={classNames.titleColumn}>State</th>
          <th className={classNames.titleColumn}>State Changed At</th>
          <th className={classNames.titleColumn}>Category</th>
          <th className={classNames.titleColumn}>
            Time to Spend
            {companyDailyAvailableTimes && (
              <TimeToSpendHeader companyDailyAvailableTimes={companyDailyAvailableTimes} />
            )}
          </th>
        </tr>
      </thead>
    )
  }

  function TableRows() {
    return (
      <tbody>
        {tickets.map((ticket, index) => (
          <TicketRow ticket={ticket} index={index} key={index} />
        ))}
      </tbody>
    )
  }

  const handlePageChange = ({ selected }: { selected: number }) => getTicketsByCompany(company.id, selected)

  const { page, total, perPage } = pagination

  if (!total) return <></>

  const pageCount = Math.ceil(total / perPage)
  return (
    <div>
      <table>
        <Header />
        <TableRows />
      </table>
      <Pagination forcePage={page} pageCount={pageCount} onPageChange={handlePageChange} />
    </div>
  )
}
