import { ReactElement, ReactNode } from 'react'
import { AppSettings } from 'interfaces/app'
import AdminUserProvider, { AdminUser } from 'providers/admin-user-provider'

interface AdminAppProps {
  children: ReactNode
  settings: AppSettings
  user: AdminUser
}

export default function AdminApp({ children, settings, user }: AdminAppProps): ReactElement {
  return (
    <AdminUserProvider settings={settings} user={user}>
      {children}
    </AdminUserProvider>
  )
}

export function updateAdminNavigatorTitle(title: string): void {
  document.title = `${title || '(Untitled)'} | Admin | Design Pickle`
}
