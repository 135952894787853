import { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'components/core/button'

import { BetaBadge } from 'lib/components/badge/badge'
import ExternalLink from 'lib/components/links/external-link'
import Modal from 'lib/components/modals/modal'
import { GenAiDSImage, GenAiRequest, GenAiRequestTypes, getGenAiRequest } from 'lib/api/gen-ai/gen-ai-requests'
import Textarea from 'lib/components/textarea/textarea'
import { Card } from 'lib/components/card/card'
import CopyTextButton from 'lib/components/buttons/copy-text-button'
import { AnimatedLoadingScreen } from '../../../requests/empty-screens'
import { useMediaContext } from '../../media/media-provider'
import { TicketFile } from 'lib/api/ticket-files/ticket-files'

interface ModalProps {
  open: boolean
  requestId: number
  setOpen: Dispatch<SetStateAction<boolean>>
}

enum ModalState {
  Progress = 'progress',
  Results = 'results',
  Error = 'error',
}

const classNames = {
  buttons: {
    secondary:
      'tw-bg-peppercorn-50 hover:tw-bg-peppercorn-100 tw-mt-3 tw-inline-flex tw-w-full tw-justify-center tw-px-4 tw-py-2 tw-border-0 sm:tw-mt-0 sm:tw-w-auto sm:tw-text-sm',
    wrapper: 'tw-flex tw-justify-end tw-gap-2 tw-mt-4',
  },
  progress: {
    container: 'tw-text-center',
    img: 'tw-mx-auto',
    text: 'tw-mx-auto',
  },
  title: 'tw-text-lg tw-font-bold tw-flex tw-items-center tw-gap-1',
}

export default function CreateAssistOriginalPromptModal({ open, requestId, setOpen }: ModalProps): ReactElement {
  const [request, setRequest] = useState<GenAiRequest>(null)
  const [modalState, setModalState] = useState<ModalState>(ModalState.Progress)
  const [selection, setSelection] = useState<GenAiDSImage[]>([])
  const [originalSelection, setOriginalSelection] = useState<GenAiDSImage[]>([])

  const { addFiles } = useMediaContext()

  const images = useMemo(() => {
    return (request?.response?.ds?.results as GenAiDSImage[]) || []
  }, [request?.response?.ds?.results])

  function handleAddToRequest() {
    const newFiles = selection.map(
      (image) =>
        ({
          previewUrl: image.url,
          id: image.id,
          name: imageUrlToName(image.url),
        }) as unknown as TicketFile,
    )

    addFiles(request.id, newFiles)
    reset()
    setOpen(false)
  }

  function imageUrlToName(url: string): string {
    const urlParts = url.split('/')
    return urlParts[urlParts.length - 1]
  }

  function reset() {
    setSelection([])
  }

  function toggleImage(image: GenAiDSImage) {
    if (selection.includes(image)) {
      setSelection(selection.filter((selectedImage) => selectedImage !== image))
    } else {
      setSelection([...selection, image])
    }
  }

  useEffect(() => {
    const fetchRequest = async () => {
      if (open && !request) {
        try {
          const { genAiRequest } = await getGenAiRequest(requestId)

          setRequest(genAiRequest)

          const images = (genAiRequest.response.ds.results as GenAiDSImage[]).filter((image) => image.ticket_file_id)
          setOriginalSelection(images)
        } catch (error) {
          console.error(error)
        }
      }
    }
    fetchRequest()
  }, [open, request, requestId])

  useEffect(() => {
    if (!open) {
      setRequest(null)
      setModalState(ModalState.Progress)
    }
  }, [open])

  useEffect(() => {
    if (request) {
      setModalState(ModalState.Results)
    }
  }, [request])

  return (
    <Modal open={open} setOpen={setOpen} size="lg">
      <Modal.Header>
        <span className={classNames.title}>
          <FontAwesomeIcon icon={['far', 'magic']} />
          <span>
            Create Assist <BetaBadge />
          </span>
        </span>
      </Modal.Header>

      <Modal.Body setOpen={setOpen} closeButton>
        {modalState === ModalState.Progress && (
          <>
            <div className={classNames.progress.container}>
              <AnimatedLoadingScreen />
            </div>
          </>
        )}
        {modalState === ModalState.Results && (
          <>
            <p>
              Use our AI tools to generate visual inspiration for your design request.{' '}
              <ExternalLink href="https://designpickle.com/kb/create-assist">Learn More</ExternalLink>
            </p>
            <h5 className="tw-font-bold">Your prompt</h5>

            <div className="tw-mb-6 tw-mt-1.5 tw-flex tw-flex-row">
              {request.requestType === GenAiRequestTypes.IMG2IMG && request.request.original_image && (
                <img
                  className="tw-mr-2 tw-h-24 tw-w-auto tw-self-center"
                  alt="Prompt Image"
                  src={request.request.original_image}
                />
              )}
              <Textarea className="tw-w-full" value={request.message} disabled />
              <div className="tw-ml-2 tw-mt-2">
                <CopyTextButton
                  textToCopy={request.message}
                  defaultButtonColor="transparent"
                  tooltipCopyText="Copy prompt to clipboard"
                  tooltipCopiedText="Prompt copied!"
                />
              </div>
            </div>
            <h4>Below are your results</h4>
            <p>Select image(s) to add to your request to further customize your inspirations.</p>
            <div className="tw-grid tw-max-h-96 tw-grid-cols-2 tw-items-center tw-justify-items-center tw-gap-6 tw-overflow-y-scroll tw-bg-neutral-50 tw-py-6">
              {images.map((image, index) => (
                <Card
                  size="xl"
                  key={image.id}
                  onClick={() => toggleImage(image)}
                  isSelected={selection.includes(image)}
                  disabled={originalSelection.includes(image)}
                >
                  <Card.Body className="tw-px-12">
                    <img key={image.id} src={image.url} alt={`Result Image ${index + 1}`} className="tw-w-full" />
                  </Card.Body>
                </Card>
              ))}
            </div>
            <p className="tw-neutral-500 tw-pt-6">
              This content was created with the help of advanced AI technology which is constantly improving. Please
              check for accuracy.
            </p>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {modalState === ModalState.Results && (
          <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
            <span className="tw-font-bold">
              {selection.length + originalSelection.length} of {images.length} items selected
            </span>
            <div className={classNames.buttons.wrapper}>
              <Button color="purple" type="button" onClick={handleAddToRequest} disabled={selection.length <= 0}>
                Add to request
              </Button>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}
