import { ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { CustomSize } from 'lib/api/skills/skills'
import IconButton from 'lib/components/buttons/icon-button'
import CustomSizeInputs from './custom-size-inputs'

interface CustomSizesProps {
  selectedCustomSizes: CustomSize[]
  setSelectedCustomSizes: (customSizes: CustomSize[]) => void
}

function CustomSizes({ selectedCustomSizes, setSelectedCustomSizes }: CustomSizesProps): ReactElement {
  const [showErrors, setShowErrors] = useState(selectedCustomSizes.length > 1)

  const plusButtonDivRef = useRef<HTMLDivElement>()
  const previousCustomSizesLength = useRef(selectedCustomSizes.length)
  const emptySize: CustomSize = { width: '', height: '', units: 'px' }

  function hasErrors() {
    return selectedCustomSizes.some((size) => !size.width || !size.height)
  }

  function handleRemoveClick(removalSize: CustomSize) {
    if (selectedCustomSizes.length > 1) {
      setSelectedCustomSizes(selectedCustomSizes.filter((size) => size !== removalSize))
    } else {
      setShowErrors(false)
      setSelectedCustomSizes([emptySize])
    }
  }

  function onValueChange(size: CustomSize, index: number) {
    setSelectedCustomSizes(
      selectedCustomSizes.map((selectedSize, i) => {
        if (i === index) {
          return size
        } else {
          return selectedSize
        }
      }),
    )
  }

  async function handleAddClick() {
    if (hasErrors()) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      setSelectedCustomSizes([...selectedCustomSizes, emptySize])
    }
  }

  useEffect(() => {
    const lastSelectedCustomSize = selectedCustomSizes[selectedCustomSizes.length - 1]

    const missingBothDimensions = (size: CustomSize) => !size?.width && !size?.height

    const lastSizeNewlyAdded = missingBothDimensions(lastSelectedCustomSize)
    const defaultState = selectedCustomSizes.length === 1 && missingBothDimensions(lastSelectedCustomSize)

    setShowErrors(!defaultState && !lastSizeNewlyAdded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomSizes.length])

  useLayoutEffect(() => {
    if (previousCustomSizesLength.current < selectedCustomSizes.length) {
      plusButtonDivRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    previousCustomSizesLength.current = selectedCustomSizes.length
  }, [selectedCustomSizes.length])

  return (
    <div className="tw-flex tw-flex-col">
      {selectedCustomSizes.map((size, index) => (
        <CustomSizeInputs
          size={size}
          key={index}
          index={index}
          onValueChange={onValueChange}
          handleRemoveClick={() => handleRemoveClick(size)}
          showErrors={showErrors}
        />
      ))}

      <div className="tw-mt-6 tw-self-end" ref={plusButtonDivRef}>
        <IconButton onClick={handleAddClick} icon={['far', 'plus']} color="primary" invert />
      </div>
    </div>
  )
}

export default CustomSizes
