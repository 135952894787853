import { Company } from 'interfaces/company'
import { baseRequest, BaseResponse, deleteApi, getApi, Metadata, patchApi, postApi } from '../api'
import { Designer } from 'interfaces/designer'
import { Apm } from 'interfaces/apm'

export const routes = {
  company: (id: number) => `/admin/companies?q%5Bid_eq%5D=${id}`,
  designer: (id: number) => `/admin/designers?q%5Bid_eq%5D=${id}`,
  index: '/admin/company_designer_allocations',
  show: (id: number) => `/admin/company_designer_allocations/${id}`,
}

interface TotalAllocatedTimePerScopeOfService {
  name: string
  numberOfDesigners: number
  totalAllocatedTime: number
  allocatedCompaniesCount: number
  companyTotalDailyTime: number
  reservedTime: number
}

export interface CompanyDesignerAllocation {
  id: number
  apm: Apm
  company: Company
  designerAllocations?: DesignerAllocation[]
  designerAllocationsCount?: number
  subscriptionsCount?: {
    graphics?: number
    graphicsPro?: number
    graphicsPremium?: number
    power?: number
  }
  timezoneTeamBackup: {
    aus: boolean
    est: boolean
    pst: boolean
    uk: boolean
  }
  totalAllocatedTimePerScopeOfService?: TotalAllocatedTimePerScopeOfService[]
}

export interface DesignerAllocation {
  id: number
  designer?: Designer
  designerId: number
  position: number
  scopesOfService: string[]
  validScopesOfService?: string[]
}

export interface CompanyDesignerAllocationsResponse extends BaseResponse {
  companyDesignerAllocations: CompanyDesignerAllocation[]
  meta: Metadata
}

export interface CompanyDesignerAllocationResponse extends BaseResponse {
  companyDesignerAllocation: CompanyDesignerAllocation
}

export interface DesignerResponse extends BaseResponse {
  designers: Designer[]
}

export async function getCompanyDesignerAllocations(params): Promise<CompanyDesignerAllocationsResponse> {
  const companyDesignerAllocationsUrl = '/api/internal/company_designer_allocations'
  const response = await baseRequest(companyDesignerAllocationsUrl, getApi, params)
  const { data, meta } = response.data

  return {
    error: response.error,
    status: response.status,
    companyDesignerAllocations: data as unknown as CompanyDesignerAllocation[],
    meta: meta as Metadata,
  }
}

export async function updateCompanyDesignerAllocation(params): Promise<CompanyDesignerAllocationResponse> {
  const companyDesignerAllocationUrl = `/api/internal/company_designer_allocations/${params.id}`
  const response = await baseRequest(companyDesignerAllocationUrl, patchApi, params)
  const { data } = response.data

  return {
    error: response.error,
    status: response.status,
    companyDesignerAllocation: data as unknown as CompanyDesignerAllocation,
  }
}

export interface ApmResponse extends BaseResponse {
  apms: Apm[]
}

export async function getApms(): Promise<ApmResponse> {
  const apmsUrl = '/api/internal/admin/apms?view=company_designer_allocation'
  const { error, status, data } = await baseRequest(apmsUrl, getApi)

  return {
    error: error,
    status: status,
    apms: data as unknown as Apm[],
  }
}

export async function updateDesignerAllocation(params): Promise<CompanyDesignerAllocation> {
  const designerAllocationUrl = `/api/internal/company_designer_allocations/${params.company_designer_allocation_id}/designer_allocations/${params.id}`
  const response = await baseRequest(designerAllocationUrl, patchApi, params)
  const { data } = response.data

  return data as CompanyDesignerAllocation
}

export async function createDesignerAllocation(params): Promise<CompanyDesignerAllocation> {
  const designerAllocationUrl = `/api/internal/company_designer_allocations/${params.company_designer_allocation_id}/designer_allocations`
  const response = await baseRequest(designerAllocationUrl, postApi, params)
  const { data } = response.data

  return data as CompanyDesignerAllocation
}

export async function deleteDesignerAllocation(params): Promise<CompanyDesignerAllocation> {
  const designerAllocationUrl = `/api/internal/company_designer_allocations/${params.company_designer_allocation_id}/designer_allocations/${params.id}`
  const response = await baseRequest(designerAllocationUrl, deleteApi, params)
  const { data } = response.data

  return data as CompanyDesignerAllocation
}
