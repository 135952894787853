import { postApi, putApi } from '../api'
import { AxiosResponse } from 'axios'

const baseUrl = '/api/internal/report/cancellations'

export function saveNotes(params): Promise<AxiosResponse> {
  return postApi(`${baseUrl}/notes`, { ...params, format: 'json' })
}

export function update(cancellationId, params): Promise<AxiosResponse> {
  return putApi(`${baseUrl}/${cancellationId}`, { ...params, format: 'json' })
}
