import { ReactElement, useEffect, useMemo } from 'react'
import AnnotationViewer from 'lib/components/annotation/annotation-viewer'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'
import { AnnotationRecord } from 'lib/api/annotations/annotations'
import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import { useGenAiContext } from '../providers/gen-ai-provider'
import { useMediaContext } from './media-provider'
import { useAnnotationsContext } from '../providers/annotations-provider'
import { useUserContext } from 'providers/user-provider'
import { GenAiResultTypes, getGenAiResultType } from 'lib/api/gen-ai/gen-ai-requests'

interface AnnotatedMediaViewerProps {
  isExtractable: boolean
  parentTicketFileId: number
  ticketFile: TicketFile
}

export default function AnnotatedMediaViewer({
  isExtractable,
  parentTicketFileId,
  ticketFile,
}: AnnotatedMediaViewerProps): ReactElement {
  const { user } = useUserContext()
  const { upload } = useMediaContext()
  const { createEditAssist } = useGenAiContext()
  const {
    canEditAnnotation,
    saveAnnotationDimensions,
    setHighlightedAnnotation,
    setShowImageAnnotationCreator,
    uuidMap,
  } = useAnnotationsContext()

  const isReadOnly = useMemo(() => !canEditAnnotation(), [canEditAnnotation])

  const genAiRequestType: GenAiResultTypes = useMemo(() => getGenAiResultType(ticketFile), [ticketFile])

  async function onCreateAnnotation() {
    setShowImageAnnotationCreator(true)
    setHighlightedAnnotation(null)
  }

  async function onCreateInpaintingRequest(mask: Blob, original: Blob, prompt: string): Promise<void> {
    const maskFile = new File([mask], 'mask.png', { type: 'image/png' })
    const originalFile = new File([original], 'original.png', { type: 'image/png' })

    const maskResponse = await upload(maskFile)
    const originalResponse = await upload(originalFile)
    await createEditAssist(prompt, originalResponse.url, maskResponse.url, ticketFile.id)
  }

  async function onUpdateAnnotation(annotation: AnnotoriousAnnotation) {
    await saveAnnotationDimensions(annotation, ticketFile.id, parentTicketFileId)
  }

  useEffect(() => {
    return () => {
      setShowImageAnnotationCreator(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const AnnotationViewerWrapper = useMemo<ReactElement>(() => {
    return (
      <AnnotationViewer
        annotations={ticketFile.annotations as AnnotationRecord<AnnotoriousAnnotation>[]}
        genAiResultType={genAiRequestType}
        isExtractable={isExtractable}
        onCreate={onCreateAnnotation}
        onCreateInpaintingRequest={onCreateInpaintingRequest}
        onUpdate={onUpdateAnnotation}
        readOnly={isReadOnly}
        url={ticketFile.previewUrl}
        user={user}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canEditAnnotation, ticketFile.previewUrl, uuidMap])

  return <>{AnnotationViewerWrapper}</>
}
