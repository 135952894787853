import { Popover, PopoverContent, PopoverTrigger } from 'lib/components/popover/popover'
import Button, { LinkButton } from 'components/core/button'

import { CurrentUser } from 'interfaces/app'
import { ChevronDownIcon, ChevronUpIcon, CalendarIcon } from 'lucide-react'
import { PATHS } from 'lib/constants/paths'
import { HTMLAttributes, useState } from 'react'
import { cn } from 'lib/util/cn'

export const TrialPopover = ({
  user,
  triggerClassName,
}: {
  user: CurrentUser
  triggerClassName?: HTMLAttributes<HTMLDivElement>['className']
}) => {
  const [isOpen, setIsOpen] = useState(false)

  if (user.trialDaysLeft < 0 || !user.trialing) return null

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger className={triggerClassName} asChild>
        <Button color="cherry" onClick={() => {}}>
          <CalendarIcon />{' '}
          {user.trialDaysLeft > 0 ? (
            <>
              <span className="tw-font-semibold">{user.trialDaysLeft} days</span> left in your trial
            </>
          ) : (
            <>Your trial ends today</>
          )}
          {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Button>
      </PopoverTrigger>
      <PopoverContent collisionPadding={20} className="tw-w-60" intent="flyout">
        <TrialCta user={user} />
      </PopoverContent>
    </Popover>
  )
}

export const TrialCta = ({
  user,
  className,
}: {
  user: CurrentUser
  className?: HTMLAttributes<HTMLDivElement>['className']
}) => {
  if (user?.trialDaysLeft < 0 || !user?.trialing) return null

  return (
    <div className={cn('tw-flex tw-flex-col tw-items-center tw-gap-2', className)}>
      <img src="/images/pickle_laptop.png" alt="trial days left" width={70} height={70} />
      <div data-testid="trial-days-left" className="tw-text-center tw-text-flushpink-500">
        {user.trialDaysLeft > 0 ? (
          <>
            You have{' '}
            <span className="tw-font-semibold">
              {user.trialDaysLeft} day{user.trialDaysLeft === 1 ? '' : 's'}
            </span>{' '}
            left in your trial
          </>
        ) : (
          <>Your trial ends today</>
        )}
      </div>
      <div className="tw-text-center tw-text-xs tw-text-neutral-500">
        Let your Account Executive help get the most out of your trial.
      </div>
      <LinkButton
        className="tw-px-1 tw-text-xs md:tw-px-3 md:tw-text-sm"
        color="outlineCherry"
        url={PATHS.HUBSPOT_MEETING}
        target="_blank"
      >
        Schedule a call
      </LinkButton>
    </div>
  )
}
