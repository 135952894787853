import { useState } from 'react'
import AutoAssignerTimeZoneForm from './auto-assigner-time-zone-form'
import { createAutoAssignerTimeZone, routes } from 'lib/api/auto-assigner-time-zones/auto-assigner-time-zones'
import { snakeCaseKeys } from 'lib/object/utils'
import { toast, Toaster } from 'lib/components/toast/toast'

function AutoAssignerTimeZoneNew() {
  const [params, setParams] = useState({
    name: '',
    startTime: null,
    endTime: null,
    position: '',
  })

  async function handleSubmit() {
    try {
      const { status } = await createAutoAssignerTimeZone(snakeCaseKeys(params))
      if (status === 200) {
        toast.success('Auto Assigner Time Zone created successfully')
      }
      window.open(routes.index, '_self')
    } catch (error) {
      console.error(error)
      const errorMessage = error.response?.data?.errors || 'Failed to update skill category'
      toast.error(errorMessage.toString().replace(/,/g, ', '))
    }
  }

  return (
    <div>
      <h1>New Auto Assigner Time Zone</h1>
      <AutoAssignerTimeZoneForm params={params} setParams={setParams} handleSubmit={handleSubmit} />
    </div>
  )
}

export default function AdminAutoAssignerTimeZoneNewPage() {
  return (
    <>
      <Toaster />
      <AutoAssignerTimeZoneNew />
    </>
  )
}
