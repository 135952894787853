import { removeDuplicates } from '@tiptap/react'
import Select from 'react-select'
import SingleSelect from 'components/core/single-select'
import { DesignerAllocation, routes } from 'lib/api/company-designer-allocations/company-designer-allocations'
import { snakeCaseKeys } from 'lib/object/utils'
import { X, Check, Pencil, Plus, Trash } from 'lucide-react'
import { useState } from 'react'
import Badge from 'lib/components/badge/badge'
import { useDesignerAllocationsContext } from './designer-allocations-provider'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../../../../tailwind.config'

const { colors } = resolveConfig(tailwindConfig).theme

const classNames = {
  addCreative: 'tw-text-cornflower-500 tw-cursor-pointer tw-text-sm tw-font-medium tw-flex tw-items-center',
  leftIcon: 'tw-mr-2 tw-cursor-pointer tw-text-cornflower-500 hover:tw-text-cornflower-800',
  data: 'tw-whitespace-nowrap tw-p-3 tw-text-sm tw-text-gray-500 tw-w-48',
  dataEdit: 'tw-whitespace-nowrap tw-py-1.5 tw-px-3 tw-text-sm tw-text-gray-500 tw-w-48',
  icon: 'lu-light lu-md',
  iconsWrapper: 'tw-w-14',
  plusIcon: 'tw-text-cornflower-500 tw-cursor-pointer tw-mr-2',
  row: 'tw-border-0 tw-border-solid tw-border-b tw-border-peppercorn-100',
  rowEdit: 'tw-border-0 tw-border-solid tw-border-b tw-border-peppercorn-100 tw-bg-peppercorn-50',
  rightIcon: 'tw-cursor-pointer tw-text-cornflower-500 hover:tw-text-cornflower-800',
  select: 'tw-text-xs tw-w-72',
  scope:
    'tw-mr-1 tw-border tw-border-solid tw-border-peppercorn-800 tw-rounded-full tw-px-2 tw-py-1 tw-w-11 tw-inline-flex tw-justify-center tw-items-center tw-text-xs tw-font-medium tw-text-peppercorn-800',
}

const multiSelectStyles = {
  menuList: (provided) => ({
    ...provided,
    background: colors.white,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 100,
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? colors.gherkin : colors.white,
    opacity: state.isDisabled ? 0.5 : 1,
    color: state.isSelected ? colors.white : colors.black,
    cursor: state.isDisabled ? 'default' : 'pointer',
    '&:hover': {
      background: state.isDisabled ? colors.white : colors.cornflower[50],
      color: colors.black,
    },
  }),
  control: (styles) => ({
    ...styles,
    height: '38px',
    background: colors.white,
    cursor: 'pointer',
    borderColor: colors.peppercorn[100],
    boxShadow: colors.peppercorn[100],
    whiteSpace: 'nowrap',
    '&:hover': {
      borderColor: colors.peppercorn[100],
    },
  }),
  placeholder: (styles) => ({ ...styles, fontSize: '12px', color: colors.peppercorn[600] }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: colors.peppercorn[100],
    }
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: colors.peppercorn[800],
    fontSize: '12px',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: colors.peppercorn[800],
    ':hover': {
      backgroundColor: colors.peppercorn[800],
      color: colors.white,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'nowrap',
  }),
}

interface DesignerRowProps {
  designerAllocation?: DesignerAllocation
  index: number
  position?: number
}

export default function DesignerRow({ designerAllocation, index, position }: DesignerRowProps) {
  const { companyAllocation, designerOptions, removeDesignerAllocation, upsertDesignerAllocation } =
    useDesignerAllocationsContext()
  const [editMode, setEditMode] = useState(false)
  const [params, setParams] = useState({
    id: designerAllocation?.id,
    designerId: designerAllocation?.designerId,
    position: designerAllocation?.position || position,
    scopesOfService: designerAllocation?.scopesOfService || [],
    companyDesignerAllocationId: companyAllocation.id,
  })
  const selectedDesigner = designerOptions.find((d) => d.id === params.designerId) || designerAllocation?.designer

  async function onSubmit() {
    try {
      upsertDesignerAllocation(snakeCaseKeys(params))
    } finally {
      setEditMode(false)
    }
  }

  async function onDelete() {
    if (confirm('Do you really want to delete?')) removeDesignerAllocation(snakeCaseKeys(params))
  }

  function getAcronym(scope: string) {
    return scope
      .split('_')
      .map((word) => word[0].toUpperCase())
      .join('')
  }

  if (!designerAllocation && !editMode) {
    return (
      <tr className={classNames.row}>
        <td colSpan={6} className={classNames.data}>
          <span className={classNames.addCreative} onClick={() => setEditMode(true)}>
            <Plus className={classNames.plusIcon} />
            Add a new creative
          </span>
        </td>
      </tr>
    )
  }

  return (
    <tr className={editMode ? classNames.rowEdit : classNames.row} style={{ height: '60px' }}>
      <td className={classNames.data}>{index + 1}</td>
      <td className={editMode ? classNames.dataEdit : classNames.data}>
        {editMode ? (
          // TODO: Refactor this to use the SelectBox component
          <SingleSelect
            isSearchable
            name="designers-select"
            onChange={(e) => setParams({ ...params, designerId: +e.value })}
            options={
              designerOptions &&
              designerOptions.map((designer) => ({
                label: designer.name,
                value: designer.id,
              }))
            }
            value={
              selectedDesigner && {
                label: selectedDesigner.name,
                value: selectedDesigner.id,
              }
            }
          />
        ) : (
          <a href={routes.designer(selectedDesigner.id)} target="_blank" rel="noreferrer">
            {selectedDesigner.name}
          </a>
        )}
      </td>
      <td className={classNames.data}>{designerAllocation?.designer.timeZone}</td>
      <td className={editMode ? classNames.dataEdit : classNames.data}>
        {editMode ? (
          // TODO: Refactor this to use the SelectBox component
          <Select
            components={{ IndicatorSeparator: null }}
            className={classNames.select}
            value={removeDuplicates(params.scopesOfService).map((scope) => ({
              value: scope,
              label: getAcronym(scope),
            }))}
            options={
              selectedDesigner &&
              selectedDesigner.validScopesOfService.map((scope) => ({
                value: scope,
                label: getAcronym(scope),
              }))
            }
            onChange={(e) => setParams({ ...params, scopesOfService: e.map((scope) => scope.value) })}
            styles={multiSelectStyles}
            isClearable
            isMulti
          />
        ) : (
          removeDuplicates(params.scopesOfService).map((scope) => {
            return (
              <Badge key={scope} color="light" className={classNames.scope}>
                {getAcronym(scope)}
              </Badge>
            )
          })
        )}
      </td>
      <td className={classNames.data}>{selectedDesigner?.allocatedCompaniesCount}</td>
      <td className={classNames.data}>{selectedDesigner?.dailyCapacity}</td>
      <td className={classNames.iconsWrapper}>
        {editMode ? (
          <>
            <span title="Cancel" className={classNames.leftIcon} onClick={() => setEditMode(false)}>
              <X className={classNames.icon} />
            </span>
            <span title="Save" className={classNames.rightIcon} onClick={onSubmit}>
              <Check className={classNames.icon} />
            </span>
          </>
        ) : (
          <>
            <span title="Edit" className={classNames.leftIcon} onClick={() => setEditMode(true)}>
              <Pencil className={classNames.icon} />
            </span>
            <span title="Delete" className={classNames.rightIcon} onClick={onDelete}>
              <Trash className={classNames.icon} />
            </span>
          </>
        )}
      </td>
    </tr>
  )
}
