import Modal from 'lib/components/modals/modal'
import Button from 'components/core/button'
import { useState } from 'react'
import TextInput from '../text-input/text-input'

interface LinkModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  url?: string
  onSubmit: (url: string) => void
}

export default function LinkModal({ open, setOpen, onSubmit, url = '' }: LinkModalProps) {
  const [linkUrl, setLinkUrl] = useState(url)

  function handleOpen(isOpen: boolean) {
    if (!isOpen) {
      setLinkUrl('')
    }
    setOpen(isOpen)
  }

  return (
    <Modal open={open} setOpen={handleOpen} size="lg" clickOutsideToClose={false}>
      <Modal.Header as="div">
        <h4 className="tw-mt-0">Hyperlink</h4>
      </Modal.Header>
      <Modal.Body setOpen={handleOpen} closeButton>
        <div className="tw-flex tw-flex-col tw-gap-2 tw-text-neutral-600">
          <span>This will add a link to the selected text. Paste the URL below and click submit to add the link.</span>
          <div className="tw-text-md tw-flex tw-flex-col tw-gap-6 tw-font-semibold">
            <TextInput placeholder="url" onChange={(e) => setLinkUrl(e.target.value)} value={linkUrl} />
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-2">
              <Button
                color="neutralGray"
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  handleOpen(false)
                }}
              >
                Cancel
              </Button>
              <Button
                color="purple"
                type="submit"
                onClick={(e) => {
                  e.preventDefault()
                  onSubmit(linkUrl)
                  setLinkUrl('')
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
