import { MouseEvent, useEffect, useRef, useState } from 'react'
import { PenLine } from 'lucide-react'
import OpenSeadragon, { CanvasClickEvent, ZoomEvent } from 'openseadragon'
import Annotorious from '@recogito/annotorious-openseadragon'
import '@recogito/annotorious-openseadragon/dist/annotorious.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from 'lib/components/buttons/icon-button'
import Tooltip from 'lib/components/tooltip/tooltip'
import {
  AnnotoriousAnnotation,
  AnnotoriousAnnotationTarget,
} from 'lib/components/annotation/annotorious-openseadragon-types'
import { AdminUser } from 'providers/admin-user-provider'
import { useAdminAnnotationHighlightContext } from '../providers/admin-annotation-highlight-provider'

interface AnnotationViewerProps {
  annotations: AnnotoriousAnnotation[]
  onCreate: (annotation: AnnotoriousAnnotation, callback: SimpleCallback) => void
  onUpdate: (annotation: AnnotoriousAnnotation) => void
  readOnly?: boolean
  url: string
  user: AdminUser
}

interface AnnotoriousInstance {
  cancelSelected: () => void
  readOnly: boolean
  setAnnotations: (annotations: AnnotoriousAnnotation[]) => void
  setAuthInfo: (authInfo: { displayName: string; id: number }) => void
  setDrawingEnabled: (enabled: boolean) => void
  setDrawingTool: (tool: string) => void
}

type SimpleCallback = () => void

interface UserData {
  callback: (zoom: number) => void
}

enum Tools {
  Move,
  Annotate,
  Paint,
}

const classNames = {
  buttons: {
    zoomIn: `tw-relative tw-inline-flex tw-items-center tw-rounded-l-md tw-bg-white tw-px-2 tw-py-2 tw-text-neutral-800
      tw-ring-1 tw-ring-inset tw-ring-neutral-300 hover:tw-bg-neutral-50 focus:tw-z-10
      disabled:tw-text-neutral-400 disabled:tw-ring-neutral-200 disabled:tw-bg-white`,
    zoomOut: `tw-relative tw--ml-px tw-inline-flex tw-items-center tw-rounded-r-md tw-bg-white tw-px-2 tw-py-2 tw-text-neutral-800
      tw-ring-1 tw-ring-inset tw-ring-neutral-300 hover:tw-bg-neutral-50 focus:tw-z-10
      disabled:tw-text-neutral-400 disabled:tw-ring-neutral-200 disabled:tw-bg-white`,
  },
  select: 'tw-rounded-md tw-border-0 tw-py-2 tw-px-3 tw-text-neutral-800',
  container: 'tw-relative tw-w-full tw-h-full tw-z-0',
  tools: {
    bottom: 'tw-absolute tw-bottom-12 tw-left-0 tw-w-full tw-text-center tw-z-10 tw-h-0',
    top: 'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-text-center tw-z-10 tw-h-0 tw-flex tw-justify-center',
  },
  viewer: {
    [Tools.Move]: 'tw-cursor-move',
    [Tools.Annotate]: 'tw-cursor-crosshair',
  },
}

const zoomPresets = [0, 50, 75, 100, 125, 150, 200]

export default function AdminAnnotationViewer({
  annotations,
  onCreate,
  onUpdate,
  readOnly = false,
  url,
  user,
}: AnnotationViewerProps) {
  const [currentZoom, setCurrentZoom] = useState<[number, number]>([0, 0])
  const [selectedTool, setSelectedTool] = useState<Tools>(Tools.Move)
  const [updatedSelectedAnnotation, setUpdatedSelectedAnnotation] = useState<AnnotoriousAnnotation>(null)

  const { setAnnotorious, setClickedAnnotationByAnnotoriousAnnotation } = useAdminAnnotationHighlightContext()

  const annotoriousInstance = useRef(null)
  const canvasInstance = useRef(null)
  const openSeadragonInstance = useRef(null)

  function handleButtonClick(event: MouseEvent, newTool: Tools) {
    event.stopPropagation()

    switchTool(selectedTool, newTool)
  }

  function resetTool(cancelSelected = true) {
    switchTool(Tools.Annotate, Tools.Move, cancelSelected)
  }

  function switchTool(previous: Tools, next: Tools, cancelSelected = true) {
    if (cancelSelected) {
      annotoriousInstance.current.cancelSelected()
    }

    if (next !== previous) {
      if (previous === Tools.Paint) {
        canvasInstance.current.disable()
        togglePanAndZoom(true)
      } else if (previous === Tools.Annotate) {
        setUpdatedSelectedAnnotation(null)
        annotoriousInstance.current.setDrawingEnabled(false)
      }

      if (next === Tools.Annotate) {
        annotoriousInstance.current.setDrawingEnabled(true)
        annotoriousInstance.current.setDrawingTool('rect')
      }
      setSelectedTool(next)
    } else {
      if (next === Tools.Annotate) {
        switchTool(previous, Tools.Move)
      }
    }
  }

  function togglePanAndZoom(isPannable: boolean) {
    openSeadragonInstance.current.panHorizontal = isPannable
    openSeadragonInstance.current.panVertical = isPannable
    if (isPannable) {
      openSeadragonInstance.current.removeHandler('canvas-click', openSeaDragonDisableAction)
      openSeadragonInstance.current.removeHandler('canvas-pinch', openSeaDragonDisableAction)
      openSeadragonInstance.current.removeHandler('canvas-scroll', openSeaDragonDisableAction)
    } else {
      zoomTo('0')
      openSeadragonInstance.current.addHandler('canvas-click', openSeaDragonDisableAction)
      openSeadragonInstance.current.addHandler('canvas-pinch', openSeaDragonDisableAction)
      openSeadragonInstance.current.addHandler('canvas-scroll', openSeaDragonDisableAction)
    }
  }

  function updateSelectedAnnotation(target: AnnotoriousAnnotationTarget) {
    // setHighlightedAnnotation(null)
    if (updatedSelectedAnnotation) {
      setUpdatedSelectedAnnotation({ ...updatedSelectedAnnotation, target })
    } else {
      const selected = annotoriousInstance.current.getSelected()
      const updatedAnnotation = { ...selected, target }
      setUpdatedSelectedAnnotation(updatedAnnotation)
    }
  }

  function zoomIn() {
    openSeadragonInstance.current.viewport.zoomBy(2)
  }

  function zoomOut() {
    openSeadragonInstance.current.viewport.zoomBy(0.5)
  }

  function zoomTo(zoom: string) {
    if (zoom === null || selectedTool === Tools.Paint) {
      return
    }
    const zoomValue = parseInt(zoom)
    if (!zoomValue) {
      openSeadragonInstance.current.viewport.goHome({ immediately: true })
      return
    }
    openSeadragonInstance.current.viewport.zoomTo(zoomValue / 100)
  }

  useEffect(() => {
    openSeadragonInstance.current = createOpenSeadragon(url)

    annotoriousInstance.current = createAnnotorious(openSeadragonInstance.current, readOnly, user)

    function zoomEventCallback(preciseCurrentZoom: number) {
      setCurrentZoom([preciseCurrentZoom, Math.floor(preciseCurrentZoom)])
    }

    openSeadragonInstance.current.addHandler(
      'zoom',
      (zoomEvent: ZoomEvent) => {
        const preciseCurrentZoom = zoomEvent.zoom * 100
        const { callback } = zoomEvent.userData as UserData
        callback(preciseCurrentZoom)
      },
      { callback: zoomEventCallback },
    )

    annotoriousInstance.current.setAnnotations(annotations)

    setAnnotorious(annotoriousInstance.current)
    annotoriousInstance.current.on('cancelSelected', () => {
      setUpdatedSelectedAnnotation(null)
      resetTool(false)
    })
    annotoriousInstance.current.on('createSelection', (annotation) => {
      setUpdatedSelectedAnnotation(annotation)
      onCreate(annotation, null)
    })
    annotoriousInstance.current.on('createAnnotation', resetTool)
    annotoriousInstance.current.on('changeSelectionTarget', updateSelectedAnnotation)
    annotoriousInstance.current.on('selectAnnotation', (annotation) => {
      setClickedAnnotationByAnnotoriousAnnotation(annotation)
    })

    return () => {
      try {
        annotoriousInstance.current.destroy()
        openSeadragonInstance.current.destroy()
        setSelectedTool(Tools.Move)
      } catch {
        // do nothing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotations, onCreate, onUpdate, readOnly, url, user])

  // TODO: implement when users can edit annotations
  // useEffect(() => {
  //   function mouseUpListener(event) {
  //     const target = event.target as HTMLElement
  //
  //     if (target.classList.toString().includes('a9s') && updatedSelectedAnnotation) {
  //       if (updatedSelectedAnnotation.id) {
  //         annotoriousInstance.current.updateSelected(updatedSelectedAnnotation, true)
  //         onUpdate(updatedSelectedAnnotation)
  //       }
  //     }
  //   }
  //
  //   function mouseClickListener(event) {
  //     if (!updatedSelectedAnnotation && !(event.target as HTMLElement).classList.toString().includes('a9s')) {
  //       annotoriousInstance.current.cancelSelected()
  //       // setHighlightedAnnotation(null)
  //     }
  //   }
  //
  //   document.addEventListener('mouseup', mouseUpListener)
  //   document.addEventListener('mousedown', mouseClickListener)
  //
  //   return () => {
  //     document.removeEventListener('mouseup', mouseUpListener)
  //     document.removeEventListener('mousedown', mouseClickListener)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [updatedSelectedAnnotation])

  return (
    <div className={classNames.container}>
      <div className={classNames.tools.top}>
        <IconButton
          className="tw-m-2"
          color={selectedTool === Tools.Move ? 'primary' : 'secondary'}
          invert={selectedTool === Tools.Move}
          selected={selectedTool === Tools.Move}
          icon={['fal', 'hand-paper']}
          onClick={(e: MouseEvent) => handleButtonClick(e, Tools.Move)}
          size="lg"
        />

        {!readOnly && (
          <Tooltip content="Create an annotation" direction="up">
            <IconButton
              className="tw-m-2"
              color={selectedTool === Tools.Annotate ? 'primary' : 'secondary'}
              invert={selectedTool === Tools.Annotate}
              selected={selectedTool === Tools.Annotate}
              onClick={(e: MouseEvent) => handleButtonClick(e, Tools.Annotate)}
              size="lg"
              dataTestid="pen-line-button"
            >
              <PenLine className="lu-light lu-md" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={classNames.tools.bottom}>
        <span>
          <button className={classNames.buttons.zoomIn} onClick={zoomIn} data-testid="search-plus-button">
            <FontAwesomeIcon icon={['fal', 'search-plus']} size="lg" />
          </button>
          <button className={classNames.buttons.zoomOut} onClick={zoomOut} data-testid="search-minus-button">
            <FontAwesomeIcon icon={['fal', 'search-minus']} size="lg" />
          </button>
        </span>
        <span className="tw-mx-2 tw-align-bottom">
          <select className={classNames.select} value={currentZoom[1]} onChange={(event) => zoomTo(event.target.value)}>
            {isZoomPreset(currentZoom[1]) ? null : <option value={null}>{currentZoom[1]}%</option>}
            {zoomPresets.map((zoom) => (
              <option key={zoom} value={zoom}>
                {zoom ? `${zoom}%` : 'Fit'}
              </option>
            ))}
          </select>
        </span>
      </div>
      <div id="openSeadragonViewer" className={`tw-h-full ${classNames.viewer[selectedTool]}`} />
    </div>
  )
}

function createAnnotorious(
  openSeadragonInstance: OpenSeadragon.Viewer,
  readOnly: boolean,
  user: AdminUser,
): AnnotoriousInstance {
  const annotoriousConfig = {
    fragmentUnit: 'percent',
    disableEditor: true,
  }

  const anno = Annotorious(openSeadragonInstance, annotoriousConfig)
  anno.readOnly = readOnly
  anno.setAuthInfo({
    displayName: user.fullName,
    id: user.id,
  })
  return anno
}

function createOpenSeadragon(url: string): OpenSeadragon.Viewer {
  return OpenSeadragon({
    id: 'openSeadragonViewer',
    showFullPageControl: false,
    showHomeControl: false,
    showNavigationControl: false,
    tileSources: {
      type: 'image',
      url,
    },
  })
}

function isZoomPreset(zoom: number) {
  return zoomPresets.includes(zoom)
}

function openSeaDragonDisableAction(options: CanvasClickEvent) {
  options.preventDefaultAction = true
}
