import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, useMemo } from 'react'
import Tooltip from 'lib/components/tooltip/tooltip'
import { CompanyDailyAvailableTimes } from 'lib/api/companies/company_daily_available_times'
import { getTextValue } from 'components/pages/requests/table-elements/time-to-spend-dropdown'

const classNames = {
  tooltip: 'tw-ml-1',
  columnHeaderText: 'tw-text-xs tw-font-medium tw-font-gray-700',
  columnHeaderWarningText: 'tw-text-xs tw-font-medium tw-font-gray-700 tw-text-red-600',
  dropdownTitle:
    'tw-h-5 tw-mt-1.5 tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-medium tw-rounded-full tw-bg-neutral-100 tw-text-neutral-800',
}

interface TimeToSpendHeaderProps {
  companyDailyAvailableTimes: CompanyDailyAvailableTimes
}

export default function TimeToSpendHeader({ companyDailyAvailableTimes }: TimeToSpendHeaderProps): ReactElement {
  const { totalDailyTime, totalUsedTime } = companyDailyAvailableTimes

  const timeToSpendStyle = useMemo(
    () => (totalDailyTime < totalUsedTime ? classNames.columnHeaderWarningText : classNames.columnHeaderText),
    [totalDailyTime, totalUsedTime],
  )

  const tooltipContent =
    "Hours of design time you've allocated to your requests. \n Maximum available design time may vary by request type depending on your subscription(s)."

  return (
    <>
      <span className={classNames.tooltip}>
        <Tooltip content={tooltipContent} direction="right" width={240}>
          <FontAwesomeIcon icon={['far', 'info-circle']} size={'lg'} />
        </Tooltip>
      </span>
      <div className={timeToSpendStyle}>{`${getTextValue(totalUsedTime)} of ${getTextValue(totalDailyTime)} used`}</div>
    </>
  )
}
