import React from 'react'
import ActionLinkItem from 'components/elements/dropdown/items/action-link-item'

interface CancellationsOverflowMenuProps {
  setShowEdit(boolean): void
}

export default function CancellationsOverflowMenu({ setShowEdit }: CancellationsOverflowMenuProps): JSX.Element {
  return (
    <>
      <ActionLinkItem label="Edit" iconClass="fas fa-pencil" onClick={() => setShowEdit(true)} />
    </>
  )
}
