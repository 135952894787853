import React from 'react'

interface SubscriptionsListProps {
  children: React.ReactNode
  headerOne: string
  headerTwo: string
}

export default function SubscriptionsList(props: SubscriptionsListProps): JSX.Element {
  return (
    <div className="tw-overflow-x-auto tw-bg-white tw-p-4">
      <table className="tw-w-full tw-table-fixed">
        <thead className="tw-text-peppercorn-800">
          <tr data-testid="subscription-list-header">
            <th className="tw-w-1/12">Company</th>
            <th className="tw-w-3/12">
              <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                <div>{props.headerOne}</div>
                <div>{props.headerTwo}</div>
              </div>
            </th>
            <th className="tw-w-1/12"></th>
          </tr>
        </thead>
        <tbody className="tw-divide-x-0 tw-divide-y tw-divide-solid tw-divide-black tw-bg-white">
          {props.children}
        </tbody>
      </table>
    </div>
  )
}
