import { useEffect, useState } from 'react'
import { Switch } from '@headlessui/react'
import { CompanyDesignerAllocation, routes } from 'lib/api/company-designer-allocations/company-designer-allocations'
import { ArrowLeftToLine, CircleAlert } from 'lucide-react'
import { snakeCaseKeys } from 'lib/object/utils'
import DesignerTable from './designer-table'
import DesignerAllocationsProvider, { useDesignerAllocationsContext } from './designer-allocations-provider'
import ScopeTable from './scope-table'
import { Toaster } from 'lib/components/toast/toast'
import { cn } from 'lib/util/cn'
import SingleSelect from 'components/core/single-select'

const classNames = {
  arrowIcon: 'tw-mr-3 tw-text-peppercorn-900',
  container: 'tw-container tw-px-3 tw-bg-white tw-shadow-md tw-rounded-md tw-py-4 tw-px-6 tw-w-full tw-mb-8',
  header: 'tw-flex tw-items-center navbar-custom',
  switchGroup: 'tw-flex tw-items-center',
  switchLabel: 'tw-mr-1 tw-mb-0 tw-text-black tw-uppercase',
  switchToggle: {
    enabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw-translate-x-4',
    disabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw--translate-x-1',
  },
  switchWrapper: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md',
  wrapper: 'tw-flex tw-w-full tw-flex-col tw-pb-4',
}

function CompanyDesignerAllocationDetail() {
  const { apmOptions, companyAllocation, updateCompanyAllocation } = useDesignerAllocationsContext()
  const [params, setParams] = useState({
    apmId: companyAllocation.apm?.id,
    id: companyAllocation.id,
    timezoneTeamBackup: companyAllocation.timezoneTeamBackup || {
      est: false,
      pst: false,
      uk: false,
      aus: false,
    },
  })
  const selectedApm = apmOptions.find((d) => d.id === params.apmId) || companyAllocation.apm

  useEffect(() => {
    if (params.timezoneTeamBackup !== companyAllocation.timezoneTeamBackup) {
      updateCompanyAllocation(snakeCaseKeys(params))
    }

    if (params.apmId !== companyAllocation.apm?.id) {
      if (confirm('Are you sure you want to change the APM?')) {
        updateCompanyAllocation(snakeCaseKeys(params))
      } else {
        setParams((prevParams) => ({
          ...prevParams,
          apmId: companyAllocation.apm?.id,
        }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  function handleToggleChange(key: string) {
    setParams((prevParams) => ({
      ...prevParams,
      timezoneTeamBackup: {
        ...prevParams.timezoneTeamBackup,
        [key]: !prevParams.timezoneTeamBackup[key],
      },
    }))
  }

  return (
    <div className={classNames.wrapper}>
      <div id="page-header" className={classNames.header}>
        <a href={routes.index}>
          <ArrowLeftToLine size={24} className={classNames.arrowIcon} />
        </a>
        <h3>{companyAllocation.company.name}</h3>
      </div>
      <div>
        <div className={classNames.container}>
          <h4>Pool Backup</h4>
          <div className={classNames.switchWrapper}>
            {Object.entries(params.timezoneTeamBackup).map(([key, value]: [string, boolean]) => (
              <Switch.Group key={key}>
                <div className={classNames.switchGroup}>
                  <Switch.Label className={classNames.switchLabel}>{key}</Switch.Label>
                  <Switch
                    checked={value}
                    onChange={() => handleToggleChange(key)}
                    className={cn(
                      value ? 'tw-bg-gherkin-500' : 'tw-bg-neutral-200',
                      'tw-relative tw-inline-flex tw-h-[18px] tw-w-[38px] tw-flex-shrink-0 tw-cursor-pointer tw-rounded-full tw-border-none tw-p-0.5 tw-transition-colors tw-duration-200 tw-ease-in-out',
                    )}
                  >
                    <span className="sr-only">{key}</span>
                    <span
                      aria-hidden="true"
                      className={cn(
                        'tw-pointer-events-none tw-inline-block tw-h-[14px] tw-w-[14px] tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out',
                        value ? 'tw-translate-x-[20px]' : 'tw--translate-x-0',
                      )}
                    />
                  </Switch>
                </div>
              </Switch.Group>
            ))}
          </div>
        </div>
        <div className={classNames.container}>
          <h4>Scopes</h4>
          <ScopeTable />
        </div>
        <div className={classNames.container}>
          <h4>APM / Production Team</h4>
          <p>
            <span className="tw-pr-1">
              <CircleAlert />
            </span>
            {selectedApm
              ? 'Warning: changing this will remove the previously assigned creatives.'
              : 'To add Creatives, first you need to select an APM.'}
          </p>
          <div className="tw-w-1/3">
            <SingleSelect
              isSearchable
              name="apms-select"
              onChange={(e) => setParams({ ...params, apmId: +e.value })}
              options={
                apmOptions &&
                apmOptions.map((apm) => ({
                  label: apm.name,
                  value: apm.id,
                }))
              }
              value={
                selectedApm && {
                  label: selectedApm.name,
                  value: selectedApm.id,
                }
              }
            />
          </div>
        </div>
        <div className={classNames.container}>
          <h4>Creatives</h4>
          <DesignerTable />
        </div>
      </div>
    </div>
  )
}

export default function CompanyDesignerAllocationDetailPage({
  companyDesignerAllocation,
}: {
  companyDesignerAllocation: CompanyDesignerAllocation
}) {
  return (
    <DesignerAllocationsProvider companyDesignerAllocation={companyDesignerAllocation}>
      <>
        <Toaster />
        <CompanyDesignerAllocationDetail />
      </>
    </DesignerAllocationsProvider>
  )
}
