import { FormEvent, useEffect, useState } from 'react'
import { isEqual, omit, omitBy, snakeCase, sortBy } from 'lodash'

import Modal from 'lib/components/modals/modal'
import SelectBox, { SelectBoxOption } from 'lib/components/dropdown/select-box'
import Button from 'components/core/button'

import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import TextInput from 'lib/components/text-input/text-input'
import { convertCustomSizesToString } from 'lib/util/skill-sizes/skill-sizes'
import { CustomSize, Skill, SkillSize } from 'lib/api/skills/skills'
import AdminTicketSizeDropdown from './admin-ticket-size-dropdown'
import AdminTimeSelector from '../stop-modal/admin-time-selector'
import RequestTypePopover from 'components/pages/request/request-header/request-type-popover'
import { getDesigners } from 'lib/api/admin/designers/admin-designers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getQaSpecialists } from 'lib/api/qa_specialists/qa_specialists'
import { AdminTicketState, AdminUpdateTicketParams } from 'lib/api/admin/tickets/admin-tickets'
import { toast } from 'lib/components/toast/toast'
import AdminTicketStateDropdown from './admin-ticket-state-dropdown'
import RequestFormatsPopover from 'components/pages/request/request-header/request-formats-popover'

interface AdminEditTicketDetailsModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const yesNoOptions = [
  { displayElement: 'Yes', value: true },
  { displayElement: 'No', value: false },
] as unknown as SelectBoxOption[]

export default function AdminEditTicketDetailsModal({ open, setOpen }: AdminEditTicketDetailsModalProps) {
  const { ticket, updateTicket, updateQrSpecialist } = useAdminTicketContext()

  const [training, setTraining] = useState(ticket.training)
  const [covered, setCovered] = useState(ticket.covered)
  const [designerError, setDesignerError] = useState(ticket.designerError)
  const [subject, setSubject] = useState(ticket.subject)
  const [skillSizes, setSkillSizes] = useState(ticket.selectedSkillSizes || [])
  const [size, setSize] = useState(ticket.size)
  const [selectedTime, setSelectedTime] = useState(ticket.designTime / 60)
  const [skill, setSkill] = useState<Skill>(ticket.skill)
  const [designerId, setDesignerId] = useState(ticket?.designer?.id)
  const [designerList, setDesignerList] = useState<SelectBoxOption[]>([])
  const [designerListLoading, setDesignerListLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [qsId, setQsId] = useState(ticket.qaReviews?.[0]?.qaSpecialist?.id)
  const [qsList, setQsList] = useState<SelectBoxOption[]>([])
  const [qaListLoading, setQsListLoading] = useState(true)
  const [state, setState] = useState(ticket.state.includes('Incomplete') ? 'incomplete' : snakeCase(ticket.state))
  const [formats, setFormats] = useState(ticket.selectedFormats)

  const ticketPermissions = {
    ...ticket.meta.permissions,
    qa: ticket.qaReviews?.[0]?.meta?.permissions,
  }

  const getChangedDetails = () => {
    const previousDetails = {
      training: ticket.training,
      covered: ticket.covered,
      designerError: ticket.designerError,
      subject: ticket.subject,
      skillId: ticket.skill.id,
      skillSizeIds: (ticket.selectedSkillSizes || []).map((size) => size.id),
      size: ticket.size,
      state: ticket.state.includes('Incomplete') ? 'incomplete' : snakeCase(ticket.state),
      todaysWeight: ticket.designTime / 60,
      designerId: ticket.designer?.id,
      qsId: ticket.qaReviews?.[0]?.qaSpecialist?.id,
      formats: ticket.selectedFormats,
    }

    const newDetails = {
      training,
      covered,
      designerError,
      subject,
      skillId: skill.id,
      skillSizeIds: skillSizes.map((size) => size.id),
      size,
      state,
      todaysWeight: selectedTime,
      designerId,
      qsId,
      formats,
    }

    const changedDetails = omitBy(newDetails, (v, k) => isEqual(previousDetails[k], v))
    if (previousDetails.state.includes('incomplete') && newDetails.state.includes('incomplete')) {
      delete changedDetails.state
    }

    return changedDetails
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    setLoading(true)
    try {
      const newTicketDetails = { id: ticket.id, ...getChangedDetails() } as AdminUpdateTicketParams & { qsId: number }
      const updateOps = []

      if (newTicketDetails?.qsId) {
        updateOps.push(updateQrSpecialist(newTicketDetails.qsId))
      }

      const updatedTicket = omit(newTicketDetails, ['qsId'])

      if (Object.keys(updatedTicket).length > 1) {
        updateOps.push(updateTicket(updatedTicket))
      }

      await Promise.all(updateOps)
      toast.success('Ticket details updated successfully')
      setOpen(false)
    } catch (error) {
      toast.error('There was an error updating the ticket details. try again later')
      console.error('Error updating ticket details', error)
    }
    setLoading(false)
  }

  function handleCancel() {
    setTraining(ticket.training)
    setCovered(ticket.covered)
    setDesignerError(ticket.designerError)
    setDesignerId(ticket?.designer?.id)
    setSubject(ticket.subject)
    setSize(ticket.size)
    setSkillSizes(ticket.selectedSkillSizes)
    setDesignerId(ticket?.designer?.id)
    setQsId(ticket.qaReviews?.[0]?.qaSpecialist?.id)
    setState(ticket.state.includes('Incomplete') ? 'incomplete' : snakeCase(ticket.state))
    setFormats(ticket.selectedFormats)

    setOpen(false)
  }

  function onChangeSizes(sizes: { skillSizes?: SkillSize[]; customSizes?: CustomSize[] }) {
    if (sizes.skillSizes) {
      setSkillSizes(sizes.skillSizes)
    }
    if (sizes.customSizes) {
      setSize(convertCustomSizesToString(sizes.customSizes) || null)
    }
  }

  const loadDesigners = async () => {
    try {
      const designers = await getDesigners()
      const sortedDesignerOptions = sortBy(designers, 'name').map(({ id, name }) => ({
        displayElement: <div>{name}</div>,
        label: name,
        value: id,
      }))
      setDesignerList(sortedDesignerOptions)
    } catch (error) {
      console.error('Error fetching designer list', error)
      toast.error('Error fetching designer list')
    } finally {
      setDesignerListLoading(false)
    }
  }

  const loadQSList = async () => {
    try {
      const { specialists: qsList } = await getQaSpecialists()
      const sortedQSOptions = sortBy(qsList, 'name').map(({ id, name }) => ({
        displayElement: <div>{name}</div>,
        label: name,
        value: id,
      }))
      setQsList(sortedQSOptions)
    } catch (error) {
      console.error('Error fetching QA speacialists list', error)
      toast.error('Error fetching QA specialists list')
    } finally {
      setQsListLoading(false)
    }
  }

  useEffect(() => {
    loadDesigners()
    loadQSList()
  }, [])

  return (
    <Modal open={open} setOpen={setOpen} size="lg">
      <Modal.Header>Edit Ticket Details</Modal.Header>
      <Modal.Body setOpen={setOpen}>
        <form onSubmit={handleSubmit}>
          <div className="tw-mb-8 tw-flex tw-flex-col tw-gap-4">
            <div>
              <label htmlFor="subject-input" className="tw-mb-0 tw-text-neutral-500">
                Subject
              </label>
              <TextInput
                id="subject-input"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="tw-mb-0 tw-mt-2"
              />
            </div>
            {ticketPermissions.update.skillId && (
              <div className="tw--mx-4 tw--mb-4" data-testid="skillDropdown">
                <RequestTypePopover
                  skill={skill}
                  onChange={(skill) => {
                    setSkill(skill)
                  }}
                  showValidationError={false}
                  disabled={false}
                />
              </div>
            )}
            {ticketPermissions.update.formats && (
              <div className="tw--ml-4">
                <RequestFormatsPopover
                  availableFormats={ticket?.availableFormats || []}
                  onChange={setFormats}
                  ticketSelectedFormats={formats}
                  showValidationError={false}
                />
              </div>
            )}
            {ticketPermissions.update.todaysWeight && (
              <div>
                <label className="tw-mb-0 tw-text-neutral-500" htmlFor="subject-input">
                  Design Time
                </label>
                <AdminTimeSelector selectedTime={selectedTime} setSelectedTime={setSelectedTime} className="tw-mt-2" />
              </div>
            )}
            {ticketPermissions.update.skillSizeIds && (
              <div>
                <label className="tw-mb-2 tw-block tw-text-neutral-500">Size(s)</label>
                <AdminTicketSizeDropdown
                  skillSizes={ticket.skill.skillSizes}
                  selectedSkillSizes={skillSizes}
                  selectedCustomSizes={size}
                  onChange={onChangeSizes}
                />
              </div>
            )}
            {ticketPermissions.update.state && (
              <div>
                <label className="tw-mb-2 tw-block tw-text-neutral-500">State</label>
                <AdminTicketStateDropdown state={state} onChange={setState} />
              </div>
            )}
            {ticketPermissions.update.designerId &&
              (designerListLoading ? (
                <FontAwesomeIcon spin icon="spinner-third" />
              ) : (
                <SelectBox
                  searchable
                  label="Creative"
                  options={designerList}
                  selectedValue={designerId}
                  handleChange={(value: number) => setDesignerId(value)}
                />
              ))}
            {ticketPermissions.qa?.update &&
              ticket.state === AdminTicketState.qualityReview &&
              (qaListLoading ? (
                <FontAwesomeIcon spin icon="spinner-third" />
              ) : (
                <SelectBox
                  searchable
                  label="Quality Specialist"
                  options={qsList}
                  selectedValue={qsId}
                  handleChange={(value: number) => setQsId(value)}
                />
              ))}
            {ticketPermissions.update.training && (
              <SelectBox
                options={yesNoOptions}
                handleChange={(value: boolean) => setTraining(value)}
                selectedValue={training}
                label="Is this a training ticket?"
              />
            )}
            {ticketPermissions.update.covered && (
              <SelectBox
                options={yesNoOptions}
                handleChange={(value: boolean) => setCovered(value)}
                selectedValue={covered}
                label="Is this covered by a different designer?"
              />
            )}
            {ticketPermissions.update.designerError && (
              <SelectBox
                options={yesNoOptions}
                handleChange={(value: boolean) => setDesignerError(value)}
                selectedValue={designerError}
                label="Did the designer make an error?"
              />
            )}
          </div>
          <div className="tw-flex tw-items-center tw-justify-end tw-gap-2">
            <Button onClick={handleCancel} color="lightGray">
              Cancel
            </Button>
            <Button type="submit" color="purple">
              {loading ? <FontAwesomeIcon spin icon="spinner-third" /> : 'Save'}
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
