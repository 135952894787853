import React from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { classNames } from 'lib/array/utils'
import { cn } from 'lib/util/cn'

type Size = 'md' | 'lg'
type DropdownType = 'no-bg' | 'white-bg'

interface DropdownMenuProps {
  children: React.ReactNode
  dropDownType?: DropdownType
  size?: Size
  renderOnTop?: boolean
  sticky?: boolean
}

const menuTypeClass = (dropdownType: DropdownType) =>
  ({
    'no-bg': 'tw-bg-peppercorn-50',
    'white-bg':
      'tw-bg-white hover:tw-bg-peppercorn-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-white focus:tw-ring-white',
  })[dropdownType] ||
  'tw-bg-white hover:tw-bg-peppercorn-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-white focus:tw-ring-white'

const defaultClassNames = {
  menu: 'tw-static tw-inline-block tw-items-center tw-justify-items-center tw-mx-1',
  buttonWrapper: 'tw-items-center tw-justify-items-center',
  button: 'tw-border-0 tw-rounded-full tw-flex tw-items-center tw-justify-items-center tw-text-peppercorn-400',
  icon: 'far fa-ellipsis-v tw-text-lg',
}

const defaultSizeClass = {
  button: 'tw-h-8 tw-w-8',
  icon: 'tw-ml-2',
}

const customSizeClass = (size: Size) =>
  ({
    md: defaultSizeClass,
    lg: {
      button: 'tw-h-10 tw-w-10',
      icon: 'tw-ml-3',
    },
  })[size] || defaultSizeClass

/**
 * Overflow menu component
 * @deprecated use IconFlyoutMenu instead from lib/components/icon-flyout-menu/icon-flyout-menu.tsx
 */
export default function DropdownMenu({
  children,
  dropDownType,
  size,
  renderOnTop = false,
  sticky = false,
}: DropdownMenuProps): JSX.Element {
  return (
    <Menu as="div" className={defaultClassNames.menu}>
      <div className={defaultClassNames.buttonWrapper}>
        <Menu.Button
          className={classNames(menuTypeClass(dropDownType), defaultClassNames.button, customSizeClass(size).button)}
          data-testid="overflow-button"
        >
          <span className="tw-container tw-sr-only">Open options</span>
          <i className={classNames(defaultClassNames.icon, customSizeClass(size).icon)} />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="tw-transition tw-ease-out tw-duration-100"
        enterFrom="tw-transform tw-opacity-0 tw-scale-95"
        enterTo="tw-transform tw-opacity-100 tw-scale-100"
        leave="tw-transition tw-ease-in tw-duration-75"
        leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
        leaveTo="tw-transform tw-opacity-0 tw-scale-95"
      >
        <Menu.Items
          className={cn(
            'tw-absolute tw-z-10 tw-origin-top-right tw-divide-y tw-divide-gray-100 tw-rounded-md tw-border tw-border-solid tw-border-gray-200 tw-bg-white tw-shadow-lg focus:tw-outline-none',
            sticky ? 'tw-right-12 tw-top-[calc(50%-20px)] tw-w-56' : 'tw-right-0 tw-mt-2 tw-w-56',
          )}
          style={renderOnTop ? { bottom: '3.25rem' } : {}}
        >
          <div className="tw-py-1">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
