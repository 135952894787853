import { bindJWT, postApi, patchApi, getApi, deleteApi } from 'lib/api/api'

export interface BrandPayload {
  logo: string
  primary_color: string
  url: string
}

export async function createBrand(data: BrandPayload) {
  const postWithJWT = bindJWT(postApi, null)
  const response = await postWithJWT(`/api/internal/domains/`, { data })
  return response.data
}

export async function updateBrand(id: string, data: BrandPayload) {
  const patchWithJWT = bindJWT(patchApi, null)
  const response = await patchWithJWT(`/api/internal/domains/${id}`, { data })
  return response.data
}

export async function deleteBrand(id: string) {
  const deleteWithJWT = bindJWT(deleteApi, null)
  const response = await deleteWithJWT(`/api/internal/domains/${id}`)
  return response.data
}

export async function fetchBrand(id: string) {
  const getWithJWT = bindJWT(getApi, null)
  const response = await getWithJWT(`/api/internal/domains/${id}`)
  return response.data
}

export async function refreshBrandDns(id: string) {
  const postWithJWT = bindJWT(postApi, null)
  const response = await postWithJWT(`/api/internal/domains/${id}/refresh`)
  return response.data
}
