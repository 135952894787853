import {
  CircleHelp,
  Bell,
  Rocket,
  ArrowRightToLine,
  ChevronDown,
  User,
  Users,
  Building,
  ExternalLink,
  ChevronUp,
  Clock,
  PlusIcon,
  MenuIcon,
  MegaphoneIcon,
} from 'lucide-react'
import NewRequestButton from 'components/pages/request/new-request-button/new-request-button'
import { CurrentUser } from 'interfaces/app'
import SearchInput from '../search-input/search-input'
import { useEffect, useState } from 'react'
import IconButton from '../buttons/icon-button'
import Button, { LinkButton } from 'components/core/button'
import { Designer } from 'interfaces/designer'
import Avatar from 'components/core/avatar'
import { Popover, PopoverContent, PopoverTrigger } from 'lib/components/popover/popover'
import { PATHS } from 'lib/constants/paths'
import { MenuItem } from '../menu-item/menu-item'
import Notifications, { BadgeCount } from '../notifications/notifications'
import QueryClientProvider from 'providers/query-client-provider'
import { LeaveDay } from 'interfaces/leave-day'
import { TrialPopover } from '../trial/trial'
import useSidebarState from 'lib/hooks/useSidebarState'
import CreateButton from 'components/pages/create/create-button'
import { FileExports } from 'lib/hooks/useFileExports'

interface HeaderProps {
  user: CurrentUser
  designer: Designer
  simulatedLogin: boolean
  cutOffTimes: { name: string; time: string }[]
  leaveDays: LeaveDay[]
  overflowProtection: boolean
}

const loggedInItems: MenuItem[] = [
  {
    label: 'My Profile',
    path: PATHS.SETTINGS,
    Icon: User,
  },
  {
    label: 'My Team',
    path: PATHS.COMPANIES_TEAM,
    Icon: Users,
  },
  {
    label: 'Company Settings',
    path: PATHS.COMPANIES_SETTINGS,
    Icon: Building,
  },
  {
    label: 'Notification Settings',
    path: PATHS.SETTINGS_NOTIFICATIONS,
    Icon: Bell,
  },
  {
    label: 'Product Feedback',
    path: PATHS.RECENT_UPDATES,
    Icon: MegaphoneIcon,
  },
]

export default function Header({
  user,
  designer,
  simulatedLogin,
  cutOffTimes,
  leaveDays,
  overflowProtection,
}: HeaderProps) {
  const [search, setSearch] = useState('')

  const [isOpen, setIsOpen] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useSidebarState()

  const [unreadCount, setUnreadCount] = useState(0)

  window.intercomSettings = {
    hide_default_launcher: true,
    custom_launcher_selector: '#intercom-launcher-button',
    ...window?.intercomSettings,
  }

  useEffect(() => {
    if (window?.Intercom) {
      window?.Intercom?.('onUnreadCountChange', setUnreadCount)
    }
  }, [])

  return (
    <QueryClientProvider>
      <FileExports />
      <style>
        {`#pendo-resource-center-container {
            inset: auto 20px 20px auto !important;
          }`}
      </style>
      <div className="tw-fixed tw-right-0 tw-top-0 tw-z-30 tw-ml-1 tw-flex tw-h-14 tw-w-full tw-items-center tw-gap-2 tw-border-0 tw-border-b tw-border-solid tw-border-neutral-200 tw-bg-white tw-px-2 md:tw-gap-4 md:tw-px-4">
        <IconButton
          className="md:tw-hidden"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          color="secondary"
          size="xs"
        >
          <MenuIcon className="tw-shrink-0" />
        </IconButton>
        <a href={PATHS.ROOT}>
          <img
            src="/images/icons/brand-circle.svg"
            alt="avatar"
            className="tw-h-8 tw-w-8 tw-rounded-full md:tw-h-10 md:tw-w-10"
          />
        </a>
        {user.isProjectsEnabled ? (
          <CreateButton />
        ) : (
          <NewRequestButton user={user}>
            <Button color="purple" className="tw-hidden tw-items-center md:tw-flex">
              <PlusIcon className="tw-mr-2 tw-shrink-0" />
              New Request
            </Button>
            <IconButton className="md:tw-hidden" onClick={() => {}} color="primary" size="xs">
              <PlusIcon className="tw-shrink-0" />
            </IconButton>
          </NewRequestButton>
        )}
        <SearchInput
          placeholder="Searching for design requests?"
          className="tw-flex-1"
          value={search}
          onChange={setSearch}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              window.location.href = `${PATHS.SEARCH}?query=${search}`
            }
          }}
        />
        <Button
          className="tw-hidden tw-items-center tw-font-semibold tw-text-cornflower-800 md:tw-flex"
          color="lightPurple"
          onClick={() => {
            window?.Intercom('hide')
          }}
        >
          <Rocket className="tw-mr-2 tw-shrink-0" />
          <span className="tw-hidden md:tw-block">Explore the Jar</span>
        </Button>
        <IconButton
          size="xs"
          onClick={() => {
            window?.pendo?.BuildingBlocks?.BuildingBlockResourceCenter?.dismissResourceCenter()
          }}
          color="secondary"
          id="intercom-launcher-button"
          className="tw-relative"
        >
          <CircleHelp className="tw-shrink-0" />
          {unreadCount > 0 && <BadgeCount unreadCount={unreadCount} />}
        </IconButton>
        <Notifications user={user} leaveDays={leaveDays} overflowProtection={overflowProtection} />
        <TrialPopover triggerClassName="tw-hidden md:tw-block" user={user} />
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <div className="tw-flex tw-cursor-pointer tw-items-center tw-gap-2">
              <Avatar name={user?.fullName} src={designer?.avatar} />
              <div className="tw-hidden md:tw-flex">
                <div className="tw-flex tw-flex-col">
                  <div className="tw-font-semibold">{user?.fullName?.split(' ')?.[0]?.slice(0, 15)}</div>
                  <div className="tw-text-sm tw-text-neutral-500">{user?.userRole}</div>
                </div>
                <IconButton size="xs" color="transparent" onClick={() => {}}>
                  {isOpen ? <ChevronUp /> : <ChevronDown />}
                </IconButton>
              </div>
            </div>
          </PopoverTrigger>
          <PopoverContent collisionPadding={20} className="tw-w-56" intent="flyout">
            {loggedInItems.map((item) => (
              <MenuItem key={item.label} item={item} />
            ))}
            <hr className="tw-my-2 tw-w-full tw-border-neutral-200" />
            <MenuItem item={{ label: 'Request cutoff times', Icon: Clock }} hover={false} className="tw-text-nowrap" />
            <div className="tw-my-2 tw-flex tw-flex-col tw-gap-5">
              {cutOffTimes.map((cutOffTime) => (
                <div key={cutOffTime.name} className="tw-flex tw-justify-between tw-text-sm tw-text-neutral-500">
                  <div>{cutOffTime.name}</div>
                  <div className="tw-font-semibold tw-text-neutral-800">
                    {new Date(cutOffTime.time).toLocaleTimeString('en-US', {
                      hour: 'numeric',
                      minute: '2-digit',
                      timeZoneName: 'short',
                    })}
                  </div>
                </div>
              ))}
            </div>
            <div className="tw-flex-shrink tw-break-normal tw-text-xs tw-text-neutral-500">
              Any requests submitted after the cutoff time will be worked on during the next day&apos;s shift.
            </div>
            <a
              href={PATHS.KB_REQUEST_CUTOFF_TIMES}
              target="_blank"
              rel="noreferrer"
              className="tw-ml-1 tw-whitespace-nowrap tw-text-cornflower-500"
            >
              Learn more <ExternalLink className="tw-ml-1 tw-h-4 tw-w-4" />
            </a>

            <hr className="tw-my-2 tw-w-full tw-border-neutral-200" />

            <MenuItem
              item={{ label: 'System Status', path: PATHS.STATUS_PAGE, RightIcon: ExternalLink, newTab: true }}
            />
            <hr className="tw-my-2 tw-w-full tw-border-neutral-200" />
            <MenuItem data-method="DELETE" item={{ label: 'Logout', path: PATHS.SIGN_OUT, Icon: ArrowRightToLine }} />
          </PopoverContent>
        </Popover>
        {simulatedLogin && (
          <LinkButton data-method="POST" url={PATHS.BACK_TO_ADMIN} className="tw-font-semibold" color="neutral">
            <ArrowRightToLine className="tw-mr-2 tw-h-4 tw-w-4" />
            Admin
          </LinkButton>
        )}
      </div>
    </QueryClientProvider>
  )
}
