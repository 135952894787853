import { Table } from '@tanstack/react-table'
import SelectBox, { SelectBoxOption, SelectBoxValue } from 'lib/components/dropdown/select-box'
import { columnIds } from '../use-table'
import { useAllCompanyTagsContext } from 'providers/all-company-tags-provider'

export default function BrandFilter<TData>({ table }: { table: Table<TData> }) {
  const { companyTags } = useAllCompanyTagsContext()
  const column = table.getColumn(columnIds.tag)

  const handleChange = (value: SelectBoxValue[]) => {
    column.setFilterValue(value.length > 0 ? value : undefined)
  }

  const options: SelectBoxOption[] =
    companyTags?.map((tag) => ({
      value: String(tag.id),
      displayElement: <span>{tag.name}</span>,
      label: String(tag.name),
    })) || []

  const selectedValue = () => {
    const filterValue = column.getFilterValue()
    if (Array.isArray(filterValue)) {
      return filterValue.map(String)
    }
    return []
  }

  if (!column) return null
  return (
    <SelectBox
      label="Tags"
      buttonClassName="tw-min-w-52 tw-max-w-xs"
      showLabel={false}
      placeholder="Tags"
      options={options}
      selectedValue={selectedValue()}
      handleChange={handleChange}
      multiple={true}
      searchable={true}
    />
  )
}
