import { useDirectionsContext } from 'components/pages/request/providers/timeline-directions-provider'
import { useRequestContext } from 'components/pages/request/providers/request-provider'
import Tooltip from 'lib/components/tooltip/tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cn } from 'lib/util/cn'
import { useAnnotationsContext } from '../providers/annotations-provider'

function RequestDirectionsHeader() {
  const { areDirectionsValid } = useDirectionsContext()
  const { showValidationErrors } = useRequestContext()
  const { areAnnotationsValid } = useAnnotationsContext()

  const showError = showValidationErrors && (!areDirectionsValid || !areAnnotationsValid())

  return (
    <>
      <h2 className={cn('tw-m-0 tw-text-base', { 'tw-text-flushpink-500': showError })}>
        Directions*
        <Tooltip
          content="Each direction and annotation will create a checklist for your creative to follow as they create your request."
          width={375}
        >
          <FontAwesomeIcon icon={['far', 'info-circle']} className="tw-cursor-help tw-px-2" />
        </Tooltip>
      </h2>
      <div>
        Type general directions for your designer below. You can also click the &ldquo;+&rdquo; to add exact copy and
        leverage our built-in AI tools to assist with your creative brief.
      </div>
    </>
  )
}

function RevisionsDirectionsHeader() {
  const { areDirectionsValid } = useDirectionsContext()
  const { showValidationErrors } = useRequestContext()
  const { areAnnotationsValid } = useAnnotationsContext()

  const showError = showValidationErrors && (!areDirectionsValid || !areAnnotationsValid())

  return (
    <>
      <h2 className={cn('tw-m-0 tw-text-base', { 'tw-text-flushpink-500': showError })}>Directions</h2>
      <div>Once you&lsquo;re done adding revisions, click “Send updates to designer” to submit.</div>
    </>
  )
}

export default function DirectionsHeader() {
  const { showRevisions } = useRequestContext()

  return (
    <header className="tw-bg-white tw-pb-0">
      {showRevisions && <RevisionsDirectionsHeader />}
      {!showRevisions && <RequestDirectionsHeader />}
    </header>
  )
}
