import { camelCaseKeysDeep } from 'lib/object/utils'
import { getApi, putApi } from '../api'

export interface AvailableTimes {
  availableTime: number
  minimumTime: number
  timeInterval: number
  totalDailyTime: number
}

export interface CompanyDailyAvailableTimes {
  'Custom Illustration': AvailableTimes
  'Graphic Design': AvailableTimes
  'Motion Graphics': AvailableTimes
  'Presentation Design': AvailableTimes
  totalDailyTime: number
  totalUsedTime: number
}

const companyDailyAvailableTimesBaseUrl = '/api/internal/company_daily_available_times'
export default async function getCompanyDailyAvailableTimes({
  companyId,
}: {
  companyId: bigint
}): Promise<CompanyDailyAvailableTimes> {
  const url = `${companyDailyAvailableTimesBaseUrl}?company_id=${companyId}`
  const { data } = await getApi(url)
  return camelCaseKeysDeep(data) as CompanyDailyAvailableTimes
}

interface UpdateCompanyDailyAvailableTimesParams {
  ticketId: bigint
  value: number
}

export async function updateCompanyDailyAvailableTimes({
  ticketId,
  value,
}: UpdateCompanyDailyAvailableTimesParams): Promise<{ data: Record<string, unknown> }> {
  const url = `${companyDailyAvailableTimesBaseUrl}/${ticketId}`
  return await putApi(url, { todays_weight: value / 60.0 })
}
