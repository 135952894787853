declare global {
  interface Window {
    intercomSettings: object
    Intercom: (command: string, options?: object) => void
  }
}

export function moveIntercom() {
  window.intercomSettings = {
    ...window.intercomSettings,
    alignment: 'right',
    horizontal_padding: 180,
    vertical_padding: 20,
  }
}
