import { FormEvent, ReactElement, useState } from 'react'
import Button from 'components/core/button'
import TextInput from 'lib/components/text-input/text-input'
import { AuthRoute, useUserContext } from 'providers/user-provider'
import AuthBranding from './auth-branding'
import { updatePassword } from 'lib/api/auth/auth'
import { toast } from 'lib/components/toast/toast'

export default function SignUp(): ReactElement {
  const [invalidFields, setInvalidFields] = useState<string[]>([])
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const { auth } = useUserContext()
  const queryParameters = new URLSearchParams(window.location.search)

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const invalidFields = getInvalidFields(password, confirmPassword)
    setInvalidFields(invalidFields)
    if (invalidFields.length === 0) {
      // Hit the reset password endpoint, and redirect to login, ideally with a toast notification.
      try {
        await updatePassword(password, queryParameters.get('change_password_token'))
        removeTokenFromUrl()

        toast.success('Password changed successfully - please log in')
        auth.setAuthRoute(AuthRoute.LOGIN)
      } catch (error) {
        console.error(error)
        toast.error('Something went wrong - please try again.')
      }
      return
    }
  }

  function removeTokenFromUrl() {
    queryParameters.delete('change_password_token')
    window.history.replaceState({}, document.title, window.location.pathname)
  }

  return (
    <AuthBranding>
      <form onSubmit={handleSubmit} data-testid="reset-password">
        <h1 className="tw-m-0 tw-text-center tw-text-base">Please enter your new password</h1>
        <div className="tw-flex">
          <TextInput
            error={invalidFields.includes('password')}
            name="user[password]"
            onChange={(event) => setPassword(event.target.value)}
            placeholder="Password"
            type="password"
            autoComplete="new-password"
          />
        </div>
        <div className="tw-flex">
          <TextInput
            error={invalidFields.includes('confirmPassword')}
            name="user[confirm_password]"
            onChange={(event) => setConfirmPassword(event.target.value)}
            placeholder="Confirm password"
            type="password"
            autoComplete="off"
          />
        </div>
        <div className="tw-text-center">
          <Button color="purple" type="submit">
            Reset Password
          </Button>
          <p>
            Already know your password?{' '}
            <a
              href="#"
              data-testid="auth-login-link"
              onClick={(e) => {
                e.preventDefault()
                removeTokenFromUrl()
                auth.setAuthRoute(AuthRoute.LOGIN)
              }}
            >
              Log in here
            </a>
          </p>
        </div>
      </form>
    </AuthBranding>
  )
}

function getInvalidFields(password: string, confirmPassword: string): string[] {
  const invalidFields = []
  if (password.length < 8) invalidFields.push('password')
  if (confirmPassword.length < 8 || password !== confirmPassword) invalidFields.push('confirmPassword')
  return invalidFields
}
