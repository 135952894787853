import { CurrentUser } from 'interfaces/app'
import { authenticateCollaborator, createCollaborator } from 'lib/api/auth/auth'
import * as UIStorage from '../storage/ui-storage'

interface JwtPayload {
  aud: string
  email: string
  first_name: string
  iat: number
  iss: string
  last_name: string
  sub: number
  flags?: {
    merging?: boolean
  }
}

export interface NewUser {
  email: string
  firstName: string
  lastName: string
  password: string
}

const STORAGE_KEY = 'collaborator-token'

export default class CollaboratorAuthenticator {
  readonly #isStorable = UIStorage.getIsStorable()
  token: string = null
  user: CurrentUser = null

  constructor() {
    if (this.#isStorable) {
      this.token = UIStorage.read(STORAGE_KEY)
      this.#setUserFromToken()
    }
  }

  async authenticate(email: string, password: string) {
    if (!this.#isStorable) {
      throw new Error('CollaboratorAuthenticator: LocalStorage is unavailable')
    }
    try {
      const { auth } = await authenticateCollaborator(email, password)
      this.#setToken(auth.token)
    } catch (error) {
      this.#setToken(null)
      throw error
    }
  }

  async create(newUser: NewUser) {
    try {
      const { auth } = await createCollaborator(newUser.email, newUser.firstName, newUser.lastName, newUser.password)
      this.#setToken(auth.token)
    } catch (error) {
      this.#setToken(null)
      throw error
    }
  }

  #setToken(token: string | null) {
    if (this.#isStorable) {
      this.token = token
      this.#setUserFromToken()
      if (token) {
        UIStorage.write(STORAGE_KEY, token)
      } else {
        UIStorage.remove(STORAGE_KEY)
      }
    }
  }

  #setUserFromToken() {
    if (this.token) {
      const parts = this.token.split('.')
      const payload: JwtPayload = JSON.parse(atob(parts[1]))
      this.user = {
        canChooseDesigners: false,
        collaborativeReviewEnabled: true,
        companyId: null,
        email: payload.email,
        fullName: `${payload.first_name} ${payload.last_name}`,
        genAiInpainting: false,
        graphicsOnlyCustomer: false,
        id: payload.sub,
        isCompanyAdmin: false,
        isDPAdmin: false,
        merging: !!payload.flags?.merging,
        newRevisionsExperienceEnabled: true,
        preferredFormats: [],
        ticketFeedback: 0,
      } as CurrentUser
    } else {
      this.user = null
    }
  }
}
