import { useCompanyTaggingContext } from 'providers/company-tagging-provider'

interface TagCountProps {
  tagId: number
}

export default function TagCount({ tagId }: TagCountProps) {
  const { tagCount } = useCompanyTaggingContext()

  if (tagCount > 0) {
    return (
      <>
        <a href={`/requests?company_tags_id_in_any=${tagId}`}>{tagCount} request(s)</a>{' '}
        <span className="tw-text-neutral-500">using the tag</span>
      </>
    )
  } else {
    return <>{tagCount} requests using the tag</>
  }
}
