import { ChangeEvent, Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'components/core/button'
import ConfirmationModal from 'components/elements/confirmation-modal'

import { GenAiDSImage, GenAiRequest, GenAiRequestStatus, GenAiRequestTypes } from 'lib/api/gen-ai/gen-ai-requests'
import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import { BetaBadge } from 'lib/components/badge/badge'
import { Card } from 'lib/components/card/card'
import ExternalLink from 'lib/components/links/external-link'
import Modal from 'lib/components/modals/modal'
import Textarea, { TextCounter } from 'lib/components/textarea/textarea'

import { useGenAiContext } from '../../providers/gen-ai-provider'
import { AnimatedLoadingScreen } from '../../../requests/empty-screens'
import { useMediaContext } from '../../media/media-provider'

interface ConfirmationMessage {
  confirmAction: () => void | Promise<void>
  message: string
  title: string
  visible: boolean
}

interface ModalProps {
  open: boolean
  request: GenAiRequest
  setOpen: Dispatch<SetStateAction<boolean>>
}

enum ModalState {
  Progress = 'progress',
  Results = 'results',
  Error = 'error',
}

const classNames = {
  buttons: {
    secondary:
      'tw-bg-peppercorn-50 hover:tw-bg-peppercorn-100 tw-mt-3 tw-inline-flex tw-w-full tw-justify-center tw-px-4 tw-py-2 tw-border-0 sm:tw-mt-0 sm:tw-w-auto sm:tw-text-sm',
    wrapper: 'tw-flex tw-justify-end tw-gap-2 tw-mt-4',
  },
  progress: {
    container: 'tw-text-center',
    img: 'tw-mx-auto',
    text: 'tw-mx-auto',
  },
  title: 'tw-text-lg tw-font-bold tw-flex tw-items-center tw-gap-1',
}

const emptyConfirmationMessage: ConfirmationMessage = Object.freeze({
  confirmAction: () => null,
  message: '',
  title: '',
  visible: false,
})

const PROMPT_PLACEHOLDER = 'Begin your prompt here about what you want to create'

function Subheader() {
  return (
    <p>
      Immediately generate images to visualize your ideas for your designer.{' '}
      <ExternalLink href="https://help.designpickle.com/en/articles/8304523">Learn More</ExternalLink>
    </p>
  )
}

export default function CreateAssistResultsModal({ open, request, setOpen }: ModalProps): ReactElement {
  const [confirmationMessage, setConfirmationMessage] = useState<ConfirmationMessage>(emptyConfirmationMessage)
  const [modalState, setModalState] = useState<ModalState>(ModalState.Progress)
  const [newPrompt, setNewPrompt] = useState('')
  const [selection, setSelection] = useState<GenAiDSImage[]>([])

  const { removeAndCancel, removeAndDismiss, removeAndAccept, removeAndRetry } = useGenAiContext()
  const { addFiles } = useMediaContext()
  const { createCreateAssist } = useGenAiContext()

  const images = useMemo(() => {
    return (request?.response?.ds?.results as GenAiDSImage[]) || []
  }, [request?.response?.ds?.results])

  function handleAddToRequest() {
    const newFiles = selection.map(
      (image) =>
        ({
          previewUrl: image.url,
          id: image.id,
          name: imageUrlToName(image.url),
        }) as unknown as TicketFile,
    )

    addFiles(request.id, newFiles)
    reset()
    removeAndAccept(request.id)
    setOpen(false)
  }

  function handleAfterConfirmationClose() {
    setConfirmationMessage(emptyConfirmationMessage)
  }

  function handleCancel() {
    removeAndCancel(request.id)
    setOpen(false)
  }

  function handleCancelConfirmation() {
    setConfirmationMessage((previous) => ({ ...previous, visible: false }))
  }

  function handleConfirmDelete() {
    removeAndDismiss(request.id)
    setOpen(false)
    reset()
  }

  async function handleConfirmRegenerate(): Promise<void> {
    try {
      await createCreateAssist(newPrompt, request.requestType, imageForRegenerate(request))
      removeAndDismiss(request.id)
    } catch (error) {
      console.error('There was an error when attempting to regenerate this prompt.', error)
    }
  }

  function handleDelete() {
    setConfirmationMessage({
      confirmAction: handleConfirmDelete,
      message: 'Results will be removed, items not added to the request will be lost.',
      title: 'Delete Results',
      visible: true,
    })
  }

  function handlePromptChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setNewPrompt(event.currentTarget.value)
  }

  function handleRegenerate() {
    setConfirmationMessage({
      confirmAction: handleConfirmRegenerate,
      message: 'Regenerate will refresh results, items not added to the request will be lost.',
      title: 'Regenerate Results',
      visible: true,
    })
  }

  function handleRetry() {
    removeAndRetry(request)
    setOpen(false)
  }

  function imageForRegenerate(request: GenAiRequest) {
    return request.request.original_image || request.request.filestack_handle
  }

  function imageUrlToName(url: string): string {
    const urlParts = url.split('/')
    return urlParts[urlParts.length - 1]
  }

  function reset() {
    setSelection([])
  }

  function toggleImage(image: GenAiDSImage) {
    if (selection.includes(image)) {
      setSelection(selection.filter((selectedImage) => selectedImage !== image))
    } else {
      setSelection([...selection, image])
    }
  }

  useEffect(() => {
    if (request) {
      setNewPrompt(request.message)
      if (request.status === GenAiRequestStatus.Completed) {
        setModalState(ModalState.Results)
      } else if (request.status === GenAiRequestStatus.Error) {
        setModalState(ModalState.Error)
      }
    }
  }, [request])

  return (
    <Modal open={open} setOpen={setOpen} size="lg">
      <Modal.Header>
        <span className={classNames.title}>
          <FontAwesomeIcon icon={['far', 'magic']} />
          <span>
            Create Assist <BetaBadge />
          </span>
        </span>
      </Modal.Header>

      <Modal.Body setOpen={setOpen} closeButton>
        {modalState === ModalState.Progress && (
          <div className={classNames.progress.container}>
            <AnimatedLoadingScreen />
            <div style={{ width: '440px' }} className={classNames.progress.text}>
              <p>
                <strong>Your generative results are brining...</strong>
              </p>
              <p>
                You can click the “X” button on the top right to minimize this window. Your Create Assist imagery
                generation will run in the background while you continue with your request.
              </p>
            </div>
          </div>
        )}
        {modalState === ModalState.Error && (
          <>
            <Subheader />
            <div className="tw-my-20 tw-text-center">
              <img
                alt="Crying Pickle Error"
                className="tw-mx-auto tw-mb-6"
                height="333"
                src="/images/states/error-crying-pickle.svg"
              />
              <h2 className="tw-text-lg tw-text-neutral-500">Oh no!</h2>
              <p className="tw-mx-auto tw-max-w-md">
                Looks like something failed, click &quot;Retry&quot; to load again!
              </p>
            </div>
          </>
        )}
        {modalState === ModalState.Results && (
          <>
            <ConfirmationModal
              afterLeave={handleAfterConfirmationClose}
              title={confirmationMessage.title}
              message={confirmationMessage.message}
              confirmBtnText="Confirm"
              confirmAction={confirmationMessage.confirmAction}
              cancelBtnText="Cancel"
              cancelAction={handleCancelConfirmation}
              visible={confirmationMessage.visible}
            />
            <Subheader />
            <h5>Your prompt</h5>
            <div className="tw-mb-6 tw-mt-1.5 tw-flex tw-flex-row">
              {request.requestType === GenAiRequestTypes.IMG2IMG && (
                <img
                  className="tw-mr-2 tw-h-24 tw-w-auto tw-self-center"
                  alt="Prompt Image"
                  src={request.request.original_image}
                />
              )}
              <Textarea onChange={handlePromptChange} placeholder={PROMPT_PLACEHOLDER} value={newPrompt} />
              <div className="tw-my-2.5 tw-ml-2 tw-flex tw-flex-col tw-justify-between">
                <div className="tw-flex-grow tw-text-right">
                  <Button color="neutralGray" onClick={handleRegenerate}>
                    Regenerate
                  </Button>
                </div>
                <div className="tw-flex-none">
                  <TextCounter className="tw-text-left" value={newPrompt} />
                </div>
              </div>
            </div>
            <h4>Add results to your request or generate more</h4>
            <p>
              Select image(s) to help your designer understand your vision (or start over as many times as {`you'd `}
              like)!{' '}
            </p>
            <div className="tw-grid tw-max-h-96 tw-grid-cols-2 tw-items-center tw-justify-items-center tw-gap-6 tw-overflow-y-scroll tw-bg-neutral-50 tw-py-6">
              {images.map((image, index) => (
                <Card
                  size="xl"
                  key={image.id}
                  onClick={() => toggleImage(image)}
                  isSelected={selection.includes(image)}
                >
                  <Card.Body className="tw-px-12">
                    <img key={image.id} src={image.url} alt={`Result Image ${index + 1}`} className="tw-w-full" />
                  </Card.Body>
                </Card>
              ))}
            </div>
            <p className="tw-neutral-500 tw-pt-6">
              This content was created with the help of advanced AI technology which is constantly improving. Please
              check for accuracy.
            </p>

            <p className="tw-flex tw-items-center tw-gap-1 tw-pt-6 tw-font-semibold tw-text-skyblue-800">
              <FontAwesomeIcon icon={['far', 'exclamation-circle']} /> Generated images are low resolution and intended
              as creative inspiration for designers, not as final design for digital or print.
            </p>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {modalState === ModalState.Results && (
          <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
            <span className="tw-font-bold">
              {selection.length} of {images.length} items selected
            </span>
            <div className={classNames.buttons.wrapper}>
              <Button color="lightGray" type="button" onClick={handleDelete}>
                Delete results
              </Button>
              <Button color="purple" type="button" onClick={handleAddToRequest} disabled={!selection.length}>
                Add to request
              </Button>
            </div>
          </div>
        )}
        {modalState === ModalState.Progress && (
          <div className={classNames.buttons.wrapper}>
            <Button color="lightGray" type="button" onClick={handleCancel}>
              Cancel generation
            </Button>
          </div>
        )}

        {modalState === ModalState.Error && (
          <div className={classNames.buttons.wrapper}>
            <Button color="purple" type="button" onClick={handleRetry}>
              Retry
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}
