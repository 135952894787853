import { ChangeEvent, FormEvent, ReactElement, useState } from 'react'
import Button from 'components/core/button'
import Checkbox from 'components/core/checkbox'
import TextInput from 'lib/components/text-input/text-input'
import { AuthRoute, useUserContext } from 'providers/user-provider'
import AuthBranding from './auth-branding'

interface NewUser {
  email: string
  firstName: string
  lastName: string
  password: string
}

const defaultUser: NewUser = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
}

export default function SignUp(): ReactElement {
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false)
  const [invalidFields, setInvalidFields] = useState<string[]>([])
  const [user, setUser] = useState<NewUser>(defaultUser)
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const { create, auth, settings } = useUserContext()

  const url = new URL(location.href)
  const inviteeEmail = url.searchParams.get('invitee')
  url.searchParams.delete('invitee')
  window.history.pushState({}, '', url.toString())

  function handleCheck() {
    setAgreeToTerms((previous) => !previous)
  }

  function updateUser(property: string, event: ChangeEvent<HTMLInputElement>) {
    setUser((previous) => ({
      ...previous,
      [property]: event.target.value,
    }))
  }

  async function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const invalidFields = getInvalidFields(user, agreeToTerms, confirmPassword, !!settings?.customBranding)
    setInvalidFields(invalidFields)
    if (invalidFields.length === 0) {
      await create(user)
    }
  }

  return (
    <AuthBranding>
      <form onSubmit={submit} data-testid="auth-signup-form">
        <h1 className="tw-m-0 tw-text-center tw-text-base">First things first. Let us know who you are!</h1>
        <div className="tw-flex tw-space-x-2">
          <div className="tw-flex-1">
            <TextInput
              error={invalidFields.includes('firstName')}
              name="user[firstName]"
              onChange={(event) => updateUser('firstName', event)}
              placeholder="First"
            />
          </div>
          <div className="tw-flex-1">
            <TextInput
              error={invalidFields.includes('lastName')}
              name="user[lastName]"
              onChange={(event) => updateUser('lastName', event)}
              placeholder="Last"
            />
          </div>
        </div>
        <div className="tw-flex">
          <TextInput
            error={invalidFields.includes('email')}
            name="user[email]"
            onChange={(event) => updateUser('email', event)}
            placeholder="Email"
            {...(inviteeEmail ? { defaultValue: inviteeEmail } : {})}
          />
        </div>
        <div className="tw-flex">
          <TextInput
            error={invalidFields.includes('password')}
            name="user[password]"
            onChange={(event) => updateUser('password', event)}
            placeholder="Password"
            type="password"
          />
        </div>
        <div className="tw-flex">
          <TextInput
            error={invalidFields.includes('confirmPassword')}
            name="user[confirm_password]"
            onChange={(event) => setConfirmPassword(event.target.value)}
            placeholder="Confirm password"
            type="password"
          />
        </div>
        {!settings?.customBranding && (
          <div className="tw-py-4">
            <Checkbox checked={agreeToTerms} error={invalidFields.includes('agree')} onChange={handleCheck}>
              I agree to the Design Pickle{' '}
              <a href="https://designpickle.com/terms-conditions/" target="_blank" rel="noreferrer">
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a href="https://designpickle.com/privacy-policy/" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </Checkbox>
          </div>
        )}
        <div className="tw-text-center">
          <Button color="purple" type="submit">
            Sign up
          </Button>
          <p>
            Have a collaborator account?{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                auth.setAuthRoute(AuthRoute.LOGIN)
              }}
              data-testid="auth-login-link"
            >
              Log in here
            </a>
          </p>
        </div>
      </form>
    </AuthBranding>
  )
}

function getInvalidFields(
  user: NewUser,
  agreeToTerms: boolean,
  confirmPassword: string,
  customBranding?: boolean,
): string[] {
  const invalidFields = []
  if (!customBranding && !agreeToTerms) invalidFields.push('agree')
  if (user?.email.length < 6) invalidFields.push('email')
  if (user?.firstName.length < 2) invalidFields.push('firstName')
  if (user?.lastName.length < 2) invalidFields.push('lastName')
  if (user?.password.length < 8) invalidFields.push('password')
  if (confirmPassword.length < 8 || user?.password !== confirmPassword) invalidFields.push('confirmPassword')
  return invalidFields
}
