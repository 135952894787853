import { cn } from 'lib/util/cn'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import * as React from 'react'
import { DayPicker } from 'react-day-picker'

const buttonClasses = 'tw-border-none tw-p-0 tw-bg-transparent'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({ className, ...props }: CalendarProps) {
  return (
    <DayPicker
      className={className}
      classNames={{
        months: 'tw-flex tw-flex-col tw-gap-8 sm:tw-flex-row tw-space-y-4 sm:tw-space-y-0 tw-relative',
        month: 'tw-space-y-4 tw-text-peppercorn-900',
        month_caption: 'tw-flex tw-relative tw-items-center',
        caption_label: 'tw-text-sm tw-font-medium',
        nav: 'tw-flex tw-items-center tw-absolute tw-right-0 tw-left-auto tw-top-0 tw-justify-end tw-z-10',
        button_previous: cn(buttonClasses, 'tw-h-7 tw-w-7 tw-bg-transparent tw-p-0 tw-opacity-50 hover:tw-opacity-100'),
        button_next: cn(buttonClasses, 'tw-h-7 tw-w-7 tw-bg-transparent tw-p-0 tw-opacity-50 hover:tw-opacity-100'),
        month_grid: 'tw-w-full tw-border-collapse tw-space-y-1',
        weekdays: 'tw-flex',
        weekday: 'tw-text-peppercorn-400 tw-rounded-md tw-w-7 tw-font-bold tw-text-xs tw-text-center',
        week: 'tw-flex tw-w-full tw-mt-1',
        day: cn('tw-relative tw-p-0 tw-text-center tw-text-sm focus-within:tw-relative focus-within:tw-z-20'),
        day_button: cn(buttonClasses, 'tw-h-7 tw-w-7 tw-p-0 tw-font-normal hover:tw-bg-cornflower-100 tw-rounded-full'),
        range_start: 'tw-day-range-start tw-bg-cornflower-200 tw-rounded-l-full hover:tw-bg-cornflower-200',
        range_end: 'tw-day-range-end tw-bg-cornflower-200 tw-rounded-r-full hover:tw-bg-cornflower-200',
        selected: 'tw-bg-peppercorn-100',
        outside: 'tw-text-peppercorn-400 tw-opacity-50',
        disabled: 'tw-text-peppercorn-400 tw-opacity-50',
        range_middle: 'tw-bg-peppercorn-100 tw-text-peppercorn-900 tw-bg-cornflower-100',
        hidden: 'tw-invisible',
      }}
      components={{
        Chevron: (props) => {
          if (props.orientation === 'left') {
            return <ChevronLeft {...props} className="tw-h-4 tw-w-4" />
          }
          return <ChevronRight {...props} className="tw-h-4 tw-w-4" />
        },
      }}
      {...props}
    />
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
