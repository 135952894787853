import { CompanyTag } from 'lib/api/company-tags/company-tags'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useAllCompanyTagsContext } from 'providers/all-company-tags-provider'

export default function useSelectedTicketTags(ticket): {
  selectedTags: CompanyTag[]
  setSelectedTags: (tags: Dispatch<SetStateAction<CompanyTag[]>>[]) => void
} {
  const [selectedTags, setSelectedTags] = useState(ticket.tags)
  const { companyTags } = useAllCompanyTagsContext()

  useEffect(() => {
    setSelectedTags((prevSelectedTags) => {
      return prevSelectedTags
        .filter((selectedTag) => companyTags.some((companyTag) => companyTag.id === selectedTag.id))
        .map((selectedTag) => companyTags.find((tag) => tag.id === selectedTag.id))
    })
  }, [companyTags])

  useEffect(() => {
    setSelectedTags(ticket.tags)
  }, [ticket.tags])

  return { selectedTags, setSelectedTags }
}
