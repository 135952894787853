import { useCollaboratorTicketContext } from './collaborator-ticket-provider'
import { useMediaContext } from '../request/media/media-provider'
import { useMemo } from 'react'
import { generateRangeTo } from 'lib/util/range'
import TicketVersionSelectBox from 'lib/components/ticket/ticket-versions-select-box'
import MediaList from '../request/media/media-list'
import OpenButton from '../../elements/open-button'
import MediaUploadButton from '../request/media/media-upload-button'
import { DesignRequestStatus } from 'lib/api/tickets/tickets'

interface LeftSidebarProps {
  showMainContent: boolean
  openMainContent: () => void
}

const classNames = {
  aside:
    'tw-flex-none tw-w-60 tw-relative tw-bg-gray-50 tw-flex tw-flex-col tw-border-r-gray-200 tw-border-0 tw-border-r tw-border-solid',
  header: 'tw-flex-none tw-h-min tw-m-4 tw-mb-0',
  h2: 'tw-text-base tw-mb-1',
  hr: 'tw-border-0 tw-border-t-1 tw-border-neutral-400 tw-mt-4 tw-mb-2',
  container: 'tw-flex-auto tw-overflow-y-scroll',
  footer: 'tw-flex-none tw-h-min tw-p-5 tw-bg-gray-50',
}

export default function LeftSidebar({ showMainContent, openMainContent }: LeftSidebarProps) {
  const { ticket } = useCollaboratorTicketContext()
  const { files, selectedFile, selectedVersion, setSelectedVersion } = useMediaContext()
  const canUploadMedia = ticket.status === DesignRequestStatus.draft || ticket.status === DesignRequestStatus.review

  const versions = useMemo(() => {
    return generateRangeTo(ticket?.lastDeliveredVersion)
  }, [ticket?.lastDeliveredVersion])

  function handleVersionChange(version: number) {
    setSelectedVersion(version)
  }

  return (
    <aside className={classNames.aside}>
      {!!files?.length && (
        <>
          <header className={classNames.header}>
            <h2 className={classNames.h2}>Delivered Assets</h2>
            <TicketVersionSelectBox
              selectedVersion={selectedVersion}
              setSelectedVersion={handleVersionChange}
              versions={versions}
            />
            <hr className={classNames.hr} />
          </header>
          <div className="tw-overflow-y-scroll">
            <MediaList />
          </div>
        </>
      )}
      {canUploadMedia && (
        <div className="tw-h-min tw-flex-none tw-bg-gray-50 tw-p-5" data-testid="media-upload-buttons">
          <p className="tw-mb-2 tw-mt-0 tw-text-sm tw-font-semibold tw-text-neutral-800">
            Need to add additional media?
          </p>
          <MediaUploadButton />
        </div>
      )}
      {!showMainContent && !!selectedFile && <OpenButton onClick={openMainContent} />}
    </aside>
  )
}
