import { ColorInput } from 'lib/components/color-input/color-input'
import { isValidHexColor, generateTailwindPalette } from 'lib/util/color'
import { FormState, UpdateFormState } from './custom-branding'

export function CustomBrandingColorSettings({
  formState,
  updateFormState,
}: {
  formState: FormState
  updateFormState: UpdateFormState
}) {
  const getButtonColor = (color: string) => {
    if (isValidHexColor(color)) {
      const palette = generateTailwindPalette(color)
      return palette['500']
    }
    return '#576EE7' // default color
  }

  const buttonColor = getButtonColor(formState.primary_color.value)

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <div>
        <label htmlFor="primary_color" className="tw-mb-0">
          Primary color
        </label>
      </div>
      <ColorInput
        id="primary_color"
        name="primary_color"
        value={formState.primary_color.value}
        onChange={(color) => updateFormState('primary_color', color)}
        className="tw-w-full"
        error={!!formState.primary_color.error}
      />
      {formState.primary_color.error && <div className="tw-text-flushpink-500">{formState.primary_color.error}</div>}
      <div className="tw-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-p-4">
        <button
          style={{
            backgroundColor: buttonColor,
          }}
          className="tw-pointer-events-none tw-rounded-md tw-border tw-border-none tw-border-transparent tw-bg-transparent tw-px-3.5 tw-py-1.5 tw-text-center tw-font-semibold tw-text-white disabled:tw-opacity-60"
        >
          Preview Button
        </button>
      </div>
    </div>
  )
}
