import { AppSettings, CurrentUser } from 'interfaces/app'
import { ReactElement } from 'react'
import CompanyDropdown from './company-dropdown'
import CompanyDailyAvailabletimesProvider, {
  useCompanyDailyAvailableTimesContext,
} from 'providers/company-daily-available-times-provider'
import TicketsTable from './tickets-table'
import UserProvider from 'providers/user-provider'
import { Company } from 'interfaces/company'

type PartialCompany = Pick<Company, 'id' | 'name'>

interface TimeAllocationsPageProps {
  settings: AppSettings
  user: CurrentUser
  companies: PartialCompany[]
}

const classNames = {
  companyTitle: 'text-2xl font-bold',
}

export default function TimeAllocationsPage({
  user,
  settings,
  companies = [],
}: TimeAllocationsPageProps): ReactElement {
  function Title(): ReactElement {
    return <h1 className="text-2xl font-bold">Time Allocations</h1>
  }

  function Company(): ReactElement {
    const { company } = useCompanyDailyAvailableTimesContext()
    if (!company) return <h1 className={`${classNames.companyTitle}`}>No company selected</h1>

    return <h1 className={`${classNames.companyTitle}`}>{company.name}</h1>
  }

  return (
    <UserProvider settings={settings} user={user}>
      <CompanyDailyAvailabletimesProvider companies={companies}>
        <div style={{ minHeight: '49rem' }}>
          <Title />
          <Company />
          <CompanyDropdown />
          <TicketsTable />
        </div>
      </CompanyDailyAvailabletimesProvider>
    </UserProvider>
  )
}
