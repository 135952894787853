import Pagination from 'components/elements/pagination'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import TicketFileFilters from './ticket-file-filters'
import TicketFileVersionsGrid from './ticket-file-versions-grid'
import { useTicketFileVersionsContext } from './ticket-file-versions-provider'
import FilterOptionsProvider from './filter-options-provider'
import { hasOnlyEmptyValues } from 'lib/object/utils'
import FiltersEmptyState from './filters-empty-state'
import Button from 'components/core/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const classNames = {
  loading: 'tw-m-auto tw-flex tw-pt-96',
  container: 'tw-pt-12',
  multiselectBannerWrapper: 'tw-flex tw-mr-10',
  multiselectBanner: 'tw-flex tw-w-96 tw-bg-skyblue-50 tw-py-4 tw-rounded-full tw-mt-2 tw-w-1/3',
  multiselectText: 'tw-pl-6',
  multiselectClear: 'tw-ml-auto tw-pr-6 tw-cursor-pointer tw-text-cornflower-500',
  totalAssets: 'tw-text-peppercorn-900 tw-font-semibold tw-pl-4 tw-py-1',
  totalAssetsText: 'tw-ml-2 tw-bg-peppercorn-100 tw-py-0.5 tw-px-2.5 tw-rounded-3xl',
  button: 'tw-inline-flex tw-w-full tw-justify-center tw-h-9 tw-mt-auto tw-ml-4 tw-pl-3 sm:tw-w-auto sm:tw-text-sm',
  buttonWrapper: 'tw-self-end tw-w-1/3',
  addToRequestIcon: 'tw-text-base tw-mr-2 tw-mt-0.5',
}

interface TicketFileVersionsContainerProps {
  isGridView: boolean
}

export default function TicketFileVersionsContainer({ isGridView }: TicketFileVersionsContainerProps): JSX.Element {
  const {
    getTicketFileVersionList,
    ticketFileVersionList,
    isLoading,
    metadata,
    filters,
    currentPage,
    selectedList,
    setSelectedList,
  } = useTicketFileVersionsContext()

  if (isLoading) {
    return (
      <div className={classNames.loading}>
        <LoadingScreen size="5x" />
      </div>
    )
  }

  const { total, pageSize } = metadata
  const renderEmptyResults = !isLoading && total === 0 && !hasOnlyEmptyValues(filters)

  function onPageClick(data) {
    getTicketFileVersionList({ page: data.selected + 1 })
  }

  function MultiSelectBanner() {
    const { length } = selectedList
    if (length) {
      return (
        <div className={classNames.multiselectBannerWrapper}>
          <div className={classNames.buttonWrapper}>
            <Button type="button" color="purple" onClick={() => null} className={classNames.button}>
              <FontAwesomeIcon icon={['fal', 'plus-circle']} className={classNames.addToRequestIcon} />
              Add to Request
            </Button>
          </div>
          <div className={classNames.multiselectBanner}>
            <div className={classNames.multiselectText}>{`${length} selected`}</div>
            <span className={classNames.multiselectClear} onClick={() => setSelectedList([])}>
              Clear Selection
            </span>
          </div>
        </div>
      )
    }
    return null
  }

  function TotalAssets() {
    return (
      <div className={classNames.totalAssets}>
        Assets found <span className={classNames.totalAssetsText}>{total}</span>
      </div>
    )
  }

  function TicketFileVersionSection() {
    return (
      <>
        <TotalAssets />
        <MultiSelectBanner />
        {isGridView && <TicketFileVersionsGrid ticketFileVersions={ticketFileVersionList} />}
        <Pagination pageCount={Math.ceil(total / pageSize)} onPageChange={onPageClick} forcePage={currentPage} />
      </>
    )
  }

  return (
    <div className={classNames.container}>
      <FilterOptionsProvider>
        <TicketFileFilters />
      </FilterOptionsProvider>
      {renderEmptyResults ? <FiltersEmptyState /> : <TicketFileVersionSection />}
    </div>
  )
}
