import { Controller } from 'stimulus';
import Sortable from 'sortablejs';

import { commitStateIntoStore, request, ticketsVisibility, initScripts, highlightSelected } from '../helpers/queue_helper';

export default class extends Controller {
  static targets = [
    'atmList', 'middleBlock', 'rightBlock', 'loading', 'detailsContainer',
    'detailsLoading', 'conversationLoading', 'emptyResponse'
  ]
  static values = {
    url: String,
    showUrl: String,
    positionUrl: String,
    tickets: Array
  }

  async connect() {
    this.emptyResponseTarget.hidden = true;
    const { tickets, todays_weight } = await request(this.urlValue)

    ticketsVisibility(this, tickets, todays_weight)
  }

  loadTicketConversation(ticketId) {
    $.ajax({
      url: `${this.showUrlValue}/${ticketId}?block=conversations`,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
      }
    }).then(data => {
      this.conversationLoadingTarget.hidden = true;
      this.renderConversationsBlock(data)
    })
  }

  loadDetails(ticketId) {
    $.ajax({
      url: `${this.showUrlValue}/${ticketId}?block=details`,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
      }
    }).then(data => {
      this.detailsLoadingTarget.hidden = true;
      this.rightBlockTarget.hidden = false;
      this.renderDetailsBlock(data);
    })
  }

  renderConversationsBlock(html) {
    this.middleBlockTarget.innerHTML = html
    this.middleBlockTarget.hidden = false

    initScripts(this.middleBlockTarget, html)
  }

  renderDetailsBlock(html) {
    this.rightBlockTarget.innerHTML = html
    this.rightBlockTarget.hidden = false
    this.detailsContainerTarget.hidden = true

    initScripts(this.rightBlockTarget, html)
  }

  listItemHtml({queue_position, position, designer, subject, short_subject, todays_weight, revision, id}) {
    const revisionHtml = revision ?
      "<div data-toggle='tooltip' data-title='Revision' class='admin-ticket-revision'><i class='fas fa-redo'></i></div>" :
      ''
    const html =
      `<li data-id='${queue_position.id}'>` +
        "<div class='atm-item'>" +
          `<div class='atm-position'>${position}</div>` +
          `<div class='handle'><img src='${designer.photo_url}' width='48' height='48' /></div>` +
          `<div class='atm-item-name' title='${subject}' data-id='${id}' data-action='click->admin--teams#renderTicketDetails'>` +
            `${short_subject}` +
          '</div>' +
          `<div class='atm-weight'>${todays_weight}</div>` +
          `<div class='atm-revision'>${revisionHtml}</div>` +
        '</div>'+
      '</li>'

    return html
  }

  renderTickets(tickets) {
    const atmSortable = document.getElementById('atm-sortable');

    this.sortable = new Sortable(atmSortable, {
      ...this.defaultOptions,
      ...this.options,
      onUpdate: (e) => {
        commitStateIntoStore(this.positionUrlValue)
      },
    })

    this.atmListTarget.innerHTML = tickets.map(ticket => this.listItemHtml(ticket));
    this.loadingTarget.hidden = true;
  }

  renderTicketDetails({ target }) {
    const ticketId = target.dataset.id;

    this.detailsContainerTarget.hidden = false;
    this.detailsLoadingTarget.hidden = false;
    this.conversationLoadingTarget.hidden = false;
    this.rightBlockTarget.hidden = true;

    highlightSelected(target)

    this.loadTicketConversation(ticketId)
    this.loadDetails(ticketId)
  }

  updateTicketDetails() {
    $.unblockUI()
    this.fetchDetailsBlock(this.ticketId)
    $('html, body').animate({ scrollTop: 0 }, 'slow')
  }

  updateTicketConversations() {
    $.unblockUI()
    this.fetchConversationsBlock(this.ticketId)
    $('html, body').animate({ scrollTop: 0 }, 'slow')
  }
}
