import { ReactElement, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { AdminTicketState } from 'lib/api/admin/tickets/admin-tickets'
import { AdminTicketListItem, getTicketsForCurrentUser } from 'lib/api/admin/tickets/admin-tickets'
import { asDays } from 'lib/util/date'

interface AdminTicketListContextValue {
  cumulativeDesignTime: number
  ticketsInQueue: AdminTicketListItem[]
  ticketsInProgress: AdminTicketListItem[]
  ticketsInReview: AdminTicketListItem[]
  ticketsInClarification: AdminTicketListItem[]
}

interface AdminTicketListProviderProps {
  children: ReactNode
}

interface TicketPartition {
  queue: AdminTicketListItem[]
  review: AdminTicketListItem[]
  wip: AdminTicketListItem[]
  clarification: AdminTicketListItem[]
}

const AdminTicketListContext = createContext({})

export default function AdminTicketListProvider({ children }: AdminTicketListProviderProps): ReactElement {
  const [ticketsInQueue, setTicketsInQueue] = useState<AdminTicketListItem[]>([])
  const [ticketsInProgress, setTicketsInProgress] = useState<AdminTicketListItem[]>([])
  const [ticketsInReview, setTicketsInReview] = useState<AdminTicketListItem[]>([])
  const [ticketsInClarification, setTicketsInClarification] = useState<AdminTicketListItem[]>([])

  const cumulativeDesignTime = useMemo(() => {
    return ticketsInQueue.reduce((acc: number, ticket) => {
      return acc + (ticket.designTime || 0)
    }, 0)
  }, [ticketsInQueue])

  useEffect(() => {
    const ticketSorter = (a: AdminTicketListItem, b: AdminTicketListItem) => {
      if (a.position < b.position) return -1
      if (a.position > b.position) return 1

      if (a.submittedAt < b.submittedAt) return -1
      if (a.submittedAt > b.submittedAt) return 1

      if (a.state === AdminTicketState.inProgress && b.state !== AdminTicketState.inProgress) return -1
      if (a.state !== AdminTicketState.inProgress && b.state === AdminTicketState.inProgress) return 1

      return 0
    }

    getTicketsForCurrentUser().then((response) => {
      const partitionedTickets = response.sort(ticketSorter).reduce(partitionAccumulator, {
        queue: [],
        review: [],
        wip: [],
        clarification: [],
      })
      setTicketsInQueue(partitionedTickets?.queue || [])
      setTicketsInReview(partitionedTickets?.review || [])
      setTicketsInProgress(partitionedTickets?.wip || [])
      setTicketsInClarification(partitionedTickets?.clarification || [])
    })
  }, [])

  const context: AdminTicketListContextValue = {
    ticketsInQueue,
    ticketsInReview,
    ticketsInProgress,
    ticketsInClarification,
    cumulativeDesignTime,
  }

  return <AdminTicketListContext.Provider value={context}>{children}</AdminTicketListContext.Provider>
}

function partitionAccumulator(acc: TicketPartition, ticket: AdminTicketListItem): TicketPartition {
  if (ticket.state === AdminTicketState.qualityReview) {
    acc.review.push(ticket)
    return acc
  }

  if (ticket.state === AdminTicketState.customerClarification) {
    acc.clarification.push(ticket)
    return acc
  }

  if (ticket.state === AdminTicketState.incompleteNeedsMoreTime && asDays(ticket.stateChangedAt) === 0) {
    acc.wip.push(ticket)
    return acc
  }

  if (ticket.designTime > 0) {
    acc.queue.push(ticket)
    return acc
  }

  return acc
}

export function useAdminTicketListContext(): AdminTicketListContextValue {
  return useContext(AdminTicketListContext) as AdminTicketListContextValue
}
