import { isStatusActive, isStatusCompleted, isStatusDraft } from 'lib/ticket/utils'
import Button from 'components/core/button'
import { MenuButtonAction } from 'providers/confirmation-modal-provider'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import { useState } from 'react'
import BulkManageTagsModal from 'components/elements/company-tagging/bulk-manage-tags-modal'
import { TaggableTag } from 'lib/api/company-tags/company-tags'
import { BulkActionId } from 'providers/single-queue-provider'

interface BulkActionsButtonsProps {
  status: string
  bulkActionIds: Array<BulkActionId>
  menuActionBtn: MenuButtonAction
  selectedTags?: TaggableTag[]
  onTagUpdate?: () => void
  editTags?: boolean
}

export default function BulkActionsButtons({
  status,
  bulkActionIds,
  menuActionBtn,
  selectedTags,
  onTagUpdate,
  editTags = true,
}: BulkActionsButtonsProps): JSX.Element {
  const [isManageTagsModalOpen, setIsManagedTagsModalOpen] = useState(false)
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const bulkRequestActionUrl = window.Routes.apiInternalRequestUrl('bulk')
  const markArchivedTicketUrl = window.Routes.apiInternalMarkArchivedUrl()
  const cancelTicketUrl = window.Routes.cancelApiInternalTicket('bulk')
  const bulkActionDataWithTicketIds = { tickets: bulkActionIds.map((bulkActionId) => bulkActionId.ticketId) }
  const bulkActionDataWithRequestIds = { tickets: bulkActionIds.map((bulkActionId) => bulkActionId.requestId) }
  const bulkActionArchive = {
    ticket_ids: bulkActionIds.map((bulkActionId) => bulkActionId.ticketId),
    update_action: 'mark_archived',
  }
  const cancelModalData = {
    title: 'Cancel Request?',
    message: `Are you sure you want to cancel all selected requests?`,
    confirmBtnText: 'Yes, Cancel',
    confirmAction: () => {
      menuActionBtn(cancelTicketUrl, bulkActionDataWithTicketIds, 'patch')
    },
  }
  const deleteDraftModalData = {
    title: 'Are you sure?',
    message: `Drafts will be deleted permanently and cannot be undone`,
    confirmBtnText: 'Yes, delete',
    confirmAction: () => {
      menuActionBtn(bulkRequestActionUrl, bulkActionDataWithRequestIds, 'DELETE')
    },
  }

  return (
    <>
      {isFeatureFlagEnabled('company_tags') && editTags && (
        <div className="tw-mr-2">
          <Button type={'button'} color={'lightGray'} size={'cut'} onClick={() => setIsManagedTagsModalOpen(true)}>
            <i className="fas fa-pencil" />
            <span className="tw-pl-1.5">Add or Remove Tags</span>
          </Button>
          <BulkManageTagsModal
            taggableIds={bulkActionIds.map((bulkActionId) => Number(bulkActionId.ticketId))}
            setIsOpen={setIsManagedTagsModalOpen}
            taggableType="Ticket"
            isOpen={isManageTagsModalOpen}
            onSubmit={onTagUpdate}
            selectedTags={selectedTags}
          />
        </div>
      )}
      {isStatusActive(status) && (
        <Button
          type={'button'}
          color={'lightGray'}
          size={'cut'}
          onClick={() => menuActionBtn(cancelTicketUrl, bulkActionDataWithTicketIds, 'PATCH', cancelModalData)}
        >
          <i className="fas fa-ban"></i>
          <span className="tw-pl-1.5">Cancel</span>
        </Button>
      )}
      {isStatusDraft(status) && (
        <Button
          type={'button'}
          color={'lightGray'}
          size={'cut'}
          onClick={() =>
            menuActionBtn(bulkRequestActionUrl, bulkActionDataWithRequestIds, 'DELETE', deleteDraftModalData)
          }
        >
          <i className="fas fa-ban"></i>
          <span className="tw-pl-1.5">Delete</span>
        </Button>
      )}
      {isStatusCompleted(status) && (
        <Button
          type={'button'}
          color={'lightGray'}
          size={'cut'}
          onClick={() => {
            menuActionBtn(markArchivedTicketUrl, bulkActionArchive, 'PATCH')
          }}
        >
          <i className="far fa-check"></i>
          <span className="tw-pl-1.5">Archive</span>
        </Button>
      )}
    </>
  )
}
