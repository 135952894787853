import AdminFilters from 'components/elements/admin-filters'
import { Apm } from 'interfaces/apm'
import { Designer } from 'interfaces/designer'
import { Team } from 'interfaces/team'
import React from 'react'
import {
  ApmsFilter,
  CompanyNameFilter,
  CompanyStatusFilter,
  DesignersFilter,
  TeamsFilter,
  UnassignedFilter,
} from '../shared/subscriptions-filters'

interface ProSubscriptionsFiltersProps {
  apms: Apm[]
  companyStatusOptions: string[]
  designers: Designer[]
  fetchSubscriptions: () => void
  filters: ProFilters
  setFilters: (Filters) => void
  teams: Team[]
}

// Filter names are ransack matchers in camelCase
export interface ProFilters {
  backupDesignerIdEq: number
  companyNameCont: string
  withDesignerId: number
  designerUnassigned: boolean
  qrTeamApmIdEq: number
  qrTeamIdEq: number
  companyStatusEq: string
}

export const emptyProFilters: ProFilters = {
  backupDesignerIdEq: null,
  companyNameCont: '',
  withDesignerId: null,
  designerUnassigned: false,
  qrTeamApmIdEq: null,
  qrTeamIdEq: null,
  companyStatusEq: '',
}

export default function ProSubscriptionsFilters(props: ProSubscriptionsFiltersProps): JSX.Element {
  return (
    <AdminFilters
      emptyFilters={emptyProFilters}
      filters={props.filters}
      setFilters={props.setFilters}
      handleSubmit={props.fetchSubscriptions}
    >
      <CompanyNameFilter filters={props.filters} setFilters={props.setFilters} />
      <CompanyStatusFilter
        companyStatusOptions={props.companyStatusOptions}
        filters={props.filters}
        setFilters={props.setFilters}
      />
      <ApmsFilter apms={props.apms} filters={props.filters} filterKey="designerApmIdEq" setFilters={props.setFilters} />
      <TeamsFilter apms={props.apms} teams={props.teams} filters={props.filters} setFilters={props.setFilters} />
      <DesignersFilter
        apms={props.apms}
        designers={props.designers}
        filterKey="withDesignerId"
        filters={props.filters}
        label="Designer"
        setFilters={props.setFilters}
        teams={props.teams}
      />
      <DesignersFilter
        apms={props.apms}
        designers={props.designers}
        filterKey="backupDesignerIdEq"
        filters={props.filters}
        label="Backup Designer"
        setFilters={props.setFilters}
        teams={props.teams}
      />
      <UnassignedFilter filterKey="designerUnassigned" filters={props.filters} setFilters={props.setFilters} />
    </AdminFilters>
  )
}
