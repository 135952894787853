import { DesignRequestStatus, EditMode, CollaboratorEditMode } from 'lib/api/tickets/tickets'

export default function getCollaboratorEditMode(status: DesignRequestStatus): CollaboratorEditMode {
  switch (status) {
    case DesignRequestStatus.draft:
    case DesignRequestStatus.review:
      return EditMode.revise
    case DesignRequestStatus.attention:
    case DesignRequestStatus.onDeck:
    case DesignRequestStatus.preview:
    case DesignRequestStatus.wipOnDeck:
    case DesignRequestStatus.inProgress:
      return EditMode.processing
    case DesignRequestStatus.completed:
    case DesignRequestStatus.canceled:
    case DesignRequestStatus.archived:
    default:
      return EditMode.complete
  }
}
