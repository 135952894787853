import { ReactElement, useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import SubscriptionsList from '../shared/subscriptions-list'
import ProSubscriptionsItem from './pro-subscriptions-item'
import Pagination from 'components/elements/pagination'
import { hasOnlyFalsyValues, snakeCaseKeys } from 'lib/object/utils'
import { Dpu } from 'interfaces/dpu'
import ProSubscriptionsFilters, { emptyProFilters, ProFilters } from '../pro/pro-subscriptions-filters'
import { getInitialFilters } from 'components/elements/admin-filters'
import { fetchAbilities, validateIfUserCanAssignDpu } from 'lib/api/ability/ability'
import { Designer } from 'interfaces/designer'
import { Toaster, toast } from 'lib/components/toast/toast'

function ProSubscriptionsPageContent() {
  const initialFilters = getInitialFilters(emptyProFilters) as ProFilters
  const [subscriptions, setSubscriptions] = useState([])
  const [apms, setApms] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [filters, setFilters] = useState<ProFilters>(initialFilters)
  const [designers, setDesigners] = useState([])
  const [teams, setTeams] = useState([])
  const [companyStatusOptions, setCompanyStatusOptions] = useState([])

  const oops = useCallback(() => toast.error('Oops, something went wrong.'), [])

  useEffect(() => {
    if (hasOnlyFalsyValues(filters)) {
      fetchSubscriptions().catch(oops)
    }
    // TODO: add memoization to have more reliable dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    async function fetchApms() {
      const { data } = await axios.get(window.Routes.apiInternalAdminApmsUrl())
      setApms(data)
    }

    async function fetchCompanyStatusOptions() {
      const { data } = await axios.get(window.Routes.apiInternalCompanyStatusIndexUrl())
      setCompanyStatusOptions(data)
    }

    async function fetchDesigners() {
      const { data } = await axios.get(window.Routes.apiInternalDesigners({ scope: 'from_pro_team' }))
      setDesigners(data)
    }

    async function fetchTeams() {
      const { data } = await axios.get(window.Routes.apiInternalTeams({ scope: 'same_day' }))
      setTeams(data)
    }

    fetchSubscriptions().catch(oops)
    fetchDesigners().catch(oops)
    fetchTeams().catch(oops)
    fetchApms().catch(oops)
    fetchCompanyStatusOptions().catch(oops)
    // TODO: add memoization to have more reliable dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchAbilities(['Dpu']).then((data) => {
      setUserCanAssign(validateIfUserCanAssignDpu(data))
    })
  }, [])

  const [userCanAssign, setUserCanAssign] = useState(null)
  const fetchSubscriptions = async (params = {}) => {
    const { data } = await axios.get(
      window.Routes.apiInternalDpusUrl({
        scope: 'pro',
        q: snakeCaseKeys(filters),
        ...params,
      }),
    )

    setSubscriptions(data.dpus)
    setPageCount(Math.ceil(data.count / data.per_page))
  }

  const onDesignerChange = (dpuId, changes) => {
    const subs = subscriptions.map((subscription) => {
      return subscription.id === dpuId ? { ...subscription, ...changes } : subscription
    })

    setSubscriptions(subs)
  }

  const onPageClick = (data) => {
    fetchSubscriptions({ page: data.selected + 1 }).catch(oops)
    window.scrollTo(0, 0)
  }

  function designersFromCompanyApm(apmIds: number[]): Designer[] {
    return apmIds.length > 0 ? designers.filter((designer) => apmIds.includes(designer.apmId)) : designers
  }

  return (
    <div className="tw-flex tw-w-full tw-flex-col md:tw-flex-row">
      <ProSubscriptionsFilters
        companyStatusOptions={companyStatusOptions}
        designers={designers}
        fetchSubscriptions={fetchSubscriptions}
        setFilters={setFilters}
        filters={filters}
        apms={apms}
        teams={teams}
      />
      {subscriptions.length > 0 ? (
        <div className="tw-w-10/12 tw-flex-grow">
          <SubscriptionsList headerOne="Designer" headerTwo="Backup Designer">
            {subscriptions.map((dpu: Dpu) => (
              <ProSubscriptionsItem
                designers={designersFromCompanyApm(dpu.company.apmIds)}
                dpu={dpu}
                key={dpu.id}
                onDesignerChange={onDesignerChange}
                userCanAssign={userCanAssign}
              />
            ))}
          </SubscriptionsList>
          <Pagination pageCount={pageCount} onPageChange={onPageClick} />
        </div>
      ) : (
        <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-text-2xl tw-font-medium">
          No results were found.
        </div>
      )}
    </div>
  )
}

export default function ProSubscriptionsPage(): ReactElement {
  return (
    <>
      <Toaster />
      <ProSubscriptionsPageContent />
    </>
  )
}
