import { PickerOptions } from 'filestack-js'
import { client, PickerOverlay } from 'filestack-react'
import { FilestackFile } from 'lib/util/filestack'
import { displayFilesize } from '../../util/file/file'

const tenMegabytes = 1024 * 1024 * 100

export const defaultFilestackOptions = {
  accept: [
    '.ai',
    '.doc',
    '.docx',
    '.eof',
    '.eot',
    '.eps',
    '.fig',
    '.indd',
    '.indl',
    '.key',
    '.mogrt',
    '.mp4',
    '.otf',
    '.pdf',
    '.pdfx',
    '.ppt',
    '.pptm',
    '.pptx',
    '.prproj',
    '.psd',
    '.rar',
    '.sketch',
    '.ttf',
    '.woff',
    '.xls',
    '.xlsx',
    '.zip',
    'audio/*',
    'image/*',
    'text/*',
    'video/*',
  ],
  exposeOriginalFile: true,
  fromSources: [
    'local_file_system',
    'imagesearch',
    'dropbox',
    'onedrive',
    'box',
    'googledrive',
    'facebook',
    'instagram',
  ],
  storeTo: {
    location: 'S3',
  },
  maxFiles: 50,
}

interface FilePickerProps {
  apiKey: string
  fileSizeLimitInBytes?: number
  onUploadDone: (file: client.PickerFileMetadata) => void
  pickerOptions?: PickerOptions
  onClose: () => void
}

export default function FilestackPicker({
  apiKey,
  onUploadDone,
  onClose,
  pickerOptions = defaultFilestackOptions,
  fileSizeLimitInBytes = tenMegabytes,
}: FilePickerProps) {
  function onFileSelected(file: FilestackFile) {
    if (fileSizeLimitInBytes !== null && file.size >= fileSizeLimitInBytes) {
      throw new Error(
        `The selected file is larger than the ${displayFilesize(
          fileSizeLimitInBytes,
        )} megabytes file size limit and cannot be uploaded. Feel free to give us an external link to this file instead.`,
      )
    }

    if (file.source === 'local_file_system' && file.mimetype && file.mimetype.startsWith('image/') && file.size > 1) {
      const reader = new FileReader()
      reader.onload = () => {
        const image = new Image()
        image.onload = () => {
          if (image.width < 400 || image.height < 400) {
            throw new Error(
              'One or more of your images is low resolution. Please consider uploading a higher resolution version.',
            )
          }
        }

        image.src = reader.result as string
      }
      reader.readAsDataURL(file.originalFile)
    }
  }

  const modifiedPickerOptions = {
    ...pickerOptions,
    onFileSelected,
    onClose,
  }

  return (
    <div className="tw-absolute tw-inset-x-1/2 tw-z-30 tw-w-full">
      <PickerOverlay apikey={apiKey} pickerOptions={modifiedPickerOptions} onUploadDone={onUploadDone} />
    </div>
  )
}
