import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'

import {
  CompanyTag,
  createCompanyTag,
  deleteCompanyTag,
  getCompanyTagCount,
  TaggableTag,
  updateCompanyTag,
} from 'lib/api/company-tags/company-tags'

import { CompanyTagging, TaggableType, updateCompanyTagging } from 'lib/api/company-tagging/company-tagging'
import { useAllCompanyTagsContext } from 'providers/all-company-tags-provider'
import { SearchTicketCompanyTag } from 'interfaces/ticket'
import { toast } from 'lib/components/toast/toast'

export enum ChangeTagAction {
  Delete = 'delete',
  Update = 'update',
  Select = 'select',
  Unselect = 'unselect',
  ClearAll = 'clearAll',
}

export interface CompanyTaggingContextProps {
  children: ReactNode
  onViewChange?: (view: View) => void
  partiallySelectedTags?: TaggableTag[]
  selectedTags: CompanyTag[] | SearchTicketCompanyTag[] | TaggableTag[]
  taggableId?: number
  taggableType?: TaggableType
  updateTaggableState: (tag: CompanyTag | TaggableTag, action: ChangeTagAction, taggableId: number) => void
  warningMessage?: string
}

export enum ViewStates {
  Search = 'Search',
  Edit = 'Edit',
  Delete = 'Delete',
}

export interface View {
  viewState: ViewStates
  tag?: CompanyTag
}

export type CompanyTaggingContextValue = {
  clearSelectedTags: () => void
  companyTags: CompanyTag[]
  createAndSelectCompanyTag: (name: string) => Promise<void>
  currentView: View
  deleteCompanyTag: (tag: CompanyTag) => Promise<void>
  partiallySelectedTags?: TaggableTag[]
  searchResults: CompanyTag[]
  searchTerm: string
  selectCompanyTag: (tag: CompanyTag) => Promise<void>
  selectedTags: CompanyTag[] | SearchTicketCompanyTag[]
  setCurrentView: Dispatch<SetStateAction<View>>
  setSearchTerm: Dispatch<SetStateAction<string>>
  tagCount: number
  unselectCompanyTag: (tag: CompanyTag) => Promise<void>
  updateCompanyTag: (tag: CompanyTag, name: string) => Promise<CompanyTag>
  warningMessage?: string
}

const CompanyTagsContext = createContext<CompanyTaggingContextValue | null>(null)

export function useCompanyTaggingContext(): CompanyTaggingContextValue {
  const context = useContext<CompanyTaggingContextValue>(CompanyTagsContext)
  if (!context) {
    throw new Error('useCompanyTaggingContext must be used within a CompanyTaggingProvider')
  }
  return context
}

export default function CompanyTaggingProvider({
  children,
  onViewChange,
  partiallySelectedTags,
  selectedTags: selectedTagsProp,
  taggableId,
  taggableType,
  updateTaggableState,
  warningMessage,
}: CompanyTaggingContextProps) {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchResults, setSearchResults] = useState<CompanyTag[]>([])
  const [tagCount, setTagCount] = useState<number>(0)
  const [currentView, setCurrentView] = useState<View>({ viewState: ViewStates.Search })
  const { companyTags, setCompanyTags } = useAllCompanyTagsContext()

  const selectedTags = selectedTagsProp?.filter((tag) => companyTags.some((t) => t.id === tag.id))

  function clearSelectedTags() {
    updateTaggableState(null, ChangeTagAction.ClearAll, taggableId)
  }

  async function selectCompanyTag(tag: CompanyTag): Promise<void> {
    if (taggableId && taggableType) {
      try {
        const companyTagging: CompanyTagging = {
          taggableType: taggableType,
          taggableId: taggableId,
          companyTagId: tag.id,
        }
        const selectedCompanyTaggings = selectedTags.map((tag) => ({
          taggableType: taggableType,
          taggableId: taggableId,
          companyTagId: tag.id,
        }))
        await updateCompanyTagging([...selectedCompanyTaggings, companyTagging])
        updateTaggableState(tag, ChangeTagAction.Select, taggableId)
      } catch (error) {
        console.error('Error selecting company tag', error)
        toast.error('Error selecting company tag')
      }
    } else {
      updateTaggableState(tag, ChangeTagAction.Select, taggableId)
    }
  }

  async function unselectCompanyTag(tag: CompanyTag): Promise<void> {
    if (taggableId && taggableType) {
      try {
        const selectedCompanyTaggings = selectedTags
          .filter((selectedTag) => selectedTag.id !== tag.id)
          .map((tag) => ({
            taggableType: taggableType,
            taggableId: taggableId,
            companyTagId: tag.id,
          }))

        await updateCompanyTagging(
          selectedCompanyTaggings.length > 0 ? selectedCompanyTaggings : [{ taggableType, taggableId }],
        )

        updateTaggableState(tag, ChangeTagAction.Unselect, taggableId)
      } catch (error) {
        console.error('Error unselecting company tag', error)
        toast.error('Error unselecting company tag')
      }
    } else {
      updateTaggableState(tag, ChangeTagAction.Unselect, taggableId)
    }
  }

  async function createAndSelectCompanyTag(name: string): Promise<void> {
    try {
      const companyTag = await createCompanyTag(name)
      await selectCompanyTag(companyTag)
      setSearchTerm('')
      setCompanyTags([...companyTags, companyTag])
    } catch (error) {
      console.error('Error creating company tag', error)
      toast.error('Error creating company tag')
    }
  }

  async function saveCompanyTag(tag: CompanyTag, name: string): Promise<CompanyTag> {
    try {
      const updatedTag = await updateCompanyTag(tag.id, name)
      setCompanyTags(companyTags.map((t) => (t.id === updatedTag.id ? updatedTag : t)))
      updateTaggableState(updatedTag, ChangeTagAction.Update, taggableId)
      setCurrentView({ viewState: ViewStates.Search })
      return updatedTag
    } catch (error) {
      console.error('Error updating tag', error)
      toast.error('Error updating tag')
      return tag
    }
  }

  async function destroyCompanyTag(tag: CompanyTag) {
    try {
      await deleteCompanyTag(tag.id)
      setCompanyTags(companyTags.filter((t) => t.id !== tag.id))
      updateTaggableState(tag, ChangeTagAction.Delete, taggableId)
      setCurrentView({ viewState: ViewStates.Search })
    } catch (error) {
      console.error('Error deleting tag', error)
      toast.error('Error deleting tag')
    }
  }

  useEffect(() => {
    onViewChange?.(currentView)
  }, [currentView, onViewChange])

  useEffect(() => {
    async function fetchTagCount() {
      try {
        const count = await getCompanyTagCount(currentView.tag.id, 'Ticket')
        setTagCount(count)
      } catch (e) {
        console.error('Error fetching tag count', e)
      }
    }

    if (currentView.tag) {
      fetchTagCount()
    } else {
      setTagCount(0)
    }
  }, [currentView.tag])

  useEffect(() => {
    setSearchResults(
      searchTerm ? companyTags.filter((tag) => tag.name.toLowerCase().includes(searchTerm.toLowerCase())) : companyTags,
    )
  }, [searchTerm, companyTags])

  const contextValue: CompanyTaggingContextValue = {
    clearSelectedTags,
    companyTags,
    createAndSelectCompanyTag,
    currentView,
    deleteCompanyTag: destroyCompanyTag,
    partiallySelectedTags,
    searchResults,
    searchTerm,
    selectCompanyTag,
    selectedTags,
    setCurrentView,
    setSearchTerm,
    tagCount,
    unselectCompanyTag,
    updateCompanyTag: saveCompanyTag,
    warningMessage,
  }

  return <CompanyTagsContext.Provider value={contextValue}>{children}</CompanyTagsContext.Provider>
}
