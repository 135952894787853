import { Ticket } from 'interfaces/ticket'
import { canBeRated } from 'lib/ticket/utils'
import { useMemo } from 'react'
import { TicketFeedbackValues } from 'interfaces/ticket_feedback'
import { ThumbsButtonProps, ThumbsDownButton, ThumbsUpButton } from 'components/core/thumbs-rating-buttons'
import { useUserContext } from 'providers/user-provider'
import { LoadingScreen } from '../empty-screens'
import { useTicketFeedbackContext } from 'providers/ticket-feedback-provider'

interface RatingModalLinkProps {
  ticket: Ticket
}

function RatingThumbsCell({ ticket }: RatingModalLinkProps) {
  const { user } = useUserContext()
  const { rateTicket, isLoading, ratingModalTicket } = useTicketFeedbackContext()

  const { isDPAdmin, isCompanyAdmin } = user
  const userIsAdmin = isDPAdmin || isCompanyAdmin

  const { overallRating, status } = ticket

  const canRate = useMemo(() => {
    if (isLoading) return false

    const userHasPermissions = userIsAdmin || user.id === ticket.user.id
    const ticketHasNotBeenRated = ![TicketFeedbackValues.ThumbsDown, TicketFeedbackValues.ThumbsUp].includes(
      overallRating,
    )
    const ticketIsInStateToBeRated = canBeRated(status)

    return userHasPermissions && ticketHasNotBeenRated && ticketIsInStateToBeRated
  }, [isLoading, overallRating, status, ticket.user.id, user.id, userIsAdmin])

  const showLoader = isLoading && ratingModalTicket?.id === ticket.id

  if (showLoader)
    return (
      <div className="tw-ml-4">
        <LoadingScreen />
      </div>
    )

  const thumbsUpProps: ThumbsButtonProps = {
    className: 'tw-mr-1.5',
    onClick: () => handleRate({ thumbRating: TicketFeedbackValues.ThumbsUp }),
    disabled: !canRate,
    filled: overallRating === TicketFeedbackValues.ThumbsUp,
  }

  const thumbsDownProps: ThumbsButtonProps = {
    className: 'tw-ml-1.5',
    onClick: () => handleRate({ thumbRating: TicketFeedbackValues.ThumbsDown }),
    disabled: !canRate,
    filled: overallRating === TicketFeedbackValues.ThumbsDown,
  }

  function handleRate({ thumbRating }) {
    if (!canRate) return

    rateTicket({ ticket, thumbRating })
  }

  return (
    <>
      <ThumbsUpButton {...thumbsUpProps} />
      <ThumbsDownButton {...thumbsDownProps} />
    </>
  )
}

interface RatingRowProps {
  ticket: Ticket
}

export default function RatingRow({ ticket }: RatingRowProps) {
  return (
    <div className="tw-flex tw-items-center">
      <div className="tw-align-middle">
        <RatingThumbsCell ticket={ticket} />
      </div>
    </div>
  )
}
