export function isValidUrl(url: string) {
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}

export function isValidFormatUrl(formats: string[], url: string): boolean {
  if (!isValidUrl(url)) {
    return false
  }

  const formatChecks: Record<string, boolean> = {
    figma: url.includes('figma.com'),
    canva: url.includes('canva.com'),
    google_slides: url.includes('docs.google.com/presentation'),
  }

  return formats.some((format) => !!formatChecks[format])
}
