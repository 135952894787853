import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/core/button'
import { CurrentUser } from 'interfaces/app'
import { Drawer } from 'lib/components/drawer/drawer'
import SkillCategories from '../skills/skill-categories'
import SkillsProvider from '../skills/skills-provider'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import SearchSkillsByName from '../skills/search-skills-by-name'
import React from 'react'
import { createTicketAndRedirect } from 'lib/ticket/utils'

interface NewRequestButtonProps {
  user: CurrentUser
  type?: 'button' | 'link'
  text?: string
  children?: React.ReactNode
}

function OpenModalButton({
  setIsOpen,
  type,
  text,
}: {
  setIsOpen: (isOpen: boolean) => void
  type: string
  text: string
}) {
  const linkClassName = `
    tw-outline-none
    tw-border-none
    tw-bg-transparent
    tw-font-semibold
    tw-text-cornflower-500
    hover:tw-text-cornflower-700
  `

  function openModal() {
    setIsOpen(true)
  }

  if (type === 'link') {
    return (
      <button type="button" className={linkClassName} onClick={openModal}>
        {text}
      </button>
    )
  }

  return (
    <Button color="purple" type="button" onClick={openModal}>
      <FontAwesomeIcon icon={['fal', 'plus']} className="tw-mr-2" />
      {text}
    </Button>
  )
}

export default function NewRequestButton({
  user,
  type = 'button',
  text = 'New Request',
  children,
}: NewRequestButtonProps): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  async function handleSelect(skill) {
    try {
      setIsOpen(false)
      setIsLoading(true)

      await createTicketAndRedirect(skill, user.preferredFormats)
    } catch (e) {
      setIsLoading(false)
      console.error(e)
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingScreen size="xl" />
      ) : children ? (
        <div className="tw-cursor-pointer" onClick={() => setIsOpen(true)}>
          {children}
        </div>
      ) : (
        <OpenModalButton type={type} text={text} setIsOpen={setIsOpen} />
      )}
      <SkillsProvider>
        <Drawer isOpen={isOpen} setOpen={setIsOpen} size="xl">
          <Drawer.Header setOpen={setIsOpen}>
            <Drawer.Title className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center">
              <div>
                What are you looking to <span className="tw-font-bold">create</span>?
              </div>
              <SearchSkillsByName />
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>{isOpen && <SkillCategories onSelect={handleSelect} size="lg" showOtherSkills />}</Drawer.Body>
        </Drawer>
      </SkillsProvider>
    </>
  )
}
