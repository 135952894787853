import styles from './text-input.module.scss'
import { useEffect, useRef } from 'react'

export interface TextInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  name: string
  hasError?: boolean
  errorClass?: string
  focusClass?: string
  label?: string
  hideLabel?: boolean
  className?: string
  size?: 'md' | 'sm'
  type?: 'text' | 'number'
  focusInput?: boolean

  [otherProps: string]: unknown
}

export default function TextInput({
  label,
  hideLabel,
  name,
  hasError = false,
  errorClass = 'tw-ring-2 tw-ring-flushpink-500',
  focusClass = 'focus:tw-ring-eggplant focus:tw-ring-2',
  className = '',
  size = 'md',
  type = 'text',
  focusInput = false,
  ...otherProps
}: TextInputProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>()
  const classNames = {
    label: {
      default: 'tw-font-semibold',
      valid: 'tw-text-peppercorn-700',
      hidden: 'tw-absolute tw-left--9999 tw-w-1 tw-h-1 tw-overflow-hidden',
      invalid: 'tw-text-flushpink-500',
    },
    size: {
      md: `tw-py-2 tw-px-4`,
      sm: `tw-py-1 tw-px-4`,
    },
  }

  const labelClasses = `${classNames.label.default} ${hasError ? classNames.label.invalid : classNames.label.valid} ${
    hideLabel ? classNames.label.hidden : ''
  }`

  useEffect(() => {
    if (focusInput) {
      inputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {label && (
        <label htmlFor={name} className={labelClasses} aria-hidden={hideLabel}>
          {label}
        </label>
      )}
      <div>
        <input
          {...otherProps}
          ref={inputRef}
          type={type}
          className={`${styles.input || ''} tw-block tw-appearance-none tw-rounded tw-border tw-border-solid tw-bg-white tw-text-lg tw-shadow-none tw-outline-none ${className} ${classNames.size[size]} ${hasError ? errorClass : focusClass}`}
          id={name}
          name={name}
        />
      </div>
    </div>
  )
}
