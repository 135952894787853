import { Fragment, HTMLAttributes, ReactElement, ReactNode, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDown, ChevronUp, X } from 'lucide-react'
import TextInput from '../text-input/text-input'
import { cn } from 'lib/util/cn'

export type SelectBoxValue = string | number | boolean

export interface SelectBoxOption {
  value: SelectBoxValue
  displayElement: ReactElement
  label?: string
  disabled?: boolean
}

interface BaseSelectBoxProps {
  options: SelectBoxOption[]
  label?: string
  placeholder?: string
  labelClassName?: string
  className?: HTMLAttributes<HTMLDivElement>['className']
  multiple?: boolean
  searchable?: boolean
  button?: ReactNode
  footer?: ReactNode
  dropdownClassName?: HTMLAttributes<HTMLDivElement>['className']
  buttonClassName?: HTMLAttributes<HTMLDivElement>['className']
  disabled?: boolean
}

interface SingleSelectProps extends BaseSelectBoxProps {
  multiple?: false
  handleChange: (selectedValue: SelectBoxValue) => void
  selectedValue: SelectBoxValue
}

interface MultiSelectProps extends BaseSelectBoxProps {
  multiple: true
  label: string
  showLabel?: boolean
  handleChange: (selectedValue: SelectBoxValue[]) => void
  selectedValue: SelectBoxValue[]
}

type SelectBoxProps = SingleSelectProps | MultiSelectProps

const classNames = {
  button: `
    tw-h-10
    tw-w-full
    tw-py-2
    tw-pl-3
    tw-pr-2
    tw-rounded-md
    tw-border-solid
    tw-border-neutral-200
    tw-bg-white
    tw-flex
    tw-justify-between
    tw-items-center
    tw-text-black
    tw-border
    tw-border-neutral-200
    tw-text-sm
    tw-gap-2
    focus:tw-ring
    focus:tw-ring-cornflower-500
  `,
  options: `
    tw-absolute
    tw-my-2
    tw-max-h-60
    tw-w-full
    tw-overflow-auto
    tw-rounded-md
    tw-bg-white
    tw-py-1
    tw-shadow-lg
    tw-border 
    tw-border-solid 
    tw-border-neutral-200
    tw-z-30
    tw-px-1
    tw-min-w-56
    focus:outline-none
  `,
  option: `tw-relative tw-cursor-pointer tw-p-2 tw-list-none tw-rounded`,
  disabled: `tw-text-cornflower-100 tw-border-cornflower-100`,
}

function SelectBox(props: SelectBoxProps) {
  const {
    options,
    label,
    labelClassName = 'tw-text-neutral-500',
    buttonClassName = '',
    className = '',
    multiple = false,
    searchable = false,
    button,
    footer,
    disabled,
  } = props

  const selectedOptions = multiple
    ? options.filter((option) => (props.selectedValue as SelectBoxValue[]).includes(option.value))
    : options.find((option) => option.value === props.selectedValue)

  const hasSelectedOptions = multiple
    ? (props.selectedValue as SelectBoxValue[]).length > 0
    : props.selectedValue !== undefined && props.selectedValue !== null

  const handleOptionChange = (value) => {
    props.handleChange(value)
  }

  const [searchValue, setSearchValue] = useState('')

  return (
    <Listbox disabled={disabled} value={props.selectedValue} onChange={handleOptionChange} multiple={multiple}>
      {({ open }) => (
        <div className={`tw-relative ${className}`}>
          {label && (props as MultiSelectProps)?.showLabel !== false && (
            <Listbox.Label className={cn(labelClassName, disabled && classNames.disabled)}>{label}</Listbox.Label>
          )}
          <Listbox.Button
            className={cn(
              !button && {
                [classNames.button]: !button,
                'tw-ring tw-ring-cornflower-500': open,
                'tw-mt-2': label && (props as MultiSelectProps)?.showLabel !== false,
                'tw-overflow-hidden': multiple,
                'tw-border-cornflower-400 tw-bg-cornflower-100': multiple && hasSelectedOptions,
                [buttonClassName]: true,
              },
              { 'tw-border-none tw-bg-transparent tw-p-0': button },
              { [classNames.disabled]: disabled },
            )}
          >
            {button ? (
              button
            ) : (
              <>
                {!hasSelectedOptions && !!props.placeholder ? <span>{props.placeholder}</span> : null}
                {multiple ? (
                  <span className="tw-min-w-0 tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">
                    {(selectedOptions as SelectBoxOption[])[0]?.label
                      ? `${label}: ${(selectedOptions as SelectBoxOption[])[0]?.label}`
                      : ''}
                    <span className="tw-font-bold">
                      {(selectedOptions as SelectBoxOption[]).length > 1
                        ? ` +${(selectedOptions as SelectBoxOption[]).length - 1}`
                        : ''}
                    </span>
                  </span>
                ) : (
                  <div className="tw-flex tw-flex-wrap tw-gap-1">
                    {selectedOptions && (selectedOptions as SelectBoxOption).displayElement}
                  </div>
                )}
                {open ? (
                  <ChevronUp className="lu-sm tw-flex-shrink-0" />
                ) : (
                  <ChevronDown className="lu-sm tw-flex-shrink-0" />
                )}
                {multiple && hasSelectedOptions && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                      handleOptionChange([])
                      return
                    }}
                    className="tw--mr-2 tw-flex tw-h-10 tw-w-8 tw-flex-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-border-0 tw-border-l tw-border-solid tw-border-cornflower-400 hover:tw-bg-cornflower-200"
                  >
                    <X className="lu-sm tw-inline-block" data-testid="multiselect-clear" />
                  </div>
                )}
              </>
            )}
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className={cn(classNames.options, props.dropdownClassName)}>
              {searchable && (
                <div className="tw-sticky tw--top-1 tw-z-40 tw-bg-white tw-py-1">
                  <TextInput
                    placeholder={'Search...'}
                    value={searchValue}
                    onKeyDown={(e) => {
                      // Required to stop Headless-UI Listbox from hijacking keyboard input
                      e.stopPropagation()
                    }}
                    onChange={(e) => {
                      setSearchValue(e.target.value)
                    }}
                    className="tw-mt-0"
                  />
                </div>
              )}
              {options
                .filter((option) => String(option.label).toLowerCase().includes(searchValue.toLowerCase()))
                .map((option, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `${classNames.option} ${active && 'tw-bg tw-bg-cornflower-100'} ${
                        option?.disabled ? 'tw-text-gray-300' : 'tw-text-gray-900'
                      }`
                    }
                    value={option.value}
                    data-testid={`option-${option.value}`}
                    disabled={option?.disabled}
                  >
                    {({ selected }) => (
                      <div className="tw-flex tw-items-center">
                        {multiple && <input type="checkbox" checked={selected} readOnly className="tw-mr-2" />}
                        {option.displayElement}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              {options.length === 0 && searchValue === '' && (
                <div className="tw-text-peppercorn-500 tw-p-4">No options available</div>
              )}
              {options.length === 0 && searchValue !== '' && (
                <div className="tw-text-peppercorn-500 tw-p-4">No results found for {searchValue}</div>
              )}
              {!!footer && footer}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}

export default SelectBox
