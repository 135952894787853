import { baseRequest, BaseResponse, getApi, Metadata } from '../api'

export interface TicketFileVersion {
  id: number
  name: string
  fileVersion: number
  createdAt: string
  updatedAt: string
  downloadedAt?: string
  originalTicketFileVersionId: number
  source: 'stock_asset' | 'uploaded_by_customer' | 'uploaded_by_creative'
  objectType: 'Ticket' | 'Request'
  fileType: 'TicketFile' | 'RequestFile'
  uploader: Uploader
  file: File
  object: TicketObject
}

interface Uploader {
  id: number
  firstName: string
  lastName: string
}

interface File {
  id: number
  name: string
  createdAt: string
  mimetype?: string
  url: string
  thumbnailUrl?: string
  fileSize?: number
  deletedAt?: string
}

interface TicketObject {
  id: number
  brandId?: number
  companyId: number
}

export interface TicketFileVersionsPayload {
  ticketFileVersions: TicketFileVersion[]
  meta: Metadata
}

interface TicketFileVersionsResponse extends BaseResponse, TicketFileVersionsPayload {}
const ticketFileVersionsBaseUrl = '/api/internal/ticket_file_versions'

export async function getTicketFileVersions(params?: Record<string, string>): Promise<TicketFileVersionsResponse> {
  function getUrl() {
    if (params) {
      return `${ticketFileVersionsBaseUrl}?${new URLSearchParams(params).toString()}`
    }
    return ticketFileVersionsBaseUrl
  }

  const response = await baseRequest(getUrl(), getApi)
  const { data, meta } = response.data

  return {
    error: response.error,
    status: response.status,
    ticketFileVersions: data as unknown as TicketFileVersion[],
    meta: meta as Metadata,
  }
}

export interface TicketSubject {
  id: number
  subject: string
  type: string
}

export interface TicketSubjectsPayload {
  ticketSubjects: TicketSubject[]
}

interface TicketSubjectsResponse extends BaseResponse, TicketSubjectsPayload {}

const ticketSubjectsBaseUrl = '/api/internal/ticket_file_versions/new_tickets_subjects'

export async function getTicketSubjects(): Promise<TicketSubjectsResponse> {
  const response = await baseRequest(ticketSubjectsBaseUrl, getApi)
  const { data } = response

  return {
    error: response.error,
    status: response.status,
    ticketSubjects: data as unknown as TicketSubject[],
  }
}

export interface SubmittedByUser {
  avatarUrl: string
  id: number
  firstName: string
  lastName: string
}

export interface FilterOptions {
  formats: string[]
  sources: string[]
  users: SubmittedByUser[]
}

export interface FilterOptionsPayload {
  options: FilterOptions
}

interface FilterOptionsResponse extends BaseResponse, FilterOptionsPayload {}

const filterOptionsBaseUrl = '/api/internal/ticket_file_versions/filter_options'

export async function getFilterOptions(): Promise<FilterOptionsResponse> {
  const response = await baseRequest(filterOptionsBaseUrl, getApi)
  const { data } = response

  return {
    error: response.error,
    status: response.status,
    options: data as unknown as FilterOptions,
  }
}
