import { bindJWT, deleteApi, getApi, postApi } from '../api'
import { Annotation, AnnotationStatus } from '../annotations/annotations'
import { GenAiRequestTypes } from '../gen-ai/gen-ai-requests'
import { CompanyTag } from '../company-tags/company-tags'
export interface GenAiStockAsset {
  id: number
  createdAt: number
  message: string
  request: {
    message: string
    ticket_id: number
    request_type: string
  }
  requestType: GenAiTicketFileType
  response: {
    ds: {
      prompt: string
      status: string
      results: {
        id: string
        url: string
        ticket_file_id?: number
      }[]
      request_id: string
      session_id: string
      end_timestamp: number
      start_timestamp: number
    }
    req: {
      requestId: string
    }
  }
  status: string
  ticketId: number
  updatedAt: number
  userId: number
}

type GenAiTicketFileType = GenAiRequestTypes.IMG2IMG | GenAiRequestTypes.Inpainting | GenAiRequestTypes.TXT2IMG

interface StockAsset {
  id: number
  photoContentType: string
  photoFileName: string
  previewUrl: string
  provider: string
  requestType?: null
  viewUrl: string
}

export interface TicketFile {
  dpi?: number
  width?: number
  height?: number
  fileSize?: number
  colorspace?: string
  createdAt: string
  annotations: Annotation[]
  errors: unknown
  externalLink?: string
  extractedPages?: TicketFile[]
  handle: string
  id: number
  imageableId: number
  imageableType: ImageableType
  hasImagePreview: boolean
  isExtractable: boolean
  isVideo: boolean
  mimetype: string
  name?: string
  preview: boolean
  previewUrl: string
  stockAsset?: GenAiStockAsset | StockAsset | null
  tags: CompanyTag[]
  ticketId?: number
  ticketVersion: number
  uploadedByCreative: boolean
  user?: {
    id: number
    name: string
    collaborator: boolean
  }
}

export interface TicketFilePayload extends Record<string, unknown> {
  filename: string
  handle?: string
  mimetype?: string
  externalLink?: string
  imageableType?: ImageableType
  imageableId?: number
}

export interface VideoAnnotationPayload {
  detail: VideoJSAnnotationObject[]
}

export interface VideoJSAnnotationComment {
  id: string
  meta: {
    datetime: string
    detail_task?: {
      id: number
      status: AnnotationStatus
    }
    user_id: number
    user_name: string
  }
  body: string
}

export interface VideoJSAnnotationLocation {
  commentId?: string
  range: {
    start: number
    stop: number
  }
  shape: {
    x1: number
    y1: number
    x2: number
    y2: number
  }
}

export interface VideoJSAnnotationObject extends VideoJSAnnotationLocation {
  id: string
  dbId?: number
  comments: VideoJSAnnotationComment[]
}

export interface VideoJSAnnotation {
  id: string
  range: {
    start: number
    stop: number
  }
  shape: {
    x1: number
    y1: number
    x2: number
    y2: number
  }
  comments: VideoJSAnnotationComment[]
}

export enum ImageableType {
  GenAiRequest = 'GenAiRequest',
  JarTicketFile = 'JarTicketFile',
}

export async function createTicketFile(
  ticketId: number,
  payload: TicketFilePayload,
  token?: string,
): Promise<TicketFile> {
  const postWithJWT = bindJWT(postApi, token ?? '')
  const { data } = await postWithJWT(`/api/internal/tickets/${ticketId}/ticket_files`, payload)
  return data
}

export async function bulkCreateTicketFiles(
  ticketId: number,
  genAiRequestID: number,
  files: TicketFile[],
  token?: string,
): Promise<TicketFile[]> {
  const payloadSnakeCase = {
    data: files.map((ticketFile) => {
      return {
        filename: ticketFile.name,
        external_link: ticketFile.previewUrl,
        imageable_type: ImageableType.GenAiRequest,
        imageable_id: genAiRequestID,
      }
    }),
  }

  const postApiWithToken = bindJWT(postApi, token ?? '')
  const { data } = await postApiWithToken(
    `/api/internal/tickets/${ticketId}/ticket_files/bulk_create`,
    payloadSnakeCase,
  )
  return data.ticket_files
}

export async function deleteTicketFile(ticketId: number, ticketFileId: number): Promise<TicketFile> {
  const { data } = await deleteApi(`/api/internal/tickets/${ticketId}/ticket_files/${ticketFileId}`)
  return data
}

export async function getTicketFile(ticketId: number, ticketFileId: number): Promise<TicketFile> {
  const { data } = await getApi(`/api/internal/tickets/${ticketId}/ticket_files/${ticketFileId}`)
  return data
}

export async function getTicketFiles(ticketId: number, token?: string): Promise<TicketFile[]> {
  const getApiWithToken = bindJWT(getApi, token)
  const { data } = await getApiWithToken(`/api/internal/tickets/${ticketId}/ticket_files`)
  return data
}

export async function retryExtractPages(ticketId: number, ticketFileId: number): Promise<TicketFile> {
  const { data } = await postApi(`/api/internal/tickets/${ticketId}/ticket_files/${ticketFileId}/retry_extract_pages`)
  return data
}
