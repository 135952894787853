import { ChevronDown, ChevronRight } from 'lucide-react'
import { ReactNode, useState } from 'react'

interface AccordianProps {
  title: string
  children: ReactNode
}

const Accordian = ({ title, children }: AccordianProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <span className="tw-flex tw-flex-row tw-items-center tw-gap-1" onClick={() => setIsOpen((prev) => !prev)}>
        {isOpen ? <ChevronDown /> : <ChevronRight />}
        {title}
      </span>
      {isOpen && <div>{children}</div>}
    </div>
  )
}

export default Accordian
