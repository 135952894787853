import { ChangeEvent, Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'components/core/button'
import ConfirmationModal from 'components/elements/confirmation-modal'

import { GenAiDSImage, GenAiRequest, GenAiRequestStatus, getGenAiRequest } from 'lib/api/gen-ai/gen-ai-requests'
import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import { BetaBadge } from 'lib/components/badge/badge'
import MaskCanvas from 'lib/components/canvas/mask-canvas'
import { Card } from 'lib/components/card/card'
import ExternalLink from 'lib/components/links/external-link'
import Modal from 'lib/components/modals/modal'
import Textarea, { TextCounter } from 'lib/components/textarea/textarea'

import { useGenAiContext } from '../../providers/gen-ai-provider'
import { AnimatedLoadingScreen } from '../../../requests/empty-screens'
import { useMediaContext } from '../../media/media-provider'
import CopyTextButton from 'lib/components/buttons/copy-text-button'

interface ConfirmationMessage {
  confirmAction: () => void | Promise<void>
  message: string
  title: string
  visible: boolean
}

interface ModalProps {
  open: boolean
  originalSelection?: GenAiDSImage[]
  request: GenAiRequest
  setOpen: Dispatch<SetStateAction<boolean>>
}

interface PromptModalProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  requestId: number
}

enum ModalState {
  Accepted = 'accepted',
  Error = 'error',
  Progress = 'progress',
  Results = 'results',
}

const classNames = {
  buttons: {
    secondary:
      'tw-bg-peppercorn-50 hover:tw-bg-peppercorn-100 tw-mt-3 tw-inline-flex tw-w-full tw-justify-center tw-px-4 tw-py-2 tw-border-0 sm:tw-mt-0 sm:tw-w-auto sm:tw-text-sm',
    wrapper: 'tw-flex tw-justify-end tw-gap-2 tw-mt-4',
  },
  progress: {
    container: 'tw-text-center',
    img: 'tw-mx-auto',
    text: 'tw-mx-auto',
  },
  title: 'tw-text-lg tw-font-bold tw-flex tw-items-center tw-gap-1',
}

const emptyConfirmationMessage: ConfirmationMessage = Object.freeze({
  confirmAction: () => null,
  message: '',
  title: '',
  visible: false,
})

const PROMPT_PLACEHOLDER = 'Begin your prompt here about what you want to create'

export function EditAssistOriginalPromptModal({ open, requestId, setOpen }: PromptModalProps): ReactElement {
  const [request, setRequest] = useState<GenAiRequest>(null)

  const originalSelection = useMemo(() => {
    const results = request?.response?.ds?.results as GenAiDSImage[]
    return results ? results.filter((image) => image.ticket_file_id) : []
  }, [request?.response?.ds?.results])

  useEffect(() => {
    let isAbandoned = false
    if (open && requestId) {
      getGenAiRequest(requestId).then(({ genAiRequest }) => {
        if (!isAbandoned) {
          setRequest(genAiRequest)
        }
      })
    }
    return () => {
      isAbandoned = true
    }
  }, [open, requestId])

  if (!open && !request) return null

  return (
    <EditAssistResultsModal open={open} originalSelection={originalSelection} request={request} setOpen={setOpen} />
  )
}

export default function EditAssistResultsModal({
  open,
  originalSelection = [],
  request,
  setOpen,
}: ModalProps): ReactElement {
  const [confirmationMessage, setConfirmationMessage] = useState<ConfirmationMessage>(emptyConfirmationMessage)
  const [modalState, setModalState] = useState<ModalState>(ModalState.Progress)
  const [newPrompt, setNewPrompt] = useState('')
  const [selection, setSelection] = useState<GenAiDSImage[]>([])

  const { removeAndCancel, removeAndDismiss, removeAndAccept, removeAndRetry } = useGenAiContext()
  const { addFiles } = useMediaContext()
  const { createEditAssist } = useGenAiContext()

  const thumbnailRef = useRef<HTMLDivElement>(null)

  const images = useMemo(() => {
    return (request?.response?.ds?.results as GenAiDSImage[]) || []
  }, [request?.response?.ds?.results])

  function handleAddToRequest() {
    const newFiles = selection.map(
      (image) =>
        ({
          previewUrl: image.url,
          id: image.id,
          name: imageUrlToName(image.url),
        }) as unknown as TicketFile,
    )

    addFiles(request.id, newFiles)
    reset()
    if (modalState !== ModalState.Accepted) {
      removeAndAccept(request.id)
    }
    setOpen(false)
  }

  function handleAfterConfirmationClose() {
    setConfirmationMessage(emptyConfirmationMessage)
  }

  function handleCancel() {
    removeAndCancel(request.id)
    setOpen(false)
  }

  function handleCancelConfirmation() {
    setConfirmationMessage((previous) => ({ ...previous, visible: false }))
  }

  function handleConfirmDelete() {
    removeAndDismiss(request.id)
    setOpen(false)
    reset()
  }

  async function handleConfirmRegenerate(): Promise<void> {
    try {
      const { mask_image, original_image, ticket_file_id } = request.request
      const ticketFileId = ticket_file_id || null
      await createEditAssist(newPrompt, original_image, mask_image, ticketFileId)
      removeAndDismiss(request.id)
    } catch (error) {
      console.error('There was an error when attempting to regenerate this prompt.', error)
    }
  }

  function handleDelete() {
    setConfirmationMessage({
      confirmAction: handleConfirmDelete,
      message: 'Results will be removed, items not added to the request will be lost.',
      title: 'Delete Results',
      visible: true,
    })
  }

  function handlePromptChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setNewPrompt(event.currentTarget.value)
  }

  function handleRegenerate() {
    setConfirmationMessage({
      confirmAction: handleConfirmRegenerate,
      message: 'Regenerate will refresh results, items not added to the request will be lost.',
      title: 'Regenerate Results',
      visible: true,
    })
  }

  function handleRetry() {
    removeAndRetry(request)
    setOpen(false)
  }

  function imageUrlToName(url: string): string {
    const urlParts = url.split('/')
    return urlParts[urlParts.length - 1]
  }

  function reset() {
    setSelection([])
  }

  function toggleImage(image: GenAiDSImage) {
    if (selection.includes(image)) {
      setSelection(selection.filter((selectedImage) => selectedImage !== image))
    } else {
      setSelection([...selection, image])
    }
  }

  useEffect(() => {
    if (request) {
      setNewPrompt(request.message)
      if (request.status === GenAiRequestStatus.Accepted) {
        setModalState(ModalState.Accepted)
      } else if (request.status === GenAiRequestStatus.Completed) {
        setModalState(ModalState.Results)
      } else if (request.status === GenAiRequestStatus.Error) {
        setModalState(ModalState.Error)
      }
    }
  }, [request])

  useEffect(() => {
    let ref: MaskCanvas
    if ((modalState === ModalState.Accepted || modalState === ModalState.Results) && open) {
      const mask_image = request?.request?.mask_image
      const original_image = request?.request?.original_image
      if (mask_image && original_image) {
        ref = new MaskCanvas(thumbnailRef, original_image, mask_image, 96)
      }
    }
    return () => {
      if (ref) {
        ref.destroy()
      }
    }
  }, [modalState, open, request?.request?.mask_image, request?.request?.original_image])

  return (
    <Modal open={open} setOpen={setOpen} size="lg">
      <Modal.Header>
        <span className={classNames.title}>
          <FontAwesomeIcon icon={['far', 'paint-brush']} />
          <span>
            Edit Assist <BetaBadge />
          </span>
        </span>
      </Modal.Header>

      <Modal.Body setOpen={setOpen} closeButton>
        {modalState === ModalState.Progress && (
          <div className={classNames.progress.container}>
            <AnimatedLoadingScreen />
            <div style={{ width: '440px' }} className={classNames.progress.text}>
              <p>
                <strong>Your generative results are brining...</strong>
              </p>
              <p>
                You can click the “X” button on the top right to minimize this window. Your Edit Assist imagery
                generation will run in the background while you continue with your request.
              </p>
            </div>
          </div>
        )}
        {modalState === ModalState.Error && (
          <>
            <Subheader />
            <div className="tw-my-20 tw-text-center">
              <img
                alt="Crying Pickle Error"
                className="tw-mx-auto tw-mb-6"
                height="333"
                src="/images/states/error-crying-pickle.svg"
              />
              <h2 className="tw-text-lg tw-text-neutral-500">Oh no!</h2>
              <p className="tw-mx-auto tw-max-w-md">
                Looks like something failed, click &quot;Retry&quot; to load again!
              </p>
            </div>
          </>
        )}
        {(modalState === ModalState.Accepted || modalState === ModalState.Results) && (
          <>
            <ConfirmationModal
              afterLeave={handleAfterConfirmationClose}
              title={confirmationMessage.title}
              message={confirmationMessage.message}
              confirmBtnText="Confirm"
              confirmAction={confirmationMessage.confirmAction}
              cancelBtnText="Cancel"
              cancelAction={handleCancelConfirmation}
              visible={confirmationMessage.visible}
            />
            <Subheader />
            <h5>Your prompt</h5>
            <div className="tw-mb-6 tw-mt-1.5 tw-flex tw-flex-row">
              <div className="tw-mr-2 tw-self-center" ref={thumbnailRef} />

              <Textarea
                disabled={modalState === ModalState.Accepted}
                onChange={handlePromptChange}
                placeholder={PROMPT_PLACEHOLDER}
                value={newPrompt}
              />
              {modalState === ModalState.Accepted && (
                <div className="tw-ml-2 tw-mt-2">
                  <CopyTextButton
                    textToCopy={newPrompt}
                    defaultButtonColor="transparent"
                    tooltipCopyText="Copy prompt to clipboard"
                    tooltipCopiedText="Prompt copied!"
                  />
                </div>
              )}
              {modalState === ModalState.Results && (
                <div className="tw-my-2.5 tw-ml-2 tw-flex tw-flex-col tw-justify-between">
                  <div className="tw-flex-grow tw-text-right">
                    <Button color="neutralGray" onClick={handleRegenerate}>
                      Regenerate
                    </Button>
                  </div>
                  <div className="tw-flex-none">
                    <TextCounter className="tw-text-left" value={newPrompt} />
                  </div>
                </div>
              )}
            </div>
            {modalState === ModalState.Accepted && (
              <>
                <h4>Below are your results</h4>
                <p>Select image(s) to add to your request to further customize your inspirations.</p>
              </>
            )}
            {modalState === ModalState.Results && (
              <>
                <h4>Add results to your request or generate more</h4>
                <p>
                  Select image(s) to help your designer understand your vision (or start over as many times as{' '}
                  {`you'd `}
                  like)!{' '}
                </p>
              </>
            )}
            <div className="tw-grid tw-max-h-96 tw-grid-cols-2 tw-items-center tw-justify-items-center tw-gap-6 tw-overflow-y-scroll tw-bg-neutral-50 tw-py-6">
              {images.map((image, index) => (
                <Card
                  disabled={originalSelection.includes(image)}
                  size="xl"
                  key={image.id}
                  onClick={() => toggleImage(image)}
                  isSelected={selection.includes(image)}
                >
                  <Card.Body className="tw-px-12">
                    <img key={image.id} src={image.url} alt={`Result Image ${index + 1}`} className="tw-w-full" />
                  </Card.Body>
                </Card>
              ))}
            </div>
            <p className="tw-neutral-500 tw-pt-6">
              This content was created with the help of advanced AI technology which is constantly improving. Please
              check for accuracy.
            </p>

            <p className="tw-flex tw-items-center tw-gap-1 tw-pt-6 tw-font-semibold tw-text-skyblue-800">
              <FontAwesomeIcon icon={['far', 'exclamation-circle']} /> Generated images are low resolution and intended
              as creative inspiration for designers, not as final design for digital or print.
            </p>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        {modalState === ModalState.Accepted && (
          <div className={classNames.buttons.wrapper}>
            <Button color="purple" type="button" onClick={handleAddToRequest} disabled={!selection.length}>
              Add to request
            </Button>
          </div>
        )}

        {modalState === ModalState.Error && (
          <div className={classNames.buttons.wrapper}>
            <Button color="purple" type="button" onClick={handleRetry}>
              Retry
            </Button>
          </div>
        )}

        {modalState === ModalState.Progress && (
          <div className={classNames.buttons.wrapper}>
            <Button color="lightGray" type="button" onClick={handleCancel}>
              Cancel generation
            </Button>
          </div>
        )}

        {modalState === ModalState.Results && (
          <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
            <span className="tw-font-bold">
              {selection.length} of {images.length} items selected
            </span>
            <div className={classNames.buttons.wrapper}>
              <Button color="lightGray" type="button" onClick={handleDelete}>
                Delete results
              </Button>
              <Button color="purple" type="button" onClick={handleAddToRequest} disabled={!selection.length}>
                Add to request
              </Button>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}

function Subheader(): ReactElement {
  return (
    <p>
      Immediately generate images to visualize your ideas for your designer.{' '}
      <ExternalLink href="https://help.designpickle.com/en/articles/8304523">Learn More</ExternalLink>
    </p>
  )
}
