import { useUserContext } from 'providers/user-provider'
import NewRequestButton from '../../request/new-request-button/new-request-button'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import CreateButton from 'components/pages/create/create-button'

const classNames = {
  boldText: 'tw-font-bold',
  button: 'ajax-modal-link tw-mt-2',
  container:
    'tw-flex tw-flex-col tw-flex-wrap tw-items-center tw-justify-center tw-mb-2 tw-pt-12 tw-overflow-auto tw-text-peppercorn-900',
  image: 'tw-mb-4',
}

export default function AssetsEmptyState(): JSX.Element {
  const { user } = useUserContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  const customStyle = {
    gridView: { height: 'calc(100vh - 22rem)' },
  }

  return (
    <div className={classNames.container} style={customStyle.gridView}>
      <img src="/images/states/none-exist.svg" alt="No Assets Exist" className={classNames.image} />
      <div className={classNames.boldText}>{"You don't currently have any assets."}</div>
      <div>Get started by uploading or using stock assets in a request.</div>
      <span className="mt-2">
        {isFeatureFlagEnabled('projects') ? <CreateButton /> : <NewRequestButton user={user} />}
      </span>
    </div>
  )
}
