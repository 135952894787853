import { capitalize } from 'lib/string/utils'
import { cn } from 'lib/util/cn'

interface tabsProps {
  tabs: Array<string>
  activeTab: string
  onClick: (string) => void
}

export default function Tabs({ tabs, activeTab, onClick }: tabsProps): JSX.Element {
  activeTab = activeTab ? activeTab : tabs[0]

  return (
    <div className="tw-sm:block tw-flex tw-justify-center">
      <div className="">
        <nav className="tw--mb-px tw-flex tw-items-center tw-space-x-8 tw-border-b-2" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab}
              onClick={() => onClick(tab)}
              className={cn(
                'tw-whitespace-nowrap tw-border-b-4 tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-px-1 tw-py-4 tw-text-sm tw-font-medium hover:tw-border-gherkin hover:tw-text-gherkin',
                activeTab === tab
                  ? 'tw-border-gherkin tw-font-bold tw-text-gherkin'
                  : 'tw-border-transparent tw-font-semibold tw-text-peppercorn',
              )}
              aria-current={activeTab === tab ? 'page' : undefined}
              role="tab"
            >
              {capitalize(tab)}
            </a>
          ))}
        </nav>
      </div>
    </div>
  )
}
