import { LinkLikeButton } from 'components/core/button'
import ConfirmationModal from 'components/elements/confirmation-modal'
import { AnnotationRecord } from 'lib/api/annotations/annotations'
import { VideoJSAnnotation } from 'lib/api/ticket-files/ticket-files'
import {
  AnnotationTimelineItem,
  ConversationTimelineItem,
  DetailTaskTimelineItem,
  TimelineItem,
  TimelineItemTypes,
} from 'lib/api/timeline/timeline'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'
import { toast } from 'lib/components/toast/toast'
import { groupBy } from 'lodash'
import { useUserContext } from 'providers/user-provider'
import { MouseEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import ImageAnnotationForm from '../annotations/image-annotation-form'
import { getCurrentlyViewedFile } from '../annotations/utils'
import { useMediaContext } from '../media/media-provider'
import { ALL_VERSIONS, useTimelineListContext } from '../providers/timeline-list-provider'
import { useTimelineContext } from '../providers/timeline-provider'
import { DetailTaskType } from '../types/detail-task'
import TimelineAnnotation from './timeline-annotation'
import TimelineConversation from './timeline-conversation'
import CommentForm from './timeline-conversation-form'
import TimelineDirection from './timeline-direction'
import TimelineDirectionForm from './timeline-direction-form'

interface AnnotationProps {
  item: AnnotationTimelineItem
  onDelete: (item: DetailTaskTimelineItem) => void
}

interface ConversationProps {
  item: ConversationTimelineItem
  lastDeliveredVersion: number
  onDelete: (item: ConversationTimelineItem) => void
}

interface DirectionProps {
  item: DetailTaskTimelineItem
  onDelete: (item: DetailTaskTimelineItem) => void
}

const deleteModalOptions = {
  [TimelineItemTypes.CONVERSATION]: {
    title: 'Delete Comment',
    message: 'Are you sure you want to delete this comment? This action cannot be undone.',
  },

  [TimelineItemTypes.ANNOTATION]: {
    title: 'Delete Annotation',
    message: 'Are you sure you want to delete this annotation? This action cannot be undone.',
  },

  [TimelineItemTypes.DIRECTION]: {
    title: 'Delete Direction',
    message: 'Are you sure you want to delete this direction? This action cannot be undone.',
  },
  [TimelineItemTypes.COPY]: {
    title: 'Delete Exact Copy Direction',
    message: 'Are you sure you want to delete this direction? This action cannot be undone.',
  },
}

function Annotation({ item, onDelete }: AnnotationProps): ReactElement {
  function handleDelete() {
    onDelete(item)
  }

  const isVideo = item?.annotation?.data ? Object.hasOwn(item?.annotation?.data, 'range') : false

  if (isVideo) {
    const videoAnnotation = {
      id: item.annotation.id,
      assetId: item.annotation.assetId,
      body: item.annotation.body,
      createdAt: item.createdAt,
      createdBy: item.userFullName,
      data: item.annotation.data,
      detailTaskId: item.id,
      fileName: item.annotation.fileName,
      previewUrl: item.annotation.previewUrl,
      status: item.status,
      time: (item.annotation.data as VideoJSAnnotation).range.start,
      type: DetailTaskType.VIDEO_ANNOTATION,
      user: item.user || item.annotation.user,
      uuid: item.annotation.uuid,
    } as AnnotationRecord<VideoJSAnnotation>

    return <TimelineAnnotation annotation={videoAnnotation} onDeleteClick={handleDelete} />
  }

  const imageAnnotation: AnnotationRecord<AnnotoriousAnnotation> = {
    id: item.annotation.id,
    assetId: item.annotation.assetId,
    body: item.description,
    createdAt: item.createdAt,
    createdBy: item.userFullName,
    data: item.annotation.data as AnnotoriousAnnotation,
    detailTaskId: item.id,
    fileName: item.annotation.fileName,
    fileParentId: item.annotation.fileParentId,
    fileVersion: item.annotation.fileVersion,
    previewUrl: item.annotation.previewUrl,
    status: item.status,
    ticketVersion: item.ticketVersion,
    user: item.user || item.annotation.user,
    userId: item.annotation.userId,
    uuid: item.annotation.uuid,
  }

  return <TimelineAnnotation annotation={imageAnnotation} onDeleteClick={handleDelete} />
}

function Conversation({ item, lastDeliveredVersion, onDelete }: ConversationProps): ReactElement {
  const { user } = useUserContext()
  const { saveComment } = useTimelineContext()

  function handleOnDelete(): void {
    return onDelete(item)
  }

  function handleOnSubmit(body: string): Promise<void> {
    return saveComment(item.id, body)
  }

  return (
    <TimelineConversation
      conversation={item}
      editable={user.id === item.user.id && item.ticketVersion === lastDeliveredVersion && !user.isInternalUser}
      onDelete={handleOnDelete}
      onSubmit={handleOnSubmit}
    />
  )
}

function ConversationFooter(): ReactElement {
  const { addComment } = useTimelineContext()
  return <CommentForm onSend={addComment} />
}

function Direction({ item, onDelete }: DirectionProps): ReactElement {
  return <TimelineDirection direction={item} onDelete={() => onDelete(item)} />
}

interface TimelineItemComponentProps {
  item: TimelineItem
  lastDeliveredVersion: number
  onDelete: (item: TimelineItem) => void
}

function TimelineItemComponent({ onDelete, item, lastDeliveredVersion }: TimelineItemComponentProps): ReactElement {
  switch (item.taskType) {
    case TimelineItemTypes.ANNOTATION:
      return <Annotation item={item as AnnotationTimelineItem} onDelete={onDelete} />
    case TimelineItemTypes.CONVERSATION:
      return (
        <Conversation
          item={item as ConversationTimelineItem}
          lastDeliveredVersion={lastDeliveredVersion}
          onDelete={onDelete}
        />
      )
    case TimelineItemTypes.COPY:
    case TimelineItemTypes.DIRECTION:
    case TimelineItemTypes.GEN_AI_REQUEST:
      return <Direction item={item as DetailTaskTimelineItem} onDelete={onDelete} />
    default:
      return <div>Unknown Item Type: {item.taskType}</div>
  }
}

function Line() {
  return <div className="tw-w-full tw-bg-neutral-600" style={{ height: '1px' }} />
}

export default function TimelineList(): ReactElement {
  const [isNextPageLoading, setIsNextPageLoading] = useState(false)
  const [topItemId, setTopItemId] = useState(null)
  const [selectedDeleteItem, setSelectedDeleteItem] = useState<TimelineItem>(null)
  const { isMediaLoaded } = useMediaContext()
  const {
    getNextPage,
    hasNextPage,
    isCurrentVersion,
    currentVersion,
    orderedItems,
    removeItem,
    selectLatestVersion,
    selectedVersion,
  } = useTimelineListContext()

  const deleteModalDetails = useMemo(
    () =>
      deleteModalOptions[selectedDeleteItem?.taskType || TimelineItemTypes.DIRECTION] ||
      deleteModalOptions[TimelineItemTypes.DIRECTION],
    [selectedDeleteItem],
  )

  const groupedItems = useMemo(() => {
    if (selectedVersion === ALL_VERSIONS) {
      return groupBy(orderedItems, 'ticketVersion')
    }
    return null
  }, [selectedVersion, orderedItems])

  const observerTargetDiv = useRef<HTMLDivElement>(null)
  const topItemRef = useRef<HTMLDivElement>(null)

  function handleCancelDelete() {
    setSelectedDeleteItem(null)
  }

  async function handleConfirmDelete() {
    await removeItem(selectedDeleteItem)
    const itemType =
      selectedDeleteItem.taskType === TimelineItemTypes.GEN_AI_REQUEST
        ? 'Direction'
        : `${selectedDeleteItem.taskType[0].toUpperCase()}${selectedDeleteItem.taskType.slice(1)}`
    toast.success(`${itemType} successfully deleted`)
    setSelectedDeleteItem(null)
  }

  function handleDelete(item: TimelineItem) {
    setSelectedDeleteItem(item)
  }

  useEffect(() => {
    if (hasNextPage && isMediaLoaded && orderedItems?.length) {
      const observer = new IntersectionObserver(
        async ([targetDiv]) => {
          if (targetDiv.isIntersecting && !isNextPageLoading) {
            setIsNextPageLoading(true)
            setTopItemId(orderedItems[0].__cacheKey)
            await getNextPage()
            observer.disconnect()
            topItemRef.current.scrollIntoView()
            setTimeout(() => {
              setIsNextPageLoading(false)
            }, 500)
          }
        },
        { threshold: 0.5 },
      )

      if (observerTargetDiv.current && !isNextPageLoading) {
        observer.observe(observerTargetDiv.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, isMediaLoaded, orderedItems, isNextPageLoading])

  if (!orderedItems || !isMediaLoaded) {
    return <div>Loading...</div>
  }

  return (
    <div className="tw-pt-6">
      {hasNextPage && (
        <div ref={observerTargetDiv} className="tw-ml-5 tw-bg-white tw-text-center tw-text-sm tw-text-gray-500">
          <LinkLikeButton onClick={getNextPage}>More...</LinkLikeButton>
        </div>
      )}

      {groupedItems ? (
        <>
          {Object.keys(groupedItems).map((version) => (
            <div key={version}>
              <div className="tw-flex tw-items-center tw-text-center">
                <Line />
                <h6 className="tw-mx-2 tw-min-w-14">Version {version}</h6>
                <Line />
              </div>
              {groupedItems[version].map((item) => (
                <span key={item.__cacheKey}>
                  {item.__cacheKey === topItemId && <div ref={topItemRef} />}
                  <TimelineItemComponent item={item} lastDeliveredVersion={currentVersion} onDelete={handleDelete} />
                </span>
              ))}
            </div>
          ))}
        </>
      ) : (
        <>
          {orderedItems?.map((item) => (
            <span key={item.__cacheKey}>
              {item.__cacheKey === topItemId && <div ref={topItemRef} />}
              <TimelineItemComponent item={item} lastDeliveredVersion={currentVersion} onDelete={handleDelete} />
            </span>
          ))}
        </>
      )}

      <ConfirmationModal
        title={deleteModalDetails.title}
        message={deleteModalDetails.message}
        confirmBtnText="Yes, Delete"
        confirmAction={handleConfirmDelete}
        cancelAction={handleCancelDelete}
        visible={selectedDeleteItem !== null}
      />
      {isCurrentVersion && <Footer />}
      {!isCurrentVersion && <PastVersionFooter onClick={selectLatestVersion} />}
    </div>
  )
}

function Footer(): ReactElement {
  const { orderedItems, newItemType } = useTimelineListContext()
  const { extractedPreviewIndex, selectedFile } = useMediaContext()

  const currentlyViewedFile = useMemo(
    () => getCurrentlyViewedFile(selectedFile, extractedPreviewIndex),
    [selectedFile, extractedPreviewIndex],
  )

  const classNames = {
    footer: {
      default: `tw-sticky tw-bottom-0 tw-pb-5 tw-bg-white tw-w-full`,
      withDirections: `tw-pt-4`,
      withoutDirections: `tw-pt-0`,
    },
  }

  return (
    <footer
      data-testid="directions-footer"
      className={`${classNames.footer.default} ${
        orderedItems?.length > 0 ? classNames.footer.withDirections : classNames.footer.withoutDirections
      }`}
    >
      {newItemType === TimelineItemTypes.ANNOTATION && <ImageAnnotationForm ticketFile={currentlyViewedFile} />}
      {newItemType === TimelineItemTypes.DIRECTION && <TimelineDirectionForm />}
      {newItemType === TimelineItemTypes.CONVERSATION && <ConversationFooter />}
    </footer>
  )
}

interface PastVersionFooterProps {
  onClick: () => void
}

function PastVersionFooter({ onClick }: PastVersionFooterProps): ReactElement {
  const classNames = {
    footer: 'tw-sticky tw-bottom-0 tw-py-5 tw-border-solid tw-border-gray-200 tw-border-t tw-border-0 tw-bg-white',
    footerText: 'tw-ml-5 tw-bg-white tw-text-center tw-text-sm tw-text-gray-500',
  }

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    onClick()
  }

  return (
    <footer className={classNames.footer} style={{ marginLeft: '-14px', marginRight: '-20px' }}>
      <div className={classNames.footerText}>
        {'To respond to your designer, '}
        <LinkLikeButton onClick={handleClick}>go to latest version</LinkLikeButton>
      </div>
    </footer>
  )
}
