import { useEffect } from 'react'
import { FileExport } from 'interfaces/file-exports'
import { FileExportStatus } from 'interfaces/file-exports'
import { useState } from 'react'
import { useCreateFileExport } from './useCreateFileExport'
import { useMutation, useQuery } from '@tanstack/react-query'
import { downloadFile, getFileNameWithoutExtension } from 'lib/util/file/file'
import { toast } from 'sonner'
import { request, requestQuery } from 'lib/api/fetch-api'
import { LinkLikeButton } from 'components/core/button'
import { queryClient } from 'providers/query-client-provider'

interface FileExportSuccessToastProps {
  name: string
  link: string
  id?: number
}

export const fileExportSuccessToast = ({ name, link, id }: FileExportSuccessToastProps) => {
  toast.success(`"${name}" downloaded successfully`, {
    id,
    description: 'Please check your downloads folder.',
    classNames: {
      toast: '!tw-w-[650px]',
      closeButton: '!tw-ml-0',
    },
    action: (
      <LinkLikeButton
        className="tw-ml-auto tw-text-nowrap tw-text-inherit"
        onClick={() => {
          downloadFile(name, link)
        }}
      >
        Download again
      </LinkLikeButton>
    ),
    closeButton: true,
    duration: Infinity,
  })
}

export const useFileExports = () => {
  const [downloadedIds, setDownloadedIds] = useState<number[]>([])

  const { data: fileExports } = useQuery({
    queryKey: ['file-exports'],
    queryFn: requestQuery({ endpoint: 'getFileExports' }),
    refetchInterval: ({ state }: { state: { data: { data: FileExport[] } } }) => {
      const fileExports = state?.data?.data as FileExport[]
      if (fileExports?.some((fileExport) => fileExport.display)) {
        return 1000
      }
      return false
    },
  })

  const { mutate: updateFileExport } = useMutation({
    mutationFn: (id: number) => request({ endpoint: 'updateFileExport', query: { id }, body: { display: false } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['file-exports'] })
    },
  })
  const { createFileExport } = useCreateFileExport()

  useEffect(() => {
    fileExports?.data?.forEach((arrayFormat) => {
      if (arrayFormat.display) {
        switch (arrayFormat.status) {
          case FileExportStatus.finished:
            if (!downloadedIds.includes(arrayFormat.id) && arrayFormat.display) {
              downloadFile(
                `${getFileNameWithoutExtension(arrayFormat.fileName)}.${arrayFormat.outputFormat}`,
                arrayFormat.temporaryLink,
              )
              updateFileExport(arrayFormat.id)
              setDownloadedIds((prev) => [...prev, arrayFormat.id])
            }

            fileExportSuccessToast({
              name: `${getFileNameWithoutExtension(arrayFormat.fileName)}.${arrayFormat.outputFormat}`,
              link: arrayFormat.temporaryLink,
              id: arrayFormat.id,
            })
            break
          case FileExportStatus.error:
            toast.error(
              `"${getFileNameWithoutExtension(arrayFormat.fileName)}.${arrayFormat.outputFormat}" failed to download`,
              {
                id: arrayFormat.id,
                description: 'Please try again.',
                classNames: {
                  toast: '!tw-w-[650px]',
                  closeButton: '!tw-ml-0',
                },
                action: (
                  <LinkLikeButton
                    className="tw-ml-auto tw-text-nowrap tw-text-inherit"
                    onClick={() =>
                      createFileExport({
                        ticketFileId: arrayFormat.ticketFileId,
                        selectedFormats: [arrayFormat.outputFormat],
                      })
                    }
                  >
                    Retry
                  </LinkLikeButton>
                ),
                onDismiss: () => {
                  updateFileExport(arrayFormat.id)
                },
                closeButton: true,
                duration: Infinity,
              },
            )
            break
          case FileExportStatus.processing:
          case FileExportStatus.waiting:
            toast.loading(`Downloading "${arrayFormat.fileName}" as .${arrayFormat.outputFormat}`, {
              id: arrayFormat.id,
              classNames: {
                toast: '!tw-w-[650px]',
              },
              description:
                'Please wait while your files are being prepared—they will download automatically once ready.',
              duration: Infinity,
              closeButton: false,
            })
            break
        }
      }
    })
  }, [fileExports, createFileExport, setDownloadedIds, downloadedIds, updateFileExport])
}

// Used in the header to show the file exports toast because it needs to be below the QueryClientProvider
export const FileExports = () => {
  useFileExports()

  return null
}
