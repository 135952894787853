import {
  AdminTicketAssignerLog,
  AdminTicketChangeLog,
  AdminTicketLog,
  AdminTicketQAReviewLog,
  AdminTicketTimeTrackingLog,
} from 'lib/api/admin/logs/admin-logs'
import Badge from 'lib/components/badge/badge'
import { snakeToTitleCase } from 'lib/object/utils'
import { decimalHoursToTimeString, getHoursFromMinutes } from 'lib/util/date'
import { omit } from 'lodash'

const styles = {
  base: 'tw-bg-neutral-50 tw-text-neutral-800 tw-rounded-lg tw-mb-4 tw-p-4 tw-w-full tw-max-w-md tw-break-words',
  header: 'tw-mb-2',
}

const LogEntryHeader = ({ user, timestamp }: { user: string; timestamp?: string }) => (
  <div className={styles.header}>
    <div>{user}</div>
    {timestamp && <Badge color="light">{new Date(timestamp).toLocaleString()}</Badge>}
  </div>
)

const AutoAssignerLogEntry = ({ log }: { log: AdminTicketAssignerLog }) => {
  return (
    <div className={styles.base} data-testid="log-entry">
      <LogEntryHeader user={log.user} timestamp={log.createdAt} />
      <div>Auto Assigner Attempt</div>
      <div className="tw-font-bold">{log.success ? 'Success' : 'Failed'}</div>
      {!log.success && <div>{log.errorMessages[0]}</div>}
    </div>
  )
}

const QAReviewLogEntry = ({ log }: { log: AdminTicketQAReviewLog }) => {
  return (
    <div className={styles.base} data-testid="log-entry">
      <LogEntryHeader user={log.user} timestamp={log.createdAt} />
      <div>
        QA Review Status: <span className="tw-font-bold">{snakeToTitleCase(log.state)}</span>
      </div>
      <div>
        Review Started At: <span className="tw-font-bold">{new Date(log.startedAt).toLocaleString()}</span>
      </div>
      <div>
        Review Ended At: <span className="tw-font-bold">{new Date(log.finishedAt).toLocaleString()}</span>
      </div>
    </div>
  )
}

const TicketChangeLogEntry = ({ log }: { log: AdminTicketChangeLog }) => {
  const formatValue = (key: string, value: string | number) => {
    if (value === null) return 'null'

    if (['skill_id', 'last_event', 'state'].includes(key)) {
      return snakeToTitleCase(value.toString())
    }

    if (key === 'total_design_time') {
      return decimalHoursToTimeString(parseFloat(value.toString()))
    }

    if (key.includes('time') || ['graphics', 'graphics_pro', 'graphics_premium', 'power'].includes(key)) {
      return getHoursFromMinutes(parseInt(value.toString()))
    }

    if (key === 'completed_at') {
      return new Date(value).toLocaleString()
    }

    return value
  }

  const changes = Object.entries(omit(log.changeset, ['updated_at', 'state_changed_at'])).map(([key, change]) => ({
    key: snakeToTitleCase(key),
    before: formatValue(key, change[0]),
    after: formatValue(key, change[1]),
  }))

  if (log?.designer) {
    changes.push({
      key: 'Designer',
      before: log.designer.old || 'null',
      after: log.designer.new || 'null',
    })
  }

  changes.sort((a, b) => {
    if (a.key < b.key) return -1
    if (a.key > b.key) return 1
    return 0
  })

  return (
    <div className={styles.base} data-testid="log-entry">
      <LogEntryHeader user={log.user} timestamp={log.createdAt} />
      {changes.map(({ key, before, after }) => (
        <div key={key}>
          {key}: <span className="tw-font-bold">{before}</span> to <span className="tw-font-bold">{after}</span>
        </div>
      ))}
    </div>
  )
}

const TimeTrackingLogEntry = ({ log }: { log: AdminTicketTimeTrackingLog }) => {
  return (
    <div className={styles.base} data-testid="log-entry">
      <LogEntryHeader user={log.user} />
      <div>Start Time:&nbsp;{new Date(log.startTime).toLocaleString()}</div>
      <div>End Time:&nbsp;{new Date(log.endTime).toLocaleString()}</div>
      <div>Hours Worked:&nbsp;{decimalHoursToTimeString(log.hoursWorked)}</div>
    </div>
  )
}

export default function AdminLogEntry({ log }: { log: AdminTicketLog }) {
  switch (log.logType) {
    case 'auto_assigner_tracking':
      return <AutoAssignerLogEntry log={log} />
    case 'qa_review':
      return <QAReviewLogEntry log={log} />
    case 'ticket_version':
    case 'qa_review_version':
    case 'company_daily_available_time_version':
    case 'company_time_tracking_version':
      return <TicketChangeLogEntry log={log} />
    case 'ticket_time_tracking':
    case 'ticket_tracking':
      return <TimeTrackingLogEntry log={log} />
  }
}
