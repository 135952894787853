import { Dispatch, ElementType, Fragment, HTMLAttributes, ReactNode, SetStateAction } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import IconButton from '../buttons/icon-button'
import { cn } from 'lib/util/cn'

interface ModalBodyProps {
  children: ReactNode
  closeButton?: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  className?: HTMLAttributes<HTMLDivElement>['className']
}

const classNames = {
  panel: `tw-relative
    tw-transform
    tw-w-full
    tw-overflow-visible
    tw-rounded-lg
    tw-bg-white
    tw-px-4
    tw-pt-5
    tw-pb-4
    tw-text-left
    tw-shadow-xl
    tw-transition-all
    sm:tw-my-8
    sm:tw-p-6`,
  size: {
    xs: 'tw-max-w-xs',
    sm: 'tw-max-w-md',
    md: 'tw-max-w-xl',
    lg: 'tw-max-w-5xl',
  },
  body: 'tw-mt-2',
  bodyContainer: 'sm:tw-flex sm:tw-items-start',
  bodyWrapper: 'tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-text-left tw-w-full',
  closeButtonWrapper: 'tw-absolute tw-top-0 tw-right-0 tw-hidden tw-pt-4 tw-pr-4 sm:tw-block',
  closeButton: 'tw-text-xl tw-text-peppercorn-400 hover:tw-text-peppercorn-600 tw-transition-colors',
  title: 'tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900',
}

function Body({ closeButton, children, setOpen, className }: ModalBodyProps): JSX.Element {
  return (
    <>
      {closeButton && (
        <div className={classNames.closeButtonWrapper}>
          <IconButton
            color="secondary"
            className={classNames.closeButton}
            onClick={() => setOpen(false)}
            icon={['far', 'times']}
          />
        </div>
      )}

      <div className={cn(classNames.body, className)}>{children}</div>
    </>
  )
}

interface ModalFooterProps {
  children: ReactNode
}

interface ModalHeaderProps {
  children: ReactNode
  as?: ElementType
}

function Footer({ children }: ModalFooterProps) {
  return <>{children}</>
}

export interface BaseModalProps {
  afterEnter?: () => void
  afterLeave?: () => void
  children: ReactNode
  open: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
  size?: 'xs' | 'sm' | 'md' | 'lg'
  clickOutsideToClose?: boolean
  className?: HTMLAttributes<HTMLDivElement>['className']
}

function BaseModal({
  afterEnter,
  afterLeave,
  children,
  open,
  setOpen = () => null,
  size = 'xs',
  clickOutsideToClose = true,
  className,
}: BaseModalProps) {
  function handleAfterEnter() {
    if (afterEnter) {
      afterEnter()
    }
  }

  function handleAfterLeave() {
    if (afterLeave) {
      afterLeave()
    }
  }

  function handleOutsideClick(value: boolean) {
    if (clickOutsideToClose) {
      setOpen(value)
    }
  }

  return (
    <Transition.Root show={open} as={Fragment} afterEnter={handleAfterEnter} afterLeave={handleAfterLeave}>
      <Dialog as="div" className="tw-relative tw-z-40" onClose={handleOutsideClick}>
        <Transition.Child
          as={Fragment}
          enter="tw-ease-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        </Transition.Child>

        <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
          <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <Dialog.Panel className={cn(classNames.panel, classNames.size[size], className)}>
                <div className={classNames.bodyContainer}>
                  <div className={classNames.bodyWrapper}>{children}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function Header({ as = 'h3', children }: ModalHeaderProps) {
  return (
    <Dialog.Title as={as} className={classNames.title}>
      {children}
    </Dialog.Title>
  )
}

const Modal = Object.assign(BaseModal, { Body, Footer, Header })

export default Modal
