import { ReactElement, useMemo, useState } from 'react'
import { AdminTicketFile, AdminTicketFileState } from 'lib/api/admin/ticket-files/admin-ticket-files'
import AdminMediaItem from './admin-media-item'
import { AdminQaReviewStates } from 'lib/api/admin/tickets/admin-tickets'
import { displayDate } from 'lib/util/date'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import { DownloadAllAdminAssetsButton } from 'lib/components/download-assets-button/download-assets-button'
import { Trash } from 'lucide-react'
import ConfirmationModal from 'components/elements/confirmation-modal'
import { useAdminMediaContext } from '../providers/admin-media-provider'
import IconButton from 'lib/components/buttons/icon-button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'lib/components/toast/toast'

const classNames = {
  container: 'tw-flex tw-flex-col tw-gap-6 tw-items-center tw-pb-6',
  zeroState: 'tw-text-center tw-text-neutral-600 tw-text-sm tw-p-4',
}

export default function AdminMediaList(): ReactElement {
  const { visibleFiles } = useAdminMediaContext()
  if (visibleFiles.length === 0) {
    return <div className={classNames.zeroState}>No media files</div>
  }

  const areThereRejectedfiles = visibleFiles.some((file) => file.state === AdminTicketFileState.Rejected)

  if (areThereRejectedfiles) {
    return <QaFailLabelWithFiles files={visibleFiles} />
  }

  return (
    <div>
      <div className={classNames.container}>
        <AdminMediaListHeader files={visibleFiles} />
        {visibleFiles.map((file) => {
          return <AdminMediaItem key={file.id} file={file} />
        })}
      </div>
    </div>
  )
}

function AdminMediaListHeader({ files }: { files: AdminTicketFile[] }): ReactElement {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const { deleteFile, filters, currentVersion } = useAdminMediaContext()

  const deleteAllFiles = async () => {
    setDeleteLoading(true)
    const deleteOps = files.map((file) => deleteFile(file))
    try {
      await Promise.all(deleteOps)
      toast.success('Successfully deleted files')
      setShowDeleteModal(false)
    } catch (e) {
      console.error('Error deleting files', e)
      toast.error('Error deleting files')
    }
    setDeleteLoading(false)
  }

  return (
    <>
      <div className="tw-flex tw-items-center tw-gap-2">
        <DownloadAllAdminAssetsButton files={files} />
        {filters.isUploadedByCreative && filters.version === currentVersion && (
          <IconButton
            color="secondary"
            onClick={() => setShowDeleteModal(true)}
            className="tw-flex tw-items-center tw-justify-center tw-gap-1"
            dataTestid="delete-files"
          >
            <Trash className="lu-sm tw-text-flushpink-500" />
          </IconButton>
        )}
      </div>
      <ConfirmationModal
        visible={showDeleteModal}
        cancelAction={() => setShowDeleteModal(false)}
        confirmAction={deleteAllFiles}
        title="Delete All Files"
        message="Are you sure you want to delete all files in this version?"
        confirmBtnText={deleteLoading ? <FontAwesomeIcon spin icon="spinner-third" /> : 'Delete Files'}
      />
    </>
  )
}

function QaFailLabelWithFiles({ files }: { files: AdminTicketFile[] }) {
  const { ticket } = useAdminTicketContext()

  const rejectedFiles = useMemo(() => files.filter((file) => file.state === AdminTicketFileState.Rejected), [files])
  const nonRejectedFiles = useMemo(
    () => files.filter((file) => file.state !== AdminTicketFileState.Rejected && !file.preview),
    [files],
  )
  const previewFiles = useMemo(
    () => files.filter((file) => file.state !== AdminTicketFileState.Rejected && file.preview),
    [files],
  )

  const failedQRs = useMemo(
    () => ticket.qaReviews.filter((review) => review.state === AdminQaReviewStates.failed),
    [ticket.qaReviews],
  )

  const failedQRsWithFiles = useMemo(
    () =>
      failedQRs
        .map((review, i) => {
          const filesAssociatedWithReview = rejectedFiles.filter((file) => {
            const fileCreatedAt = new Date(file.createdAt)
            const reviewFinishedAt = new Date(review.finishedAt)

            if (i + 1 < failedQRs.length) {
              const nextReviewFinishedAt = new Date(failedQRs[i + 1].finishedAt)

              // Check if the file was created after the current review and before the next review
              return fileCreatedAt < reviewFinishedAt && fileCreatedAt > nextReviewFinishedAt
            }

            return fileCreatedAt < reviewFinishedAt
          })
          return {
            review: review,
            files: filesAssociatedWithReview,
          }
        })
        .filter((failedQRWithFiles) => failedQRWithFiles.files.length > 0),
    [failedQRs, rejectedFiles],
  )

  return (
    <div>
      <AdminMediaListHeader files={nonRejectedFiles} />
      {nonRejectedFiles.length > 0 && (
        <div className="tw-pt-6">
          <hr className="tw-mb-4" />
          <div className="tw-pb-4 tw-font-bold tw-text-gray-500">DELIVERED ASSETS</div>
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-6">
            {nonRejectedFiles.map((file) => (
              <AdminMediaItem key={file.id} file={file} />
            ))}
          </div>
        </div>
      )}
      {previewFiles.length > 0 && (
        <div>
          <hr className="tw-mb-4" />
          <div className="tw-pb-4 tw-font-bold tw-text-gray-500">PREVIEW ASSETS</div>
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-6">
            {previewFiles.map((file) => (
              <AdminMediaItem key={file.id} file={file} />
            ))}
          </div>
        </div>
      )}
      {failedQRsWithFiles.map((failedQRWithFiles) => (
        <div key={failedQRWithFiles.review.id} data-testid={`failed-qr-${failedQRWithFiles.review.id}`}>
          <hr className="tw-mb-4" />
          <div className="tw-pb-4 tw-font-bold tw-text-gray-500">
            QR FAIL {displayDate(failedQRWithFiles.review.finishedAt)}
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-6">
            {failedQRWithFiles.files.map((file) => (
              <AdminMediaItem key={file.id} file={file} />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
