import { HTMLAttributes, useEffect, useRef, useState } from 'react'
import { X } from 'lucide-react'

const classNames = {
  container: {
    base: `tw-w-full
           tw-appearance-none
           tw-rounded-md
           tw-border
           tw-border-solid
           tw-px-2
           tw-my-2.5
           tw-shadow-none
           tw-outline-none
           tw-py-2
           tw-flex
           tw-items-center
           tw-justify-start
           tw-gap-2
           tw-flex-wrap
           tw-break-all
           tw-cursor-text`,
    error: 'tw-border-flushpink-500 tw-shadow',
    focused: 'tw-border-cornflower-500 tw-shadow',
  },
  tagContainer: {
    base: `tw-pl-2
           tw-pr-1
           tw-min-h-10
           tw-rounded
           tw-flex
           tw-items-center
           tw-gap-1
           tw-max-w-full
           tw-cursor-default`,
    tagColor: {
      light: `tw-bg-neutral-100
              tw-text-neutral-800`,
      primary: `tw-bg-cornflower-100
                tw-text-cornflower-800`,
    },
  },
  xButton: {
    base: `tw-border-none
           tw-bg-transparent
           tw-outline-none
           tw-rounded
           tw-flex
           tw-items-center
           tw-justify-center
           tw-p-0
           tw-w-6
           tw-h-6
           tw--mr-1`,
    tagColor: {
      light: 'hover:tw-bg-gray-200',
      primary: 'hover:tw-bg-cornflower-200',
    },
  },
}

export interface Tag {
  id: number | string
  name: string
}

interface TagInputProps {
  selectedTags: Tag[]
  removeTag: (tag: Tag) => void
  handleSubmit: (value: string) => void
  inputValue: string
  onInputChange: (value: string) => void
  focusOnMount?: boolean
  error?: boolean
  placeholder?: string
  tagColor?: 'light' | 'primary'
  className?: HTMLAttributes<HTMLDivElement>['className']
}

export default function TagInput({
  className = '',
  error = false,
  focusOnMount = false,
  handleSubmit,
  inputValue,
  onInputChange,
  placeholder = '',
  removeTag,
  selectedTags,
  tagColor = 'light',
}: TagInputProps) {
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef(null)
  const wrapperId = 'tag_input'

  function handleDivClick(e) {
    if (e.target.id === wrapperId) {
      setIsFocused(true)
      inputRef.current.focus()
    }
  }

  useEffect(() => {
    if (focusOnMount) {
      setIsFocused(true)
      inputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={className}>
      <div
        onClick={handleDivClick}
        id={wrapperId}
        className={`${classNames.container.base} ${isFocused && !error && classNames.container.focused} ${
          error && classNames.container.error
        }`}
      >
        {selectedTags.map((tag) => (
          <div
            className={`${classNames.tagContainer.base} ${classNames.tagContainer.tagColor[tagColor]}`}
            key={tag.id || tag.name}
          >
            <span className="tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">{tag.name}</span>
            <button
              onClick={() => {
                removeTag(tag)
              }}
              className={`${classNames.xButton.base} ${classNames.xButton.tagColor[tagColor]}`}
              data-testid="tag-input-remove-tag"
            >
              <X className="lu-sm" />
            </button>
          </div>
        ))}
        <form
          onSubmit={(event) => {
            event.preventDefault()
            handleSubmit(event.target[0].value)
          }}
          className="tw-flex-1"
          style={{ minWidth: '150px' }}
        >
          <input
            ref={inputRef}
            value={inputValue}
            onChange={(e) => {
              onInputChange(e.target.value)
            }}
            onFocus={() => {
              setIsFocused(true)
            }}
            onBlur={() => {
              setIsFocused(false)
            }}
            className="tw-h-8 tw-w-full tw-border-none tw-outline-none"
            placeholder={!selectedTags.length ? placeholder : ''}
          />
        </form>
      </div>
    </div>
  )
}
