type UpdateCallback = (value: unknown) => unknown

const hostState = {
  isStorable: null,
}

const PREFIX = 'jarUi_'

export function getIsStorable(): boolean {
  if (hostState.isStorable === null) {
    const r = Math.ceil(Math.random() * 9999).toString()
    try {
      localStorage.setItem(`${PREFIX}juljbhyqlbhqbguvf`, r)
      localStorage.removeItem('jarUi_juljbhyqlbhqbguvf')
      hostState.isStorable = true
    } catch {
      console.error('localStorage is not available')
      hostState.isStorable = false
    }
  }
  return hostState.isStorable
}

export function read<type>(key: string): type {
  getIsStorable()

  if (hostState.isStorable) {
    try {
      return JSON.parse(localStorage.getItem(PREFIX + key)) as type
    } catch (e) {
      console.error(`key "${key} could not be read from localStorage.`, e)
      return null
    }
  } else {
    return null
  }
}

export function remove(key: string) {
  getIsStorable()

  if (hostState.isStorable) {
    try {
      localStorage.removeItem(PREFIX + key)
    } catch (e) {
      console.error(`key "${key} could not be removed from localStorage.`, e)
    }
  }
}

export function update<type>(key: string, callback: UpdateCallback) {
  const value = read(key) as type
  const newValue = callback(value)
  write(key, newValue)
}

export function write<type>(key: string, value: type) {
  getIsStorable()

  if (hostState.isStorable) {
    try {
      localStorage.setItem(PREFIX + key, JSON.stringify(value))
    } catch (e) {
      console.error(`key "${key} could not be written to localStorage.`, e)
    }
  }
}
