import { useMemo, useState } from 'react'
import { allSizesAsSkillSizes, skillSizeDetailsWithName } from 'lib/util/skill-sizes/skill-sizes'
import Button from 'components/core/button'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import AdminEditTicketDetailsModal from './admin-edit-ticket-details-modal'
import { Copy, Pencil, ThumbsDownIcon, ThumbsUpIcon } from 'lucide-react'
import Badge from 'lib/components/badge/badge'
import { toast } from 'lib/components/toast/toast'
import { TicketFeedbackValues } from 'interfaces/ticket_feedback'
import { getHoursFromMinutes } from 'lib/util/date'

export default function AdminTicketDetails() {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { ticket } = useAdminTicketContext()

  const ticketPermissions = ticket.meta.permissions

  const allSizes = useMemo(() => {
    const sizes = allSizesAsSkillSizes(ticket.selectedSkillSizes, ticket.size).map(skillSizeDetailsWithName)
    return sizes
  }, [ticket])

  const classNames = {
    detailItem: 'tw-flex tw-flex-col tw-gap-1',
    detailItemTitle: 'tw-text-gray-400',
    detailItemTags: 'tw-flex tw-gap-1 tw-flex-wrap tw-max-w-xs',
  }

  const handleQAIDCopy = async () => {
    await navigator.clipboard.writeText(ticket.qaReviews[0].id.toString())
    toast.success('Value Copied to Clipboard')
  }

  const EditLink = (props: { 'data-testid'?: string }) => (
    <a
      data-testid={`edit-${props['data-testid']}`}
      className="tw-ml-1 tw-inline-flex tw-cursor-pointer tw-gap-1 tw-align-bottom"
      onClick={() => setEditModalOpen(true)}
    >
      <Pencil className="lu-light lu-sm" />
      Edit
    </a>
  )

  return (
    <div>
      <header className="tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-p-4">
        <h4 className="tw-m-0">Ticket Details</h4>
      </header>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4">
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Client name</span>
          <span>
            <a
              href={`/admin/users?user_id=${ticket.submittedBy.id}`}
              target="_blank"
              rel="noreferrer"
              className="tw-font-semibold"
            >
              {ticket.submittedBy.fullName}
            </a>
          </span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Client company</span>
          <span>
            <a
              href={`/admin/companies?company_id=${ticket.company.id}`}
              target="_blank"
              rel="noreferrer"
              className="tw-font-semibold"
            >
              {ticket.company.name}
            </a>
          </span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Request type</span>
          <span>
            {ticket.skill.name} {ticketPermissions.update.skillId && <EditLink data-testid="skill-id" />}
          </span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>File size(s)</span>
          <>
            {allSizes.map(({ size, name }) => (
              <div key={`${name}${size}`}>
                <span className="tw-font-bold">{name}</span> - {size}
              </div>
            ))}
          </>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>File format(s)</span>
          <span>{ticket.selectedFormats.join(', ')}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Design time</span>
          <span>
            <span className="tw-text-black">{getHoursFromMinutes(ticket.designTime)}</span>
            <span className="tw-text-neutral-600"> / {ticket?.companyDailyAvailableTime / 60} hr</span>
            {ticketPermissions.update.todaysWeight && <EditLink data-testid="todays-weight" />}
          </span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Brand Profile</span>
          <span>
            {!!ticket.selectedBrand && (
              <a href="#" className="tw-font-semibold">
                {ticket?.selectedBrand.name}
              </a>
            )}
            {!ticket.selectedBrand && <em>None</em>}
          </span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Number of revisions</span>
          <span>{ticket.revisions}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Customer position</span>
          <span>{ticket.position}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Updated</span>
          <span>{new Date(ticket.updatedAt).toLocaleString()}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Submitted</span>
          <span>{ticket.submittedAt ? new Date(ticket.submittedAt).toLocaleString() : 'Unknown'}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Creative</span>
          <span>{ticket.designer ? ticket.designer.name : 'No Creative Associated'}</span>
        </div>
        {ticket.qaReviews?.[0]?.id && (
          <div className={classNames.detailItem}>
            <span className={classNames.detailItemTitle}>Quality Review</span>
            <div className="tw-flex tw-cursor-pointer hover:tw-opacity-70" onClick={handleQAIDCopy}>
              <Copy className="lu-sm tw-mr-2" />
              <span className="">#{ticket.qaReviews[0].id}</span>
            </div>
          </div>
        )}
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Source</span>
          <span>{ticket.source}</span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Is this a training ticket?</span>
          <span>
            {ticket.training ? 'Yes' : 'No'} {ticketPermissions.update.training && <EditLink data-testid="training" />}
          </span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Covered by a different designer?</span>
          <span>
            {ticket.covered ? 'Yes' : 'No'} {ticketPermissions.update.covered && <EditLink data-testid="covered" />}
          </span>
        </div>
        <div className={classNames.detailItem}>
          <span className={classNames.detailItemTitle}>Did the designer make an error?</span>
          <span>
            {ticket.designerError ? 'Yes' : 'No'}&nbsp;
            {ticketPermissions.update.designerError && <EditLink data-testid="designer-error" />}
          </span>
        </div>
        {ticket.tags.length > 0 && (
          <div className={classNames.detailItem}>
            <span className={classNames.detailItemTitle}>Tags</span>
            <div className={classNames.detailItemTags}>
              {ticket.tags.map(({ id, name }) => (
                <Badge color="light" key={id} fontWeight="normal" className="tw-text-berry">
                  {name}
                </Badge>
              ))}
            </div>
          </div>
        )}
        {ticket.ticketFeedback && (
          <div className={classNames.detailItem}>
            <span className={classNames.detailItemTitle}>Client Feedback</span>
            <div className="tw-flex tw-gap-2">
              {ticket.ticketFeedback.thumbRating === TicketFeedbackValues.ThumbsUp && (
                <ThumbsUpIcon className="lu-md" data-testid="thumbs-up-icon" />
              )}
              {ticket.ticketFeedback.thumbRating === TicketFeedbackValues.ThumbsDown && (
                <ThumbsDownIcon className="lu-md" data-testid="thumbs-down-icon" />
              )}
              <div>{ticket.ticketFeedback.reasons}</div>
            </div>
            <div>{ticket.ticketFeedback.privateFeedback}</div>
            <div>Rated on: {new Date(ticket.ticketFeedback.createdAt).toLocaleString()}</div>
          </div>
        )}
        <div className="tw-sticky tw-bottom-6 tw-flex tw-w-full tw-items-center tw-justify-center">
          <Button color="lightGray" onClick={() => setEditModalOpen(true)} className="tw-flex tw-items-center tw-gap-2">
            <Pencil className="lu-light lu-md" /> Edit Ticket Details
          </Button>
          <AdminEditTicketDetailsModal open={editModalOpen} setOpen={setEditModalOpen} />
        </div>
      </div>
    </div>
  )
}
