import { useState } from 'react'
import { Format } from 'interfaces/format'
import { Switch } from '@headlessui/react'
import { SkillCategory } from 'interfaces/skill-category'
import SelectBox from 'lib/components/dropdown/select-box'
import TextInput from 'components/core/text-input/text-input'
import Button from 'components/core/button'
import { routes, createSkill } from 'lib/api/admin/skills/skills'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { Toaster, toast } from 'lib/components/toast/toast'
import { cn } from 'lib/util/cn'

const classNames = {
  container: 'tw-container tw-px-3 tw-mt-14',
  option: 'tw-mx-1 tw-flex-grow tw-text-right',
  select: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md',
  switchGroup: 'tw-flex tw-items-center',
  switchLabel: 'tw-mr-1 tw-mb-0 tw-text-black tw-uppercase',
  switchToggle: {
    disabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw--translate-x-1',
    enabled:
      'tw-pointer-events-none tw-inline-block tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out tw-translate-x-4',
  },
  switchWrapper: 'tw-flex tw-justify-between tw-mt-4 tw-max-w-md tw-grid tw-grid-cols-3 tw-gap-4',
  wrapper: 'tw-flex tw-w-full tw-flex-col md:tw-flex-row',
}

interface SkillCreatePageProps {
  skillCategories: SkillCategory[]
  formats: Format[]
}

function AdminSkillsEdit({ skillCategories, formats }: SkillCreatePageProps) {
  const [name, setName] = useState<string>('')
  const [permalink, setPermalink] = useState<string>('')
  const [averageTime, setAverageTime] = useState<number>(0)
  const [skillCategoryId, setSkillCategoryId] = useState<number>(0)
  const [selectedFormats, setSelectedFormats] = useState<Format[]>([])
  const [image, setImage] = useState<File | null>(null)
  const [updating, setUpdating] = useState<boolean>(false)

  const scopeOfService = () =>
    skillCategories.find(({ id }) => id === skillCategoryId)?.scopeOfService || 'No Category Selected'
  const options = () =>
    skillCategories.map(({ id, name }) => ({
      value: id.toString(),
      displayElement: <SkillCategoryOption categoryName={name} />,
    }))

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    setUpdating(true)
    const params = new FormData()
    params.append('name', name)
    params.append('average_time', averageTime?.toString())
    params.append('permalink', permalink)
    params.append('skill_category_id', skillCategoryId?.toString())
    if (image !== null) {
      params.append('image', image)
    }
    selectedFormats?.forEach(({ id }) => params.append('format_ids[]', id.toString()))

    try {
      const { status } = await createSkill(params)
      if (status === 201) {
        toast.success('Skill created successfully')
        window.open(routes.index, '_self')
      }
    } catch (error) {
      console.error(error)
      toast.error('Something went wrong - please try again.')
    } finally {
      setUpdating(false)
    }
  }

  if (updating) {
    return <LoadingScreen />
  }

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.container}>
        <a href={routes.index} className="tw-mb-4 tw-block tw-text-sm tw-text-gherkin-500">
          Back to Skills
        </a>
        <h1 className="tw-mb-4 tw-text-2xl tw-font-semibold">{'New Skill'}</h1>
        <form data-testid="edit-form" onSubmit={handleSubmitForm}>
          <div className="tw-flex tw-flex-col tw-pb-2">
            <input type="file" name="image" onChange={(e) => setImage(e.target.files[0])} />
          </div>
          <TextInput
            data-testid="name"
            label="Name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            name="name"
          />
          <TextInput
            label="Permalink"
            onChange={(e) => setPermalink(e.target.value)}
            value={permalink}
            name="permalink"
          />
          <TextInput
            label="Average Skill Time"
            value={averageTime}
            onChange={(e) => setAverageTime(+e.target.value)}
            min={0}
            step={1}
            type="number"
            name="averageTime"
          />
          <div className={classNames.select}>
            <SelectBox
              data-testid="skill-category"
              label="Skill Category"
              handleChange={(skillCategoryId) => setSkillCategoryId(+skillCategoryId)}
              selectedValue={skillCategoryId?.toString() || ''}
              options={options()}
              className="tw-min-w-48"
            />
          </div>
          <h5 className="tw-mt-4">
            Scope of Service:
            <span className="tw-ml-2 tw-text-peppercorn">{scopeOfService()}</span>
          </h5>
          <h5 className="tw-mt-4">Formats</h5>
          <FormatList formats={formats} selectedFormats={selectedFormats} setSelectedFormats={setSelectedFormats} />
          <div className="tw-mt-8"></div>
          <Button data-testid="submit-button" color="green" type="submit">
            Submit
          </Button>
        </form>
      </div>
    </div>
  )
}

function SkillCategoryOption({ categoryName }: { categoryName: string }) {
  return <div className={classNames.option}>{categoryName}</div>
}

interface FormatListProps {
  formats: Format[]
  selectedFormats: Format[]
  setSelectedFormats: (formats: Format[]) => void
}

function FormatList({ formats, selectedFormats, setSelectedFormats }: FormatListProps) {
  const selectedFormat = (formatId) => selectedFormats?.map(({ id }) => id).includes(formatId)

  return (
    <div className={classNames.switchWrapper}>
      {formats.map((format) => (
        <Switch.Group key={format.id}>
          <div className={classNames.switchGroup}>
            <Switch.Label className={classNames.switchLabel}>{format.name}</Switch.Label>
            <Switch
              checked={selectedFormat(format.id)}
              onChange={(checked) =>
                setSelectedFormats(
                  checked ? [...selectedFormats, format] : selectedFormats.filter(({ id }) => id !== format.id),
                )
              }
              className={cn(
                selectedFormat(format.id) ? 'tw-bg-gherkin-500' : 'tw-bg-neutral-200',
                'tw-relative tw-inline-flex tw-h-[18px] tw-w-[38px] tw-flex-shrink-0 tw-cursor-pointer tw-rounded-full tw-border-none tw-p-0.5 tw-transition-colors tw-duration-200 tw-ease-in-out',
              )}
            >
              <span className="sr-only">{format.id}</span>
              <span
                aria-hidden="true"
                className={cn(
                  'tw-pointer-events-none tw-inline-block tw-h-[14px] tw-w-[14px] tw-transform tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out',
                  selectedFormat(format.id) ? 'tw-translate-x-[20px]' : 'tw--translate-x-0',
                )}
              />
            </Switch>
          </div>
        </Switch.Group>
      ))}
    </div>
  )
}

export default function AdminSkillsCreatePage(props: SkillCreatePageProps) {
  return (
    <>
      <Toaster />
      <AdminSkillsEdit {...props} />
    </>
  )
}
