import { Plus } from 'lucide-react'
import { LinkButton } from 'components/core/button'

export default function CreateButton() {
  return (
    <LinkButton url="/create" color="purple" data-testid="create-button">
      <Plus className="lu-light" /> Create
    </LinkButton>
  )
}
