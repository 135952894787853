import { Editor } from '@tiptap/react'
import Button from 'components/core/button'
import { init as filestackInit } from 'filestack-js'
import { FilestackFile } from 'lib/util/filestack'
import { useAdminUserContext } from 'providers/admin-user-provider'
import { useState } from 'react'
import Modal from '../modals/modal'

interface WYSIWYGFileModalProps {
  editor: Editor
  onFilesUploaded: (files: FilestackFile[]) => void
}

interface Token {
  cancel?: () => void
}

const WYSIWYGFileModal = ({ editor, onFilesUploaded }: WYSIWYGFileModalProps) => {
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false)
  const [numFilesUploading, setNumFilesUploading] = useState<number>(0)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [uploadToken, setUploadToken] = useState<Token>()
  const { settings } = useAdminUserContext()
  const client = settings?.filestackApiKey ? filestackInit(settings.filestackApiKey) : null

  const onFilesChange = async (fileList: FileList) => {
    if (!client) return
    const files = Array.from(fileList)
    setNumFilesUploading(files.length)
    setShowUploadModal(true)
    const token = {} as Token
    client
      .multiupload(
        files,
        {
          onProgress: ({ totalPercent }) => {
            if (!uploadToken?.cancel) {
              setUploadToken(token)
            }
            setUploadProgress(totalPercent)
          },
          progressInterval: 100,
        },
        {},
        token,
      )
      .then(async (res) => {
        setShowUploadModal(false)
        onFilesUploaded(res.filter(({ status }) => status === 'InTransit'))
      })
  }

  const cancelUpload = () => {
    setShowUploadModal(false)
    uploadToken?.cancel?.()
  }

  editor.setOptions({
    editorProps: {
      handleDrop: (_view, event, _slice, moved) => {
        if (!moved && event?.dataTransfer?.files?.length > 0) {
          onFilesChange(event.dataTransfer.files)
          return true
        }
        return false
      },
      handlePaste: (_view, event) => {
        if (event?.clipboardData?.files?.length > 0) {
          onFilesChange(event.clipboardData.files)
          return true
        }
        return false
      },
    },
  })

  return (
    <Modal open={showUploadModal}>
      Uploading {numFilesUploading} files: {uploadProgress}%
      <hr />
      <Button color="lightGray" className="tw-float-right" onClick={cancelUpload}>
        Cancel
      </Button>
    </Modal>
  )
}

export default WYSIWYGFileModal
