import { JSX } from 'react'
import ReactPaginate from 'react-paginate'

interface Props {
  forcePage?: number
  pageCount: number
  onPageChange: (selectedItem: { selected }) => void
}

export default function Pagination(props: Props): JSX.Element {
  return (
    <div className="tw-flex-grow">
      <ReactPaginate
        activeClassName="tw-border-gherkin tw-text-gherkin hover:tw-text-gherkin hover:tw-border-gherkin tw-border-t-2 tw-inline-flex tw-items-center tw-text-sm tw-font-bold tw--mt-px tw-border-l-0 tw-border-r-0 tw-border-b-0 tw-border-solid tw-border-gherkin"
        activeLinkClassName="tw-border-t-2 tw-border-gherkin tw-text-gherkin hover:tw-text-gherkin hover:tw-border-gherkin tw-border-t-2 tw-inline-flex tw-items-center tw-text-sm tw-font-bold tw-pt-3"
        breakClassName="tw-hidden md:tw--mt-px md:tw-flex"
        breakLinkClassName="tw-border-t-2 tw-border-transparent tw-inline-flex tw-items-center tw-text-sm tw-font-semibold tw-text-peppercorn hover:tw-text-gherkin hover:tw-border-gherkin tw-justify-center tw-px-3 tw-pt-3"
        containerClassName="tw-border-t tw-border-l-0 tw-border-r-0 tw-border-b-0 tw-border-solid tw-border-peppercorn-50 tw-px-4 tw-flex tw-items-center tw-justify-between sm:tw-px-0 tw-mb-3"
        disabledClassName="hover:tw-text-peppercorn tw-pt-0"
        forcePage={props.forcePage}
        marginPagesDisplayed={3}
        nextClassName="tw--mt-px tw-w-0 tw-flex-1 tw-flex tw-justify-end"
        nextLabel="Next >"
        nextLinkClassName="tw-border-t-2 tw-border-transparent tw-pt-4 tw-pl-1 tw-inline-flex tw-items-center tw-text-sm tw-font-medium tw-text-peppercorn hover:tw-text-gherkin hover:tw-border-gherkin tw-pt-3"
        onPageChange={props.onPageChange}
        pageClassName="tw-hidden md:tw--mt-px md:tw-flex"
        pageCount={props.pageCount}
        pageLinkClassName="tw-border-t-2 tw-border-transparent tw-inline-flex tw-items-center tw-text-sm tw-font-semibold tw-text-peppercorn hover:tw-text-gherkin hover:tw-border-gherkin tw-justify-center tw-px-3 tw-pt-3"
        pageRangeDisplayed={2}
        previousClassName="tw--mt-px tw-w-0 tw-flex-1 tw-flex tw-justify-start"
        previousLabel="< Previous"
        previousLinkClassName="tw-border-t-2 tw-border-transparent tw-pt-4 tw-pl-1 tw-inline-flex tw-items-center tw-text-sm tw-font-medium tw-text-peppercorn hover:tw-text-gherkin hover:tw-border-gherkin tw-pt-3"
      />
    </div>
  )
}
