import { ReactElement, ReactNode, useMemo } from 'react'
import { useCollaboratorTicketContext } from '../collaborator-ticket-provider'
import { DesignRequestStatus } from 'lib/api/tickets/tickets'
import { TicketStatusBadge } from 'lib/components/badge/badge'
import Button from 'components/core/button'

const classNames = {
  actions: 'tw-text-right',
  container: 'tw-flex tw-items-start tw-justify-between tw-px-3 tw-pb-3 tw-pt-4',
  details: 'tw-flex tw-items-center tw-mb-2',
  feedbackCount: 'tw-rounded-full tw-bg-white tw-text-neutral-800 tw-px-2 tw-mr-2',
  subject: 'tw-m-0 tw-ml-3',
}

export default function CollaboratorTicketHeader(): ReactElement {
  const { submitFeedback, ticket } = useCollaboratorTicketContext()
  const requestSubject = ticket?.subject || ''
  const isInReview = useMemo(() => ticket?.status === DesignRequestStatus.review, [ticket?.status])
  const isInDraft = useMemo(() => ticket?.status === DesignRequestStatus.draft, [ticket?.status])
  const canSubmitFeedback = isInReview || isInDraft
  const updateCount = useMemo(() => {
    return ticket?.meta.pendingUpdates || 0
  }, [ticket?.meta.pendingUpdates])

  async function handleSubmitFeedback() {
    try {
      await submitFeedback()
    } catch (e) {
      console.error('CollaboratorTicketHeader Error:', e)
    }
  }

  return (
    <header>
      <div className={classNames.container}>
        <div className={classNames.details}>
          <h2 className={classNames.subject}>{requestSubject}</h2>
          <TicketStatusBadge status={ticket?.status} />
        </div>
        <div className={classNames.actions}>
          {canSubmitFeedback && (
            <FeedbackButton count={updateCount} onClick={handleSubmitFeedback}>
              Send Feedback
            </FeedbackButton>
          )}
        </div>
      </div>
    </header>
  )
}

interface FeedbackButtonProps {
  children: ReactNode
  count: number
  onClick: () => void
}

function FeedbackButton({ children, count, onClick }: FeedbackButtonProps): ReactElement {
  const color = count ? 'purple' : 'lightGray'
  return (
    <Button type="button" color={color} disabled={!count} onClick={onClick}>
      {!!count && <span className={classNames.feedbackCount}>{count}</span>}
      {children}
    </Button>
  )
}
