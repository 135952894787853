import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import { colorStatus, initScripts, highlightSelected, commitStateIntoStore } from '../helpers/queue_helper'

const PER_PAGE = 10
export default class extends Controller {
  static targets = [
    'atmList',
    'middleBlock',
    'rightBlock',
    'loading',
    'detailsContainer',
    'detailsLoading',
    'conversationLoading',
    'ticketsInfo',
    'loadMore',
    'emptyResponse'
  ]
  static values = { positionUrl: String, url: String }

  initialize() {
    this.ticketId = null
    this.currentPage = 1
    this.initState()
  }

  updateTicketDetails() {
    $.unblockUI()
    this.fetchDetailsBlock(this.ticketId)
    $('html, body').animate({ scrollTop: 0 }, 'slow')
  }

  updateTicketConversations() {
    $.unblockUI()
    this.fetchConversationsBlock(this.ticketId)
    $('html, body').animate({ scrollTop: 0 }, 'slow')
  }

  connect() {
    this.fetchTickets(1);
  }

  initState() {
    this.emptyResponseTarget.hidden = true;
    this.middleBlockTarget.hidden = true;
    this.loadingTarget.hidden = false;
    this.detailsContainerTarget.hidden = false;
    this.conversationLoadingTarget.hidden = true;
    this.detailsLoadingTarget.hidden = true;
  }

  displayTicket(event) {
    highlightSelected(event.currentTarget)
    this.ticketId = event.currentTarget.dataset.id;
    this.renderTicket(this.ticketId)
  }

  renderTicket(ticketId) {
    this.detailsContainerTarget.hidden = false;
    this.detailsLoadingTarget.hidden = false;
    this.conversationLoadingTarget.hidden = false;
    this.rightBlockTarget.hidden = true;

    this.fetchConversationsBlock(ticketId)
    this.fetchDetailsBlock(ticketId)
  }

  renderTickets(tickets) {
    const atmListHTML = tickets.map((ticket) => this.commitTemplate(ticket));
    const atmSortable = document.getElementById('atm-sortable');

    this.atmListTarget.innerHTML = atmListHTML;
    this.sortable = new Sortable(atmSortable, {
      ...this.defaultOptions,
      ...this.options,
      onUpdate: (e) => {
        commitStateIntoStore(this.positionUrlValue)
      },
    })

    this.loadingTarget.hidden = true;
  }

  loadMoreTickets() {
    this.loadMoreTarget.text = 'Loading...'
    this.fetchTickets(++this.currentPage)
  }

  renderConversationsBlock(html) {
    this.middleBlockTarget.innerHTML = html;
    this.middleBlockTarget.hidden = false;

    initScripts(this.middleBlockTarget, html)
  }

  renderDetailsBlock(html) {
    this.rightBlockTarget.innerHTML = html
    this.rightBlockTarget.hidden = false
    this.detailsContainerTarget.hidden = true;

    initScripts(this.rightBlockTarget, html)
  }

  fetchTickets(page) {
    const params = new URLSearchParams(window.location.search);
    const url = new URL(`${this.urlValue}/queue`);
    let oneTicketId = null;

    url.searchParams.append('per_page', PER_PAGE);
    url.searchParams.append('page', page);

    if (params.has('type')) {
        url.searchParams.append('type', params.get('type'));
    } else if (params.has('id')) {
        oneTicketId = params.get('id')
        url.searchParams.append('id', oneTicketId);
    }

    this.loadingTarget.hidden = false;
    this.detailsLoadingTarget.hidden = false;
    this.conversationLoadingTarget.hidden = false;

    fetch(
      url,
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Accept': 'application/json'
        }
      })
      .then(data => data.json())
      .then(data => this.order(data))
      .then(data => this.pager(data))
      .then(data => data.map(colorStatus))
      .then(data => {
        if(data.length === 0) {
          this.emptyResponseTarget.hidden = false;
          this.loadMoreTarget.hidden = true;
        } else {
          this.emptyResponseTarget.hidden = true;
          this.renderTickets(data);
          this.loadMoreTarget.text = 'Load more'
        }
        this.conversationLoadingTarget.hidden = true;
        this.detailsLoadingTarget.hidden = true;
        this.loadingTarget.hidden = true;
      }
    );

    // Render ticket if it is single (direct link to the ticket)
    if (oneTicketId) {
      this.renderTicket(oneTicketId);
    }
  }

  fetchConversationsBlock(ticketId) {
    $.ajax({
      url: `${this.urlValue}/${ticketId}?block=conversations`,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
      }
    }).then(data => {
      this.conversationLoadingTarget.hidden = true;
      this.renderConversationsBlock(data)
    })
  }

  fetchDetailsBlock(ticketId) {
    $.ajax({
      url: `${this.urlValue}/${ticketId}?block=details`,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
      }
    }).then(data => {
      this.detailsLoadingTarget.hidden = true;
      this.rightBlockTarget.hidden = false;
      this.renderDetailsBlock(data);
    })
  }

  commitTemplate({ queue_position, statusColor, subject, id, short_title }) {
    const html =
      `<li data-id='${queue_position.id}'>` +
        "<div class='atm-item'>" +
          "<div class='handle'><i class='fas fa-grip-vertical'></i></div>" +
          `<div class='${statusColor}'><i class='fas fa-circle'></i></div>` +
          `<div class='atm-item-name' title='${subject}' data-id='${id}' data-action='click->admin--tickets#displayTicket'>` +
            `#${short_title}` +
          '</div>'
      '</div>'
    '</li>'

    return html
  }

  order(data) {
    data['tickets'].sort((currentTicket, nextTicket) => currentTicket['queue_position']['position'] - nextTicket['queue_position']['position']);
    return data;
  }

  pager(data) {
    if (this.currentPage == 1) {
      this.totalTickets = data['total_tickets']

      if (data['todays_workload']) {
        this.ticketsInfoTarget.innerHTML = `Today’s workload: <b>${data['todays_workload']}</b>`
      } else {
        this.ticketsInfoTarget.innerHTML = `Tickets total: <b>${this.totalTickets}</b>`
      }
    }

    if (this.currentPage * PER_PAGE < this.totalTickets) {
      this.loadMoreTarget.hidden = false
    } else {
      this.loadMoreTarget.hidden = true
    }

    return data['tickets']
  }
}
