export default function debounce(callback: (...args) => void, delay: number, isLeading = false) {
  let timerId
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId)
    } else if (isLeading) {
      callback(...args)
    }

    timerId = setTimeout(() => {
      if (!isLeading) {
        callback(...args)
      }
      timerId = null
    }, delay)
  }
}
