import { baseRequest, BaseResponse, getApi } from '../api'

export interface QaSpecialist {
  id: number
  name: string
  state: string
}

export interface QaSpecialistsResponse extends BaseResponse {
  specialists: QaSpecialist[]
}

const qaReviewsBaseUrl = '/api/internal/qa_specialists'

export async function getQaSpecialists(): Promise<QaSpecialistsResponse> {
  const url = `${qaReviewsBaseUrl}`
  const response = await baseRequest(url, getApi)

  return {
    error: response.error,
    status: response.status,
    specialists: response.data.qa_specialists as unknown as QaSpecialist[],
  }
}
