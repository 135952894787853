import { useQuery } from '@tanstack/react-query'
import { requestQuery } from 'lib/api/fetch-api'

export const useFirstRender = () => {
  const ticketQuery = useQuery({
    queryKey: ['tickets'],
    queryFn: requestQuery({
      endpoint: 'getTickets',
      query: {
        first_render: 'true',
        page: 1,
      },
    }),
  })

  return {
    ticketQuery,
    loading: ticketQuery.isLoading,
    brands: ticketQuery.data?.brands,
    users: ticketQuery.data?.users,
    statuses: ticketQuery.data?.statuses,
    skills: ticketQuery.data?.categories,
    formats: ticketQuery.data?.formats,
  }
}
