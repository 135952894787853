import { PATHS } from 'lib/constants/paths'
import { Clock, FileChartColumn, Palette, Share2, Ticket, User, Users, Wrench } from 'lucide-react'
import { IconMenuVariant, MenuItem } from 'lib/components/menu-item/menu-item'
import { UserWithFeatureAndAbilities } from 'interfaces/user'
import { cn } from 'lib/util/cn'

const filterMenuItems = (items: MenuItem[]) => {
  if (items.length === 0) return []

  return items
    .filter((item) => item.permission !== false)
    .map((item): MenuItem => {
      if (item.submenu) {
        return { ...item, submenu: filterMenuItems(item.submenu) }
      }
      return item
    })
    .filter((item) => item.path || !!item.submenu?.length)
}

export const getAdminItems = (userAbilities: UserWithFeatureAndAbilities) => {
  const { abilities, features } = userAbilities

  const toolsAdminItem: MenuItem = {
    label: 'Admin',
    submenu: [
      {
        label: 'Internship Bot',
        path: PATHS.ADMIN_WATSON_INTERNSHIP,
      },
      {
        label: 'Tags',
        path: PATHS.ADMIN_TAGS,
      },
    ],
  }

  const toolsAutoAssignerItem: MenuItem = {
    label: 'Auto Assigner',
    submenu: [
      {
        label: 'Pro Subscriptions DPU',
        path: PATHS.ADMIN_PRO_SUBSCRIPTIONS,
        permission: abilities.readDpu,
      },
      {
        label: 'Graphics Subscriptions DPU',
        path: PATHS.ADMIN_SCALE_SUBSCRIPTIONS,
        permission: abilities.readDpu,
      },
      {
        label: 'Company Designer Allocations',
        path: PATHS.ADMIN_COMPANY_DESIGNER_ALLOCATIONS,
        permission: abilities.readCompanyDesignerAllocation,
      },
      {
        label: 'Blocklisted Designers',
        path: PATHS.ADMIN_BLOCKLISTS,
        permission: abilities.readBlocklist,
      },
      {
        label: 'Time Zones',
        path: PATHS.ADMIN_AUTO_ASSIGNER_TIME_ZONES,
        permission: abilities.readAutoAssignerTimeZone,
      },
    ],
  }

  const toolsFreshStockItem: MenuItem = {
    label: 'FreshStock',
    submenu: [
      {
        label: 'FreshStock Assets',
        path: PATHS.ADMIN_FRESH_STOCK_ASSETS,
        permission: abilities.readFreshStockAsset,
      },
    ],
  }

  const toolsCustomerSuccessItem: MenuItem = {
    label: 'Customer Success',
    submenu: [
      {
        label: 'Announcements',
        path: PATHS.ADMIN_ANNOUNCEMENTS,
        permission: abilities.readAnnouncement,
      },
      {
        label: 'Canned Responses',
        path: PATHS.ADMIN_CANNED_RESPONSES,
        permission: abilities.readCannedResponse,
      },
      {
        label: 'Coupons',
        path: PATHS.ADMIN_PICKLE_COUPONS,
        permission: abilities.readPickleCoupon,
      },
      {
        label: 'Emails',
        path: PATHS.ADMIN_EMAILER,
        permission: abilities.manageNotification,
      },
      {
        label: 'Plans',
        path: PATHS.ADMIN_PICKLE_PLANS,
        permission: abilities.managePicklePlan,
      },
    ],
  }

  const toolsProductionItem: MenuItem = {
    label: 'Production',
    submenu: [
      {
        label: 'Feedback',
        path: PATHS.ADMIN_FEEDBACK,
        permission: abilities.readAdminFeedback,
      },
      {
        label: 'Work Availability Calendar',
        path: PATHS.ADMIN_AVAILABILITY_SCHEDULE,
        permission: abilities.calendarPendingLeaveDay,
      },
      {
        label: 'Request Type Categories',
        path: PATHS.ADMIN_SKILL_CATEGORIES,
        permission: abilities.readSkillCategory,
      },
      {
        label: 'Request Types',
        path: PATHS.ADMIN_SKILLS,
        permission: abilities.readSkill,
      },
      {
        label: 'Formats',
        path: PATHS.ADMIN_FORMATS,
        permission: abilities.readFormat,
      },
    ],
  }

  const adminItems: MenuItem[] = [
    {
      label: 'Tickets',
      Icon: Ticket,
      submenu: [
        {
          label: 'All Tickets',
          path: PATHS.ADMIN_TICKETS,
        },
        {
          label: 'Assigned to me',
          path: PATHS.ADMIN_TICKETS_ME,
          permission: features.productionTicketExperience,
        },
        {
          label: 'Assigned to Team',
          path: PATHS.ADMIN_ASSIGNED_TO_TEAM,
          permission: abilities.manageTicket,
        },
      ],
    },
    {
      label: 'People',
      Icon: User,
      submenu: [
        {
          label: 'Companies',
          path: PATHS.ADMIN_COMPANIES,
        },
        {
          label: 'Customers',
          path: PATHS.ADMIN_USERS,
          permission: abilities.readUser,
        },
      ],
    },
    {
      label: 'Teams',
      Icon: Users,
      submenu: [
        {
          label: 'APMs',
          path: PATHS.ADMIN_APMS,
          permission: abilities.readApm,
        },
        {
          label: 'Designers',
          path: PATHS.ADMIN_DESIGNERS,
          permission: abilities.readDesigner,
        },
        {
          label: 'Quality Specialists',
          path: PATHS.ADMIN_QA_SPECIALISTS,
          permission: abilities.readQaSpecialist,
        },
        {
          label: 'TAGs',
          path: PATHS.ADMIN_TAG_TEAMS,
          permission: abilities.readTagTeam,
        },
        {
          label: 'Team Dashboard',
          path: PATHS.ADMIN_TEAMS_DASHBOARD,
          permission: abilities.readApm,
        },
        {
          label: 'Teams',
          path: PATHS.ADMIN_QR_TEAMS,
          permission: abilities.readQrTeam,
        },
      ],
    },
    {
      label: 'Reports',
      Icon: FileChartColumn,
      submenu: [
        {
          label: 'At A Glance',
          path: PATHS.ADMIN_TICKETS_OVERVIEW,
          permission: abilities.designerAttendance,
        },
        {
          label: 'Attendance',
          path: PATHS.ADMIN_ATTENDANCE,
          permission: abilities.designerAttendance,
        },
        {
          label: 'Cancellations',
          path: PATHS.ADMIN_REPORTS_CANCELLATIONS,
          permission: abilities.readCancellation,
        },
        {
          label: 'Conversations',
          path: PATHS.ADMIN_CONVERSATIONS,
          permission: abilities.readConversation,
        },
        {
          label: 'Quality Reviews',
          path: PATHS.ADMIN_QA_REVIEWS,
          permission: abilities.readQaReview,
        },

        {
          label: 'Designer Records',
          path: PATHS.ADMIN_DESIGNER_TRACKINGS,
          permission: abilities.readDesignerTracking,
        },
        {
          label: 'Availability Schedule',
          path: PATHS.ADMIN_AVAILABILITY_SCHEDULE_CALENDAR,
          permission: abilities.readPendingLeaveDay,
        },
        {
          label: 'Requests',
          path: PATHS.ADMIN_REQUESTS,
          permission: abilities.readRequestReport,
        },

        {
          label: 'Ticket Feedback',
          path: PATHS.ADMIN_TICKET_FEEDBACKS,
          permission: abilities.readTicketFeedback,
        },
      ],
    },
    {
      label: 'Tools',
      Icon: Wrench,
      submenu: [
        toolsAdminItem,
        toolsAutoAssignerItem,
        toolsFreshStockItem,
        toolsCustomerSuccessItem,
        toolsProductionItem,
      ],
    },
    {
      label: 'Time Allocations',
      Icon: Clock,
      path: PATHS.ADMIN_TIME_ALLOCATIONS,
      permission: userAbilities.isProductionCoordinator,
    },
  ]

  return filterMenuItems(adminItems)
}

export const getMainItems = (userAbilities: UserWithFeatureAndAbilities) => {
  const { abilities } = userAbilities

  const mainItems: MenuItem[] = [
    {
      label: 'Requests',
      Icon: Ticket,
      path: PATHS.REQUESTS,
      permission: userAbilities.companyPresent,
    },
    {
      label: 'Brands',
      Icon: Palette,
      path: PATHS.BRANDS,
    },
    {
      label: 'White Label',
      Icon: Share2,
      path: PATHS.COMPANIES_WHITE_LABEL,
      permission: abilities.whiteLabel,
      RightIcon: ({ intent }: IconMenuVariant) => (
        <div
          className={cn(
            'tw-ml-auto tw-rounded tw-bg-cornflower-100 tw-font-semibold tw-text-cornflower-800',
            intent === 'col' &&
              'tw-absolute tw-right-0 tw-top-0 tw-flex tw-min-h-3 tw-min-w-3 tw-rounded-full tw-text-[0px] tw-leading-[0px]',
            intent === 'rowMobileCol' &&
              'tw-absolute tw-right-0 tw-top-0 tw-flex tw-min-h-3 tw-min-w-3 tw-rounded-full tw-text-[0px] tw-leading-[0px] md:tw-static md:tw-rounded md:tw-bg-cornflower-100 md:tw-px-2 md:tw-py-1 md:tw-text-xs md:tw-text-cornflower-800',
          )}
        >
          New
        </div>
      ),
    },
  ]

  return filterMenuItems(mainItems)
}
