import { ReactElement, useEffect, useRef, useState } from 'react'
import AdminTimeline from './admin-timeline'
import IconButton from 'lib/components/buttons/icon-button'
import { ClipboardList, History, Info, User } from 'lucide-react'
import AdminClientProfile from './admin-client-profile'
import AdminTicketDetails from './admin-ticket-details'
import { AdminView, useAdminTicketContext } from '../providers/admin-ticket-provider'
import { useAdminTimelineContext } from '../providers/admin-timeline-provider'
import { PAGE_SIZE } from 'lib/api/admin/timeline/admin-timeline'
import AdminLogs from './admin-logs/admin-logs'

const classNames = {
  iconContainer: `tw-flex
    tw-flex-col
    tw-border-0
    tw-border-r
    tw-border-solid
    tw-border-gray-200
    tw-p-3
    tw-gap-2
    tw-h-full
  `,
}

function CurrentView({ view }: { view: AdminView['rightSidebar'] }): ReactElement {
  const [mountedViews, setMountedViews] = useState<AdminView['rightSidebar'][]>([])
  const endOfTimelineRef = useRef<HTMLDivElement>(null)
  const { orderedTimelineItems, initialLoadComplete, selectedAPIFilters, setInitialLoadComplete } =
    useAdminTimelineContext()

  useEffect(() => {
    setMountedViews((previous) => {
      if (!previous.includes(view)) {
        return [...previous, view]
      } else {
        return previous
      }
    })
  }, [view])

  function scrollTimeline() {
    endOfTimelineRef.current?.scrollIntoView({ behavior: 'auto' })
  }

  useEffect(() => {
    if (view === 'timeline' && orderedTimelineItems?.length <= PAGE_SIZE && initialLoadComplete) {
      setInitialLoadComplete(false)
      scrollTimeline()
    }
  }, [orderedTimelineItems, view, selectedAPIFilters.ticketVersion, initialLoadComplete, setInitialLoadComplete])

  return (
    <>
      <div className={view == 'timeline' ? 'tw-block' : 'tw-hidden'}>
        {mountedViews.includes('timeline') && <AdminTimeline />}
        <div ref={endOfTimelineRef}></div>
      </div>
      <div className={view == 'clientProfile' ? 'tw-block' : 'tw-hidden'}>
        {mountedViews.includes('clientProfile') && <AdminClientProfile />}
      </div>
      <div className={view === 'logs' ? 'tw-block' : 'tw-hidden'}>{mountedViews.includes('logs') && <AdminLogs />}</div>
      <div className={view == 'ticketDetails' ? 'tw-block' : 'tw-hidden'}>
        {mountedViews.includes('ticketDetails') && <AdminTicketDetails />}
      </div>
    </>
  )
}

function SidebarContent(): ReactElement {
  const { currentView, setRightSidebar } = useAdminTicketContext()

  const currentRightSidebarView = currentView.rightSidebar

  function updateCurrentView(view: AdminView['rightSidebar']) {
    setRightSidebar(view)
  }

  return (
    <div className="tw-relative tw-flex tw-h-full tw-flex-auto tw-overflow-auto">
      <div className={classNames.iconContainer}>
        <IconButton
          color={currentRightSidebarView === 'timeline' ? 'primary' : 'secondary'}
          invert={currentRightSidebarView === 'timeline'}
          selected={currentRightSidebarView === 'timeline'}
          onClick={() => updateCurrentView('timeline')}
          dataTestid="timeline-button"
        >
          <ClipboardList className="lu-light lu-md" />
        </IconButton>
        <IconButton
          color={currentRightSidebarView === 'clientProfile' ? 'primary' : 'secondary'}
          invert={currentRightSidebarView === 'clientProfile'}
          selected={currentRightSidebarView === 'clientProfile'}
          onClick={() => updateCurrentView('clientProfile')}
          dataTestid="client-profile-button"
        >
          <User className="lu-light lu-md" />
        </IconButton>
        <IconButton
          color={currentRightSidebarView === 'logs' ? 'primary' : 'secondary'}
          invert={currentRightSidebarView === 'logs'}
          selected={currentRightSidebarView === 'logs'}
          onClick={() => updateCurrentView('logs')}
          dataTestid="ticket-details-button"
        >
          <History className="lu-light lu-md" />
        </IconButton>
        <IconButton
          color={currentRightSidebarView === 'ticketDetails' ? 'primary' : 'secondary'}
          invert={currentRightSidebarView === 'ticketDetails'}
          selected={currentRightSidebarView === 'ticketDetails'}
          onClick={() => updateCurrentView('ticketDetails')}
          dataTestid="ticket-details-button"
        >
          <Info className="lu-light lu-md" />
        </IconButton>
      </div>
      <div className="tw-w-full tw-overflow-auto">
        <CurrentView view={currentRightSidebarView} />
      </div>
    </div>
  )
}

export default function AdminRightPanel(): ReactElement {
  return <SidebarContent />
}
