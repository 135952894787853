import { hasOnlyFalsyValues } from 'lib/object/utils'
import SingleSelect from 'components/core/single-select'
import Button from 'components/core/button'
import { User } from 'interfaces/user'
import { findById } from 'lib/array/utils'
import { Brand } from 'interfaces/brand'
import { SearchTitleFilter } from 'components/elements/admin-filters'
import { useSingleQueueContext } from 'providers/single-queue-provider'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import CompanyTaggingFilter from 'components/pages/requests/company-tagging-filter'

export interface TicketFilters {
  brandIdEq: number
  companyTagsIdInAny: number[]
  friendlyStatus: string
  skillSkillCategorySubscriptionTypeEq: string
  titleQuery: string
  userIdEq: number
}

export const EmptyTicketFilters: TicketFilters = {
  brandIdEq: null,
  companyTagsIdInAny: null,
  friendlyStatus: '',
  skillSkillCategorySubscriptionTypeEq: '',
  titleQuery: '',
  userIdEq: null,
}

export default function TicketListFilters(): JSX.Element {
  const { brands, categories, fetchTickets, filters, handleFilterChange, statuses, users } = useSingleQueueContext()
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  const { brandIdEq, friendlyStatus, skillSkillCategorySubscriptionTypeEq, titleQuery, userIdEq } = filters

  const onSubmit = (e) => {
    e.preventDefault()
    if (titleQuery) {
      location.href = `/search?query=${titleQuery}`
      return
    }
    fetchTickets()
  }
  const selectedRequester: User = filters.userIdEq && findById(userIdEq, users)
  const selectedBrand: Brand = brandIdEq && findById(brandIdEq, brands)

  const hasUsers = users.length > 1

  return (
    <div className="tw-flex-none">
      <div className="tw-flex tw-justify-between tw-p-2">
        <form onSubmit={onSubmit} onKeyDown={(e) => e.key === 'Enter' && onSubmit(e)}>
          <div className="tw-relative tw-flex">
            <SearchTitleFilter
              value={titleQuery}
              updateValue={(value) =>
                handleFilterChange({
                  ...filters,
                  titleQuery: value,
                })
              }
              placeholder="Title or ticket ID"
            />
            <span className="tw-px-2 tw-pt-2.5 tw-text-xl tw-text-peppercorn-100">|</span>
            <TicketFilterSelect
              filterName={'friendlyStatus'}
              placeholder="Status"
              options={statuses.map((status) => ({
                value: status,
                label: status,
              }))}
              value={
                friendlyStatus && {
                  label: friendlyStatus,
                  value: friendlyStatus,
                }
              }
            />
            <TicketFilterSelect
              filterName={'skillSkillCategorySubscriptionTypeEq'}
              placeholder="Request Type"
              options={categories.map((category) => ({
                value: category,
                label: category,
              }))}
              value={
                skillSkillCategorySubscriptionTypeEq && {
                  label: skillSkillCategorySubscriptionTypeEq,
                  value: skillSkillCategorySubscriptionTypeEq,
                }
              }
            />
            {hasUsers && (
              <TicketFilterSelect
                filterName={'userIdEq'}
                placeholder="Submitted by"
                options={users.map((user) => ({
                  value: user.id,
                  label: user.fullName,
                }))}
                value={
                  userIdEq && {
                    label: selectedRequester.fullName,
                    value: selectedRequester.id,
                  }
                }
              />
            )}
            <TicketFilterSelect
              filterName={'brandIdEq'}
              placeholder="Brand"
              options={brands.map((brand) => ({
                value: brand.id,
                label: brand.name,
              }))}
              value={
                brandIdEq && {
                  label: selectedBrand.name,
                  value: selectedBrand.id,
                }
              }
            />
            {isFeatureFlagEnabled('company_tags') && <CompanyTaggingFilter />}
            {!hasOnlyFalsyValues(filters) && (
              <div
                className="tw-flex tw-cursor-pointer tw-items-center tw-justify-between"
                onClick={() => handleFilterChange(EmptyTicketFilters)}
                data-testid="clear-filters"
              >
                <span className="tw-mr-1">Clear Filters</span>
              </div>
            )}
          </div>
          <Button color="outlineDark" type="submit" className="tw-hidden">
            Search
          </Button>
        </form>
      </div>
    </div>
  )
}

interface TicketFilterSelectProps {
  filterName: string
  options: { value: string | number; label: string }[]
  placeholder: string
  value: { value: string | number; label: string }
}

function TicketFilterSelect({ filterName, options, placeholder, value }: TicketFilterSelectProps) {
  const { handleFilterChange, filters } = useSingleQueueContext()

  const handleChange = (e) => {
    const value = e ? formatValue(e.value) : EmptyTicketFilters[filterName]

    handleFilterChange({
      ...filters,
      [filterName]: value,
    })
  }

  const formatValue = (value) => {
    return isNaN(value) ? String(value) : +value
  }

  return (
    <div className="tw-mr-4" data-testid={filterName}>
      <label className="tw-hidden" htmlFor={filterName}>
        {filterName}
      </label>
      <SingleSelect
        className="tw-my-2 tw-w-48 tw-text-xs"
        value={value}
        placeholder={placeholder}
        name={filterName}
        inputId={filterName}
        isClearable
        options={options}
        onChange={handleChange}
      />
    </div>
  )
}
