import { JSX, ReactNode, HTMLAttributes } from 'react'
import { Colors, getClassNames, Sizes } from './icon-button'

interface LinkButtonProps extends HTMLAttributes<HTMLAnchorElement> {
  children: ReactNode
  className?: string
  color: Colors
  size?: Sizes
  url: string
  target?: '_self' | '_blank'
  invert?: boolean
  disabled?: boolean
  forceFocus?: boolean
  download?: string | boolean
  dataTestId?: string
}

export function IconLinkButton({
  children,
  className,
  color,
  size = 'sm',
  url,
  target,
  invert = false,
  disabled = false,
  forceFocus = false,
  download,
  dataTestId = '',
  ...rest
}: LinkButtonProps): JSX.Element {
  const classes = getClassNames({ size, color, className, invert, disabled, forceFocus })

  return (
    <a
      className={`${classes} tw-flex tw-items-center tw-justify-center`}
      href={url}
      target={target || undefined}
      download={download || undefined}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
    </a>
  )
}
