import React, { ReactElement } from 'react'

import { GenericAdminTable } from 'interfaces/generic-admin-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { classNames } from 'lib/array/utils'
import Badge from 'lib/components/badge/badge'

export const ConvertDateToAmerican = (date: string): string => {
  const [year, month, day] = date.split('T')[0].split('-')
  return `${month}/${day}/${year}`
}

export const DateDifferenceInDays = (date1: number, date2: number): number => {
  const ageInDays = (date1 - date2) / (1000 * 60 * 60 * 24)
  return Math.round(ageInDays)
}

export const DefaultRow = (value: string, index: number): ReactElement => <td key={index}>{value}</td>

export function CompanyName({ company, userId, user }: GenericAdminTable): JSX.Element {
  const { id, email, name } = company
  return (
    <td className="" data-testid="company-name-container">
      <a
        href={window.Routes.adminCompaniesUrl({
          company_id: id,
        })}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon="building" /> {name}
      </a>
      <br />
      <a
        href={window.Routes.adminUsersUrl({
          user_id: userId,
        })}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon="user" /> {user}
      </a>
      <br />
      <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
        <div className="tw-mr-3 tw-whitespace-nowrap">
          <FontAwesomeIcon icon="envelope" /> {email}
        </div>
      </a>
    </td>
  )
}

export const CompanyAge = ({ company, ageOfClient }: GenericAdminTable): ReactElement => (
  <td data-testid="company-age-container">
    {`${ageOfClient} days\n`}
    {`(${ConvertDateToAmerican(company?.createdAt)})`}
  </td>
)

interface ConfidenceLevelProps {
  labelProbability: number
}

export const ConfidenceLevel = ({ labelProbability }: ConfidenceLevelProps): ReactElement => (
  <td data-testid="company-confidence-level-container" className="tw-font-semibold">
    {labelProbability ? `${Math.ceil(labelProbability * 100)}%` : 'N/A'}
  </td>
)

interface PredictedLabelProps {
  predictedLabel: string
  humanAnnotated: boolean
}

const getStatusBadge = (predictedLabel: string): { label: string; color: 'danger' | 'success' } =>
  statusBadge[predictedLabel] || statusBadge.not_applicable

export const PredictedLabel = ({ predictedLabel, humanAnnotated }: PredictedLabelProps): ReactElement => (
  <td data-testid="company-predicted-label-container">
    <Badge color={getStatusBadge(predictedLabel).color} style="pill" className="tw-mr-1">
      {getStatusBadge(predictedLabel).label}
    </Badge>
    {humanAnnotated && (
      <div className={classNames('tw-text-red-500', 'tw-text-xs', 'tw-text-center', 'tw-mt-1')}>Overridden</div>
    )}
  </td>
)

const defaultBubbleStyle = 'success'
const preventableBubbleStyle = 'danger'

export const statusBadge = {
  preventable: { label: 'Preventable', color: preventableBubbleStyle },
  non_preventable: { label: 'Non-Preventable', color: defaultBubbleStyle },
  not_applicable: { label: 'Not Applicable', color: defaultBubbleStyle },
}
