import Select, { ActionMeta } from 'react-select'
import { selectStyles } from './single-select-styles'

interface SelectProps {
  className?: string
  isClearable?: boolean
  defaultValue?: OptionType
  isDisabled?: boolean
  inputId?: string
  isLoading?: boolean
  name?: string
  onChange?: (value: OptionType, actionMeta: ActionMeta<OptionType>) => void
  options: OptionType[]
  placeholder?: string
  isSearchable?: boolean
  value?: OptionType
}

export interface OptionType {
  label: string
  value: string | number
}

export default function SingleSelect(props: SelectProps): JSX.Element {
  return (
    <Select
      components={{ IndicatorSeparator: null }}
      menuPortalTarget={document.body}
      styles={selectStyles}
      className={props.className}
      classNamePrefix="select"
      isClearable={props.isClearable}
      {...props}
    />
  )
}
