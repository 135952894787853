import { Company } from 'interfaces/company'
import { Ticket } from 'interfaces/ticket'
import getCompanyDailyAvailableTimes, {
  CompanyDailyAvailableTimes,
} from 'lib/api/companies/company_daily_available_times'
import { getTicketsFromCompany } from 'lib/api/tickets/tickets'
import { createContext, ReactElement, ReactNode, useContext, useMemo, useState } from 'react'

type PartialCompany = Pick<Company, 'id' | 'name'>

interface CompanyDailyAvailableTimesContextProps {
  children: ReactNode
  companies: PartialCompany[]
}

type CompanyDailyAvailableTimesContextValue = {
  companies: PartialCompany[]
  company: PartialCompany
  companyDailyAvailableTimes: CompanyDailyAvailableTimes
  errors: ErrorObject[]
  getTicketsByCompany: (companyId: number, page?: number) => Promise<void>
  isLoading: boolean
  isPartialLoading: boolean
  pagination: Pagination
  refreshTicketList: (companyId?: number, currentPage?: number, invalidateCache?: boolean) => Promise<void>
  resetErrors: () => void
  setIsPartialLoading: (value: boolean) => void
  tickets: Ticket[]
}

interface CompanyDailyAvailableTimesState {
  [companyId: number]: CompanyDailyAvailableTimes
}

interface TicketState {
  [companyId: number]: Ticket[][]
}

interface PaginationState {
  [companyId: number]: Pick<Pagination, 'page' | 'total'>
  perPage: number
}

interface Pagination {
  page: number
  perPage: number
  total: number
}

interface ErrorObject {
  message: string
  error: Error
}

const CompanyDailyAvailableTimesContext = createContext({})

export function useCompanyDailyAvailableTimesContext(): CompanyDailyAvailableTimesContextValue {
  return useContext(CompanyDailyAvailableTimesContext) as CompanyDailyAvailableTimesContextValue
}

export default function CompanyDailyAvailabletimesProvider({
  companies,
  children,
}: CompanyDailyAvailableTimesContextProps): ReactElement {
  const [tickets, setTickets] = useState<TicketState | null>(null)
  const [pagination, setPagination] = useState<PaginationState | null>(null)
  const [company, setCompany] = useState<number | null>(null)
  const [companyDailyAvailableTimes, setCompanyDailyAvailableTimes] = useState<CompanyDailyAvailableTimesState | null>(
    null,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPartialLoading, setIsPartialLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<ErrorObject[]>([])

  function createError(error: ErrorObject): void {
    setErrors((oldErrors) => [...oldErrors, error])
  }

  async function fetchCompanyDailyAvailableTimes(companyId: number): Promise<void> {
    try {
      const dailyAvailableTimes = await getCompanyDailyAvailableTimes({ companyId: companyId as unknown as bigint })

      if (!dailyAvailableTimes || Object.keys(dailyAvailableTimes).length === 0) {
        throw new Error('No daily available times')
      }

      setCompanyDailyAvailableTimes((oldDailyAvailableTimesState) => ({
        ...oldDailyAvailableTimesState,
        [companyId]: dailyAvailableTimes,
      }))

      return
    } catch (error) {
      const message = error?.response?.data?.message || 'Company Daily Available Times could not be fetched.'
      createError({ message, error })
    }
  }

  async function updateTickets(companyId = company, currentPage = 0, invalidateCache = true): Promise<void> {
    setIsPartialLoading(true)

    try {
      const getAvailableTimes = fetchCompanyDailyAvailableTimes(companyId)
      const { ticketData } = await getTicketsFromCompany(companyId, currentPage)
      const { tickets: newTickets, perPage, total } = ticketData

      setTickets((oldTickets): TicketState => {
        // In case we're invalidating the cache, or if it's the first time fetching tickets for this company, we build an empty object
        const newCompanyTickets = (!invalidateCache && oldTickets?.[companyId]) || []
        newCompanyTickets[currentPage] = newTickets

        return { ...oldTickets, [companyId]: newCompanyTickets }
      })
      setPagination(
        (oldPagination): PaginationState => ({ ...oldPagination, [companyId]: { page: currentPage, total }, perPage }),
      )

      const error = await getAvailableTimes

      return error
    } catch (e) {
      createError({ message: 'Page could not be fetched.', error: e })
    } finally {
      setIsPartialLoading(false)
    }
  }

  async function getTicketsByCompany(companyId: number, currentPage = 0): Promise<void> {
    setCompany(companyId)

    if (tickets?.[companyId]?.[currentPage]) {
      setPagination(
        (oldPagination): PaginationState => ({
          ...oldPagination,
          [companyId]: { ...oldPagination[companyId], page: currentPage },
        }),
      )

      return
    }

    try {
      setIsLoading(true)
      return await updateTickets(companyId, currentPage, false)
    } catch (e) {
      createError({ message: 'Tickets could not be fetched.', error: e })
    } finally {
      setIsLoading(false)
    }
  }

  const companyContext = useMemo(() => companies.find((c) => c.id === company), [company, companies])
  const companyDailyAvailableTimesContext = useMemo(
    () => companyDailyAvailableTimes?.[company],
    [companyDailyAvailableTimes, company],
  )
  const paginationContext = useMemo(
    () => ({
      ...pagination?.[company],
      perPage: pagination?.perPage || 0,
    }),
    [pagination, company],
  )
  const ticketsContext = useMemo(
    () => tickets?.[company]?.[paginationContext.page] || [],
    [tickets, company, paginationContext.page],
  )

  const context: CompanyDailyAvailableTimesContextValue = {
    companies,
    company: companyContext,
    companyDailyAvailableTimes: companyDailyAvailableTimesContext,
    errors,
    getTicketsByCompany,
    isLoading,
    isPartialLoading,
    refreshTicketList: updateTickets,
    resetErrors: () => setErrors([]),
    setIsPartialLoading,
    tickets: ticketsContext,
    pagination: paginationContext,
  }

  return (
    <CompanyDailyAvailableTimesContext.Provider value={context}>{children}</CompanyDailyAvailableTimesContext.Provider>
  )
}
