import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import { TaggableTag } from 'lib/api/company-tags/company-tags'
import { Ticket } from 'interfaces/ticket'

export interface SingleQSelectedTagsProviderProps {
  children: ReactNode
  tickets: Ticket[]
}

export interface SingleQSelectedTagsContextValue {
  selectedTags: TaggableTag[]
  setSelectedTags: Dispatch<SetStateAction<TaggableTag[]>>
}

const SingleQSelectedTagsContext = createContext<SingleQSelectedTagsContextValue | null>(null)

export function useSingleQSelectedTagsContext(): SingleQSelectedTagsContextValue {
  const context = useContext<SingleQSelectedTagsContextValue>(SingleQSelectedTagsContext)
  if (!context) {
    throw new Error('useSingleQueueSelectedTagsContextValue must be used within a SingleQSelectedTagsProvider')
  }
  return context
}

export default function SingleQSelectedTagsProvider({ children, tickets }: SingleQSelectedTagsProviderProps) {
  const [selectedTags, setSelectedTags] = useState<TaggableTag[]>(getSelectedTags(tickets))

  const contextValue: SingleQSelectedTagsContextValue = {
    selectedTags,
    setSelectedTags,
  }

  useEffect(() => {
    setSelectedTags(getSelectedTags(tickets))
  }, [tickets])

  return <SingleQSelectedTagsContext.Provider value={contextValue}>{children}</SingleQSelectedTagsContext.Provider>
}

function getSelectedTags(tickets: Ticket[]): TaggableTag[] {
  return tickets.flatMap((ticket) => {
    const ticketId = ticket.ticketId || ticket.id

    return ticket.tags.map((tag) => ({
      taggableId: Number(ticketId),
      taggableType: 'Ticket',
      ...tag,
    }))
  })
}
