import { CompanyTag } from 'lib/api/company-tags/company-tags'
import Button from '../../core/button'
import { useCompanyTaggingContext, ViewStates } from 'providers/company-tagging-provider'
import TagCount from 'components/elements/company-tagging/tag-count'

interface DeleteCompanyTagProps {
  tag: CompanyTag
}

export function DeleteCompanyTag({ tag }: DeleteCompanyTagProps) {
  const { deleteCompanyTag, setCurrentView } = useCompanyTaggingContext()

  async function handleDeleteCompanyTag() {
    await deleteCompanyTag(tag)
  }

  return (
    <div className="tw-pt-4">
      <p>Are you sure you want to delete the tag &apos;{tag.name}&apos;?</p>
      <TagCount tagId={tag.id} />
      <div className="tw-mt-4 tw-flex tw-items-center tw-justify-end tw-gap-2">
        <Button color="lightGray" onClick={() => setCurrentView({ viewState: ViewStates.Edit, tag })}>
          Go Back
        </Button>
        <Button color="red" onClick={handleDeleteCompanyTag}>
          Delete
        </Button>
      </div>
    </div>
  )
}
