import { ChangeEvent, ReactElement, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/core/button'
import { BetaBadge } from 'lib/components/badge/badge'
import Modal from 'lib/components/modals/modal'
import Textarea, { TextCounter } from 'lib/components/textarea/textarea'
import { AnimatedLoadingScreen } from 'components/pages/requests/empty-screens'

interface PromptModalProps {
  open: boolean
  onClose: (prompt: string) => Promise<void>
}

const classNames = {
  buttons: {
    wrapper: 'tw-flex tw-justify-end tw-gap-2 tw-mt-4',
  },
}

export default function EditAssistPromptModal({ open, onClose }: PromptModalProps): ReactElement {
  const [prompt, setPrompt] = useState<string>('')
  const [inProgress, setInProgress] = useState<boolean>(false)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  function handleAfterEnter() {
    textareaRef.current?.focus()
  }

  function handleCancel() {
    onClose(null).catch(() => null)
  }

  async function handleGenerate() {
    setInProgress(true)
    try {
      await onClose(prompt)
      setPrompt('')
    } finally {
      setInProgress(false)
    }
  }

  function onChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setPrompt(e.target.value)
  }

  return (
    <Modal afterEnter={handleAfterEnter} open={open} setOpen={handleCancel} size={inProgress ? 'lg' : 'md'}>
      <form>
        <Modal.Header>
          <span className="tw-flex tw-items-center tw-gap-1 tw-text-lg tw-font-bold">
            <FontAwesomeIcon icon={['far', 'paint-brush']} />
            <span>
              Edit Assist <BetaBadge />
            </span>
          </span>
        </Modal.Header>
        <Modal.Body setOpen={handleCancel}>
          {inProgress && (
            <div className="tw-mb-16 tw-mt-20 tw-text-center">
              <AnimatedLoadingScreen />
              <div style={{ width: '440px' }} className="tw-mx-auto">
                <p>
                  <strong>Uploading your image and drawing...</strong>
                </p>
              </div>
            </div>
          )}
          {!inProgress && (
            <>
              <Textarea
                ref={textareaRef}
                label="What would you like the area to change into?"
                onChange={onChange}
                rows={2}
                value={prompt}
              />
              <TextCounter className="tw-text-right" value={prompt} />
            </>
          )}
        </Modal.Body>
        {!inProgress && (
          <Modal.Footer>
            <div className={classNames.buttons.wrapper}>
              <Button type="button" color="neutralGray" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="button" color="purple" disabled={prompt.length < 3} onClick={handleGenerate}>
                Generate
              </Button>
            </div>
          </Modal.Footer>
        )}
      </form>
    </Modal>
  )
}
