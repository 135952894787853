import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState, useEffect } from 'react'
import { CurrentUser } from 'interfaces/app'
import { getTicketFileVersions, TicketFileVersion } from 'lib/api/ticket-files/ticket-file-versions'
import { Metadata } from 'lib/api/api'
import { SubmittedByUser } from 'lib/api/ticket-files/ticket-file-versions'
import { Brand } from 'lib/api/companies/companies'

interface ContextProps {
  children: ReactNode
  user: CurrentUser
}

export interface Filters {
  brands: Brand[]
  name: string
  formats: string[]
  source: string[]
  users: SubmittedByUser[]
}

export const emptyFilters: Filters = {
  brands: [],
  name: '',
  formats: [],
  source: [],
  users: [],
}

interface FiltersParams {
  brands?: number[]
  name?: string
  formats?: string[]
  source?: string[]
  users?: number[]
}

interface Params {
  page?: string
  invalidateCache?: boolean
}

interface TicketFileVersionsContextValue {
  getTicketFileVersionList: (params?: Params) => Promise<void>
  isLoading: boolean
  selectedList: number[]
  setSelectedList: Dispatch<SetStateAction<number[]>>
  user: CurrentUser
  ticketFileVersionList: TicketFileVersion[]
  metadata: Metadata
  currentPage: number
  filters: Filters
  setFilters: (filters: Filters) => void
}

const TicketFileVersionsContext = createContext({})

export function useTicketFileVersionsContext(): TicketFileVersionsContextValue {
  return useContext(TicketFileVersionsContext) as TicketFileVersionsContextValue
}

export const defaultMetadata: Metadata = {
  nextPage: null,
  previousPage: null,
  total: 0,
  pageSize: 25,
}

function filterReducer(filters: FiltersParams) {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    if (!value || (value instanceof Array && !value.length)) return acc
    return { ...acc, [key]: value }
  }, {})
}

function mapFilters(filters: Filters) {
  const mappedFilters = {
    ...filters,
    brands: filters.brands.map((brand) => brand.id),
    users: filters.users.map((user) => user.id),
  }

  return filterReducer(mappedFilters)
}

export default function TicketFileVersionsProvider({ children, user }: ContextProps): JSX.Element {
  const [ticketFileVersionList, setTicketFileVersionList] = useState<TicketFileVersion[][]>([])
  const [metadata, setMetadata] = useState<Metadata>(defaultMetadata)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedList, setSelectedList] = useState<number[]>([])
  const [shouldClearCache, setShouldClearCache] = useState<boolean>(false)
  const [filters, setFilters] = useState<Filters>(emptyFilters)
  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    getTicketFileVersionList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.brands, filters.formats, filters.source, filters.users])

  function updateFilters(updateFilters: Filters) {
    setShouldClearCache(true)
    setFilters(updateFilters)
  }

  function clearCache() {
    setTicketFileVersionList([])
    setCurrentPage(0)
    setShouldClearCache(false)
    setSelectedList([])
  }

  function getCurrentPage(page = currentPage) {
    return ticketFileVersionList[page]
  }

  async function getTicketFileVersionList(params?: Params): Promise<void> {
    function getPageNumber() {
      if (params?.page) {
        return parseInt(params.page) - 1
      }

      return currentPage
    }

    if (params?.page) setCurrentPage(parseInt(params.page) - 1)

    if (shouldClearCache || params?.invalidateCache) {
      clearCache()
    } else {
      if (getCurrentPage(getPageNumber())) return
    }

    setIsLoading(true)

    try {
      const filterParams = params?.invalidateCache ? {} : mapFilters(filters)
      const pageParams = params?.page ? { page: params.page } : {}

      const paramsWithFilters = { ...pageParams, ...filterParams }

      const { ticketFileVersions, meta } = await getTicketFileVersions(paramsWithFilters)

      setTicketFileVersionList((oldTicketFileVersionList) => {
        const newTicketFileVersionList = [...oldTicketFileVersionList]
        newTicketFileVersionList[getPageNumber()] = ticketFileVersions
        return newTicketFileVersionList
      })
      setMetadata(meta)
    } catch (e) {
      console.error('TicketFileVersions could not be fetched.', e)
    } finally {
      setIsLoading(false)
    }
  }

  const context: TicketFileVersionsContextValue = {
    getTicketFileVersionList,
    isLoading,
    selectedList,
    setSelectedList,
    user,
    ticketFileVersionList: getCurrentPage() || [],
    metadata,
    currentPage,
    filters,
    setFilters: updateFilters,
  }

  return <TicketFileVersionsContext.Provider value={context}>{children}</TicketFileVersionsContext.Provider>
}
