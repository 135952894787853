import { MouseEvent, useState } from 'react'
import Modal from 'lib/components/modals/modal'
import Button from 'components/core/button'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import WYSIWYGTextarea from 'lib/components/wysiwyg/wysiwyg-textarea'
import { useAdminTimelineContext } from '../providers/admin-timeline-provider'
import Banner from 'lib/components/banner/banner'
import { toast } from 'lib/components/toast/toast'

interface QrDenyModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  isMessageRequired: boolean
}

export default function AdminHeaderQrDenyModal({ open, setOpen, isMessageRequired }: QrDenyModalProps) {
  const { failQr } = useAdminTicketContext()
  const { createPrivateDetailTask } = useAdminTimelineContext()
  const [message, setMessage] = useState('')

  async function handleSubmit(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    try {
      await failQr()

      if (message) {
        await createPrivateDetailTask(message)
      }
      setOpen(false)
      toast.success('Sent ticket for revision.')
    } catch {
      toast.error('Sending ticket for revision failed.')
    }
  }

  return (
    <Modal open={open} setOpen={setOpen} size="lg" clickOutsideToClose={false}>
      <Modal.Header as="div">
        <h4 className="tw-my-0 tw-text-neutral-800">Send ticket back for revision</h4>
        <p className="tw-text-sm tw-text-black">Add a note for the creative</p>
      </Modal.Header>
      <Modal.Body setOpen={setOpen} closeButton>
        <div className="tw-flex tw-flex-col tw-gap-2 tw-text-neutral-600">
          {isMessageRequired && (
            <div className="tw-pb-4">
              <Banner type="error">
                You must add a task or a note for the creative before sending back for revision
              </Banner>
            </div>
          )}
          <form className="tw-text-md tw-flex tw-flex-col tw-gap-6 tw-font-semibold">
            <WYSIWYGTextarea defaultValue={message} onChange={setMessage} placeholder="Start typing notes" />
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-2">
              <Button
                color="neutralGray"
                type="button"
                onClick={() => {
                  setOpen(false)
                }}
              >
                Cancel (Resume quality review)
              </Button>
              <Button
                color="purple"
                type="submit"
                onClick={handleSubmit}
                disabled={isMessageRequired && message.length < 12}
              >
                Send for revision
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}
