import { CustomSize, SkillSize } from '../../api/skills/skills'
import { abbreviatedUnits, truncateText } from '../../string/utils'

export function convertStringToCustomSize(customSizes: string): CustomSize[] {
  if (!customSizes) return []

  return customSizes.split(';').reduce((accumulator, current) => {
    const result = /^(\d+\.*\d*)x(\d+\.*\d*)(\D*)$/.exec(current)

    if (result?.length === 4) {
      accumulator.push({
        width: Number(result[1]) || '',
        height: Number(result[2]) || '',
        units: result[3] || '',
      } as CustomSize)
    }
    return accumulator
  }, [])
}

export function customSizeFilter(size: CustomSize) {
  return size.width || size.height
}

export function convertCustomSizesToString(customSizes: CustomSize[]) {
  return customSizes
    ?.filter(customSizeFilter)
    ?.reduce(
      (accumulator, current) =>
        `${accumulator && accumulator + ';'}${current.width || 0}x${current.height || 0}${current.units}`,
      '',
    )
}

export function customSizesAsSkillSizes(customSizes: CustomSize[]): SkillSize[] {
  return (
    customSizes
      ?.filter((customSize) => customSize.width && customSize.height)
      ?.map(
        (customSize) =>
          ({
            details: `${customSize.width} x ${customSize.height}`,
            units: customSize.units,
          }) as SkillSize,
      ) || []
  )
}

function containsUnits(details: string) {
  return /in|cm|px/.test(details)
}

export function skillSizeDetails(size: SkillSize): string {
  if (containsUnits(size.details)) return size.details
  return `${size.details}${abbreviatedUnits(size.units)}`
}

export function skillSizeDetailsWithName(size: SkillSize): { size: string; name: string } {
  const name = size.name || 'Custom'
  if (containsUnits(size.details)) return { name, size: size.details }
  return {
    name,
    size: `${size.details}${abbreviatedUnits(size.units)}`,
  }
}

export function allSizesAsSkillSizes(selectedSkillSizes: SkillSize[] = [], customSizes = ''): SkillSize[] {
  return selectedSkillSizes.concat(customSizesAsSkillSizes(convertStringToCustomSize(customSizes)))
}

export function getSizesTitle(selectedSkillSizes: SkillSize[], size: string, noneText: 'N/A' | 'Select Sizes' = 'N/A') {
  const sizes = allSizesAsSkillSizes(selectedSkillSizes, size)

  if (!sizes || sizes.length === 0) {
    return noneText
  } else if (sizes.length <= 2) {
    return truncateText(sizes.map(skillSizeDetails).join(', '))
  }
  return `${truncateText(sizes.slice(0, 2).map(skillSizeDetails).join(', '))} + ${sizes.length - 2} more`
}
