import { getApi, Metadata } from 'lib/api/api'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'
import { AnnotationStatus } from '../annotations/annotations'
import { GenAiRequest } from '../gen-ai/gen-ai-requests'
import { VideoJSAnnotation } from '../ticket-files/ticket-files'

export interface AnnotationUser {
  id: number
  fullName: string
  avatar: string
  submittedByDesigner: boolean
}

export interface AnnotationTimelineItem extends DetailTaskTimelineItem {
  annotation: {
    id: number
    assetId: number
    body: string
    data: AnnotoriousAnnotation | VideoJSAnnotation
    fileName: string
    fileParentId: number
    fileVersion: number
    previewUrl: string
    userId: number
    user: AnnotationUser
    uuid: string
  }
  user: AnnotationUser
  taskType: TimelineItemTypes.ANNOTATION
}

export interface ConversationTimelineItem extends TimelineItem {
  body: string
  incoming: boolean
  taskType: TimelineItemTypes.CONVERSATION
  ticketId: number
  updatedAt: string
  user: ConversationUser
  ticketSubject?: string
}

export interface ConversationUser {
  id: number
  avatar?: string
  fullName: string
  companyName?: string
}

export interface CopyTimelineItem extends DetailTaskTimelineItem {
  taskType: TimelineItemTypes.COPY
}

interface DirectionUser {
  id: number
  avatar: string
  fullName: string
  submittedByDesigner: boolean
}

export interface DetailTaskTimelineItem extends TimelineItem {
  description: string
  genAiRequest?: GenAiRequest
  status: AnnotationStatus
  taskId: number
  taskType:
    | TimelineItemTypes.ANNOTATION
    | TimelineItemTypes.COPY
    | TimelineItemTypes.DIRECTION
    | TimelineItemTypes.GEN_AI_REQUEST
  userId: number
  user: DirectionUser
  userFullName: string
}

export interface DirectionTimelineItem extends DetailTaskTimelineItem {
  taskType: TimelineItemTypes.DIRECTION
}

export interface GenAiRequestTimelineItem extends DetailTaskTimelineItem {
  genAiRequest: GenAiRequest
  taskType: TimelineItemTypes.GEN_AI_REQUEST
}

interface GetTimelineItemsResponse {
  data: TimelineResponseItem[]
  meta: Partial<Metadata>
}

export interface TimelineItem extends TimelineResponseItem {
  __cacheKey?: string
}

interface TimelineResponseItem {
  id: number
  createdAt: string
  private: boolean
  taskType: TimelineItemTypes
  ticketVersion: number
}

export enum TimelineItemTypes {
  ANNOTATION = 'annotation',
  CONVERSATION = 'conversation',
  COPY = 'copy',
  DIRECTION = 'direction',
  GEN_AI_REQUEST = 'gen_ai_request',
}

const PAGE_SIZE = 50

function getNextPageUrlFromResponse(response: TimelineResponseItem[], ticketId: number, version: number): string {
  if (response.length === PAGE_SIZE) {
    const { createdAt } = response[PAGE_SIZE - 1]
    const versionParam = version ? `&ticket_version=${version}` : ''
    return `/api/internal/tickets/${ticketId}/directions?last_created_at=${createdAt}${versionParam}`
  }
  return null
}

export async function getTimelineItems(
  ticketId: number,
  version?: number,
  nextPageUrl?: string,
): Promise<GetTimelineItemsResponse> {
  const url = `/api/internal/tickets/${ticketId}/directions`
  const versionedUrl = version ? `${url}?ticket_version=${version}` : url

  const response = await getApi(nextPageUrl || versionedUrl)
  const data = response.data.data as TimelineResponseItem[]
  const nextPage = getNextPageUrlFromResponse(data, ticketId, version)
  return { data, meta: { nextPage } }
}
