import { LoadingScreen } from 'components/pages/requests/empty-screens'
import TimeToSpendDropdown, { getTextValue } from 'components/pages/requests/table-elements/time-to-spend-dropdown'
import { Ticket } from 'interfaces/ticket'
import { createIntervalsBetweenValues } from 'lib/array/utils'
import { useCompanyDailyAvailableTimesContext } from 'providers/company-daily-available-times-provider'
import { ReactElement, useMemo } from 'react'

const classNames = {
  tableRow: 'tw-h-12 hover:tw-bg-neutral-200',
  ticketLinkRow: 'tw-text-eggplant hover:tw-text-eggplant-600',
  timetoSpendCell: 'tw-flex',
  dropdownTitle:
    'tw-h-5 tw-mt-3.5 tw-px-2 tw-inline-flex tw-text-xs tw-leading-5 tw-font-medium tw-rounded-full tw-bg-neutral-100 tw-text-neutral-800',
}

interface TicketRowProps {
  ticket: Ticket
  index?: number
}

function DropdownTitle({ title }: { title: string }) {
  return <span className={classNames.dropdownTitle}>{title}</span>
}

function TimeToSpendCell({ ticket, index }: { ticket: Ticket; index: number }) {
  const options = useMemo(() => createIntervalsBetweenValues(15, 545, 15), [])

  const forceRenderOnTop = useMemo(() => {
    if (options.length === 0) return index > 4

    return index > 6
  }, [options.length, index])

  if (!['open', 'assigned', 'incomplete'].includes(ticket.status.toLowerCase())) {
    return <></>
  }

  return <TimeToSpendDropdown ticket={ticket} forceRenderOnTop={forceRenderOnTop} options={options} />
}

function StateCell({ status, incompleteLabel }: { status: string; incompleteLabel: string }) {
  if (!incompleteLabel) return <td>{status}</td>

  return <td>{`${status} (${incompleteLabel})`}</td>
}

function TimeToSpendCellWrapper({ ticket, index }: { ticket: Ticket; index: number }) {
  const { isPartialLoading } = useCompanyDailyAvailableTimesContext()
  const { todaysWeightInMinutes } = ticket
  const title = useMemo(() => getTextValue(todaysWeightInMinutes), [todaysWeightInMinutes])

  return (
    <td className={classNames.timetoSpendCell}>
      <DropdownTitle title={title} />
      {isPartialLoading ? <LoadingScreen /> : <TimeToSpendCell ticket={ticket} index={index} />}
    </td>
  )
}

export default function TicketRow({ ticket, index = 0 }: TicketRowProps): ReactElement {
  const { id, subject, status, stateChangedAt, category, url, incompleteLabel } = ticket

  return (
    <tr key={id as unknown as number} className={classNames.tableRow}>
      <td>
        <a href={`/admin${url}`} className={classNames.ticketLinkRow}>
          {subject}
        </a>
      </td>
      <StateCell status={status} incompleteLabel={incompleteLabel} />
      <td>{stateChangedAt}</td>
      <td>{category}</td>
      <TimeToSpendCellWrapper ticket={ticket} index={index} />
    </tr>
  )
}
