import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useDirectionsContext } from '../providers/timeline-directions-provider'
import EditableDirection from './editable-direction'
import ConfirmationModal from 'components/elements/confirmation-modal'
import DetailTask from '../types/detail-task'

const className = {
  container: `
    tw-px-5
    tw-my-5
    tw-divide-solid
    tw-divide-gray-300
    tw-divide-y
    tw-divide-x-0
  `,
}

export default function DirectionsList(): ReactElement {
  const [selectedDirection, setSelectedDirection] = useState<DetailTask>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const lastUpdateIndex = useRef<number>(-1)
  const { deleteDirection, directions, filters, removeDirectionFromInEditMode } = useDirectionsContext()

  const filteredDirections = useMemo(() => {
    if (directions && filters.version) {
      return directions.filter((direction) => direction.ticketVersion === filters.version)
    }
    return directions
  }, [directions, filters])

  function handleCancelAction() {
    setSelectedDirection(null)
  }

  async function handleConfirmAction() {
    await deleteDirection(selectedDirection)
    removeDirectionFromInEditMode(selectedDirection)
    setSelectedDirection(null)
  }

  function handleDelete(direction: DetailTask) {
    setSelectedDirection(direction)
  }

  useEffect(() => {
    if (directions?.length) {
      if (lastUpdateIndex.current !== -1 && lastUpdateIndex.current < directions.length) {
        scrollToUpdatedDetailTask(containerRef.current)
      }
      lastUpdateIndex.current = directions.length
    }
  }, [directions?.length])

  if (!directions || !directions.length) {
    return null
  }

  return (
    <div className={className.container} ref={containerRef}>
      {filteredDirections.map((direction) => (
        <EditableDirection key={direction.id} direction={direction} onDelete={handleDelete} />
      ))}
      <ConfirmationModal
        title="Delete Direction"
        message={`Are you sure you want to delete this direction? All of your data will be permanently removed from our servers forever. This action cannot be undone.`}
        confirmBtnText="Yes, Delete"
        confirmAction={handleConfirmAction}
        cancelAction={handleCancelAction}
        visible={selectedDirection !== null}
      />
    </div>
  )
}

function scrollToUpdatedDetailTask(parent: HTMLElement) {
  if (parent && parent.childNodes.length) {
    const index = parent.childNodes.length - 1
    const node = parent.childNodes[index] as HTMLDivElement
    node.scrollIntoView()
  }
}
