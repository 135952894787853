import { ReactElement, useMemo } from 'react'

import { AnnotationStatus } from 'lib/api/annotations/annotations'
import Badge, { BadgeColor } from 'lib/components/badge/badge'

import { useAnnotationsContext } from '../providers/annotations-provider'

interface AnnotationStatusBadgeProps {
  status: AnnotationStatus
}

export default function AnnotationStatusBadge({ status }: AnnotationStatusBadgeProps): ReactElement {
  const { isCollaboratorView } = useAnnotationsContext()

  interface BadgeValues {
    color: BadgeColor
    text: string
  }

  const badgeValues = useMemo((): BadgeValues => {
    if (isCollaboratorView) {
      return statuses.collaborator[status]
    } else {
      return statuses.default[status]
    }
  }, [isCollaboratorView, status])

  if (badgeValues) {
    return (
      <div className="tw-pb-2">
        <Badge color={badgeValues.color} style="pill">
          {badgeValues.text}
        </Badge>
      </div>
    )
  }

  return null
}

const statuses = {
  collaborator: {
    [AnnotationStatus.Approved]: { color: 'success', text: 'Reviewed' },
    [AnnotationStatus.Rejected]: { color: 'success', text: 'Reviewed' },
    [AnnotationStatus.Review]: { color: 'light', text: 'Pending' },
  },
  default: {
    [AnnotationStatus.Rejected]: { color: 'danger', text: 'Rejected' },
  },
}
