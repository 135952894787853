import { ThumbsDown, ThumbsUp } from 'lucide-react'
import { useState } from 'react'

export interface ThumbsButtonProps {
  className?: string
  onClick: () => void
  filled?: boolean
  disabled?: boolean
  dataTestId?: string
}

interface ThumbRatingButtonProps {
  ThumbsButton: typeof ThumbsUp | typeof ThumbsDown
  buttonProps: ThumbsButtonProps
}

function ThumbRatingButton({ ThumbsButton, buttonProps }: ThumbRatingButtonProps) {
  const { onClick, filled, disabled, dataTestId } = buttonProps
  const [isHover, setIsHover] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled)

  let { className } = buttonProps

  if (isDisabled) {
    className += ' tw-cursor-not-allowed'
    if (!filled) {
      className += ' tw-opacity-30'
    }
  } else {
    className += ' tw-cursor-pointer'
  }

  if (filled || (!isDisabled && isHover)) {
    className += ' tw-fill-current'
  }

  function handleOnClick() {
    if (isDisabled) return

    setIsDisabled(true)
    onClick()
  }

  return (
    <ThumbsButton
      className={className}
      data-testid={dataTestId}
      onClick={handleOnClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      size={24}
    />
  )
}

export function ThumbsUpButton({ className = '', disabled = false, filled = false, onClick }: ThumbsButtonProps) {
  const buttonProps: ThumbsButtonProps = {
    className: className + ' tw-text-gherkin-500',
    dataTestId: 'thumbs-up-button',
    disabled,
    filled,
    onClick,
  }

  return <ThumbRatingButton ThumbsButton={ThumbsUp} buttonProps={buttonProps} />
}

export function ThumbsDownButton({ className = '', disabled = false, filled = false, onClick }: ThumbsButtonProps) {
  const buttonProps: ThumbsButtonProps = {
    className: className + ' tw-text-flushpink-500',
    dataTestId: 'thumbs-down-button',
    disabled,
    filled,
    onClick,
  }

  return <ThumbRatingButton ThumbsButton={ThumbsDown} buttonProps={buttonProps} />
}
