import { useState } from 'react'
import { TicketFileVersion } from 'lib/api/ticket-files/ticket-file-versions'
import Checkbox from 'lib/components/checkbox/checkbox'
import Footer from './ticket-file-versions-cell-footer'
import { useTicketFileVersionsContext } from './ticket-file-versions-provider'

const customStyles = {
  cellBody: {
    width: '378px',
    height: '307px',
  },
  imageContainer: {
    height: '251px',
  },
}

const classNames = {
  cellWrapper: 'tw-p-4',
  cellBody: 'tw-rounded-lg tw-mb-1 tw-shadow tw-relative',
  image: 'tw-max-w-full tw-max-h-full',
  imageContainer: 'tw-w-full tw-flex tw-items-center tw-justify-center',
  checkbox: 'tw-absolute tw--m-2',
}

function Thumbnail({ file }: TicketFileVersion): JSX.Element {
  const { url, thumbnailUrl } = file

  return (
    <div className={classNames.imageContainer} style={customStyles.imageContainer}>
      <img className={classNames.image} src={thumbnailUrl || url} alt="File Thumbnail" />
    </div>
  )
}

export default function TicketFileVersionsCell({
  ticketFileVersion,
}: {
  ticketFileVersion: TicketFileVersion
}): JSX.Element {
  const [isHovered, setIsHovered] = useState(false)
  const { selectedList, setSelectedList } = useTicketFileVersionsContext()

  const isSelected = selectedList.includes(ticketFileVersion.id)

  function onClick() {
    if (isSelected) {
      setSelectedList((prevList) => prevList.filter((id) => id !== ticketFileVersion.id))
      return
    }
    setSelectedList((prevList) => [...prevList, ticketFileVersion.id])
  }

  function CheckboxElement() {
    if (isHovered || isSelected) {
      return <Checkbox isChecked={isSelected} onClick={onClick} className={classNames.checkbox} />
    }
    return null
  }

  return (
    <div
      className={classNames.cellWrapper}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={classNames.cellBody} style={customStyles.cellBody}>
        <CheckboxElement />
        <Thumbnail {...ticketFileVersion} />
        <Footer {...ticketFileVersion} />
      </div>
    </div>
  )
}
