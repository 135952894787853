import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface Permission {
  id: string
  description: string
  icon: IconProp
  admin: boolean
  manager: boolean
  member: boolean
}

export const adminPermissions: Readonly<Permission[]> = Object.freeze([
  {
    id: 'tickets',
    description: 'Tickets',
    icon: ['fal', 'clipboard-list-check'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'assigned-tickets',
    description: 'View, edit assigned tickets',
    icon: null,
    admin: true,
    manager: true,
    member: true,
  },
  {
    id: 'any-tickets',
    description: 'View, edit any ticket',
    icon: null,
    admin: true,
    manager: true,
    member: false,
  },
  {
    id: 'customers',
    description: 'Customers',
    icon: ['fal', 'users'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'view-any-company',
    description: 'View any company or customer profile',
    icon: null,
    admin: true,
    manager: true,
    member: true,
  },
  {
    id: 'edit-notes',
    description: 'Edit notes in any company or customer profile',
    icon: null,
    admin: true,
    manager: true,
    member: true,
  },
  {
    id: 'edit-any-company',
    description: 'Edit any company or customer profile',
    icon: null,
    admin: true,
    manager: true,
    member: false,
  },
  {
    id: 'cancel-subscription',
    description: 'Cancel subscription, put company on hold, disassociate customer',
    icon: null,
    admin: true,
    manager: false,
    member: false,
  },
  {
    id: 'teams',
    description: 'Teams',
    icon: ['fal', 'users-class'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'edit-designers',
    description: 'View, add, and edit designers and teams information',
    icon: null,
    admin: true,
    manager: true,
    member: false,
  },
  {
    id: 'delete-designers',
    description: 'Delete designers and teams information',
    icon: null,
    admin: true,
    manager: true,
    member: false,
  },
  {
    id: 'reports',
    description: 'Reports',
    icon: ['fal', 'file-chart-line'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'view-reports',
    description: 'View applicable reports available',
    icon: null,
    admin: true,
    manager: true,
    member: true,
  },
  {
    id: 'edit-reports',
    description: 'View and edit all reports available',
    icon: null,
    admin: true,
    manager: false,
    member: false,
  },
  {
    id: 'requests',
    description: 'Production Time-off Requests',
    icon: ['fal', 'calendar'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'submit-requests',
    description: "Submit and edit users's own time-off requests",
    icon: null,
    admin: true,
    manager: true,
    member: true,
  },
  {
    id: 'approve-requests',
    description: 'Approve/reject submitted time-off requests',
    icon: null,
    admin: true,
    manager: true,
    member: false,
  },
  {
    id: 'customer-success-tools',
    description: 'Customer Success Tools',
    icon: ['fal', 'toolbox'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'edit-customer-success-tools',
    description: 'Add, edit, or delete Announcements, Holidays, Canned Responses and Coupons',
    icon: null,
    admin: true,
    manager: true,
    member: false,
  },
  {
    id: 'tagging',
    description: 'Tagging',
    icon: ['fal', 'tag'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'own-tags',
    description: "Edit users' OWN tags",
    icon: null,
    admin: true,
    manager: true,
    member: true,
  },
  {
    id: 'all-tags-edit',
    description: 'Edit, and delete ALL tags in the company account',
    icon: null,
    admin: true,
    manager: true,
    member: false,
  },
  {
    id: 'all-tags-other',
    description: 'Assign, and unassign ALL tags in the company account',
    icon: null,
    admin: true,
    manager: true,
    member: true,
  },
])

export const customerPermissions: Readonly<Permission[]> = Object.freeze([
  {
    id: 'account-subscriptions',
    description: 'Account Subscriptions',
    icon: ['fal', 'shopping-cart'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'edit-account-subscriptions',
    description: 'Edit, add, and cancel company’s subscriptions',
    icon: null,
    admin: true,
    manager: false,
    member: false,
  },
  {
    id: 'billing-and-payment',
    description: 'Billing and Payment',
    icon: ['fal', 'credit-card'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'edit-billing-and-payment',
    description: "Edit company's billing and payment information",
    icon: null,
    admin: true,
    manager: false,
    member: false,
  },
  {
    id: 'user-management',
    description: 'User Management',
    icon: ['fal', 'user'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'invite-user',
    description: 'Invite user, deactivate user, and initiate password change',
    icon: null,
    admin: true,
    manager: true,
    member: false,
  },
  {
    id: 'requests',
    description: 'Requests',
    icon: ['fal', 'ticket'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'own-requests',
    description: "Add, edit, and cancel users' OWN requests",
    icon: null,
    admin: true,
    manager: true,
    member: true,
  },
  {
    id: 'all-requests',
    description: 'Add, edit, and cancel ALL requests in the company account',
    icon: null,
    admin: true,
    manager: true,
    member: false,
  },
  {
    id: 'request-priorities',
    description: 'Request Priorities',
    icon: ['fal', 'list-ol'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'manage-request-priorities',
    description: 'Manage request priorities in the company account',
    icon: null,
    admin: true,
    manager: true,
    member: false,
  },
  {
    id: 'brands',
    description: 'Brands',
    icon: ['fal', 'palette'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'edit-brands',
    description: 'Add, edit, and delete a brand profile in the company acccount',
    icon: null,
    admin: true,
    manager: true,
    member: true,
  },
  {
    id: 'tagging',
    description: 'Tagging',
    icon: ['fal', 'tags'],
    admin: null,
    manager: null,
    member: null,
  },
  {
    id: 'own-tags',
    description: "Delete users' OWN tags",
    icon: null,
    admin: true,
    manager: true,
    member: true,
  },
  {
    id: 'all-tags-edit',
    description: 'Delete ALL tags in the company account',
    icon: null,
    admin: true,
    manager: true,
    member: false,
  },
  {
    id: 'all-tags-other',
    description: 'Add, edit, assign, and unassign ALL tags in the company account',
    icon: null,
    admin: true,
    manager: true,
    member: true,
  },
])
