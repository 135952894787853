import { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp, MessageSquareQuote } from 'lucide-react'

import Modal from 'lib/components/modals/modal'
import {
  CannedResponse,
  CannedResponseFolder,
  getCannedResponses,
} from 'lib/api/admin/canned-responses/canned-responses'

import Button from 'components/core/button'
import { toast } from 'lib/components/toast/toast'

interface AdminCannedResponseModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  onSelect: (selectedResponse: string) => void
}

const classNames = {
  expandCollapseContainer:
    'tw-inline-flex tw-flex-row tw-items-center tw-gap-1 tw-p-0 tw-py-2 tw-border-none tw-bg-transparent tw-cursor-pointer',
}

export default function AdminCannedResponseModal({ open, setOpen, onSelect }: AdminCannedResponseModalProps) {
  const [expandedFolder, setExpandedFolder] = useState<CannedResponseFolder>(null)
  const [expandedResponse, setExpandedResponse] = useState<CannedResponse>(null)
  const [cannedResponseFolders, setCannedResponseFolders] = useState<CannedResponseFolder[]>([])

  function reset() {
    setExpandedFolder(null)
    setExpandedResponse(null)
  }

  function cancel() {
    setOpen(false)
    reset()
  }

  async function fetchCannedResponses() {
    try {
      const response = await getCannedResponses()
      setCannedResponseFolders(response)
    } catch (error) {
      toast.error('There was an error fetching canned responses, try again later')
      console.error('Error fetching canned responses', error)
    }
  }

  function toggleFolder(folder: CannedResponseFolder) {
    if (expandedFolder === folder) {
      setExpandedFolder(null)
    } else {
      setExpandedFolder(folder)
    }
  }

  function toggleResponse(response: CannedResponse) {
    if (expandedResponse === response) {
      setExpandedResponse(null)
    } else {
      setExpandedResponse(response)
    }
  }

  function onSelectCannedResponse(responseText: string) {
    onSelect(responseText)
    setOpen(false)
    reset()
  }

  useEffect(() => {
    fetchCannedResponses()
  }, [])

  return (
    <Modal open={open} setOpen={setOpen} size="md">
      <Modal.Header as="div">
        <h4 className="tw-mt-0">Insert canned response</h4>
      </Modal.Header>
      <Modal.Body setOpen={setOpen} closeButton>
        <div className="tw-flex tw-flex-col tw-gap-2 tw-text-neutral-600">
          {cannedResponseFolders.map((folder) => (
            <div key={folder.id}>
              <button className={classNames.expandCollapseContainer} onClick={() => toggleFolder(folder)}>
                {folder.name} {expandedFolder === folder ? <ChevronUp /> : <ChevronDown />}
              </button>
              {expandedFolder === folder && (
                <div className="tw-ml-4 tw-flex tw-flex-col tw-gap-2">
                  {folder.cannedResponses.map((response) => (
                    <div key={response.id}>
                      <button className={classNames.expandCollapseContainer} onClick={() => toggleResponse(response)}>
                        <MessageSquareQuote className="lu-md" /> {response.subject}{' '}
                        {expandedResponse === response ? <ChevronUp /> : <ChevronDown />}
                      </button>
                      {expandedResponse === response && (
                        <div className="tw-ml-4">
                          <div dangerouslySetInnerHTML={{ __html: response.body }} />
                          <Button
                            color="purple"
                            className="tw-mt-2"
                            onClick={() => {
                              onSelectCannedResponse(response.body)
                            }}
                          >
                            Insert
                          </Button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="tw-flex tw-items-center tw-justify-end">
          <Button color="neutralGray" type="button" onClick={cancel}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
