import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['info']
    static values = {
        url: String
    }

    initialize() {
        console.log('Stimulus init')
    }

    connect() {
        this.infoTarget.innerHTML = this.urlValue
    }
}