import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface ExternalLinkProps {
  children: ReactNode
  className?: string
  href: string
}

export default function ExternalLink({ children, className = '', href }: ExternalLinkProps) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`tw-font-bold tw-text-neutral-800${className ? ' ' + className : ''}`}
    >
      {children}
      {` `}
      <FontAwesomeIcon icon={['far', 'external-link']} />
    </a>
  )
}
