import { ChevronDown, ChevronUp, Clipboard, Download, ExternalLink } from 'lucide-react'
import { ReactNode, useCallback, useState } from 'react'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import { AdminTicketBrandAsset, AdminTicketImageAsset } from 'lib/api/admin/tickets/admin-tickets'
import Tooltip from 'lib/components/tooltip/tooltip'
import Button, { LinkLikeButton } from 'components/core/button'
import { generateAndDownloadZip } from 'lib/util/file/file'
import Accordian from 'lib/components/accordian/accordian'
import { uniqBy } from 'lodash'

export default function AdminClientProfile() {
  const { ticket } = useAdminTicketContext()
  const company = ticket.company
  const clientTeam = uniqBy([...(ticket?.company?.designers || []), ...(ticket?.company?.backupDesigners || [])], 'id')
  const apms = uniqBy(ticket.company.apms, 'id')
  const tls = uniqBy(ticket.company.qrTeams, 'id')

  const activeTicketsSearchParams = new URLSearchParams({
    'q[company_id_eq]': String(company.id),
    'q[ticket_status]': 'active',
  })

  const closedTicketsSearchParams = new URLSearchParams(
    Object.entries({
      'q[company_id_eq]': String(company.id),
      'q[state_in][]': ['6', '8', '9', '10', '11'], // customer_review closed archived canceled inactive
    }).flatMap(([key, value]) => (Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]])),
  )

  const baseLinkUrl = '/admin/tickets'

  return (
    <div>
      <header className="tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-p-4">
        <h4 className="tw-m-0">Client Profile View</h4>
      </header>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4">
        <BrandProfileCard />
        <div className="tw-flex tw-flex-col tw-gap-1">
          <div className="tw-flex tw-gap-2">
            <span className="tw-text-gray-400">Company notes</span>
            <a
              href={`/admin/companies?company_id=${company.id}`}
              target="_blank"
              rel="noreferrer"
              className="tw-flex tw-items-center tw-gap-2"
            >
              <ExternalLink className="lu-sm" />
              <span>Edit Company Notes</span>
            </a>
          </div>
          <div dangerouslySetInnerHTML={{ __html: company.notes ?? 'No notes set' }} />
        </div>{' '}
        <div className="tw-flex tw-flex-col tw-gap-1">
          <span className="tw-text-gray-400">Other requests</span>
          <Accordian title={`Active Requests (${company.activeTickets.length})`}>
            <ul>
              {company.activeTickets.slice(0, 5).map(([ticketId, ticketName]) => (
                <li key={ticketId}>
                  <a href={`${baseLinkUrl}/pte/${ticketId}`}>{ticketName}</a>
                </li>
              ))}
              <a href={`${baseLinkUrl}?${activeTicketsSearchParams.toString()}`} target="_blank" rel="noreferrer">
                View All
              </a>
            </ul>
          </Accordian>
          <Accordian title={`Closed Requests (${company.completedTickets.length})`}>
            <ul>
              {company.completedTickets.slice(0, 5).map(([ticketId, ticketName]) => (
                <li key={ticketId}>
                  <a href={`${baseLinkUrl}/pte/${ticketId}`}>{ticketName}</a>
                </li>
              ))}
              <a href={`${baseLinkUrl}?${closedTicketsSearchParams.toString()}`} target="_blank" rel="noreferrer">
                View All
              </a>
            </ul>
          </Accordian>
        </div>{' '}
        <div className="tw-flex tw-flex-col tw-gap-1">
          <span className="tw-text-gray-400">Creative team</span>
          <div className="tw-flex tw-flex-col tw-gap-1">
            {clientTeam.length > 0
              ? clientTeam.map((designer) => (
                  <a
                    key={designer.id}
                    href={`/admin/designers/?designer_id=${designer.id}`}
                    target="_blank"
                    rel="noreferrer"
                    className="tw-w-max"
                  >
                    {designer.name}
                  </a>
                ))
              : 'No designers associated with this company'}
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1">
          <span className="tw-text-gray-400">Support team</span>
          <div className="tw-flex tw-flex-col tw-gap-1">
            {ticket.designer && (
              <div>
                Designer:{' '}
                <a
                  href={`/admin/designers/?designer_id=${ticket.designer.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="tw-w-max"
                >
                  {ticket.designer.name}
                </a>
              </div>
            )}
            {tls.length > 0 && (
              <div>
                TL(s):{' '}
                {tls.map((tl, idx) => (
                  <a
                    key={tl.id}
                    href={`/admin/qr_teams/${tl.id}/edit`}
                    target="_blank"
                    rel="noreferrer"
                    className="tw-w-max"
                  >
                    {tl.name}
                    {idx !== tls.length - 1 && ', '}
                  </a>
                ))}
              </div>
            )}
            {apms.length > 0 && (
              <div>
                APM(s):{' '}
                {apms.map((apm, idx) => (
                  <a key={apm.id} href="/admin/apm" target="_blank" rel="noreferrer" className="tw-w-max">
                    {apm.name}
                    {idx !== apms.length - 1 && ', '}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

interface DetailContainerProps {
  children: ReactNode
  label: string
  zipFiles?: AdminTicketBrandAsset[]
  brandName?: string
}
function DetailContainer({ children, label, zipFiles, brandName }: DetailContainerProps) {
  const zipFileName = `${brandName} ${label}`

  return (
    <div className="tw-flex tw-flex-col tw-gap-1">
      <span className="tw-text-gray-400">
        {label}{' '}
        {zipFiles?.length > 0 && (
          <DownloadAllButtonLink files={zipFiles} zipName={zipFileName}>
            Download all {label.toLowerCase()}
          </DownloadAllButtonLink>
        )}
      </span>
      <div className="tw-flex tw-flex-col tw-items-start">{children}</div>
    </div>
  )
}

function TextAssetLink({ asset }: { asset: AdminTicketBrandAsset }) {
  return (
    <a
      href={asset.expiringUrl}
      className="tw-flex tw-items-center tw-gap-1 hover:tw-text-cornflower-600"
      download={asset.assetFileName}
    >
      {asset.name} <Download className="lu-sm" />
    </a>
  )
}

function ImageAsset({ thumbnailUrl, url, name }: AdminTicketImageAsset) {
  return (
    <Tooltip direction="up" content={name} width={300}>
      <a
        href={url}
        className="tw-relative tw-flex tw-w-28 tw-overflow-hidden tw-rounded-md tw-border tw-border-solid tw-border-gray-400"
        style={{ aspectRatio: '1/1' }}
        target="_blank"
        download={name}
        rel="noreferrer"
      >
        <img src={thumbnailUrl} alt={name} className="tw-h-full tw-w-full tw-object-contain tw-object-center" />
      </a>
    </Tooltip>
  )
}

interface DownloadAllButtonProps {
  files: AdminTicketBrandAsset[]
  zipName: string
  children: ReactNode
}

function DownloadAllButtonLink({ files, zipName, children }: DownloadAllButtonProps) {
  const handleButtonClick = useCallback(async () => {
    await generateAndDownloadZip(brandAssetFileToZipFiles(files), zipName)
  }, [files, zipName])

  return (
    <LinkLikeButton onClick={handleButtonClick}>
      <Download className="lu-sm" /> {children}
    </LinkLikeButton>
  )
}

function BrandProfileCard() {
  const [showFullBrand, setShowFullBrand] = useState(false)
  const { ticket } = useAdminTicketContext()

  if (!ticket?.selectedBrand) {
    return (
      <div className="tw-flex tw-flex-col tw-gap-4 tw-rounded tw-border tw-border-solid tw-border-gray-200 tw-p-3">
        No brand profile set
      </div>
    )
  }

  const brand = ticket.selectedBrand

  const fonts = brand.assets.filter((asset) => asset.isFont)
  const imageAssets = brand.assets.filter((asset) => asset.isImage && !asset.isInspiration)
  const brandInspirationAssets = brand.assets.filter((asset) => asset.isImage && asset.isInspiration)
  const otherAssets = brand.assets.filter((asset) => !asset.isFont && !asset.isImage)

  async function handleZipAllAssetsClick() {
    await generateAndDownloadZip(brandAssetFileToZipFiles(brand.assets), `${brand.name} Assets`)
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-rounded tw-border tw-border-solid tw-border-gray-200 tw-p-3">
      <DetailContainer label="Brand profile">
        <span>{brand.name}</span>
      </DetailContainer>
      <DetailContainer label="Target audience">
        <span>{brand.target}</span>
      </DetailContainer>
      <DetailContainer label="Description">
        <span>{brand.description ?? 'No description'}</span>
      </DetailContainer>
      <DetailContainer label="Colors">
        <div>
          {brand.colors.map((color) => (
            <div
              key={color.id}
              className="tw-flex tw-cursor-pointer tw-flex-row tw-items-center tw-gap-1 hover:tw-text-cornflower-600"
              onClick={() => {
                navigator.clipboard.writeText(color.value)
              }}
            >
              <div style={{ backgroundColor: color.value }} className="tw-h-4 tw-w-4 tw-rounded-full"></div>
              <span>{color.value}</span>
              <Clipboard className="lu-sm tw-text-gray-400" />
            </div>
          ))}
        </div>
      </DetailContainer>
      <Button
        color="neutralGray"
        onClick={handleZipAllAssetsClick}
        className="tw-flex tw-w-52 tw-items-center tw-justify-center tw-gap-1"
      >
        <Download className="lu-sm" />
        <span>Download all assets</span>
      </Button>
      <div className="tw-full tw-text-center">
        <button
          className="tw-inline-flex tw-cursor-pointer tw-flex-row tw-items-center tw-gap-1 tw-border-none tw-bg-transparent tw-p-0 tw-text-cornflower-600"
          onClick={() => setShowFullBrand((prev) => !prev)}
        >
          {showFullBrand ? <ChevronUp /> : <ChevronDown />} {showFullBrand ? 'Hide' : 'View'} full brand profile
        </button>
      </div>
      {showFullBrand && (
        <div className="tw-flex tw-flex-col tw-gap-4">
          <DetailContainer label="Brand Notes">
            <div dangerouslySetInnerHTML={{ __html: brand.notes }} />
          </DetailContainer>
          <DetailContainer label="Fonts" zipFiles={fonts} brandName={brand.name}>
            {fonts.length === 0 ? 'No Fonts' : fonts.map((asset) => <TextAssetLink key={asset.id} asset={asset} />)}
          </DetailContainer>
          <DetailContainer label="Image Assets" zipFiles={imageAssets} brandName={brand.name}>
            {imageAssets.length === 0 ? (
              'No Image Assets'
            ) : (
              <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-2">
                {imageAssets.map((asset) => {
                  return (
                    <ImageAsset
                      key={asset.id}
                      url={asset.expiringUrl}
                      thumbnailUrl={asset.expiringThumbnailUrl}
                      name={asset.name}
                    />
                  )
                })}
              </div>
            )}
          </DetailContainer>
          <DetailContainer label="Inspiration Assets" zipFiles={brandInspirationAssets} brandName={brand.name}>
            {brandInspirationAssets.length === 0 ? (
              'No Inspiration Assets'
            ) : (
              <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-2">
                {brandInspirationAssets.map((asset) => {
                  return (
                    <ImageAsset
                      key={asset.id}
                      url={asset.expiringUrl}
                      thumbnailUrl={asset.expiringThumbnailUrl}
                      name={asset.name}
                    />
                  )
                })}
              </div>
            )}
          </DetailContainer>
          <DetailContainer label="Stock Inspiration Assets">
            {ticket.selectedBrand.inspirations.length === 0 ? (
              'No Stock Inspiration Assets'
            ) : (
              <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-2">
                {ticket.selectedBrand.inspirations.map((asset) => {
                  return (
                    <ImageAsset
                      key={asset.id}
                      url={asset.previewUrl}
                      thumbnailUrl={asset.thumbnailUrl}
                      name={asset.name}
                    />
                  )
                })}
              </div>
            )}
          </DetailContainer>
          <DetailContainer label="Other Assets" zipFiles={otherAssets} brandName={brand.name}>
            {otherAssets.length === 0
              ? 'No Other Assets'
              : otherAssets.map((asset) => <TextAssetLink key={asset.id} asset={asset} />)}
          </DetailContainer>
        </div>
      )}
    </div>
  )
}

function brandAssetFileToZipFiles(files: AdminTicketBrandAsset[]) {
  return files.map((file) => ({ name: file.assetFileName, url: file.expiringUrl }))
}
