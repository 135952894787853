import { ChangeEvent, ReactElement, useRef, useState } from 'react'
import Textarea from 'lib/components/textarea/textarea'
import IconButton from 'lib/components/buttons/icon-button'

const classNames = {
  container: 'tw-flex tw-items-center tw-gap-2 tw-pb-4',
  textarea: 'tw-w-full tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-p-2',
}

interface EditCommentFormProps {
  body: string
  onCancel: () => void
  onSubmit: (body: string) => void | Promise<void>
}

export default function EditCommentForm({ body, onCancel, onSubmit }: EditCommentFormProps): ReactElement {
  const bodyRef = useRef<HTMLTextAreaElement>()
  const [value, setValue] = useState<string>(body)

  function handleOnCancel() {
    onCancel()
  }

  function handleOnChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setValue(event.target.value)
  }

  async function handleOnSubmit() {
    onSubmit(value)
  }

  return (
    <div className={classNames.container}>
      <Textarea
        ref={bodyRef}
        className={classNames.textarea}
        maxLength={-1}
        onChange={handleOnChange}
        placeholder="Start typing your reply."
        rows="2"
        resize={{ vertical: true }}
        value={value}
      />
      <div style={{ minWidth: '40px' }}>
        <IconButton color="secondary" icon={['far', 'check']} onClick={handleOnSubmit} />
      </div>
      <span onMouseDown={(e) => e.preventDefault()}>
        <IconButton color="secondary" icon={['far', 'times']} onClick={handleOnCancel} />
      </span>
    </div>
  )
}
