import Button from 'components/core/button'
import { Link } from 'lucide-react'
import { toast } from 'lib/components/toast/toast'

export default function CopyButton({ isShared, shareUrl }: { isShared: boolean; shareUrl: string }) {
  function handleCopyLinkClick() {
    navigator.clipboard.writeText(shareUrl)
    toast.success('Link copied')
  }

  return (
    <Button color="lightGray" type="button" disabled={!isShared} onClick={handleCopyLinkClick}>
      <Link className="lu-light lu-md" /> Copy Link
    </Button>
  )
}
