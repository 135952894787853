import React from 'react'
import { Menu } from '@headlessui/react'

interface ExternalActionLinkItemProps {
  label: string
  iconClass: string
  route: string
  method?: 'GET' | 'POST' | 'DELETE'
}

export default function ExternalActionLinkItem({
  label,
  iconClass,
  route,
  method = 'GET',
}: ExternalActionLinkItemProps): JSX.Element {
  const jqueryProps = {}
  if (method !== 'GET') {
    // This is handled by a click event listener jQuery binds to the body node.
    jqueryProps['data-method'] = method
  }

  return (
    <Menu.Item>
      {() => (
        <a
          href={route}
          className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-peppercorn-700 hover:tw-bg-peppercorn-100 hover:tw-text-peppercorn-900"
          {...jqueryProps}
        >
          <i className={iconClass}></i>
          &nbsp;
          {label}
        </a>
      )}
    </Menu.Item>
  )
}
