import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import placeholderImage from 'images/requests/request-type-placeholder.png'
import { ProjectTemplate } from 'interfaces/project-templates'
import { request, requestQuery } from 'lib/api/fetch-api'
import { Card } from 'lib/components/card/card'
import { toast } from 'lib/components/toast/toast'
import { FolderOpen } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { LoadingScreen } from '../requests/empty-screens'
import CreateProjectDrawer from './create-project-drawer'

export default function ProjectTemplates() {
  const [isCreateProjectDrawerOpen, setIsCreateProjectDrawerOpen] = useState(false)
  const [selectedProjectTemplate, setSelectedProjectTemplate] = useState<ProjectTemplate>()
  const { ref, inView } = useInView()

  const {
    data: projectTemplates,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    error,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['projectTemplates'],
    queryFn: ({ pageParam = 1 }) => request({ endpoint: 'getAllCustomProjectTemplates', query: { page: pageParam } }),
    getNextPageParam: (lastPage, pages) => (lastPage.meta?.nextPage ? pages.length + 1 : undefined),
  })

  if (error) {
    toast.error('Failed to fetch project templates')
    console.error('Failed to fetch project templates', error)
  }

  const projectTemplateId = parseInt(new URLSearchParams(window.location.search).get('projectTemplateId'))

  if (!selectedProjectTemplate && projectTemplateId && projectTemplates?.pages.length > 0) {
    const projectTemplate = projectTemplates.pages
      .flatMap((page) => page.data)
      .find((template) => template.id === projectTemplateId)
    if (projectTemplate) {
      selectProjectTemplate(projectTemplate)
    }
  }

  const { data: projectTemplate, error: projectTemplateError } = useQuery({
    queryKey: ['projectTemplate', projectTemplateId],
    queryFn: requestQuery({ endpoint: 'getProjectTemplate', query: { id: projectTemplateId } }),
    enabled: !!projectTemplateId && !selectedProjectTemplate && projectTemplates?.pages.length > 0,
  })

  if (projectTemplateError) {
    toast.error('Failed to fetch project template')
    console.error('Failed to fetch project template', projectTemplateError)
  } else if (!selectedProjectTemplate && projectTemplate) {
    selectProjectTemplate(projectTemplate)
  }

  function selectProjectTemplate(projectTemplate: ProjectTemplate) {
    setSelectedProjectTemplate(projectTemplate)
    setIsCreateProjectDrawerOpen(true)
    history.replaceState(null, '', `/create?tab=projects&projectTemplateId=${projectTemplate.id}`)
  }

  function handleCloseCreateProjectDrawer() {
    setSelectedProjectTemplate(undefined)
    setIsCreateProjectDrawerOpen(false)
    history.replaceState(null, '', '/create?tab=projects')
  }

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, isFetchingNextPage])

  return (
    <>
      {projectTemplates?.pages.length > 0 || isLoading ? (
        <>
          <h4 className="tw-m-0 tw-py-6">All Projects</h4>
          <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-4">
            {projectTemplates?.pages
              .flatMap((page) => page.data)
              .map((projectTemplate) => (
                <Card
                  key={projectTemplate.id}
                  size="lg"
                  onClick={() => selectProjectTemplate(projectTemplate)}
                  className="tw-h-64 tw-w-64"
                >
                  <Card.Body className="tw-h-full tw-w-full">
                    <img
                      src={projectTemplate.imageLink || placeholderImage}
                      alt={projectTemplate.name}
                      className="tw-h-3/4 tw-w-full tw-rounded-t-lg tw-object-cover"
                    />
                    <div className="tw-flex tw-h-16 tw-w-full tw-items-center tw-justify-center tw-gap-2 tw-p-2">
                      <FolderOpen className="lu-light tw-text-black" />
                      <div>
                        <h6 className="tw-text-medium tw-m-0">{projectTemplate.name}</h6>
                        <p className="tw-neutral-300 tw-m-0 tw-text-xs">{projectTemplate.description}</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            <div ref={ref} className="tw-h-4" />
            {isFetchingNextPage && <div className="tw-py-4 tw-text-center">Loading more...</div>}
          </div>
          <CreateProjectDrawer
            isOpen={isCreateProjectDrawerOpen}
            setOpen={handleCloseCreateProjectDrawer}
            projectTemplate={selectedProjectTemplate}
          />
        </>
      ) : (
        <LoadingScreen size="2xl" />
      )}
    </>
  )
}
