import { AppSettings, CurrentUser } from 'interfaces/app'
import TicketFileVersionsProvider from './ticket-file-versions-provider'
import AssetsPageContainer from './assets-page-container'
import UserProvider from 'providers/user-provider'
import { Toaster } from 'lib/components/toast/toast'

interface AssetsPageProps {
  settings: AppSettings
  user: CurrentUser
}

export default function AssetsPage({ user, settings }: AssetsPageProps): JSX.Element {
  return (
    <TicketFileVersionsProvider user={user}>
      <Toaster />
      <UserProvider settings={settings} user={user}>
        <AssetsPageContainer />
      </UserProvider>
    </TicketFileVersionsProvider>
  )
}
