import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/core/button'
import { BetaBadge } from 'lib/components/badge/badge'
import ExternalLink from 'lib/components/links/external-link'
import Modal from 'lib/components/modals/modal'
import Textarea, { TextCounter } from 'lib/components/textarea/textarea'
import { useGenAiContext } from '../../providers/gen-ai-provider'

interface RequestAssistGenerateModalProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const classNames = {
  buttons: {
    secondary:
      'tw-bg-peppercorn-50 hover:tw-bg-peppercorn-100 tw-mt-3 tw-inline-flex tw-w-full tw-justify-center tw-px-4 tw-py-2 tw-border-0 sm:tw-mt-0 sm:tw-w-auto sm:tw-text-sm',
    wrapper: 'tw-flex tw-justify-end tw-gap-2 tw-mt-4',
  },
  progress: {
    container: 'tw-text-center',
    img: 'tw-mx-auto',
    text: 'tw-mx-auto',
  },
  title: 'tw-text-lg tw-font-bold tw-flex tw-items-center tw-gap-1',
}

export default function RequestAssistGenerateModal({ open, setOpen }: RequestAssistGenerateModalProps): JSX.Element {
  const [prompt, setPrompt] = useState<string>('')
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const { createRequestAssist } = useGenAiContext()

  async function generate() {
    try {
      await createRequestAssist(prompt)
      setOpen(false)
      setPrompt('')
    } catch (error) {
      console.error('Create Gen AI Request Error', error)
      alert('There was an error creating your request. Please try again.')
    }
  }

  function cancel() {
    setOpen(false)
    setPrompt('')
  }

  function handleAfterEnter() {
    textareaRef.current.focus()
  }

  return (
    <Modal afterEnter={handleAfterEnter} open={open} setOpen={setOpen} size="lg">
      <Modal.Header>
        <span className={classNames.title}>
          <FontAwesomeIcon icon={['far', 'sparkles']} />
          <span>
            Request Assist <BetaBadge />
          </span>
        </span>
      </Modal.Header>

      <Modal.Body setOpen={setOpen} closeButton>
        <>
          <p>
            Use our AI text generator to kickstart your creative request!{' '}
            <ExternalLink href="https://designpickle.com/kb/request-assist">Learn More</ExternalLink>
          </p>
          <Textarea
            ref={textareaRef}
            label="Begin your prompt here about what you want to create"
            placeholder="e.g. instagram summer flash BOGO sale posts with a party theme"
            id="gen-ai-brief-assist-input"
            onChange={(e) => setPrompt(e.target.value)}
            value={prompt}
          />
          <TextCounter className="tw-text-right" value={prompt} />
        </>
      </Modal.Body>

      <Modal.Footer>
        <div className={classNames.buttons.wrapper}>
          <Button type="button" color="neutralGray" className={classNames.buttons.secondary} onClick={cancel}>
            Cancel
          </Button>
          <Button color="purple" type="button" onClick={generate} disabled={prompt.length < 3}>
            Generate
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
