import * as React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { cn } from 'lib/util/cn'
import { cva, VariantProps } from 'class-variance-authority'

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverAnchor = PopoverPrimitive.Anchor

const PopoverContentVariants = cva(
  'tw-border tw-p-4 tw-transition-all tw-duration-200 tw-ease-in-out tw-z-20 tw-rounded-md tw-shadow-lg tw-outline-none',
  {
    variants: {
      intent: {
        tooltip: 'tw-bg-neutral-500 tw-max-w-xs',
        flyout: 'tw-bg-white',
      },
    },
  },
)

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & VariantProps<typeof PopoverContentVariants>
>(({ className, align = 'center', sideOffset = 8, intent, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(PopoverContentVariants({ intent, className }))}
      {...props}
    />
  </PopoverPrimitive.Portal>
))
PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor }
