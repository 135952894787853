import { bindJWT, getApi, postApi, deleteApi } from 'lib/api/api'
import { IntegrationKey } from './types'

export async function handleCreateApiToken(name: string): Promise<IntegrationKey> {
  const postWithJWT = bindJWT(postApi, null)
  const response = await postWithJWT(`/api/internal/integration_keys/`, { data: { name } })
  return response.data
}

export async function getIntegrationKeys(): Promise<IntegrationKey[]> {
  const getWithJWT = bindJWT(getApi, null)
  const response = await getWithJWT(`/api/internal/integration_keys/`)
  return response.data.data
}

export async function deleteIntegrationKey(id: number): Promise<void> {
  const deleteWithJWT = bindJWT(deleteApi, null)
  await deleteWithJWT(`/api/internal/integration_keys/${id}`)
}
