import React, { useState } from 'react'

import { Designer } from 'interfaces/designer'
import { Dpu } from 'interfaces/dpu'
import { findById } from 'lib/array/utils'
import SubscriptionsItem, { ProDpuRequestBody } from '../shared/subscriptions-item'
import {
  GraphicsProDesignerSelect,
  DesignerSelect,
  GraphicsPremiumOrPowerDesignerSelect,
} from 'components/elements/admin/pro-subscriptions/designer-select'

interface ProSubscriptionsItemProps {
  dpu: Dpu
  onDesignerChange: (dpuId: number, changes: Record<string, unknown>) => void
  designers: Designer[]
  userCanAssign: boolean
}

export default function ProSubscriptionsItem(props: ProSubscriptionsItemProps): JSX.Element {
  const [assigned, setAssigned] = useState(props.dpu.backupDesignerId !== null)

  const baseDisabledItems = [
    props.dpu.designerId,
    props.dpu.backupDesignerId,
    props.dpu.primaryCiDesignerId,
    props.dpu.backupCiDesignerId,
    props.dpu.primaryDesignerId,
    props.dpu.primaryPdDesignerId,
    props.dpu.backupPdDesignerId,
  ]

  const disabledItems = ['Graphics Premium', 'Power'].includes(props.dpu.picklePlan.name)
    ? [
        ...baseDisabledItems,
        props.dpu.backupMgDesignerId,
        props.dpu.primaryMgDesignerId,
        props.dpu.primaryVeDesignerId,
        props.dpu.backupVeDesignerId,
      ]
    : baseDisabledItems

  const disabled = disabledItems.some((item) => item === null)

  const requestBody: ProDpuRequestBody = {
    backupCiId: props.dpu.backupCiDesignerId,
    backupDesignerId: props.dpu.backupDesignerId,
    backupMgId: props.dpu.backupMgDesignerId,
    backupPdId: props.dpu.backupPdDesignerId,
    backupVeId: props.dpu.backupVeDesignerId,
    designerId: props.dpu.designerId,
    primaryCiId: props.dpu.primaryCiDesignerId,
    primaryDesignerId: props.dpu.primaryDesignerId,
    primaryMgId: props.dpu.primaryMgDesignerId,
    primaryPdId: props.dpu.primaryPdDesignerId,
    primaryVeId: props.dpu.primaryVeDesignerId,
  }

  const selectedDesigner = findById(props.dpu.designerId, props.designers)
  const selectedBackupDesigner = findById(props.dpu.backupDesignerId, props.designers)

  return (
    <SubscriptionsItem
      assigned={assigned}
      disabled={disabled || !props.userCanAssign}
      dpu={props.dpu}
      requestBody={requestBody}
      setAssigned={setAssigned}
      role={`dpu-item-${props.dpu.id}`}
    >
      <td className="tw-py-2">
        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
          <DesignerSelect
            checkbox
            designers={props.designers}
            disabled={!props.userCanAssign}
            dpu={props.dpu}
            name="designerId"
            onDesignerChange={props.onDesignerChange}
            placeholder="Select Designer"
            selectedDesigner={selectedDesigner}
            setAssigned={setAssigned}
          />
          <DesignerSelect
            designers={props.designers}
            disabled={!selectedDesigner || !props.userCanAssign}
            dpu={props.dpu}
            name="backupDesignerId"
            onDesignerChange={props.onDesignerChange}
            placeholder="Select Backup Designer"
            selectedDesigner={selectedBackupDesigner}
            setAssigned={setAssigned}
          />
          {['Graphics Pro', 'Graphics Premium', 'Power', 'Power Plus'].includes(props.dpu.picklePlan.name) && (
            <GraphicsProDesignerSelect {...props} setAssigned={setAssigned} userCanAssign={props.userCanAssign} />
          )}
          {['Graphics Premium', 'Power', 'Power Plus'].includes(props.dpu.picklePlan.name) && (
            <GraphicsPremiumOrPowerDesignerSelect
              {...props}
              setAssigned={setAssigned}
              userCanAssign={props.userCanAssign}
            />
          )}
        </div>
      </td>
    </SubscriptionsItem>
  )
}
