import { LinkButton } from 'components/core/button'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { AdminTicketFile } from 'lib/api/admin/ticket-files/admin-ticket-files'
import AdminAnnotatedMediaViewer from './admin-annotated-media-viewer'
import AdminVideoJsPlayer from './admin-video-js-player'

interface MediaViewerProps {
  currentTicketFile: AdminTicketFile | null
  selectedFile: AdminTicketFile
}

export default function AdminMediaViewer({ selectedFile, currentTicketFile }: MediaViewerProps) {
  if (selectedFile.isExtractable && selectedFile.extractedPages?.length === 0) {
    return (
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <h4>File is being uploaded, the content will update when finished</h4>
        <LoadingScreen />
      </div>
    )
  }

  if (selectedFile.isVideo) {
    return <AdminVideoJsPlayer ticketFile={selectedFile} />
  }

  if (selectedFile.hasImagePreview) {
    return <AdminAnnotatedMediaViewer ticketFile={currentTicketFile} />
  }

  function externalLinkFormatType() {
    if (selectedFile.externalLink.includes('canva.com')) return 'Canva'
    if (selectedFile.externalLink.includes('docs.google.com/presentation')) return 'Google Slides'
    if (selectedFile.externalLink.includes('figma.com')) return 'Figma'
    return 'External'
  }

  return (
    <div className="tw-text-center">
      <div className="tw-py-6">
        <h4>Preview and annotations are unavailable for this type of file.</h4>
        To view the file, click the download button or external link button.
      </div>
      <img src={`/images/${selectedFile.previewUrl}`} alt="" data-testid="placeholder-image" />
      {selectedFile.externalLink && (
        <div className="tw-mt-4 tw-flex tw-justify-center">
          <LinkButton url={selectedFile.externalLink} color="lightGray" target="_blank">
            Open {externalLinkFormatType()} Link
          </LinkButton>
        </div>
      )}
    </div>
  )
}
