import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import Button from 'components/core/button'
import { CurrentUser } from 'interfaces/app'
import { LeaveDay } from 'interfaces/leave-day'
import { request, requestQuery } from 'lib/api/fetch-api'
import { Drawer } from 'lib/components/drawer/drawer'
import { cn } from 'lib/util/cn'
import { BellIcon } from 'lucide-react'
import { queryClient } from 'providers/query-client-provider'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import IconButton from '../buttons/icon-button'
import { Notification, NotificationContent } from '../notification/notification'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../tabs'

export const BadgeCount = ({ unreadCount }: { unreadCount: number }) => {
  return (
    <div className="tw-absolute tw-right-0 tw-top-0 tw-flex tw-h-4 tw-min-w-4 tw-items-center tw-justify-center tw-rounded-full tw-bg-flushpink-500 tw-px-1 tw-text-xs tw-text-white">
      {unreadCount}
    </div>
  )
}

const Notifications = ({
  user,
  leaveDays,
  overflowProtection,
}: {
  user: CurrentUser
  leaveDays: LeaveDay[]
  overflowProtection: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { ref, inView } = useInView({
    threshold: 0.5,
  })

  const { data: announcements } = useQuery({
    queryKey: ['announcements'],
    queryFn: requestQuery({ endpoint: 'getAnnouncements' }),
  })

  const { data: unreadCount } = useQuery({
    queryKey: ['unreadCount'],
    queryFn: requestQuery({ endpoint: 'getUnreadCount' }),
  })

  const { mutate: markNotificationRead } = useMutation({
    mutationFn: requestQuery({ endpoint: 'markNotificationRead' }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] })
      queryClient.invalidateQueries({ queryKey: ['unreadCount'] })
    },
  })

  const {
    data: notifications,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['notifications'],
    queryFn: ({ pageParam = 1 }) => request({ endpoint: 'getNotifications', query: { page: pageParam } }),
    getNextPageParam: (lastPage, pages) => (lastPage.meta?.nextPage ? pages.length + 1 : undefined),
  })

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage])

  const daysOffNotification = !!leaveDays?.length && overflowProtection
  const unreadCountCombined =
    (unreadCount?.data?.unread_count || 0) + announcements?.length + (daysOffNotification ? 1 : 0)

  const PlatformNotifications = () => {
    if (!announcements?.length && !daysOffNotification) {
      return <div className="tw-py-4 tw-text-center">No notifications</div>
    }

    return (
      <>
        {announcements?.map((announcement, i) => (
          <Notification
            key={announcement.id}
            className={cn('tw-px-4 tw-py-3', i !== 0 && 'tw-border-t-solid tw-border-0 tw-border-t tw-border-gray-200')}
          >
            {announcement.heading && <div>{announcement.heading}</div>}
            <div className="tw-text-xs" dangerouslySetInnerHTML={{ __html: announcement.body }} />
          </Notification>
        ))}
        {daysOffNotification && (
          <Notification className="tw-border-0 tw-border-t tw-border-solid tw-border-gray-200 tw-px-4 tw-py-3">
            <div>Upcoming Designer Days Off</div>
            <div className="tw-text-xs">
              An alternate designer will work on your requests when the primary designer has the following days off:
            </div>
            {leaveDays.map((leaveDay) => (
              <div className="tw-text-xs" key={`${leaveDay.designer.id}-${leaveDay.dayOff}`}>
                {leaveDay.dayOff} - {leaveDay.designer.name}
              </div>
            ))}
          </Notification>
        )}
      </>
    )
  }

  return (
    <>
      <IconButton size="xs" className="tw-relative" color="secondary" onClick={() => setIsOpen(true)}>
        <>
          <BellIcon className="tw-shrink-0" />
          {unreadCountCombined > 0 && <BadgeCount unreadCount={unreadCountCombined} />}
        </>
      </IconButton>

      <Drawer isOpen={isOpen} setOpen={setIsOpen} size="sm">
        <div className="tw-flex tw-h-full tw-w-full tw-flex-col">
          <Tabs
            defaultValue={user?.isInternalUser ? 'myNotifications' : 'platformNotifications'}
            className="tw-flex tw-h-full tw-flex-col"
          >
            <Drawer.Header
              className="tw-border-none tw-pb-0"
              title={
                <>
                  <BellIcon /> Notifications
                </>
              }
              setOpen={setIsOpen}
            >
              {user?.isInternalUser && (
                <TabsList className="tw-mt-6 tw-w-full">
                  <TabsTrigger value="myNotifications">My Notifications</TabsTrigger>
                  <TabsTrigger value="platformNotifications">Platform Notifications</TabsTrigger>
                </TabsList>
              )}
            </Drawer.Header>
            <Drawer.Body className="tw-p-0">
              <TabsContent className="tw-flex-1 tw-overflow-y-auto" value="myNotifications">
                {!isLoading && notifications?.pages?.[0]?.data?.length === 0 && (
                  <div className="tw-py-4 tw-text-center">No notifications</div>
                )}
                {notifications?.pages.map((page) =>
                  page.data?.map((notif, i) => (
                    <Notification
                      key={notif.id}
                      read={notif.read}
                      createdAt={notif.createdAt}
                      className={cn(
                        'tw-px-4 tw-py-3',
                        i !== page.data.length - 1 && 'tw-border-0 tw-border-b tw-border-solid tw-border-gray-200',
                      )}
                    >
                      <NotificationContent notification={notif} />
                    </Notification>
                  )),
                )}
                <div ref={ref} className="tw-h-4" />
              </TabsContent>
              <TabsContent value="platformNotifications">
                <PlatformNotifications />
              </TabsContent>
            </Drawer.Body>
            <div className="tw-sticky tw-bottom-0 tw-left-0 tw-right-0">
              <TabsContent className="tw-p-4" value="myNotifications">
                {isFetchingNextPage && <div className="tw-py-4 tw-text-center">Loading more...</div>}
                <Button
                  className="tw-sticky tw-bottom-0 tw-left-0 tw-right-0 tw-mt-auto tw-w-full"
                  color="purple"
                  onClick={() => markNotificationRead()}
                >
                  Mark all as read
                </Button>
              </TabsContent>
            </div>
          </Tabs>
        </div>
      </Drawer>
    </>
  )
}

export default Notifications
