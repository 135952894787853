import { baseRequest, BaseResponse, getApi } from '../api'

export interface QaSpecialist {
  id: number
  name: string
  state: string
}

export interface QaReview {
  companyId: number
  companyName: string
  createdAt: string
  designerId: number
  designerName: string
  id: number
  finishedAt: string
  qaSpecialist: QaSpecialist
  startedAt: string
  state: string
  ticketId: number
  ticketSubject: string
  ticketStateChangedAt: string
}

export interface QaReviewsResponse extends BaseResponse {
  count: number
  perPage: number
  reviews: QaReview[]
}

export interface QaReviewStatesResponse extends BaseResponse {
  states: object
}

export async function getQaReviews(params): Promise<QaReviewsResponse> {
  const qaReviewsUrl = '/api/internal/qa_reviews'
  const response = await baseRequest(qaReviewsUrl, getApi, params)

  return {
    error: response.error,
    status: response.status,
    count: response.data.count as unknown as number,
    perPage: response.data.per_page as unknown as number,
    reviews: response.data.reviews as unknown as QaReview[],
  }
}

export async function getQaReviewStates(): Promise<QaReviewStatesResponse> {
  const qaReviewStatesUrl = '/api/internal/qa_review_states'
  const response = await baseRequest(qaReviewStatesUrl, getApi)

  return {
    error: response.error,
    status: response.status,
    states: response.data.states as unknown as string[],
  }
}
