export const fileFormatsObj = {
  ai: 'ai',
  indd: 'indd',
  psd: 'psd',
  prproj: 'prproj',
  jpg: 'jpg',
  png: 'png',
  pdf: 'pdf',
  eps: 'eps',
  ppt: 'ppt',
  canva: 'canva',
  gif: 'gif',
  mp4: 'mp4',
  aep: 'aep',
  figma: 'figma',
  mov: 'mov',
  mogrt: 'mogrt',
  key: 'key',
  googleSlides: 'google_slides',
}

export const fileFormats = [
  'ai',
  'indd',
  'psd',
  'prproj',
  'jpg',
  'png',
  'pdf',
  'eps',
  'ppt',
  'canva',
  'gif',
  'mp4',
  'aep',
  'figma',
  'mov',
  'mogrt',
  'key',
  'google_slides',
]
