import { ReactElement, useEffect, useRef, useState } from 'react'
import { ClipboardList, Info, MessagesSquare, Pen } from 'lucide-react'
import IconButton from 'lib/components/buttons/icon-button'
import AnnotationsListContainer from '../annotations/annotations-list-container'
import Directions from '../directions/directions'
import { useAnnotationsContext } from '../providers/annotations-provider'
import { useDirectionsContext } from '../providers/timeline-directions-provider'
import { RevisionsViews, useRequestContext } from '../providers/request-provider'
import RequestDetailsText from '../request-details-text/request-details-text'
import Conversations from '../conversations-deprecated/conversations'
import ConversationsProvider from '../conversations-deprecated/conversations-provider'

interface CurrentViewProps {
  view: RevisionsViews
}

const classNames = {
  scrollable: 'tw-flex-auto tw-overflow-y-auto',
  stationary: 'tw-flex-auto',
  iconContainer: `tw-flex
    tw-flex-col
    tw-border-0
    tw-border-r
    tw-border-solid
    tw-border-gray-200
    tw-p-3
    tw-gap-2
    tw-h-full
    tw-fixed
  `,
  contentContainer: 'tw-px-5 tw-w-full',
}

function CurrentView({ view }: CurrentViewProps): ReactElement {
  const [mountedViews, setMountedViews] = useState<RevisionsViews[]>([])

  const endOfConversationsRef = useRef<HTMLDivElement>(null)

  function scrollConversations(behaviorIsSmooth = false) {
    endOfConversationsRef.current?.scrollIntoView({ behavior: behaviorIsSmooth ? 'smooth' : 'auto' })
  }

  useEffect(() => {
    setMountedViews((previous) => {
      if (!previous.includes(view)) {
        return [...previous, view]
      } else {
        return previous
      }
    })
  }, [view])

  useEffect(() => {
    if (view === 'conversations') {
      scrollConversations()
    }
  }, [view])

  return (
    <>
      <div className={view == 'conversations' ? 'tw-block' : 'tw-hidden'}>
        <ConversationsProvider>
          {mountedViews.includes('conversations') && <Conversations onLoad={scrollConversations} />}
        </ConversationsProvider>
        <div ref={endOfConversationsRef}></div>
      </div>
      <div className={view == 'image-annotations' ? 'tw-block' : 'tw-hidden'}>
        <AnnotationsListContainer />
      </div>
      <div className={view == 'request-details' ? 'tw-block' : 'tw-hidden'}>
        {mountedViews.includes('request-details') && <RequestDetailsText />}
      </div>
      <div className={view == 'directions' ? 'tw-block' : 'tw-hidden'}>
        <Directions />
      </div>
    </>
  )
}

export default function DeprecatedRightPanel(): ReactElement {
  const { showDirectionErrors } = useDirectionsContext()
  const { showRevisions, currentView, setCurrentView } = useRequestContext()
  const { cancelAnnotation, highlightedAnnotation, showAnnotationCreator } = useAnnotationsContext()

  function directionsIconColor() {
    if (showDirectionErrors) {
      return 'danger'
    } else if (currentView === 'directions') {
      return 'primary'
    } else {
      return 'secondary'
    }
  }

  function updateCurrentView(view: RevisionsViews) {
    if (view !== 'image-annotations') {
      cancelAnnotation()
    }
    setCurrentView(view)
  }

  useEffect(() => {
    if (highlightedAnnotation || showAnnotationCreator) {
      setCurrentView('image-annotations')
    }
  }, [highlightedAnnotation, setCurrentView, showAnnotationCreator])

  return (
    <div className={classNames.scrollable}>
      <div className="tw-relative tw-flex tw-h-full">
        <div className={classNames.iconContainer}>
          <>
            <IconButton
              color={directionsIconColor()}
              invert={currentView === 'directions' || showDirectionErrors}
              selected={currentView === 'directions'}
              onClick={() => updateCurrentView('directions')}
              data-testid="directions-button"
            >
              <ClipboardList className="lu-light lu-md" />
            </IconButton>
            <IconButton
              color={currentView === 'image-annotations' ? 'primary' : 'secondary'}
              invert={currentView === 'image-annotations'}
              selected={currentView === 'image-annotations'}
              onClick={() => updateCurrentView('image-annotations')}
              dataTestid="image-annotations-button"
            >
              <Pen className="lu-light lu-md" />
            </IconButton>
          </>
          {showRevisions && (
            <>
              <IconButton
                color={currentView === 'conversations' ? 'primary' : 'secondary'}
                invert={currentView === 'conversations'}
                selected={currentView === 'conversations'}
                onClick={() => updateCurrentView('conversations')}
                dataTestid="conversation-button"
              >
                <MessagesSquare className="lu-light lu-md" />
              </IconButton>
              <IconButton
                color={currentView === 'request-details' ? 'primary' : 'secondary'}
                invert={currentView === 'request-details'}
                selected={currentView === 'request-details'}
                onClick={() => updateCurrentView('request-details')}
                data-testid="request-details-button"
              >
                <Info className="lu-light lu-md" />
              </IconButton>
            </>
          )}
        </div>
        <div className={`${classNames.contentContainer} tw-pl-20`}>
          <CurrentView view={currentView} />
        </div>
      </div>
    </div>
  )
}
