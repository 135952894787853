import Button from 'components/core/button'
import React, { useState } from 'react'

const EditManageOptions: React.FC = () => {
  const [previewModeToggle, setPreviewModeToggle] = useState(true)

  const manageForm = () => {
    const preview = document.getElementById('qr-team-preview-mode')
    const edit = document.getElementById('qr-team-edit-mode')

    if (previewModeToggle) {
      preview.style.display = 'none'
      edit.style.display = 'block'
    } else {
      preview.style.display = 'block'
      edit.style.display = 'none'
    }
    setPreviewModeToggle(!previewModeToggle)
  }

  const onSubmit = () => {
    document.getElementById('qr-team-submit').click()
  }

  return (
    <div>
      {previewModeToggle ? (
        <Button type="button" color="outlineDark" onClick={manageForm}>
          Manage
        </Button>
      ) : (
        <div>
          <Button type="button" className="tw-mr-1" color="outlineDark" onClick={manageForm}>
            Cancel
          </Button>
          <Button type="button" color="green" onClick={onSubmit}>
            Save Changes
          </Button>
        </div>
      )}
    </div>
  )
}

export default EditManageOptions
