import { Table } from '@tanstack/react-table'
import UserAvatar from 'components/elements/user-avatar'
import SelectBox, { SelectBoxOption, SelectBoxValue } from 'lib/components/dropdown/select-box'
import { fileTypeSrc } from 'lib/ticket/utils'
import { columnIds } from '../use-table'
import { fileFormats } from 'lib/constants/file-formats'

export default function FormatFilter<TData>({ table }: { table: Table<TData> }) {
  const column = table.getColumn(columnIds.format)

  const handleChange = (value: SelectBoxValue[]) => {
    column.setFilterValue(value.length > 0 ? value : undefined)
  }

  const options: SelectBoxOption[] =
    fileFormats?.map((fileType) => ({
      value: fileType,
      displayElement: <UserAvatar name={`.${fileType}`} avatar={fileTypeSrc(fileType)} />,
      label: fileType,
    })) || []

  const selectedValue = () => {
    const filterValue = column.getFilterValue()
    if (Array.isArray(filterValue)) {
      return filterValue.map(String)
    }
    return []
  }

  if (!column) return null
  return (
    <SelectBox
      label="Format"
      showLabel={false}
      buttonClassName="tw-min-w-52 tw-max-w-xs"
      placeholder="Format"
      options={options}
      selectedValue={selectedValue()}
      handleChange={handleChange}
      multiple={true}
      searchable={true}
    />
  )
}
