import axios, { AxiosResponse } from 'axios'
import { toast } from 'lib/components/toast/toast'
import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react'

interface ConfirmationModalProviderProps {
  children: ReactNode
}

export type ModalData = {
  title: string
  message: string | ReactNode
  confirmBtnText: string
  confirmAction: () => void
}

export type MenuButtonAction = (
  route: string,
  data: Record<string, unknown>,
  method: string,

  btnModalData?: ModalData,
  okCallback?: () => void,
  finallyCallback?: () => void,
) => void

interface ConfirmationModalContextValue {
  isModalVisible: boolean
  menuButtonAction: MenuButtonAction
  modalData: ModalData
  setModalVisible: Dispatch<SetStateAction<boolean>>
  menuButtonActionWithCallbacks: (okCallback?: () => void, finallyCallback?: () => void) => MenuButtonAction
  confirmAction: (modalData: Omit<ModalData, 'confirmAction'>, onConfirm: () => Promise<void>) => void
}

const ConfirmationModalContext = createContext<ConfirmationModalContextValue | null>(null)

export function useConfirmationModalContext(): ConfirmationModalContextValue {
  const context = useContext(ConfirmationModalContext)
  if (!context) {
    throw new Error('useConfirmationModalContext must be used within a ConfirmationModalProvider')
  }

  return context
}

export default function ConfirmationModalProvider({ children }: ConfirmationModalProviderProps): ReactElement {
  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [modalData, setModalData] = useState<ModalData>({} as ModalData)

  const oops = useCallback((err) => {
    const message = err?.response?.data?.message || 'Oops! Something went wrong. Please try again.'
    toast.error(message)
    console.error(err)
  }, [])

  function confirmAction(modalData: ModalData, onConfirm: () => Promise<void>) {
    setModalVisible(true)
    setModalData({
      ...modalData,
      confirmAction: async () => {
        await onConfirm()
        setModalVisible(false)
      },
    })
  }

  function menuButtonAction(route, data, method, btnModalData = null, okCallback = null, finallyCallback = null) {
    if (btnModalData != null) {
      setModalData(btnModalData)
      setModalVisible(true)
    } else {
      // move this to api file
      axios({
        method,
        url: route,
        data,
      })
        .then((response: AxiosResponse) => {
          if (response.data.status === 'ok') {
            try {
              toast.success(response.data.message as string)
            } catch (error) {
              console.error('Error showing notice:', error)
            }
            okCallback?.()
          } else {
            toast.error(response.data.message as string)
          }
          finallyCallback?.()
        })
        .catch(oops)
      setModalVisible(false)
    }
  }

  const menuButtonActionWithCallbacks = (okCallback, finallyCallback) => (route, data, method, btnModalData) =>
    menuButtonAction(route, data, method, btnModalData, okCallback, finallyCallback)

  const context: ConfirmationModalContextValue = {
    isModalVisible,
    menuButtonAction,
    modalData,
    setModalVisible,
    menuButtonActionWithCallbacks,
    confirmAction,
  }

  return <ConfirmationModalContext.Provider value={context}>{children}</ConfirmationModalContext.Provider>
}
