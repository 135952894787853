import { Permission, customerPermissions, adminPermissions } from './permissions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tabs from 'components/elements/tabs'
import { useState } from 'react'

export default function RolesTable({ isAdmin }: { isAdmin: boolean }): JSX.Element {
  const [permissions, setPermissions] = useState<readonly Permission[]>(customerPermissions)

  return (
    <TableWrapper>
      <div>
        {isAdmin && <AdminBanner />}
        {isAdmin && <AdminTabs setPermissions={setPermissions} permissions={permissions} />}
        <table className="tw-min-w-full">
          <TableHeader />
          <TableBody permissions={permissions} />
        </table>
      </div>
    </TableWrapper>
  )
}

function AdminBanner(): JSX.Element {
  return (
    <div className="tw-rounded tw-bg-skyblue-50 tw-p-4 tw-text-skyblue-800">
      <FontAwesomeIcon icon={['fal', 'exclamation-circle']} className="tw-mr-2 tw-text-skyblue-800" />
      Role for internal DPP user will be determined automatically by job function selected
    </div>
  )
}

function AdminTabs({
  setPermissions,
  permissions,
}: {
  setPermissions: (permissions: readonly Permission[]) => void
  permissions: readonly Permission[]
}): JSX.Element {
  function handleClick(tab: string) {
    if (tab === 'Permissions as customers') setPermissions(customerPermissions)
    if (tab === 'Permissions as DPP internal users') setPermissions(adminPermissions)
  }

  return (
    <div className="tw-border-0 tw-border-b tw-border-solid tw-border-peppercorn-100">
      <Tabs
        tabs={['Permissions as customers', 'Permissions as DPP internal users']}
        activeTab={
          permissions === customerPermissions ? 'Permissions as customers' : 'Permissions as DPP internal users'
        }
        onClick={handleClick}
      />
    </div>
  )
}

function TableHeader(): JSX.Element {
  const classNames = {
    tr: 'tw-border-0 tw-border-solid tw-border-b tw-border-peppercorn-100',
    th: 'tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-uppercase tw-tracking-wide tw-text-gray-500',
  }

  return (
    <thead>
      <tr className={classNames.tr}>
        <th scope="col" className={`tw-pl-4 tw-pr-3 sm:tw-pl-6 ${classNames.th}`}>
          Permissions
        </th>
        <th scope="col" className={`tw-px-3 tw-text-center ${classNames.th}`}>
          Admin
        </th>
        <th scope="col" className={`tw-px-3 tw-text-center ${classNames.th}`}>
          Manager
        </th>
        <th scope="col" className={`tw-px-3 tw-text-center ${classNames.th}`}>
          Member
        </th>
      </tr>
    </thead>
  )
}

function TableWrapper({ children }: { children: JSX.Element }): JSX.Element {
  return (
    <div className="tw-px-4 sm:tw-px-6 lg:tw-px-8">
      <div className="tw-mt-8 tw-flex tw-flex-col">
        <div className="tw--mx-4 tw--my-2 tw-overflow-x-auto sm:tw--mx-6 lg:tw--mx-8">
          <div className="tw-inline-block tw-min-w-full tw-py-2 tw-align-middle md:tw-px-6 lg:tw-px-8">
            <div className="tw-overflow-hidden">{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function TableBody({ permissions }: { permissions: readonly Permission[] }): JSX.Element {
  return (
    <tbody className="tw-bg-white">
      {permissions.map((permission) => (
        <TableRow key={permission.id} permission={permission} />
      ))}
      <tr className="tw-border-0 tw-border-t-2 tw-border-solid tw-border-peppercorn-100"></tr>
    </tbody>
  )
}

function TableRow({ permission }: { permission: Permission }): JSX.Element {
  const isRowHeader: boolean = permission.icon !== null
  const permissionIcon = (permitted: boolean): JSX.Element => {
    if (permitted) {
      return <FontAwesomeIcon icon={['fal', 'check']} className="tw-text-green-500" size="lg" />
    } else {
      return <FontAwesomeIcon icon={['fal', 'times']} className="tw-text-gray-400" size="lg" />
    }
  }
  const classNames = {
    tr: 'tw-border-0 tw-border-solid tw-border-t tw-border-peppercorn-100',
    tdFirst: 'tw-whitespace-nowrap tw-py-1 tw-pl-4 tw-pr-3 tw-text-sm tw-font-medium tw-text-gray-900 sm:tw-pl-6',
    td: 'tw-whitespace-nowrap tw-py-1 tw-px-3 tw-text-sm tw-text-gray-500',
  }

  return (
    <tr className={permission.icon ? classNames.tr : ''}>
      <td className={classNames.tdFirst}>
        <div className={`${isRowHeader ? 'tw-pt-2 tw-text-sm' : 'tw-text-xs'}`}>
          {permission.icon && <FontAwesomeIcon icon={permission.icon} className="tw-mr-2" />}
          {permission.description}
        </div>
      </td>
      <td className={`tw-text-center ${classNames.td}`}>{!isRowHeader && permissionIcon(permission.admin)}</td>
      <td className={`tw-text-center ${classNames.td}`}>{!isRowHeader && permissionIcon(permission.manager)}</td>
      <td className={`tw-text-center ${classNames.td}`}>{!isRowHeader && permissionIcon(permission.member)}</td>
    </tr>
  )
}
