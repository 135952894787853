import { forwardRef, ReactElement, RefObject } from 'react'

export const inputClassNames = {
  common: `
    tw-block
    tw-w-full
    tw-appearance-none
    tw-rounded-md
    tw-border
    tw-border-solid
    tw-py-2.5
    tw-px-4
    tw-my-2.5
    tw-shadow-none
    tw-outline-none
  `,
  error: 'tw-ring-2 tw-ring-flushpink-500',
  noError: 'focus:tw-ring-2 focus:tw-ring-cornflower-500',
  label: 'tw-font-bold tw-mb-0 tw-text-neutral-800',
}

interface TextareaProps {
  className?: string
  id?: string
  label?: string
  rows?: number
  error?: boolean
  maxLength?: number
  resize?: { vertical: boolean; horizontal: boolean }
  [key: string]: unknown
}

interface TextCounterProps {
  className?: string
  value: string
  maxLength?: number
}

function getResizeClassNames(resize: { vertical: boolean; horizontal: boolean }) {
  if (!resize) {
    return 'tw-resize-none'
  }
  if (resize.vertical && resize.horizontal) {
    return 'tw-resize'
  }
  if (resize.vertical) {
    return 'tw-resize-y'
  }
  if (resize.horizontal) {
    return 'tw-resize-x'
  }
}

function Textarea(
  { className = '', id, label, error = false, maxLength = 500, rows = 4, resize, ...otherProps }: TextareaProps,
  ref: RefObject<HTMLTextAreaElement>,
): ReactElement {
  return (
    <>
      {label && (
        <label htmlFor={id} className={inputClassNames.label}>
          {label}
        </label>
      )}
      <textarea
        ref={ref}
        id={id}
        maxLength={maxLength}
        rows={rows}
        {...otherProps}
        className={`${inputClassNames.common} ${
          error ? inputClassNames.error : inputClassNames.noError
        } ${getResizeClassNames(resize)} ${className}`}
      />
    </>
  )
}

export default forwardRef(Textarea)

export function TextCounter({ className = '', maxLength = 500, value }: TextCounterProps): ReactElement {
  return (
    <div className={className} data-testid="text-counter">
      {value.length}/{maxLength}
    </div>
  )
}
