import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/core/button'
import Modal from 'lib/components/modals/modal'
import { getTicketSubjects } from 'lib/api/ticket-files/ticket-file-versions'
import SingleSelect from 'components/core/single-select'

const classNames = {
  button: 'tw-inline-flex tw-w-full tw-justify-center tw-px-4 tw-py-2 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm',
  buttonWrapper: 'tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse',
  cancelButton:
    'tw-bg-peppercorn-50 hover:tw-bg-peppercorn-100 tw-mt-3 tw-inline-flex tw-w-full tw-justify-center tw-px-4 tw-py-2 tw-border-0 sm:tw-mt-0 sm:tw-w-auto sm:tw-text-sm',
  footer: 'tw-flex tw-justify-between',
  searchInputWrapper: 'tw-mt-2',
  searchInput: 'tw-w-full',
  text: 'tw-text-sm tw-text-gray-500',
}

export default function AddToTicketModal({
  open,
  setOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>
  open: boolean
}): JSX.Element {
  const [tickets, setTickets] = useState([])
  const [selectedTicket, setSelectedTicket] = useState(null)
  const mappedTickets = tickets.map((t) => ({ label: t.subject, value: t }))

  async function getSubjects() {
    try {
      const { ticketSubjects } = await getTicketSubjects()
      setTickets(ticketSubjects)
    } catch (err) {
      console.error('There was a problem fetching the subjects', err)
    }
  }

  useEffect(() => {
    if (open) getSubjects()
  }, [open])

  return (
    <Modal open={open} setOpen={setOpen} size="xs">
      <Modal.Header>Add to Request</Modal.Header>
      <Modal.Body setOpen={setOpen} closeButton>
        <div>
          <p className={classNames.text}>
            Pickle Tip: Adding assets to an existing request is only available for those requests that are not in
            progress.
          </p>
        </div>
        <div className={classNames.searchInputWrapper}>
          <SingleSelect
            name="request"
            options={mappedTickets}
            placeholder="Search for an existing request"
            className={classNames.searchInput}
            onChange={(e) => setSelectedTicket(e.value)}
            value={selectedTicket && { label: selectedTicket.subject, value: selectedTicket }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={classNames.footer}>
          <div className={classNames.buttonWrapper}>
            <Button type="button" color="green" onClick={() => setOpen(false)} className={classNames.button} rounded>
              <span>
                <FontAwesomeIcon icon={['far', 'plus']} className="tw-mr-2" />
              </span>
              Start New Request
            </Button>
          </div>
          <div className={classNames.buttonWrapper}>
            <Button type="button" color="purple" onClick={() => setOpen(false)} className={classNames.button}>
              Continue
            </Button>
            <Button
              type="button"
              color="transparent"
              onClick={() => setOpen(false)}
              className={classNames.cancelButton}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
